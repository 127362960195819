import { useEffect } from 'react';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import creditImg from "../../images/payment/other-credit-cards.png";
import paypalImg from "../../images/payment/paypal.png";
// import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';


const CancelPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];

    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' })
        window.scroll(0, 0);
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <Layout>
            {seo &&
                <Helmet>
                    <title>{`${seo['aboutUs']['subscriptionsPortal']['title']}`}</title>
                    <meta name="title" content={`${seo['aboutUs']['subscriptionsPortal']['title']}`} />
                    <meta name="description" content={`${seo['aboutUs']['subscriptionsPortal']['description']}`} />
                </Helmet>
            }
            <WidgetHome selectedCurrency={(e) => { }} />
            <div className={`w-full bg-[url('../src/images/banners/banner-bg.png')] bg-cover text-white h-auto md:h-[340px] flex justify-center items-center`}>
                <div className="w-full h-full bg-[#093484]- bg-opacity-70 py-4">
                    <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex flex-col justify-center items-center">
                        <h1 className='w-full text-white text-lg sm:text-2xl md:text-5xl text-center'>
                            {/* Cancel */}
                            We’re really sorry to see you cancel.
                        </h1>
                        <p className="w-full md:w-2/3 text-center mt-4 text-lg">
                            {/* Cancel your weekly, monthly or yearly donations */}
                            Your recurring donations have been making a real impact and building up your rewards with Allah.
                            If you’d still like to cancel, simply click on the relevant link below to get started:
                        </p>
                    </div>
                </div>
            </div>
            <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center mt-6 mx-6">
                <div className="basis-full md:w-3/4 bg-white rounded-md shadow-md py-7 md:py-20 px-2 md:px-6">
                    <div className="flex flex-col items-center">
                        <img src={paypalImg} alt="MATW" className="max-w-[30%] md:max-w-[15%] mx-auto mb-4" />
                        <h2 className='text-[#00a3da] text-lg md:text-2xl text-center'>For PayPal users</h2>
                        {/* <p className='text-center text-lg text-stone-500'>Log into paypal at <Link className='text-[#00a3da]' to={'https://www.paypal.com/'}>PayPal.com</Link></p> */}
                        <p className='text-center text-lg text-stone-500'>
                            {/* Log into paypal at <Link className='text-[#00a3da]' to={'https://www.paypal.com/'}>PayPal.com</Link> */}
                            Log into your PayPal account at <Link className='text-[#00a3da]' to={'https://www.paypal.com/'}>PayPal.com</Link> and you’ll be able to cancel from there
                            {/* If you see <strong>MATW INTERNATIONAL</strong> for the subscription payment on your statement, please <Link className='text-[#00a3da]' to={'https://www.paypal.com/'}>click</Link> */}
                        </p>
                    </div>
                    <div className="flex flex-col items-center mt-8 border-t border-stone-200 pt-6">
                        <img src={creditImg} alt="MATW" className="max-w-[60%] md:max-w-[30%] mx-auto mb-4" />
                        {/* <h2 className='text-[#00a3da] text-lg md:text-2xl text-center'>For US Account</h2> */}
                        {/* <p className='text-center text-lg text-stone-500 mb-4'>Please click <Link className='text-[#00a3da]' to={'https://billing.stripe.com/p/login/eVa9BwdCHcyY8h2eUU'}>Cancel Subscription</Link></p> */}
                        <h2 className='text-[#00a3da] text-lg md:text-2xl text-center'>For recurring donations by card</h2>
                        <p className='text-center text-lg text-stone-500 mb-2 md:mb-3 w-full md:w-2/3'>
                            If you see <strong>MATW INTERNATIONAL</strong> for the subscription payment on your statement, please click <Link className='text-[#00a3da]' to={'https://billing.stripe.com/p/login/4gw3dB4vwbSFcSc7ss'}>here</Link>
                        </p>
                        <p className='text-center text-lg text-stone-500 mb-2 md:mb-3 w-full md:w-2/3'>
                            If you see <strong>MATW PROJECT</strong> for the subscription payment on your statement, please click <Link className='text-[#00a3da]' to={'https://billing.stripe.com/p/login/9AQbMF2SjeHIdMc000'}>here</Link>
                        </p>
                        <p className='text-center text-lg text-stone-500 mb-2 md:mb-3 w-full md:w-2/3'>
                            If you see <strong>MATW PROJECT USA</strong> for the subscription payment on your statement, please click <Link className='text-[#00a3da]' to={'https://billing.stripe.com/p/login/eVa9BwdCHcyY8h2eUU'}>here</Link>
                        </p>
                        <p className='text-center mt-4 text-lg text-stone-500'>
                            Thank you for all your support, may Allah bless you.
                        </p>

                        {/* <h2 className='text-[#00a3da] text-lg md:text-2xl text-center'>For other donors using card</h2>
                        <p className='text-center text-lg text-stone-500'>Please click <Link className='text-[#00a3da]' to={'https://billing.stripe.com/p/login/4gw3dB4vwbSFcSc7ss'}>Cancel Subscription</Link></p>
                        <p className='text-center text-lg text-[#00a3da]' >OR</p>
                        <p className='text-center text-lg text-stone-500'>Please click <Link className='text-[#00a3da]' to={'https://billing.stripe.com/p/login/9AQbMF2SjeHIdMc000'}>Cancel Subscription</Link></p> */}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CancelPage;
