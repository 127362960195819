import ReactSelect from "react-select";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCurrency, setSelectedCurrencyInStore } from "../../store/user";

const styles = {
    menuList: (base) => ({
        ...base,
        border:'none',
      
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        },
        "& *": {
            cursor: "pointer"
        }
    })
}

const WidgetCurrency = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const dispatch = useDispatch();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const { onChangeCurrency } = props;
    const currencies = [
        { value: 'AUD', label: <span className='text-2xl'>$</span> },
        { value: 'USD', label: <span className='text-2xl'>$</span> },
        { value: 'GBP', label: <span className='text-2xl'>£</span> },
        { value: 'CAD', label: <span className='text-2xl'>$</span> },
        { value: 'EUR', label: <span className='text-2xl'>€</span> },
        // { value: 'AED', label: <span className='text-sm'>AED</span> },
        // { value: 'IDR', label: <span className='text-sm'>IDR</span> },
        // { value: 'SGD', label: <span className='text-sm'>SGD</span> },
        // { value: 'MYR', label: <span className='text-sm'>MYR</span> },
    ];
    const [currentCurrency, setCurrentCurrency] = useState(currencies[0]);
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSelectCurrency = (currency) => {
        setCurrentCurrency(currency);
        onChangeCurrency(currency);
        dispatch(setSelectedCurrencyInStore(currency.value));
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        let temp = {};
        currencies.map((item) => {
            if (item.value === selectedCurrencyStore) {
                temp = item;
            }
            return null
        })
        temp.value && setCurrentCurrency(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    /* -------------------------------------------------------------------------- */
    return (
        <ReactSelect
            options={currencies}
            className='max-h-[48px] [&>*]:!cursor-pointer '
            classNamePrefix="react-widget"
            value={currentCurrency}
            isSearchable={false}
            styles={styles}
            onChange={(e) => handleSelectCurrency(e)}
        />
    );
}

export default WidgetCurrency;
