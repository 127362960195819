import { useEffect } from 'react';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import Accordionn from '../../components/general/accordion';
import clickHereImg from '../../images/others/clickheretoseeourprojects.jpg';
// import liketoSeeImg from '../../images/others/liketoseeusinaction.jpg';
import liketoSeeImg from '../../images/others/liketoseeusinaction.jpg';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';

const FAQsPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const allFaqs = [
    {
      id: 0,
      question: 'Comment pouvons-nous nous impliquer avec MATW ?',
      answer: `Nous adorons entendre parler d'individus passionnés par l'aide aux autres et qui souhaitent soutenir ou faire du bénévolat au nom de MATW.
        Pour faire du bénévolat, veuillez remplir notre formulaire de demande.
        N'oubliez pas que simplement diffuser notre mission et partager notre histoire a également un grand impact !
        Alternativement, pour toute autre demande de collaboration, veuillez appeler notre bureau principal au +612 9758 9037 ou envoyer un email à info@matwproject.org`,
    },
    {
      id: 1,
      question: 'Quels sont les frais administratifs de MATW ?',
      answer: `Chez MATW, nous visons à avoir les frais administratifs les plus bas possibles afin que nos bénéficiaires reçoivent 100 % de vos dons. Nous avons eu la chance de faire fonctionner l'organisation, depuis sa création, sans frais administratifs. Cela est dû à l'engagement financier de nos sponsors. Ces sponsors contribuent aux coûts de fonctionnement de MATW. Nous leur sommes reconnaissants pour leur générosité et leur bienveillance.
        Si vous souhaitez devenir sponsor de MATW et aider aux coûts de fonctionnement de notre organisation, veuillez nous contacter au +612 9758 9037 ou par email à info@matwproject.org`,
    },
    {
      id: 2,
      question: 'Comment puis-je savoir que les fonds sont distribués correctement ?',
      answer: `Chez MATW, notre principal objectif est d'être une organisation ouverte, responsable et transparente. Nous nous efforçons de tenir nos donateurs informés à chaque étape des projets sur lesquels nous travaillons via nos réseaux sociaux ainsi que des mises à jour régulières de projets sur notre site web et nos newsletters.
        Nous valorisons nos soutiens et travaillons avec eux de manière collaborative en leur permettant de choisir les projets auxquels leurs dons seront alloués.
        MATW est une organisation de diligence raisonnable et, en tant qu'organisation à but non lucratif, elle est également soumise à des contrôles réglementaires, y compris des audits obligatoires réalisés tant en interne qu'en externe. Ces audits sont réalisés pour s'assurer que MATW respecte toujours ses obligations légales et veille à ce que nous remplissions nos obligations morales envers nos donateurs.`,
    },
    {
      id: 3,
      question: 'MATW a-t-elle des partenariats ?',
      answer: `Oui ! MATW a établi de nombreux partenariats incroyables depuis sa création, qui sont toujours en cours aujourd'hui. Une des missions de notre fondateur Ali Banat était de créer l'unité entre des organisations partageant les mêmes idées pour augmenter notre impact positif sur le monde !`,
    },

    {
      id: 5,
      question: 'Comment pouvons-nous faire un don à MATW ?',
      answer: `Vous pouvez faire un don via notre site web en utilisant une carte bancaire ou PayPal. Alternativement, vous pouvez envoyer un virement bancaire ou utiliser l'une des plateformes de collecte de fonds disponibles sur notre site.`,
    },
    {
      id: 6,
      question: 'Comment les donateurs internationaux peuvent-ils faire un don à MATW ? Et pouvons-nous faire un don en plusieurs devises ?',
      answer: `Nos donateurs internationaux peuvent faire un don dans la devise de leur choix via Paypal. Cette option de paiement est disponible sur notre site web. Vous n'avez pas besoin d'un compte Paypal ni de créer un compte pour faire un don. Pour toute assistance, veuillez appeler le +612 9758 9037 ou envoyer un email à info@matwproject.org`,
    },
    {
      id: 7,
      question: 'Recevons-nous une confirmation une fois que nous avons fait un don ?',
      answer: `Si vous faites un don sur notre site, vous verrez un message de succès de don lorsque votre paiement sera effectué avec succès.
        Nous vous enverrons également un email de confirmation à l'adresse email que vous avez indiquée dans les 1 à 2 jours ouvrables suivant la réussite de la transaction, contenant une copie de votre reçu de don.
        Si vous avez besoin d'aide supplémentaire, veuillez contacter notre bureau principal au +612 9758 9037 ou par email à info@matwproject.org.`,
    },
    {
      id: 8,
      question: 'Vos projets sont-ils éligibles à la Zakat ?',
      answer: `Nos valeurs chez MATW sont basées sur des principes islamiques.
        Tous nos projets en Afrique sont conformes à la Zakat. Cela inclut les projets suivants :
        Puits d'eau
        Parrainages
        Éducation et scolarité
        Construction de logements (Orphelins et Veuves)
        Distribution de nourriture
        Construction de Masjids
        Cependant, notre projet du village de réfugiés Rohingyas n'est pas conforme à la Zakat.`,
    },
    {
      id: 9,
      question: 'Comment puis-je donner ma Zakat al-mal ?',
      answer: `La Zakat Al-mal peut être donnée via notre site web sous l'onglet ‘Faire un don’. Il existe une catégorie spécifique pour la Zakat Al-mal.`,
    },
    {
      id: 10,
      question: 'Quels projets de MATW sont les mieux adaptés à la Sadaqah Jariyah ?',
      answer: `La Sadaqah Jariyah est une forme de charité continue qui bénéficiera à un individu même après sa mort. Aïcha (ra) rapporte : « Un homme est venu voir le Prophète (SAW) et lui a dit : “Ma mère est morte soudainement et elle n'a pas laissé de testament, mais je pense que si elle avait pu parler, elle aurait donné en charité. Est-ce qu'elle aura une récompense si je donne en son nom ?” Le Prophète (SAW) a répondu : “Oui”. [al-Bukhari – 1388 ; Muslim – 1004] Notre point de vue sur la Sadaqah Jariyah est simple, nous prenons en considération la longévité du don et ses avantages continus. Par exemple, la connaissance a un cycle continu de récompenses, lorsque vous donnez la connaissance, il y a de nombreux bénéficiaires, car la connaissance est infinie et transmise de l'un à l'autre. Les projets MATW suivants sont les plus bénéfiques : Programmes éducatifs (comme l'enseignement, Dawah et la distribution du Coran) ; Construction de puits d'eau ; Construction et entretien des Masjids ; Parrainage d'orphelins ; Parrainage de veuves ; Construction de logements et Parrainage.`,
    },

  ];

  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout>
      {seo && (
        <Helmet>
          <title>{`${seo['resourcesAndInformation']['faq']['title']}`}</title>
          <meta name="title" content={`${seo['resourcesAndInformation']['faq']['title']}`} />
          <meta name="description" content={`${seo['resourcesAndInformation']['faq']['description']}`} />
        </Helmet>
      )}
      <WidgetHome selectedCurrency={e => {}} />
      <div className={`w-full bg-[url('../src/images/others/faq.jpg')] bg-cover text-white h-auto md:h-[340px] flex justify-center items-center`}>
        <div className="w-full h-full bg-[#093484] bg-opacity-70 py-4">
          <div className="md:container md:mx-auto px-2 sm:px-3 h-full flex items-center">
            <h1 className="w-full text-white text-lg sm:text-2xl md:text-5xl text-center">FAQs</h1>
          </div>
        </div>
      </div>
      {/* <div className="md:container md:mx-auto mt-5">
                <div className="grid grid-cols-12 px-3 md:px-4">
                    <h1 className="col-span-12 text-center text-6xl text-[#093484] py-2 md:py-8 font-['Gotcha']">
                        Our Purpose...
                    </h1>
                    <p className="col-span-12 text-center text-xl">
                        Building Legacies with Global impact <br />
                        <span>#Legacy #Impact</span>
                    </p>
                    <div className="col-start-1 col-span-12 rounded-md bg-white p-6 mt-4">
                        <div className="grid grid-cols-12">
                            <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10">
                                <div className="flex flex-row justify-center flex-wrap">
                                    <div className="py-2 flex flex-row flex-wrap">
                                        <div className='basis-full lg:basis-3/4 order-2 lg:order-1 px-2 text-[#777]'>
                                            <h2 className='text-[#00a3da] text-3xl my-4'>A message from the CEO (and Ali’s friend)</h2>
                                            <p className=' mb-3'>Assalamou Alaykum,</p>
                                            <p className=' mb-3'>Going into the 5th year of MATWs operation, I feel very privileged to be writing this message as part of such an amazing and fruitful organisation. This is a special time in our organisations history as we move into what we hope to be the most prosperous year that is 2021.</p>
                                            <p className="">We began our journey January 16, 2016 to which our dedicated team has spent the past 5 years continuing the legacy of my long-time friend and brother, the late Ali Banat (الله يرحمها). Our focus has been strengthening the brand that is MATW, which is identical with our commitment to help those in need through real projects with great impact.</p>
                                            <p className="">As an organisation it is important to start the new year with goals. The goals for MATW moving into the new year are:</p>
                                            <ul className='list-disc pl-4 my-5'>
                                                <li>Maximising the impact of your donations through sustainable developments</li>
                                                <li>Improving the experiences of our donors</li>
                                                <li>Focus on communal involvement and engagement promoting change.</li>
                                                <li>Building equality, equity, and every other thing we do at MATW that are inspired by our values of trustworthiness, efficiency, sustainability, influence, and faith.</li>
                                            </ul>
                                            <p className="pt-5 mb-5">To our ongoing supporters and MATW family we are humbled and thankful for your support over the years and we look forward to continuing working together in changing the lives of those who need us most!</p>
                                            <p>
                                                Sincerely <br />
                                                Mahmoud Ismail<br />
                                                CEO MATW Project <br />
                                            </p>
                                            <img src={signImg} alt="MATW" className='max-w-[200px] mt-5' />
                                        </div>
                                        <div className="basis-full lg:basis-1/4 order-1 lg:order-2 px-2">
                                            <img src={aliFaqImg} alt="Ali banat" className='w-full' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full mt-24 bg-[url('../src/images/banners/MATW-Banner-faq.jpg')] bg-cover text-white py-20">
                <div className="md:container md:mx-auto flex justify-center flex-wrap relative px-4">
                    <img src="../images/general/logo.png" alt="" className='absolute left-0 right-0 -top-[155px] mx-auto w-[150px]' />
                    <p className='my-2 mt-10 text-3xl text-center basis-full md:basis-5/6'>
                        MATW (and why it's not just another charity)
                    </p>
                    <p className="my-2 text-center basis-full md:basis-5/6">
                        We manage all projects ourselves from beginning to end. This means we are directly involved in project execution every step of the way without the involvement of third parties. We use in-house models which means no middle man is involved. If we can’t control it, we won’t do it.
                    </p>
                    <div className="border-t border-[#999] basis-full md:basis-5/6 mt-10 flex flex-wrap">
                        <div className="flex items-center justify-center text-white basis-full md:basis-1/3 px-2 py-4 md:px-4 text-2xl">
                            1. We build legacies
                        </div>
                        <div className="flex items-center justify-center text-white basis-full md:basis-1/3 px-2 py-4 md:px-4 text-2xl">
                            2. We focus on maximum impact
                        </div>
                        <div className="flex items-center justify-center text-white basis-full md:basis-1/3 px-2 py-4 md:px-4 text-2xl">
                            3. We are completely social (media)
                        </div>
                    </div>
                    <p className="basis-full text-center text-white text-2xl mt-10">
                        MATW is big on Social Media and has built its entire global audience on it.
                    </p>
                </div>
            </div>
            <div className="w-full bg-white py-20">
                <div className="md:container md:mx-auto flex justify-center flex-wrap px-4">
                    <div className="grid grid-cols-12">
                        <div className="col-start-1 col-span-12 md:col-start-2 md:col-span-10 flex flex-row justify-center flex-wrap">
                            <div className='basis-full lg:basis-3/4 order-2 lg:order-1 px-2 text-[#777]'>
                                <h2 className='text-[#00a3da] text-3xl my-4'>Why Africa?</h2>
                                <p className='mb-3 text-[#f60362] text-lg'>Africa is one of the top regions in the world that suffers the most from the global water crisis. <br />
                                    In Africa, 45% of deaths in children under the age of 5 is caused by poor nutrition.
                                    In Africa, 650 people die every day (including children) from preventable diseases related to contaminated water.</p>
                            </div>
                            <div className="basis-full lg:basis-1/4 order-1 lg:order-2 px-2">
                                <img src={whyAfricaImg} alt="MATW" className='w-full' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="w-full bg-[url('../src/images/banners/MATW-Home-Banner-Donation-Policy.jpg')] bg-cover text-white py-20">
                <div className="md:container md:mx-auto flex justify-center flex-wrap relative px-4">
                    <img src={donationHeadingImg} alt="Matw" className='max-w-full' />
                    <p className='my-2 mt-10 text-xl text-center basis-full'>
                        Our policy is a simple $ for $ policy. What we receive from donors for projects goes directly to the projects. No admin fees or hidden fees are added!
                        <br />
                        <br />
                        We have a generous network of sponsors that have kindly donated to our day to day admin costs, so you don't have to.
                    </p>

                </div>
            </div> */}
      <div className="w-full py-20">
        <div className="md:container md:mx-auto flex justify-center flex-wrap relative px-4">
          <h2 className="text-[#23408f] text-2xl">FAQ's</h2>
          {allFaqs ? (
            allFaqs.map((item, index) => {
              return (
                <Accordionn key={`keyfaq${index}`} title={item.question} index={index} level={1}>
                  {item.answer}
                </Accordionn>
              );
            })
          ) : (
            <div role="status" className="w-full flex justify-center items-center min-h-[500px]">
              <svg
                aria-hidden="true"
                className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#00a3da]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
      <div className="w-full py-20 bg-white flex flex-col items-center justify-center">
        <img src={liketoSeeImg} alt="Matw" className="max-w-full" />
        <a href="/">
          <img src={clickHereImg} alt="Matw" className="max-w-full" />
        </a>
      </div>
    </Layout>
  );
};

export default FAQsPage;
