import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout'
// import CrisisWidget from '../crisis-and-emergencies/crisisWidget'
import ProductBox from '../../components/general/productBox'
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user'
import { useSelector } from 'react-redux'
// import ZakatWidget from './zakatWidget'
import { zakatFaqs } from './faq'
import { useNavigate } from 'react-router-dom'
import { getProducts } from '../../store/products'
import Accordion from '../../components/general/accordion'
import { useKeenSlider } from 'keen-slider/react'
import QuoteOpen from '../../images/general/quote-open.jpg';
import QuoteClose from '../../images/general/quote-close.jpg';
import Calculator from '../../images/zakat/zakat-calculator.jpg';
import Almal from '../../images/zakat/zakatalmal.jpg';
import Alfit1 from '../../images/zakat/zakat-alfitr1.jpg'
import Alfit2 from '../../images/zakat/zakat-alfitr2.jpg'
import Alfit3 from '../../images/zakat/zakat-alfitr3.jpg'
import Alfit4 from '../../images/zakat/zakat-alfitr4.jpg'
import Alfit5 from '../../images/zakat/zakat-alfitr5.jpg'
import Alfit6 from '../../images/zakat/zakat-alfitr6.jpg'
// import banner from '../../images/zakat/zakat-al-fitr-hero-banner.jpg';
// import bannerMobile from '../../images/zakat/zakat-al-fitr-hero-banner-mobile.jpg';
import banner from '../../images/zakat/Zakat-al-fitr-Last10-Desktop-Hero-Banner.jpg';
import bannerMobile from '../../images/zakat/Zakat-al-fitr-Last10-Mobile-Hero-Banner.jpg';
import { OurPromiseSection } from '../../components/general'
import ZakatAlFitrWidget from './zakatAlfitrWidget'
import { Helmet } from 'react-helmet'
import SeoData from '../../utils/seo.json'
import { generateLinkWithLang } from '../../utils/linkGenerator'
import { useTranslation } from 'react-i18next'
const images = [
    {src:Alfit1, alt:'zakat al-fitr calculator, calculate your zakat al-fitr for 2024'},
    {src:Alfit2, alt:'zakat al fitr 2024 use our zakat al fitr calculator'},
    {src:Alfit3, alt:'zakat ul fitr 2024 calculation for 2024'},
    {src:Alfit4, alt:'zakat fitr 2024 pay your zakat fitr with matw'},
    {src:Alfit5, alt:'how much fitra to pay 2024, calculate how much to pay for 2024'},
    {src:Alfit6, alt:'zakat al fitr calculator use our easy to use calculator'},
];

const ZakatAlFitr = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [ramadanProducts, setRamadanProducts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const products = useSelector((state) => getProducts(state, 'Zakat', 'give zakat'));
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const { i18n } = useTranslation();
    const lang = i18n.language;
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        let p = products.subCategory.products;

        p.forEach(item => {
            if (item.creator === 'RM24-015') {
                tempProductsList.push({ ...item });
                // tempProductsList[1].image_link = '/images/zakat/zakat-al-mal.jpg';

            }
        });

        setRamadanProducts(tempProductsList);
    };


    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    }, [selectedCurrencyStore]);
    useEffect(() => {

        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])

    /* -------------------------------------------------------------------------- */

    return (
        <Layout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['zakat']['zakatAlFitr']['title']}`}</title>
                    <meta name="title" content={`${seo['zakat']['zakatAlFitr']['title']}`} />
                </Helmet>
            }
            <section className="flex justify-center" >
                <img src={banner} alt="Zakat al Fitr 2024: Zakat al-fitr calculator for 2024" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="Zakat ul fitr 2024: Calculate your zakat al fitr" className='min-[501px]:hidden w-full' />
            </section>
            <section className='bg-[#DFEEF2]'>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold hidden sm:block'>Make a Donation</div>
                    <div className='w-full  lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <ZakatAlFitrWidget className={'bg-[#DFEEF2]  h-auto py-6'}
                            defaultProduct={{
                                creator: "RM24-015",
                                label: "Zakat Al Fitr",
                                value: 363
                            }}
                        />
                    </div>
                    {/* <div className='w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center'>
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                            creator: "RM24-015",
                            label: "Zakat Al Fitr",
                            value: 363
                        }} />
                    </div> */}
                </section>
            </section>
            <OurPromiseSection />
            <main className='max-w-[1440px] mx-auto'>
                <section className='xl:px-20 mb-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[315px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px]  w-full text-[30px] sm:text-[50px] md:text-[30px] lg:text-[40px] px-8 text-white text-center font-brandingBold'>Zakat Al-Fitr</div>
                            <p className='text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center'>
                                “The fasting of the month of Ramadan will be hanging between earth and heavens and it will not be raised up to the Divine Presence without paying the Zakat Al-Fitr.”
                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[720px]">
                            <iframe className='video pointer-events-none'
                                width={'100%'}
                                height={'100%'}
                                title={'youtube'}
                                frameBorder={0}
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://www.youtube.com/embed/7tqAADB-CIc?controls=0&autoplay=1&loop=1&mute=1&playlist=7tqAADB-CIc`}
                            >
                            </iframe>
                        </div>

                    </div>
                </section>
                <section className='xl:px-20'>
                    <div className='flex justify-center'>
                        <div className='text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12'>
                            Zakat Al-Fitr <br />
                            (also known as Fitrana or Fitrah) is a <span className='text-[#F60362] mr-2'>charitable donation of the
                                cost of one meal</span>to be given before Eid prayer, before the end of the month of Ramadan.
                        </div>
                    </div>
                    <div className='px-4 md:px-24 mt-2 sm:mt-10  font- leading-5'>
                        <div className='text-[#78716C] leading-6 text-[18px] sm:text-[24px] text-left sm:text-center font-brandingMedium'>
                            At the end of Ramadan, Zakat al-Fitr / Fitrana / Fitrah is a form of charity given to the poor and needy.  Zakat al-Fitr / Fitrana / Fitrah is the cost of one meal or the quantity of one saa' of food.It is required from every member of a household, including any children or elderly persons living there.
                            <br /><br />
                            It is obligatory to pay before the day of Eid. Some people forget to pay it before Eid prayer, so it is always good to pay it as soon as possible

                        </div>
                        {/* <div className='flex justify-center mt-8 '>
                            <button onClick={() => navigate('/zakat-calculator')} className='bg-[#F60362] text-sm sm:text-lg rounded-full w-[455px] text-white h-[50px]'>
                                Calculate and give your Zakat Al Mal here
                            </button>
                        </div> */}

                        <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 mb-6 sm:mb-10">
                            {ramadanProducts.map((item, index) => {
                                return (
                                    <div key={`makedonation${index}`} className="basis-full sm:basis-2/4 md:basis-1/3 xl:basis-1/4 p-2">
                                        <ProductBox product={item} isStaticProduct={item.isStaticProduct || false} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </section>



                <section className='xl:px-20 bg-[#DFEEF2] py-8'>

                    <div className='mb-12'>
                        <div className='flex justify-center pt-4'>
                            <div className='text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12'>
                                Why is Zakat Al-Fitr paid?
                            </div>
                        </div>
                        <div className='px-4 md:px-28 text-[18px] mt-8 leading-5'>
                            <p className='text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] sm:text-[24px]'>
                                The main purpose of Zakat al-Fitr is to provide those who fast with the means of making
                                up for their mistakes during the month of fasting.
                                It also ensures the poor can celebrate Eid al-Fitr with the rest of the Ummah.
                            </p>
                        </div>
                    </div>
                    <div >
                        <div className='flex justify-center px-4 mb-12'>
                            <div className='flex flex-col justify-center items-center bg-white min-h-[500px] sm:min-h-[284px] px-4 py-6 relative sm:w-8/12 rounded-tr-3xl rounded-bl-3xl'>

                                <div className='absolute top-8 left-8'><img src={QuoteOpen} alt="quote" /></div>
                                <div className='absolute right-8 bottom-8'><img src={QuoteClose} alt="quote" /></div>
                                <div className='w-11/12 text-[24px] text-center text-[#14A2DC] leading-7 italic'>
                                    Ibn Abbas narrated that: “The Prophet (saw) made Zakat al-Fitr compulsory so those who fasted may be purified
                                    of their idle deeds and shameful talk and so that the poor may be fed.”

                                    <p className='text-[18px] text-[#AAAAAA] mt-4'>(Abu Dawud)</p>
                                </div >

                            </div>
                        </div>
                        <div className=''>
                            <div className='flex justify-center'>
                                <div className='text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12'>
                                    How do we distribute YOUR Zakat Al-Fitr?
                                </div>
                            </div>
                        </div>
                        <div className='px-4 md:px-28 text-[18px] mt-8 leading-5'>
                            <p className='text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] sm:text-[24px]'>
                                Recipients of Zakat Al-Fitr are the most vulnerable - the poor and those in need.
                                They are also entitled to receive general zakat (Zakat Al Mal). As custodians of
                                this great Amanah, we at MATW act as your charitable agent. We use your Zakat
                                Al-Fitr to buy and distribute food on your behalf.

                            </p>
                        </div>

                    </div>


                </section>


                <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h1 className='text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full mb-10'>Zakat al fitr 2024:<br /> Pay your Zakat al-fitr with MATW</h1>
                    <div className='flex gap-x-6 flex-wrap sm:flex-nowrap'>
                        <div className="basis-full sm:basis-1/2"><img src={Calculator} alt="zakat-cal" /></div>
                        <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                            <h3 className='text-[#253B7E] text-[24px] sm:text-[45px] leading-[55px] font-brandingBold'>Zakat Calculator</h3>
                            <div className='text-[16px] sm:text-2xl text-[#78716C] leading[19px] sm:leading-6 font-brandingMedium my-4'>
                                Calculating your Zakat made easy! By using our MATW Zakat calculator,
                                you can calculate exactly how much Zakat you need to pay.

                            </div>
                            <button onClick={() => navigate(generateLinkWithLang('/zakat-calculator', lang))} className='bg-[#F60362] rounded-full text-white w-[280px] sm:w-[350px] py-3 text-[18px] sm:text-2xl font-bold'>CALCULATE MY ZAKAT</button>
                        </div>
                    </div>
                    <div className='flex mt-10 gap-x-6 flex-wrap sm:flex-nowrap'>
                        <div className="basis-full sm:basis-1/2"><img src={Almal} alt="zakat-almal" /></div>
                        <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                            <h3 className='text-[#253B7E] text-[24px] sm:text-[45px] leading-[55px] font-brandingBold'>Zakat Al-Mal</h3>
                            <div className='text-[16px] sm:text-2xl text-[#78716C] leading[19px] sm:leading-6 font-brandingMedium my-4'>
                                Muslims calculate their Zakat Al-Mal over the course of a year to ensure that
                                any wealth that is in excess of worldly needs, 2.5% is donated to those who are most in
                                need. This Islamic charity is intended to support those in the community who need
                                assistance.

                            </div>
                            <button onClick={() => window.scrollTo(0, 500)} className='bg-[#F60362] rounded-full text-white w-[280px] sm:w-[350px] py-3 text-[18px] sm:text-2xl font-bold'>GIVE ZAKAT</button>
                        </div>
                    </div>

                </section>



                {/* /* --------------------------------- Images --------------------------------- */}
                <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">

                    <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                        <div ref={sliderRefImages} className="keen-slider">
                            {images.map((item, index) => {
                                return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                    {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                    <img src={item.src} alt={item.alt} className=' bg-top object-cover w-full object-top' />
                                </div>
                            })}
                        </div>

                    </div>
                    <div className="w-full  flex justify-center">
                        {loaded && instanceRefImages.current && (
                            <div className="dots flex pt-3 pb-6">
                                {[
                                    ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                ].map((idx) => {
                                    return (
                                        <button
                                            key={idx}
                                            onClick={() => {
                                                instanceRefImages.current?.moveToIdx(idx)
                                            }}
                                            className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                        ></button>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {/* /* -------------------------------------------------------------------------- */}

                {/* -------------------------------------FAQS--------------------------------- */}
                <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h2 className='text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                        Zakat Al Fitr 2024:  <span className='text-[#F60362]'>Donation FAQ’s</span>
                    </h2>
                    <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[0].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {zakatFaqs[0].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[1].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {zakatFaqs[1].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[2].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {zakatFaqs[2].answer}
                            </p>
                        </div>
                    </div>
                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {zakatFaqs.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default ZakatAlFitr;