import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import Accordion from '../../components/general/accordion';
import {  dayOfArafatFaqs } from './faqs';
import ImportanceMuslims1Image from '../../images/landings/qurban/importance-muslims-1.png';
import ImportanceMuslims2Image from '../../images/landings/qurban/importance-muslims-2.png';
import {ReactComponent as ImportanceMuslimsIcon} from '../../images/landings/qurban/importance-muslims-icon.svg';

// import UltimateQurbanWidget from './components/qurbanUltimateWidget';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import banner from '../../images/landings/qurban/day-of-arafat-banner.jpg';
import bannerMobile from '../../images/landings/qurban/day-of-arafat-banner-mobile.jpg';
import { useEffect, useState } from 'react';
import { selectProducts } from '../../store/products';
// import ProductBox from '../../components/general/productBox';
// import { useRef } from 'react';



const importanceToMuslimsTexts = [
  {
    title: "Fast",
    description: "Observing a fast on the Day of Arafat holds immense spiritual significance. Fasting on this day expiates sins from the previous and upcoming year."
  },
  {
    title: "Engage in Dhikr and Dua",
    description: `Fill the day with remembrance and supplication. Recite Dhikhr regularly including "Laa ilaaha ill-Allah" (There is no god but Allah), "Allahu Akbar" (Allah is the Greatest), and "Al-hamdu Lillah" (All praise is to Allah).`
  },
  {
    title: "Give Sadaqah",
    description: "Increase your Sadaqah. If you haven’t given your Qurban/Qurbani donation, now is the time to do this."
  },
  {
    title: "Seek Forgiveness",
    description: "Reflect on past actions and seek forgiveness from Allah for any shortcomings or mistakes committed knowingly or unknowingly. Dua' is a powerful tool for seeking forgiveness and purifying the soul."
  },
  {
    title: "Express Gratitude",
    description: "Take a moment to appreciate the blessings Allah has bestowed upon us. Express gratitude by performing acts of kindness, helping others, and maintaining a positive outlook."
  },
  {
    title: "Offer Extra Prayers",
    description: "Increase your Sadaqah. If you haven’t given your Qurban/Qurbani donation, now is the time to do this."
  },
]

const QurbanDayOfArafatLandingPage = ({ title }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  // const selectedCurrency = useSelector(selectSelectedCurrency)
  const products = useSelector(state => selectProducts(state));
  // const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0]);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [activeTab, setActiveTab] = useState(0);
  const seo = SeoData[selectedcountryCode];
  // const contentRef = useRef()
  /* -------------------------------------------------------------------------- */
/* ------------------------------ Start Methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempPalestineEmergencyProducts = [];

    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          // Checkbox products
          // if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01' && inner_item_product.name === 'Palestine Appeal') {
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            tempPalestineEmergencyProducts[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            tempPalestineEmergencyProducts[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            tempPalestineEmergencyProducts[2] = inner_item_product;
          }
        });
      });
    });
    // setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout className="!bg-white" showRegularImg={false}>
      {seo && (
        <Helmet>
          <title>{`${seo[title.toLowerCase()]['title']}`}</title>
          <meta name="title" content={`${seo[title.toLowerCase()]['title']}`} />
          <meta name="description" content={`${seo[title.toLowerCase()]['description']}`} />
        </Helmet>
      )}
      {/* /* -------------------------------------------------------------------------- */}

      { /* --------------------------- Widget Section ----------------------------------- */}
       <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
          <CrisisWidget
              ramadanMobileWidget
              className={'bg-[#DFEEF2] h-auto'}
              fixPrice={false}
              defaultProduct={{
                  creator: 'EMR-DIS-PSE-2023-01',
                  label: 'Save Lives In Palestine',
                  value: 255,
              }}
          />
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="flex justify-center" >
          <img src={banner} alt="banner" className='max-[500px]:hidden w-full' />
          <img src={bannerMobile} alt="banner" className='min-[501px]:hidden w-full' />
      </section>
      <section className='bg-[#DFEEF2]'>
          <section className='max-w-[1440px] mx-auto'>
              <div className='w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center'>
                  <CrisisWidget
                      floating
                      className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                      defaultProduct={{
                        creator: "MKD-MN-001",
                        label: "Where Most Needed",
                        value: 122
                    }}
                  />
              </div>
          </section>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      <div className='flex flex-col-reverse md:flex-col'>
        {/* ------------------------------- Header Section ---------------------------- */}
        <section className='md:container md:mx-auto my-8 md:my-12 text-center px-5 lg:px-0'>
          <h1 className='font-brandingBold text-[30px] md:text-[40px] text-[#253B7E] leading-[32px] md:leading-[42px]'>The <span className='text-[#F60362]'>Day of Arafat (or Arafah)</span> symbolizes the ultimate journey of submission and devotion to Allah SWT. The day falls on the 9th day of Dhul-Hijjah, within the 10 best days of the entire year.</h1>
          <p className='mt-8 font-brandingBoldItalic text-lg md:text-2xl italic text-[#14A2DC]'>
            The Prophet Muhammad ﷺ emphasized the significance of this day, stating that fasting on the Day of Arafat expiates sins of the previous and upcoming year.
          </p>
          <p className='mt-4 font-brandingBoldItalic text-lg md:text-2xl italic text-[#253B7E]'>
            This year (2024) the Day of Arafat in 2024 is expected to fall on Saturday 15th June.
          </p>
        </section>

        {/* ------------------------------- Products Section ---------------------------- */}
        {/* <section className="bg-[#253B7E]" ref={contentRef}>
          <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12 flex flex-col">
            <div className="flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center mb-8">
              { ['Ultimate Qurban', 'Palestine Emergency'].map((item, index) => (
                <button
                  key={`btn_${index}`}
                  onClick={() => {
                    window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 90 })
                    setActiveTab(index);
                  }}
                  className={`${index === activeTab ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} text-base rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase`}>
                  {item}
                </button>
              ))
              }
            </div>
            <h1 className="w-full text-white text-lg  leading-[20px] md:text-[30px] md:leading-[30px] text-center font-bold mb-4 md:mb-8">
              For Muslims all over the world, the Day of Arafat is a unique opportunity to increase our Ibadah, repentance and rewards as Allah showers His Mercy upon us.
            </h1>
            {activeTab === 0 && <UltimateQurbanWidget title={title} />}
            {activeTab === 1 && <div className='hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4'>
                {palestineEmergencyProducts.map((item, index) => (
                  <div key={`products__${index}`} className="w-full basis-1/3 lg:basis-1/4 max-w-[300px]">
                    <ProductBox
                      stateVariableObj={{ productName: item.name }}
                      product={item}
                      linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : ''}
                      isStaticProduct={item.isStaticProduct}
                      staticLink={item.staticLink}
                      staticLinkText={item.staticLinkText}
                      currency={selectedCurrency || 'AUD'}
                      titleClasses={`uppercase`}
                    />
                  </div>
                ))}
              </div>
            }
            <div className='hidden md:block text-lg text-white mt-12'>
              <h2 className='text-[40px] font-brandingBold  text-center'>The significance of The Day of Arafat</h2>
              <p className='italic font-brandingBold mt-5'>
                “ This day have I perfected your religion for you, completed my favor upon you, and have chosen for you Islam as your religion” (5:3)
              </p>
              <p className='font-brandingMedium mt-2'>
                Not only was this the day that Allah SWT perfected Islam as our religion, the day of Arafat also commemorated the final sermon delivered by our beloved Prophet Muhammad ﷺ known as his Farewell Sermon.
              </p>
            </div>
          </div>
        </section> */}
        {/* /* -------------------------------------------------------------------------- */}
      </div>

      {/* /* --------------------------------- The Importance to Muslims Section ---------------------------------  */}
      <section className='md:container md:mx-auto my-8 md:my-12 px-5 lg:px-0'>
        <h2 className="text-[#253B7E] text-center text-[30px] sm:text-[40px] font-brandingBold">
          The Importance to Muslims
        </h2>
        <p className='text-[#78716C] text-lg font-brandingMedium'>The day of Arafat marks the second day of Hajj. It is on this day that millions of Muslims around the world reflect, seek forgiveness from Allah SWT, make dua and perform as many good deeds as possible.</p>

        <div className='grid grid-cols-1 lg:grid-cols-5 md:gap-x-6 lg:gap-x-8 xl:gap-x-12 mt-8 md:mt-12'>
          <div className='lg:col-span-2 flex flex-col gap-y-2.5'>
            <img src={ImportanceMuslims1Image} alt="The Importance to Muslims" className='w-full h-full object-cover rounded-[10px] md:aspect-[7/5] lg:aspect-[7/6]'/>
            <img src={ImportanceMuslims2Image} alt="The Importance to Muslims" className='hidden lg:block w-full h-full object-cover rounded-[10px] aspect-[7/6]'/>
          </div>
          <div className='lg:col-span-3 flex flex-col gap-y-5 lg:gap-y-[30px] mt-5 lg:mt-0'>
            <h3 className='font-brandingBold text-[30px] md:text-[40px] leading-[32px] md:leading-[42px] text-[#093686] max-w-[440px]'><span className='text-[#F60362]'>Maximise</span> your <span className='text-[#F60362]'>rewards</span> The Day of Arafat</h3>
            {importanceToMuslimsTexts.map((item, index) => (
              <div key={index} className='flex gap-x-5 lg:gap-x-[30px]'>
                <ImportanceMuslimsIcon />
                <div className='flex flex-col gap-y-2.5 w-fit'>
                  <h4 className='text-[#253B7E] font-brandingBold text-lg leading-5'>{item.title}</h4>
                  <p className='text-[#78716C] text-base lg:text-lg font-brandingMedium leading-normal'>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <img src={ImportanceMuslims2Image} alt="The Importance to Muslims" className='lg:hidden w-full h-full object-cover rounded-[10px] aspect-[7/6] mt-5'/>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Read Quran section ---------------------------------  */}
      <section className={`py-5 lg:py-[50px] px-5 lg:px-0 bg-[url('../src/images/landings/qurban/pattern.png')]`}>
        <div className='md:container md:mx-auto my-8 md:my-12 bg-white rounded-2xl p-5 lg:p-[50px] text-center'>
            <h1 className='text-[30px] lg:text-[40px] leading-[32px] lg:leading-[42px] text-[#F60362] font-gotcha'>Read the Quran:</h1>
            <h2 className='text-[30px] lg:text-[40px] leading-[32px] lg:leading-[42px] text-[#253B7E] font-gotcha'>Spend time reading and reflecting upon the verses of the Quran.</h2>
            <p className='font-brandingMedium text-lg lg:text-[24px] text-[#78716C] mt-[30px]'>The Day of Arafat stands as a testament to the essence of Islam - submission, devotion, and mercy. It serves as a reminder of the unity of our Ummah.</p>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- FAQ’s Section ---------------------------------  */}
      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h2 className="text-[#253B7E] text-left  sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-brandingBold">
          Day of Arafat <span className="text-[#F60362]"> FAQ’s</span>
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 md:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{dayOfArafatFaqs[0].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{dayOfArafatFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 md:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{dayOfArafatFaqs[1].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{dayOfArafatFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 md:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{dayOfArafatFaqs[2].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C] whitespace-pre-line">{dayOfArafatFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {dayOfArafatFaqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion
                    key={`keyallfaq${index}`}
                    shadow
                    title={item.question}
                    index={index}
                    level={1}
                    noBorder={true}
                    className="!text-[#253B7E] text-lg font-brandingBold"
                  >
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

    </Layout>
  );
};

export default QurbanDayOfArafatLandingPage;