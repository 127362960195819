// import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

const CategoryBox = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const ref = useRef(null);
  const { category, buttonTitle, pathName, onClick } = props;
  const [showMore, setShowMore] = useState(false);
  const [pathNames, setPathNames] = useState();
  const [isOverflow, setIsOverflow] = useState(false);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleHeight = () => {
    setShowMore(!showMore);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // console.log("ref.current.clientHeight", ref.current.clientHeight)
    category && ref.current.clientHeight > 80 && setIsOverflow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // console.log("pathname useeffect", pathName)
    if (pathName) {
      if (pathName.charAt(pathName.length - 1) === '/') {
        setPathNames(pathName.substring(0, pathName.length - 1))
      } else {
        setPathNames(pathName)
      }
    }
    // setPathName(_pathName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full p-2 pb-4 rounded-xl shadow-lg bg-white cursor-pointer">
      <div className="max-h-[270px]- overflow-hidden rounded-md flex justify-center items-center md:w-[218px] md:h-[218px] lg:w-[224px] lg:h-[224px] xl:w-[288px] xl:h-[288px] 2xl:w-[352px] 2xl:h-[352px]" onClick={() => onClick(category?.id)}>
        {category ? category?.image_link ? (
          <LazyLoadImage
            alt={category.name}
            effect="blur"
            width={`100%`}
            delayTime={500}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
            }}
            delayMethod="debounce"
            src={category.image_link} // use normal <img> attributes as props
          />
        ) : (
          <img className="w-full" src="/images/general/logo-replace.png" alt="" />
        ) : <div className='w-full'><Skeleton className='md:w-[218px] md:h-[218px] lg:w-[224px] lg:h-[224px] xl:w-[288px] xl:h-[288px] 2xl:w-[352px] 2xl:h-[352px]' /></div>}
      </div>
      <h3 className="text-[#00a3da] text-sm sm:text-md md:text-md lg:text-lg xl:text-xl text-center font-medium h-[32px] sm:h-auto md:h-[32px] lg:h-auto leading-4 my-2 cursor-pointer transition-all duration-200 hover:text-[#3189c0]">
        {category ? category?.name : <Skeleton height={24} />}
      </h3>
      {/* <div className='h-[80px]- overflow-hidden pb-2 relative'> */}
      {/* <h5 className={`text-[#777] text-center min-h-[80px] overflow-hidden text-sm leading-4 lg:leading-5 font-normal cursor-pointer transition-all duration-200`}>
                    {category.page_title}
                </h5> */}
      {/* <h5 className={`text-[#777] text-center min-h-[60px] ${showMore ? 'h-auto' : 'max-h-[60px]'} overflow-hidden text-sm leading-4 md:leading-5 font-normal cursor-pointer transition-all duration-200`}>
                    {category.page_title}
                </h5> */}
      {/* {category.page_title.split(' ').length > 16 && <span className='text-[#ccc] text-center block w-full absolute cursor-pointer bottom-1' onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less' : 'Show more'}</span>} */}
      {/* </div> */}
      <div className="relative">
        {category ? <>
          <div className={`text-center my-2 relative overflow-hidden ${showMore ? 'h-auto' : 'max-h-[80px]'} ${category?.page_title?.split(' ').length > 30 ? 'mb-4' : 'mb-4'}`}>
            <p ref={ref} className={`text-stone-400 text-xs sm:text-sm md:text-base lg:text-md overflow-hidden overflow-y-scroll- transition-all duration-200 min-h-[79px] `}>
              {category?.page_title}
            </p>
          </div>
          {isOverflow && (
            <span className="text-[#00a3da] block w-full absolute cursor-pointer -bottom-4 text-center" onClick={() => handleHeight()}>
              {showMore ? 'Show less' : 'Show more'}
            </span>
          )}
        </> : <Skeleton count={4} />}
      </div>
      <div className="grid grid-cols-6 gap-1 2xl:gap-2">
        {category ? buttonTitle && (
          <Link to={`${pathNames}/${category.name.replace(/ /g, "-").toLowerCase()}`} className='text-white text-sm md:text-md lg:text-xl col-span-6 xl:col-span-4 xl:col-start-2 flex rounded-md transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] mr-2 cursor-pointer px-2 py-2 justify-center items-center'
          // onClick={(e) => {e.preventDefault();onClick(category.id)}}
          >
            {buttonTitle}
          </Link>
        ) : <Skeleton height={39} />}

      </div>
    </div>
  );
};

export default CategoryBox;
