import { useEffect } from 'react';
// import Layout from "../components/general/layout";
import Layout from '../../components/general/layout';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';

const GDPR = () => {
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];

  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      {seo && (
        <Helmet>
          <title>{`${seo['aboutUs']['gdpr']['title']}`}</title>
          <meta name="title" content={`${seo['aboutUs']['gdpr']['title']}`} />
          <meta name="description" content={`${seo['aboutUs']['gdpr']['description']}`} />
        </Helmet>
      )}
      <div className="bg-white pb-10">
        <div className="w-full bg-opacity-70 gdpr_banner text-center">
          <h1 className="text-white text-5xl font-Roboto font-extrabold">Politique de Confidentialité pour MATW</h1>
          <p className="mt-4 text-white leading-10 px-16 text-lg">
            Chez MATW Australia, l'une de nos principales priorités est la confidentialité de nos visiteurs. Ce document de Politique de
            Confidentialité contient les types d'informations collectées et enregistrées par MATW Australia et comment nous les utilisons. Si vous
            avez des questions supplémentaires ou avez besoin de plus d'informations sur notre Politique de Confidentialité, n'hésitez pas à nous
            contacter.
          </p>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Règlement Général sur la Protection des Données (RGPD)</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">Nous sommes un Contrôleur des Données de vos informations.</p>
            <p className="mt-4 leading-10">
              La base juridique de MATW pour collecter et utiliser les informations personnelles décrites dans cette Politique de Confidentialité
              dépend des informations personnelles que nous collectons et du contexte spécifique dans lequel nous collectons ces informations :
            </p>
            <ul className="marker:text-green mt-4 leading-10 list-disc list-outside ml-6">
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">MATW doit exécuter un contrat avec vous</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Vous avez donné votre permission à MATW</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Le traitement de vos informations personnelles est dans l'intérêt légitime de MATW</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">MATW doit se conformer à la loi</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">
                  MATW conservera vos informations personnelles uniquement aussi longtemps que nécessaire aux fins énoncées dans cette Politique de
                  Confidentialité. Nous conserverons et utiliserons vos informations dans la mesure nécessaire pour nous conformer à nos obligations
                  légales, résoudre des litiges et faire respecter nos politiques.
                </span>
              </li>
            </ul>
            <p className="mt-4 leading-10">
              Si vous résidez dans l'Espace Économique Européen (EEE), vous avez certains droits en matière de protection des données. Si vous
              souhaitez être informé des informations personnelles que nous détenons sur vous et si vous souhaitez qu'elles soient supprimées de nos
              systèmes, veuillez <span className="text-lg text-[#666]">nous contacter.</span>
            </p>
            <p className="mt-4 text-lg text-[#666]">
              Dans certaines circonstances, vous avez les droits suivants en matière de protection des données :
            </p>
            <ul className="mt-4 leading-10 list-disc ml-3">
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Le droit d'accès, de mise à jour ou de suppression des informations que nous détenons sur vous</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Le droit de rectification.</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Le droit de s'opposer.</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Le droit de limitation.</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Le droit à la portabilité des données</span>
              </li>
              <li className="text-[#00aeef]">
                <span className="text-[#7A7A7A]">Le droit de retirer son consentement</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Fichiers Journaux</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              MATW Australia suit une procédure standard d'utilisation des fichiers journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils
              visitent des sites web. Toutes les sociétés d'hébergement font cela et cela fait partie des services d'analyse des hébergeurs. Les
              informations collectées par les fichiers journaux incluent les adresses de protocole Internet (IP), le type de navigateur, le
              fournisseur de services Internet (ISP), la date et l'heure, les pages de référence/sortie et éventuellement le nombre de clics. Ces
              informations ne sont pas liées à des informations personnellement identifiables. Le but de ces informations est d'analyser les
              tendances, de gérer le site, de suivre les déplacements des utilisateurs sur le site et de recueillir des informations démographiques.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Cookies et Balises Web</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              Comme tout autre site web, MATW Australia utilise des ‘cookies’. Ces cookies sont utilisés pour stocker des informations, notamment les
              préférences des visiteurs et les pages du site web que le visiteur a consultées ou visitées. Les informations sont utilisées pour
              optimiser l'expérience des utilisateurs en personnalisant le contenu de notre page web en fonction du type de navigateur des visiteurs
              et/ou d'autres informations.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Politiques de Confidentialité</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              Vous pouvez consulter cette liste pour trouver la Politique de Confidentialité de chacun des partenaires publicitaires de MATW
              Australia.
            </p>
            <p className="mt-4 leading-10">
              Les serveurs publicitaires ou réseaux publicitaires tiers utilisent des technologies telles que les cookies, JavaScript ou les balises
              Web qui sont utilisées dans leurs publicités et liens respectifs qui apparaissent sur MATW Australia, qui sont envoyés directement au
              navigateur des utilisateurs. Ils reçoivent automatiquement votre adresse IP lorsque cela se produit. Ces technologies sont utilisées
              pour mesurer l'efficacité de leurs campagnes publicitaires et/ou pour personnaliser le contenu publicitaire que vous voyez sur les sites
              web que vous visitez. Notez que MATW Australia n'a aucun accès ou contrôle sur ces cookies qui sont utilisés par des annonceurs tiers.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Politiques de Confidentialité des Tiers</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              La Politique de Confidentialité de MATW Australia ne s'applique pas aux autres annonceurs ou sites web. Ainsi, nous vous conseillons de
              consulter les Politiques de Confidentialité respectives de ces serveurs publicitaires tiers pour plus d'informations. Cela peut inclure
              leurs pratiques et instructions sur la façon de se désinscrire de certaines options.
            </p>
            <p className="mt-4 leading-10">
              Vous pouvez choisir de désactiver les cookies via les options individuelles de votre navigateur. Pour obtenir plus d'informations sur la
              gestion des cookies avec des navigateurs web spécifiques, celles-ci peuvent être trouvées sur les sites web respectifs des navigateurs.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Informations pour les Enfants</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              Une autre partie de notre priorité est d'ajouter une protection pour les enfants lors de l'utilisation d'Internet. Nous encourageons les
              parents et les tuteurs à observer, participer et/ou surveiller et guider leur activité en ligne.
            </p>
            <p className="mt-4 leading-10">
              MATW Australia ne collecte pas sciemment d'Informations Personnellement Identifiables auprès d'enfants de moins de 13 ans. Si vous
              pensez que votre enfant a fourni ce type d'informations sur notre site web, nous vous encourageons fortement à nous contacter
              immédiatement et nous ferons de notre mieux pour supprimer rapidement ces informations de nos dossiers.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Politique de Confidentialité en Ligne Seulement</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              Notre Politique de Confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site web en
              ce qui concerne les informations qu'ils ont partagées et/ou collectées dans MATW Australia. Cette politique ne s'applique pas aux
              informations collectées hors ligne ou via des canaux autres que ce site web.
            </p>
          </div>
        </div>
        <div className="w-full pl-32 pr-32 mt-8 font-lato">
          <h2 className="text-[#00aeef] text-3xl font-extrabold font-Roboto">Consentement</h2>
          <div className="font-semibold text-[#7A7A7A]">
            <p className="mt-4 leading-10">
              En utilisant notre site web, vous consentez par la présente à notre Politique de Confidentialité et acceptez ses termes.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GDPR;
