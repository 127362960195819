import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react';
import VideosSection from '../../components/general/videosSection';
import { selectProducts } from '../../store/products';
import WidgetHome from '../../components/widgets/widgetHome';
import { OurPromiseSection } from '../../components/general';
import Accordion from '../../components/general/accordion';
import SpecialCasesTab from './specialCasesTabs';
import ImagesSection from '../crisis-and-emergencies/components/common-sections/images';
import specialCasesSlider1 from '../../images/landings/special-cases/gallery-image-1.jpg';
import specialCasesSlider2 from '../../images/landings/special-cases/gallery-image-2.jpg';
import specialCasesSlider3 from '../../images/landings/special-cases/gallery-image-3.jpg';
import { useTranslation } from 'react-i18next';
import { specialCasesFaqs } from './faq';

export const videos = [
  {
    link: 'https://www.youtube.com/embed/i4iwUlD36uo',
    title: '',
  },
  {
    link: 'https://www.youtube.com/embed/zqq4p3Khbw8',
    title: '',
  },
  {
    link: 'https://www.youtube.com/embed/_Zle84F3Gdc',
    title: '',
  },
];

const SpecialCasesLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [specialCasesProducts, setSpecialCasesProducts] = useState([0, 0, 0]);
  const images = [
    { src: specialCasesSlider1, alt: 'Special Cases' },
    { src: specialCasesSlider2, alt: 'Special Cases' },
    { src: specialCasesSlider3, alt: 'Special Cases' },
  ];
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSpecialCasesProducts = [];
    const specialCasesProgramFundProduct = {
      title: 'Special Cases Program Fund',
      description:
        'Our Special Case Program Fund will help provide urgent support to specific emergency cases for those in need. Whether it be a surgery, family support or shelter assistance - our team assesses every case to ensure your donations are reaching those in dire need. Your generosity can bring hope and change to those who need it most.',
      products: [],
    };
    const hopeLineMedicalSupportProduct = {
      title: 'Hope Line Medical Support',
      description:
        'Hope Line Medical Fund is saving lives by supporting urgent medical funding, transportation, and essential supplies for those in crisis. Each case is compassionately assessed by our dedicated team to ensure your contribution reaches those who need it most, providing timely, critical care.',
      products: [],
    };
    const homeOfHopeProduct = {
      title: 'Home of Hope',
      description:
        'Our "Home for Hope" Fund provides safe, stable homes for those in need. Through this, we offer immediate shelter, rental assistance, and essential furnishings. We also renovate homes to create safe havens. This program supports low-income families, single parents, the elderly, and refugees fleeing a crisis, giving them hope and a fresh start.',
      products: [],
    };
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'SPC-PRG-24-01') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Basic Contribution' });
            specialCasesProgramFundProduct.products[0] = inner_item_product;
            specialCasesProgramFundProduct.img = inner_item_product.image_link;
          } else if (inner_item_product.creator === 'SPC-PRG-24-02') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Support Contribution' });
            specialCasesProgramFundProduct.products[1] = inner_item_product;
          } else if (inner_item_product.creator === 'SPC-PRG-24-03') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Major Contribution' });
            specialCasesProgramFundProduct.products[2] = inner_item_product;
          } else if (inner_item_product.creator === 'SPC-PRG-24-04') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Basic Contribution' });
            hopeLineMedicalSupportProduct.products[0] = inner_item_product;
            hopeLineMedicalSupportProduct.img = inner_item_product.image_link;
          } else if (inner_item_product.creator === 'SPC-PRG-24-05') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Sustaining Contribution' });
            hopeLineMedicalSupportProduct.products[1] = inner_item_product;
          } else if (inner_item_product.creator === 'SPC-PRG-24-06') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Major Contribution' });
            hopeLineMedicalSupportProduct.products[2] = inner_item_product;
          } else if (inner_item_product.creator === 'SPC-PRG-24-07') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Basic Contribution' });
            homeOfHopeProduct.products[0] = inner_item_product;
            homeOfHopeProduct.img = inner_item_product.image_link;
          } else if (inner_item_product.creator === 'SPC-PRG-24-08') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Support Contribution' });
            homeOfHopeProduct.products[1] = inner_item_product;
          } else if (inner_item_product.creator === 'SPC-PRG-24-09') {
            inner_item_product = Object.assign({}, inner_item_product, { name: 'Empowering Contribution' });
            homeOfHopeProduct.products[2] = inner_item_product;
          }
        });
      });
    });
    setSpecialCasesProducts([specialCasesProgramFundProduct, hopeLineMedicalSupportProduct, homeOfHopeProduct]);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['special-cases']['title']}`}</title>
          <meta name="title" content={`${seo['special-cases']['title']}`} />
          <meta name="description" content={`${seo['special-cases']['description']}`} />
        </Helmet>
      )}

      <section className="xs:block md:hidden sticky top-16 z-30">
        <WidgetHome
          suggestedPrices={[50, 100, 250]}
          selectedPrice={50}
          defaultProduct={{ creator: 'EMR-WNT-GEN-24-01', label: 'Emergency Winter Appeal', value: 713 }}
        />
      </section>

      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="xs:hidden md:block sticky top-16 lg:top-24 z-30">
        <WidgetHome
          suggestedPrices={[50, 100, 250]}
          selectedPrice={50}
          defaultProduct={{ creator: 'EMR-WNT-GEN-24-01', label: 'Emergency Winter Appeal', value: 713 }}
        />
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <SpecialCasesTab specialCasesProducts={specialCasesProducts} lang={lang} />
      {/* ----------------- videos section ---------------- */}
      <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
      {/* ----------------- videos section --END-------------- */}

      {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1
          className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${
            lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'
          }`}
        >
          <span className="text-[#f60362] mr-1">Our Programs:</span> Immediate and Impactful Assistance
        </h1>
        <div
          className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${
            lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'
          }`}
        >
          Our programs are meticulously crafted to provide swift and significant aid to individuals facing critical situations, including refugees,
          the homeless, and those in medical emergencies. These efforts are more than just temporary fixes; they are designed to offer lasting
          solutions and genuine hope for the future.
          <br />
          <br />
          Each initiative is carefully targeted to address the specific needs of our beneficiaries. For refugees, we focus on providing safe shelter,
          food, and medical care, helping them rebuild their lives in new environments. For the homeless, we offer not just temporary housing but
          pathways to long-term stability through job training, mental health support, and access to permanent housing solutions. For individuals in
          medical emergencies, we ensure timely medical interventions, ongoing treatment, and rehabilitation support, aiming to restore health and
          well-being.
          <br />
          <br />
          The backbone of our success lies in the generous support and donations from compassionate individuals like you. Your contributions enable us
          to act swiftly and effectively, making a tangible difference in the lives of those who need it most. Every dollar donated goes directly
          towards providing essential resources and support, creating a ripple effect of positive change.
          <br />
          <br />
          Through our dedicated efforts, we strive to not only meet immediate needs but also to empower individuals to rebuild their lives and regain
          hope. By addressing the root causes of their hardships and offering sustainable solutions, we ensure that our beneficiaries have the tools
          and support they need to move forward with confidence.
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection images={images} />
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* --------------------------------- FAQ’s --------------------------------- */}
      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h1 className="text-[#253B7E] md:text-center mb-5 md:mb-6 lg:mb-8 text-[30px] sm:text-[48px] font-montserratBold">
          Special Cases Program <span className="text-[#F60362]"> FAQ’s</span>
        </h1>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{specialCasesFaqs[0].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{specialCasesFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{specialCasesFaqs[1].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{specialCasesFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{specialCasesFaqs[2].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{specialCasesFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {specialCasesFaqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>

      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default SpecialCasesLandingPage;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${
        props.bg ? props.bg : 'bg-[#F60362]'
      } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${
        props.className ? props.className : ''
      } ${props.left ? 'arrow--left left-[8px]' : 'arrow--right left-auto right-[8px]'}`}
      onClick={props.onClick}
    >
      {props.icon ? (
        <img alt="arrow-img" src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-1' : '-ml-1'} `} />
      ) : (
        <svg
          className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
          {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
      )}
    </div>
  );
}
