import { useContext } from "react";
import RouteContext from "./../context/route";
const useBreadCrumb = () => {
    const { routeState, setRouteState } = useContext(RouteContext);
    return {
        pushRoute: (name, id) => {
            setRouteState([...routeState, { name, id }])
        },
        insertRoute: (name, id, index) => {
            // console.log("pushed")
            let cash = JSON.parse(JSON.stringify(routeState));
            cash[index] = { name: name, id: id };
            setRouteState(cash.splice(0, index + 1));

        },
        inserMenuRoute: (hasParent = false, name, id, father = { name: "", id: 0 }) => {
            if (hasParent) {
                setRouteState([{ name: "MATW Home", id: "/" }, { name: father.name, id: father.id }, { name: name, id: id }]);
            } else {
                setRouteState([{ name: "MATW Home", id: "/" }, { name: name, id: id }]);
            }
        },
        getRoute: () => {
            return routeState
        }
    }
}
export default useBreadCrumb;