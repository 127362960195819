import { useEffect, useState } from 'react';
import services from '../../services';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { palestineFaqsArabic } from './components/faqs';
import Accordion from '../../components/general/accordion';
import { useKeenSlider } from 'keen-slider/react'
import CrisisWidget from './components/crisisWidget';
import logos from '../../images/landings/palestine/logos.png';
import FoodPackImg from '../../images/landings/winter/foodPack.png';
import MedicalKitImg from '../../images/landings/winter/medical-kits.png';
import FuelImg from '../../images/landings/winter/fuel.png';
import MattressesImg from '../../images/landings/winter/mattresses.png';
import MedicalReliefImg from '../../images/landings/winter/medical-relief.png';
import HotMealsImg from '../../images/landings/winter/hot-meals.png';
import BlanketsImg from '../../images/landings/winter/blankets.png';
import MedicalBedsImg from '../../images/landings/winter/medical-beds.png';
import OurPromises from '../../images/landings/palestine/our-promise.png';
import Policy from '../../images/landings/palestine/policy100.png';
import OurPromisesMobile from '../../images/landings/palestine/OurPromisesMobile.png';
import VideosSection from '../../components/general/videosSection';
import { videos } from './unused/palestineUat';
// import BannerMobile from '../../images/landings/palestine/mobile-palestine-banner.jpg';
import HeroBanner from '../../images/landings/palestine/palestine-ar-hero-banner.jpg'

const impactNumbers = [
    {
        total: '11,389,333',
        lastUpdate: '24th January 2024'
    },
    {
        title: 'حقائب طبية',
        amount: '12,500',
        image: MedicalKitImg
    },
    {
        title: 'وقود',
        amount: '33,500 L',
        image: FuelImg
    },
    {
        title: 'فرشات',
        amount: '3,450',
        image: MattressesImg
    },
    {
        title: 'حزم طعام',
        amount: '10,577,400',
        image: FoodPackImg
    },
    {
        title: 'إغاثة طبية',
        amount: '28,183',
        image: MedicalReliefImg
    },
    {
        title: 'وجبات ساخنة',
        amount: '557,700',
        image: HotMealsImg
    },
    {
        title: 'بطانيات',
        amount: '38,500',
        image: BlanketsImg
    },
    {
        title: 'بطانيات أسرة طبية ونقالات تحميل',
        amount: '121,600',
        image: MedicalBedsImg
    }

];
const images = [
    'img1',
    'img2',
    'img3',
    'img4',
    'img5'
]

const PalestineArabic = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [products, setProducts] = useState([0, 0, 0, 0]);
    // const [allProducts, setAllProducts] = useState();
    // const [isLoading, setIsLoading] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
    const seo = SeoData[selectedcountryCode];
    const [currentSlideImpact, setCurrentSlideImpact] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImpact, instanceRefImpact] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImpact(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getProductsByName = async (name) => {
        let tempCritical = [];
        try {
            const response = await services.categoryProductsByName(name);
            response.data.products.map(item => {
                if (Number(item.status) === 1) {
                    if (item.name.trim() === 'Palestine Appeal') tempCritical.push(item)
                    if (item.name.trim() === 'Palestine Emergency Medical Kit') tempCritical.push(item)
                    if (item.name.trim() === 'Palestine Essential Family Food Pack') tempCritical.push(item)
                    if (item.name.trim() === 'Palestine Medical Relief') tempCritical.push(item);
                    if (item.creator === 'EMR-DIS-BFPL-2023-01-200') tempCritical.unshift(item); // Sponsor A Family In Palestine For A Week
                    if (item.creator === 'EMR-DIS-BFPL-2023-01-201') tempCritical.unshift(item); // Sponsor A Patient
                    if (item.creator === 'EMR-DIS-BFPL-2023-01-202') tempCritical.unshift(item); // Provide Clean Water In Palestine
                    if (item.creator === 'EMR-DIS-BFPL-2023-01-203') tempCritical.unshift(item); // Provide Shelter For A Family In Palestine
                    if (item.creator === 'EMR-DIS-BFPL-2023-01-204') tempCritical.unshift(item); // Emergency Orphan Support
                    if (item.creator === 'EMR-DIS-BFPL-2023-01-205') tempCritical.unshift(item); // Emergency Education Support
                    if (item.creator === 'EMR-DIS-BFPL-2023-01-206') tempCritical.unshift(item); // Widow Support And Shelter
                }


                return item;
            });
            tempCritical.map((item, index) => {
                switch (item.creator) {
                    case 'EMR-DIS-PSE-2023-01':
                        item.name = 'ﻓﻠﺴﻄﻴﻦ ﻓﻲ أﻣﺲ الحاجة';
                        item.description = 'توفير المساعدة الأساسية، بما في ذلك الطعام والماء والرعاية الطبية والمأوى، حيث يحتاج إليها بشكل أكبر. كن فارقًا في حياة الأشخاص المتأثرين بالطوارئ في فلسطين';
                        break;
                    case 'EMR-DIS-PSE-2023-01-01':
                        item.name = 'اﻹﻏﺎﺛﺔ اﻟﻄﺒﻴﺔ اﻟﻔﻠﺴﻄﻴﻨﻴﺔ';
                        item.description = 'تقديم المساعدة الطبية العاجلة للمصابين في حالة الطوارئ في فلسطين. سيساعد ذلك في توفير اللوازم الطبية العاجلة، ودعم المستشفيات، وتوفير الوقود للمساعدة في تشغيل الكهرباء في المستشفى.';
                        break;
                    case 'EMR-DIS-MDPL-2023-01-102':
                        item.name = 'ﻋﺪة اﻟﻄﻮارئ اﻟﻄﺒﻴﺔ';
                        item.description = 'ﻗﻄﻦ ﻃﺒﻲ ﻣﻌﻘﻢ، ﺷﺎش ﻣﻌﻘﻢ، ﺗﻀﻤﻴﺪ اﻟﺠﺮوح اﻟﻜﺒﻴﺮة، ﻗﻔﺎزات ﻃﺒﻴﺔ ﻟﻠﻨﻈﺎﻓﺔ اﻟﺸﺨﺼﻴﺔ، ﻟﺼﻘﺎت ﺟﺮوح، ﻣﺴﻜﻨﺎت، ﻣﻘﻴﺎس ﺿﻐﻂ ﻳﺪوي، ﺟﻬﺎز ﻗﻴﺎس اﻟﺴﻜﺮ ﻓﻲ اﻟﺪم، ﻣﺴﻜﻦ ﻟﻠﻜﺪﻣﺎت، ﺷﺮﻳﻂ ﻃﺒﻲ ﻋﺮﻳﺾ، ﺿﻤﺎدة، أدوات ﻃﺒﻴﺔ.';
                        break;
                    case 'EMR-DIS-BFPL-2023-01-102':
                        item.name = 'ﺣﺰﻣﺔ اﻟﻐﺬاء اﻟﻌﺎﺋﻠﻴﺔ اﻷﺳﺎﺳﻴﺔ';
                        item.description = 'ﺗﻮﻓﻴﺮ اﻹﻣﺪادات اﻷﺳﺎﺳﻴﺔ ﻟﻠﻮﺟﺒﺎت، ﺑﻤﺎ ﻓﻲ ذﻟﻚ 2 ﻛﻴﺲ أرز، 2 ﻛﻴﺲ ﻣﻌﻜﺮوﻧﺔ، 6 ﺧﻀﺎر ﻣﻌﻠﺒﺔ، 1 ﻓﺎﻛﻬﺔ ﻣﻌﻠﺒﺔ، 4 ﻟﺤﻮم وأﺳﻤﺎك ﻣﻌﻠﺒﺔ، 1 زﻳﺖ ﻃﻬﻲ، 1 ﻛﻴﺲ ﻋﺪس، 1 ﻛﻴﺲ ﻃﺤﻴﻦ، 2 ﻛﻴﺲ ﺳﻜﺮ، 2 ﻛﻴﺲ ﻣﻠﺢ، 6 اﻟﺤﻠﻴﺐ أو اﻟﺤﻠﻴﺐ اﻟﻤﺠﻔﻒ، 2 ﻋﺒﻮة ﺷﺎي وﻗﻬﻮة، و4 ﻣﻜﻮﻧﺎت ﻟﺼﻨﻊ اﻟﺨﺒﺰ واﻟﺨﺒﺰ. ';
                        break;
                    case 'EMR-DIS-BFPL-2023-01-200':
                        item.name = 'رعاية عائلة فيفلسطين لمدة أسبوع';
                        item.description = 'تبرعك الأسبوعي سيوفر لعائلة كاملة حقيبة الرعاية الأساسية التي تتضمن: توصيل وجبات غذائية ساخنة ومغذية، حزمة غذائية تحتوي على المستلزمات الأساسية مثل الأرز والمكرونة والحبوب والسلع المعلبة، صندوق خضروات يحتوي على مجموعة متنوعة من الخضروات الموسمية المتاحة، إمداد نظيف بالمياه لضمان توفير كمية كافية من المياه لمدة الأسبوع، تمور، حليب الأطفال، حقيبة ضروريات النظافة بما في ذلك الصابون ومعجون الأسنان ومعقم اليدين وغيرها من مستلزمات العناية الشخصية.'
                        break;
                    case 'EMR-DIS-BFPL-2023-01-201':
                        item.name = 'رعاية مريض';
                        item.description = 'رعاية مريض وتوفير الرعاية الطبية العاجلة التي يحتاجون إليها. ستمكن تبرعاتك من تغطية جميع أو جزء من نفقات الرعاية الطبية الضرورية للمريض. يصبح الرعاة شركاء حيويين في تعزيز صحة ورفاهية المرضى، وسيتلقون تحديثات منتظمة حول تقدم الحالة الصحية للمرضى'
                        break;
                    case 'EMR-DIS-BFPL-2023-01-202':
                        item.name = 'توفير إمدادات مياه نظيفة لمدة أسبوع';
                        item.description = 'توفير إمدادات مياه نظيفة لمدة أسبوع لعائلة فلسطينية في حاجة'
                        break;
                    case 'EMR-DIS-BFPL-2023-01-203':
                        item.name = 'توفير مأوى لعائلة في فلسطين';
                        item.description = 'توفير مأوى مؤقت عاجل لعائلة في فلسطين. المساعدة في توفير خيام قوية لحمايتهم من الظروف القاسية.'
                        break;
                    case 'EMR-DIS-BFPL-2023-01-204':
                        item.name = 'دعم الأيتام في حالات الطوارئ';
                        item.description = 'مساعدة في توفير الدعم العاجل للأيتام الفلسطينيين، والذي يشمل الاحتياجات الأساسية مثل الطعام والملابس، والدعم النفسي، والرعاية الصحية، والمساعدة التعليمية.'
                        break;
                    case 'EMR-DIS-BFPL-2023-01-205':
                        item.name = 'دعم التعليم في حالات الطوارئ';
                        item.description = 'مساعدة في توفير تعليم طارئ لأطفال فلسطينيين نازحين. سيضمن دعمك إمكانية إقامة خيام مؤقتة كفصول دراسية لضمان بيئة تعلم مؤقتة وآمنة، وتوفير موارد تعليمية وأدوات، وإعطاء الأطفال أملاً أفضل للمستقبل.'
                        break;
                    case 'EMR-DIS-BFPL-2023-01-206':
                        item.name = 'دعم عاجل للأرامل وتوفير مأوى';
                        item.description = 'توفير مكان آمن ودعم عاجل لأرامل الصراع. سيمكن دعمك من تقديم المساعدة المالية الضرورية، ومأوى مؤقت لضمان حمايتهن، ودعم نفسي اجتماعي لمساعدتهن في التعافي من آثار الصراع، وتنمية المهارات.'
                        break;

                    default:
                        break;
                }
                return item;
            })
            setProducts(tempCritical);
        } catch (error) {
            console.error('error========', error);
            // setIsLoading(false);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        getProductsByName('palestine');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='!bg-white' hideFooter={true}>
            {seo &&
                <Helmet>
                    <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
                    <meta name="title" content={`${seo['crisisAndEmergencies']['palestine']['title']}`} />
                    <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
                    {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
                </Helmet>
            }
            {/* <div className='hidden sm:flex max-w-[1440px] md:mx-auto'><img src={Banner} alt="palestine-banner" /></div>
            <div className='sm:hidden max-w-[1440px] md:mx-auto'><img src={BannerMobile} alt="palestine-banner" /></div> */}
            {/* <section className="h-[500px] hidden sm:flex justify-center md:pl-[210px] lg:pl-96 bg-cover bg-left bg-no-repeat  sm:bg-[url('../src/images/landings/palestine/palestine-ar-hero-banner.jpg')]" >

            </section> */}
            {/* <div className='sm:hidden max-w-[1440px] md:mx-auto'><img src={BannerMobile} alt="palestine-banner" /></div> */}
            <div className='sm:hidden flex justify-center'><img src={HeroBanner} alt="HeroBanner" /></div>
            <section className="hidden sm:block h-[400px] bg-center bg-cover bg-no-repeat bg-[url('../src/images/landings/palestine/palestine-ar-hero-banner.jpg')]" />

            {/* /* ------------------------------- Widget ----------------------------------- */}

            <section className='bg-[#f60362]'>
                <section className='max-w-[1440px] md:mx-auto  sm:px-0 px-4 '>
                    <div className='text-[#fff]   pt-5 leading-[45px] text-center text-[35px] font-[AlmariMedium] font-bold'>التبرع</div>
                    <div className='w-full   lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <CrisisWidget className={' px-6 h-auto pt-10 bg-[#DFEEF2]'}
                            defaultProduct={{
                                creator: "EMR-DIS-PSE-2023-01",
                                label: "فلسطين أكثر ما يحتاج",
                                value: 255
                            }}
                            arabic
                            donateBtnText='تبرع الان'
                        />
                    </div>
                </section>
            </section>
            <section dir='rtl' className="font-['AlmariMedium']">
                <section className="max-w-[1440px] md:mx-auto pt-6  flex justify-center bg-[url('../public/images/landings/crisis/palestine/pattern.png')]">
                    <div className='flex justify-center'>
                        <div className='flex justify-center items-end sm:items-center basis-full sm:basis-10/12'>
                            <div className='sm:order-1 order-2 basis-1/2 sm:basis-1/4'>
                                <img src={OurPromises} className="hidden sm:block" alt="our-promise" />
                                <img src={OurPromisesMobile} className="sm:hidden" alt="our-promise" />
                            </div>
                            <div className='pr-3 sm:pr-0 sm:order-2 order-1 basis-1/2 sm:basis-2/3 flex flex-wrap sm:flex-nowrap items-center  gap-x-4'>

                                <div className='order-2 sm:order-1 sm:basis-2/3 text-[#23408f] text-[10px] sm:text-[14px] pl-3'>
                                    <div className='border-[#14A2DC]  font-bold text-right border-b-2 mb-3 pb-1'>
                                        <b className=''>وعدنا لك:</b>
                                    </div>
                                    <div className='text-[8px] sm:text-[14px] leading-[10px] sm:leading-[16px] pb-5 sm:pb-0'>
                                        تأسست مشروع المسلمين حول العالم (MATW) بواسطة علي بنات في عام 2015، وهو مشروع خيري إسلامي عالمي يعمل في 24 دولة حول العالم. تضمنت سياستنا المئوية للتبرع أن كل تبرع يسير الزيادة في الوصول إلى أولئك الذين هم في أمس الحاجة.
                                    </div>
                                </div>

                                <div className='order-1 sm:order-2  sm:mb-[40px]'>
                                    <img src={Policy} className='w-[80px] sm:w-[130px]' alt="policy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /* -------------------------------------------------------------------------- */}

                <div className="md:container md:mx-auto flex flex-wrap mt-5">
                    <div className="basis-full text-center text-3xl sm:text-6xl text-[#253b7e] flex justify-center items-center font-['AlmariMedium'] h-[125px]">
                        اﻟﻄﻮارئ ﻓﻲ ﻓﻠﺴﻄﻴﻦ
                    </div>
                    {/* <div className="basis-full text-center text-6xl text-[#253b7e] flex justify-center items-center font-['Gotcha'] h-[125px]">
                    {!isLoading ? allProducts.name : <Skeleton height={80} />}
                </div> */}
                    <p className='w-full text-center mb-4 md:mb-8 sm:px-4 px-1 text-lg leading-9 text-[#253B7E]'>
                        تبرع اليوم وانضم إلينا في صنع فارق لصالح أولئك الذين تأثروا بشكل كبير في الصراع الحالي
                        <br /> <br />

                        مشروع المسلمين حول العالم (MATW) يعمل على الأرض، يقدم المساعدة الطبية الأساسية، والغذاء، والمياه، والمأوى، وحقائب النظافة، وكل الدعم العاجل. شراكتنا الأخيرة مع الوكالة الأممية لإغاثة وتشغيل اللاجئين الفلسطينيين (UNWRA) ومجلس الشباب المصري تضمن أن تصل تبرعاتك إلى أولئك الذين هم في أمس الحاجة في فلسطين.

                    </p>
                    <div className="basis-full">
                        <div className="flex flex-row justify-center flex-wrap">
                            {products.map((item, index) => {
                                return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                    <ProductBox product={item} currency={selectedCurrency} arabic />
                                </div>
                            })
                            }
                        </div>

                        {/* /* --------------------------------- Muslim Arounds ------------------------- */}

                        <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
                            <h1 className="text-3xl  w-full text-[#253B7E] text-center font-medium mb-8 md:mb-16 lg:mb-18">
                                المسلمون حول العالم (MATW) على الأرض في فلسطين:
                            </h1>
                            <h2 className='text-[#282828] text-center  basis-full'>In Partnership with</h2>
                            <div className="basis-full md:basis-1/2 lg:basis-1/2 flex flex-wrap">
                                <div className="flex justify-center items-center">
                                    <img src={logos} alt="sh logo" className='max-h-[100px]' />
                                </div>
                                {/* <div className="basis-1/3 flex justify-center items-center">
                                    <img src={unrwaImg} alt="unrwa logo" className='max-h-[120px]' />
                                </div>
                                <div className="basis-1/3 flex justify-center items-center">
                                    <img src={eycImg} alt="eyc logo" className='max-h-[140px]' />
                                </div> */}
                            </div>
                            <p className="text-[#253B7E] text-[18px] leading-7 md:w-10/12 font-['AlmariMedium'] mt-8 md:mt-12">
                                بفضل وجودنا الطويل في فلسطين وتوفر إمدادات جاهزة للاستجابة الفورية، كان فريقنا في (MATW) سريعًا في تقديم الضروريات العاجلة للمتضررين مباشرة من جراء النزاع.

                                <br />
                                <br />
                                فريقنا على الأرض يقوم بتوزيع حقائب الإسعاف الطبي العاجل، وحزم طعام عاجلة، ووقود، ووجبات ساخنة، والمزيد للعائلات النازحة. تركيزنا الرئيسي هو الوصول إلى الأكثر ضعفًا والذين في حاجة ماسة.

                                <br />
                                <br />
                                قبل إغلاقه، كانت شراكة MATW مع مستشفى الشفاء تعني أن تبرعاتكم كانت خط دفاع حيوي لشعب فلسطين. من خلال هذه الشراكة، قدمنا ضروريات الحياة مثل النقالات، والأسرة، والفرش، والبطاطين، وحقائب الإسعاف والإمدادات الطبية، والمساعدة في دعم الطاقم، والوقود، والكثير غيره لضمان استمرار عمل المستشفى.

                                <br />
                                <br />


                                <br />
                                نحن مصممون كما لم نكن من قبل على عدم الاستسلام. كل حياة قيمة، ودعمكم يسمح لنا بالاستمرار في القيام بالعمل الذي نقوم به.



                                <br />
                                <br />
                                <span className='text-[#f60362]'>التبرعات لحملتنا الطارئة لفلسطين تخضع أيضًا لقاعدة الزكاة بنسبة 100%.</span>

                            </p>
                            <div className='basis-full flex justify-center py-4 md:py-8'>
                                <button className='sm:flex text-white hidden items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                                    تبرع الان
                                </button>
                                <button className='sm:hidden text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 400)}>
                                    تبرع الان
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* /* -------------------------------------------------------------------------- */}
                <section className='bg-[#f1f1f1] pt-5'>



                    {/* ----------------- videos section ---------------- */}

                    <VideosSection youtube videos={videos} videoBoxClass={'p-0'} />

                    {/* ----------------- videos section --END-------------- */}

                    {/* /* --------------------------------- Images --------------------------------- */}
                    <div className="md:container md:mx-auto flex justify-center flex-wrap  sm:mt-12">
                        <div className="basis-full hidden sm:flex flex-wrap">
                            <div className="basis-full md:basis-1/3 md:pr-2 mb-4">
                                <div className="bg-[url('../src/images/landings/palestine/img1.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[465px] 2xl:h-[500px]" />
                            </div>
                            <div className="basis-full md:basis-1/3 md:px-2 mb-4">
                                <div className="bg-[url('../src/images/landings/palestine/img2.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px] mb-2" />
                                <div className="bg-[url('../src/images/landings/palestine/img3.jpg')] bg-top bg-cover sm:h-[300px] md:h-[114px] lg:h-[124px] xl:h-[150px] 2xl:h-[150px] mb-2" />
                                <div className="bg-[url('../src/images/landings/palestine/img4.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px]" />
                            </div>
                            <div className="basis-full md:basis-1/3 md:pl-2 mb-4">
                                <div className="bg-[url('../src/images/landings/palestine/img5.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[465px] 2xl:h-[500px]" />
                            </div>

                        </div>
                        <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {
                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full px-9 flex justify-center`}>
                                        <div className={`bg-[url('../src/images/landings/palestine/img${index + 1}.jpg')] bg-center bg-cover h-[300px] w-full`} />
                                    </div>
                                })}
                            </div>
                            {loaded && instanceRefImages.current && (
                                <>
                                    <Arrow
                                        left
                                        bg={'bg-[#253A7E]'}
                                        onClick={(e) =>
                                            e.stopPropagation() || instanceRefImages.current?.prev()
                                        }
                                        disabled={currentSlideImages === 0}
                                    />

                                    <Arrow
                                        bg={'bg-[#253A7E]'}
                                        onClick={(e) =>
                                            e.stopPropagation() || instanceRefImages.current?.next()
                                        }
                                        disabled={
                                            currentSlideImages ===
                                            instanceRefImages.current.track.details.slides.length - 1
                                        }
                                    />
                                </>
                            )}
                        </div>
                        <div className="w-full sm:hidden flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 pb-6">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-md p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* /* -------------------------------------------------------------------------- */}




                    {/* /* --------------------------------- The people of Palestine need your help --------------------------------- */}
                    <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
                        <h1 className="text-3xl md:text-5xl w-full text-[#253B7E] text-center font-medium mb-5 ">
                            ﺷﻌﺐ ﻓﻠﺴﻄﻴﻦ ﺑﺤﺎﺟﺔ ﻟﻜﻢ
                        </h1>
                        <h2 className="text-xl md:text-3xl md:w-2/3 px-6 text-[#253B7E] text-center font-medium ">
                            مع اقتراب فصل الشتاء، ستتدهور الحالة أكثر.
                        </h2>


                        <p className="text-[#253B7E] text-center text-[18px] md:w-11/12  leading-8 mt-3 md:mt-12 font-['AlmariMedium']">

                            لقد مرت ما يقرب من شهرين منذ أن اندلعت عمليات العنف والاضطرابات المدمرة في فلسطين، مما خلق حالة من الاضطراب، وترك العائلات في حالة من عدم اليقين والخوف.
                            <br />
                            <br />
                            لقد قام فريقنا في MATW بالاستجابة للأزمة وقد قدم مساعدات تنقذ الحياة لأولئك الذين يعانون من آثار أسوأ تفش للأحداث. استخدم زكاتك والصدقة لمساعدتنا في توفير الدعم الطبي العاجل، والطعام، والمياه النظيفة، والمأوى للذين تأثروا جراء العنف في المنطقة في هذه السنوات.
                            <br />
                            <br />
                            وصفت بأنها أكبر سجن في الهواء الطلق في العالم، لقد أخرجت عقود من العنف بنية فلسطين تحت الانهيار. العائلات في حالة مستمرة من إعادة البناء والتعافي. يقوم فريقنا في MATW على الأرض بتقديم تقارير عن تصاعد الوضع في ظل خطر يتسع: الشتاء. لقد هربت العائلات من الجنوب إلى الشمال، تاركة كل شيء وراءها. ليس فقط أن العائلات تحاول حماية أنفسها من العنف والصراع، بل يتعين عليهم الآن القلق حول كيفية حماية أنفسهم من برودة الشتاء القارسة.
                            <br />
                            <br />

                            <b> الدعم الخاص بك مطلوب الآن أكثر من أي وقت مضى.</b>
                            <br />
                            <br />
                            MATW تعمل على الأرض في فلسطين لتقديم الدعم العاجل خلال حالات الطوارئ، وتقديم الرعاية للأيتام من خلال برنامج الرعاية لدينا، وفي الفترة الأخيرة، إعادة بناء المنازل من خلال مبادرة مشروع البناء.
                            <br />
                            <br />
                            نحن ملتزمون بتقديم المساعدة العاجلة في المناطق الأكثر صعوبة الوصول إليها للأشخاص الأكثر احتياجًا. كل ثانية تحسب. يمكن أن يكون تبرعك الفارق بين الحياة والموت. كن داعمًا لشعب فلسطين في ساعة أكبر احتياج لديهم.
                        </p>
                        <div className='basis-full flex justify-center py-8'>
                            <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                                تبرع الان
                            </button>
                        </div>
                    </div>

                </section>

                <section className='max-w-[1440px] md:mx-auto bg-[#f60362] sm:px-0 px-4 '>
                    <div className='text-[#fff]   pt-5 leading-[45px] text-center text-[35px] font-bold'>قدّم تبرعًا</div>
                    <div className='w-full   lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <CrisisWidget className={' px-6 h-auto pt-10 bg-[#DFEEF2]'}
                            defaultProduct={{
                                creator: "EMR-DIS-PSE-2023-01",
                                label: 'فلسطين أكثر ما يحتاج',
                                value: 255
                            }}
                            arabic
                            donateBtnText='تبرع سريع'
                        />
                    </div>
                </section>
                {/* /* --------------------------Impact Numbers------------------------------------------------ */}

                <div className="md:container md:mx-auto  mt-12 px-4 md:px-0">
                    <h2 className="basis-full mb-3 md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-6xl">
                        تأثير <span className='text-[#F60362]'>MATWs</span>
                    </h2>
                    <div className="flex flex-col items-center text-center text-white font-['AlmariMedium']">
                        <div className='bg-[#F60362] w-[348px] text-[26px] sm:w-[675px] my-6 py-2  h-[80] rounded-full sm:text-[30px]'>
                            التأثير الإجمالي:<span className='font-bold'>{impactNumbers[0].total}</span>
                        </div>
                        {/* <div className='text-[#78716C] hidden sm:block text-[25px]'>{impactNumbers[0].lastUpdate}</div> */}
                    </div>

                    <div className='hidden sm:flex gap-4 mt-6 mb-16 justify-center flex-wrap'>
                        {impactNumbers.map((item, index) => (

                            index > 0 && <div key={index} className='relative flex justify-center  p-3'>
                                <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                    {item.amount}
                                </div>
                                <div className='flex flex-col justify-around items-center min-h-[365px] w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                    <div><img src={item.image} alt={item.title} className='w-[130px]' /></div>
                                    <div className='text-[#253B7E] text-[24px] font-bold mt-24 px-3 leading-5 text-center'> {item.title}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* -------------Impact numbers slider for mobile----------- */}


                    <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                        <div ref={sliderRefImpact} className="keen-slider">
                            {impactNumbers.map((item, index) => {
                                return index > 0 && <div key={`carousel_impact${index}`} className={`keen-slider__slide basis-full px-4 py-2 flex justify-center`}>
                                    <div className='relative flex justify-center  p-3'>
                                        <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                            {item.amount}
                                        </div>
                                        <div className='flex flex-col justify-around items-center h-[365px] min-w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                            <div><img src={item.image} alt={item.title} className='w-[130px]' /></div>
                                            <div className='text-[#253B7E] text-[24px] font-bold mt-20 leading-5 text-center'> {item.title}</div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                        {loaded && instanceRefImpact.current && (
                            <>
                                <Arrow
                                    left
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRefImpact.current?.prev()
                                    }
                                    disabled={currentSlideImpact === 0}
                                />

                                <Arrow
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRefImpact.current?.next()
                                    }
                                    disabled={
                                        currentSlideImpact ===
                                        instanceRefImpact.current.track.details.slides.length - 1
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="w-full sm:hidden flex justify-center">
                        {loaded && instanceRefImpact.current && (
                            <div className="dots flex pt-3 pb-6">
                                {[
                                    ...Array(instanceRefImpact.current.track.details.slides.length).keys(),
                                ].map((idx) => {
                                    return (
                                        <button
                                            key={idx}
                                            onClick={() => {
                                                instanceRefImpact.current?.moveToIdx(idx)
                                            }}
                                            className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImpact === idx ? " active bg-[#F60362]" : " bg-gray-300")}
                                        ></button>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
                <section className="font-['AlmariMedium'] max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
                    <h1 className='text-[#253B7E] text-left  sm:text-center mb-8 text-[30px] sm:text-[50px] '>
                        <span className='text-[#F60362] ml-3'> اﻷﺳﺌﻠﺔ اﻟﺸﺎﺋﻌﺔ</span>ﺣﻮل اﻟﺘﺒﺮﻋﺎت ﻟﻔﻠﺴﻄﻴﻦ
                    </h1>
                    <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E]  text-2xl leading-7'>{palestineFaqsArabic[0].question}</h1>
                            <p className='text-base  leading-4 mt-3'>
                                {palestineFaqsArabic[0].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] f text-2xl leading-7'>{palestineFaqsArabic[1].question}</h1>
                            <p className='text-base  leading-4 mt-3'>
                                {palestineFaqsArabic[1].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E]  text-2xl leading-7'>{palestineFaqsArabic[2].question}</h1>
                            <p className='text-base  leading-4 mt-3'>
                                {palestineFaqsArabic[2].answer}
                            </p>
                        </div>
                    </div>
                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {palestineFaqsArabic.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </section>
            {/* /* -------------------------------------------------------------------------- */}

        </Layout>
    );
}
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <div
            className={`${props.bg ? props.bg : 'bg-[#F60362]'} w-[30px] h-[60px] absolute top-[calc(50%-60px)] translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.left ? "arrow--left left-[0px]" : "arrow--right left-auto right-[0px]"}`}
            onClick={props.onClick}
        >
            <svg
                className={`w-[24px] h-[24px] fill-white cursor-pointer arrow ${props.left ? "arrow--left" : "arrow--right"
                    } ${disabeld}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                {props.left && (
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                )}
                {!props.left && (
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                )}
            </svg>
        </div>

    )
}
export default PalestineArabic;