import React, { useEffect, useState } from 'react';
import Carousel from '../../components/Carousel';
import services from '../../services';

export default function CarouselSection() {
  /* ----------------------------- Start variables ---------------------------- */
  const [banners, setBanners] = useState([]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getBanners = async () => {
    try {
      const response = await services.getBanners();
      let temp = response.data.sort((a, b) => {
        return a.order - b.order;
      });
      let temp2 = [];
      temp.map((item, index) => {
        if (item.status !== 0 && item.section === 'slider') {
          temp2.push(item);
        }
        return item;
      });
      setBanners(temp2);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    getBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full  md:py-2 min-h-[200px]- sm:min-h-[330px]- md:min-h-[393px] lg:min-h-[550px] xl:min-h-[674px] 2xl:min-h-[800px]">
      {banners.length ? (
        <Carousel
          withArrow={false}
          autoSwitch={true}
          data={banners}
          renderItem={({ item, index, currentSlide }) => {
            const isActive = currentSlide === index;
            return (
              <div className="md:p-2" key={`carousel${index}`}>
                <div
                  className={`w-full h-auto transition-all transform md:rounded-xl overflow-hidden lg:min-h-[490px] xl:min-h-[614px] 2xl:min-h-[740px] ${
                    isActive ? 'scale-100' : 'opacity-75 scale-90'
                  }`}
                >
                  <a href={item.link}>
                    <img className={`w-full h-full object-center cursor-pointer object-cover md:rounded-xl`} alt={item.name} src={`${item.image}`} />
                  </a>
                </div>
              </div>
            );
          }}
          keyExtractor={item => item.image}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
