import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../../utils/seo.json';

import banner from '../../../images/landings/palestine/anniversary-landing-banner-gaza.jpg';
import bannerFr from '../../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Desktop-fr.jpg';
import bannerMobileFr from '../../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Mobile-fr.jpg';
import Palestinepreramadan1Img from '../../../images/landings/palestine/pre-ramadan-palestine1.jpeg';
import donateMealImg from '../../../images/landings/palestine/donate-a-meal.jpg';

import WidgetHome from '../../../components/widgets/widgetHome';
import { useParams } from 'react-router-dom';
import services from '../../../services';
import ProductsSection from './productsSection';
import CustomButton from '../../../components/general/button';

const productCreators = ['EMR-DIS-PSE-2023-01', 'EMR-DIS-PSE-2023-01-01'];
const GazaAppealLandingPage = () => {
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const [products, setProducts] = useState([]);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const { lang: language } = useParams();
  const productsRef = useRef(null);

  /* ------------------------------ Start methods ----------------------------- */
  const getProductsByName = async name => {
    setIsLoaded(true);
    try {
      const response = await services.categoryProductsByName(name);
      if (response.status === 200) {
        const products = response.data?.products ?? [];
        const items = [];
        const multiProduct = {
          title: language === 'fr' ? `Faites don d'un repas` : language === 'ar' ? 'تبرع بوجبة' : 'Donate a Meal',
          description:
            language === 'fr'
              ? `Soutenez les familles palestiniennes confrontées à la faim. Faites un don d'un repas aujourd'hui. Votre don fournit des nutriments essentiels à une famille dans le besoin.`
              : language === 'ar'
              ? `قف مع العائلات في فلسطين التي تواجه الجوع. تبرع بوجبة اليوم. يوفر تبرعك العناصر الغذائية الأساسية لعائلة محتاجة.`
              : `Stand with Families in Palestine Facing Hunger. Donate a Meal Today. Your donation provides essential nutrients for a family in need.`,
          img: donateMealImg,
          products: [],
        };

        products.forEach(product => {
          if (product.creator === 'EMR-DIS-PSE-2023-01') {
            items[0] = product;
          } else if (product.creator === 'EMR-DIS-PSE-2023-01-01') {
            items[1] = product;
          } else if (product.creator === 'EMR-DIS-AA-2024-401') {
            product = Object.assign({}, product, {
              alternativeName:
                language === 'fr' ? `Faire don d'un repas - 20 personnes` : language === 'ar' ? `تبرع بوجبة - 20 شخص` : 'Donate a meal - 20 people',
            });
            multiProduct.products[0] = product;
          } else if (product.creator === 'EMR-DIS-AA-2024-402') {
            product = Object.assign({}, product, {
              alternativeName:
                language === 'fr' ? `Faire don d'un repas - 50 personnes` : language === 'ar' ? `تبرع بوجبة - 50 شخص` : 'Donate a meal - 50 people',
            });
            multiProduct.products[1] = product;
          } else if (product.creator === 'EMR-DIS-AA-2024-400') {
            product = Object.assign({}, product, {
              anyAmount: true,
            });
            multiProduct.products[2] = product;
          }
        });
        items.push(multiProduct);
        setProducts(items);
      }
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */

  useEffect(() => {
    !isLoaded && getProductsByName('palestine');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className={`bg-white`}>
      {seo && (
        <Helmet>
          <title>{seo['gaza-appeal']['title']}</title>
          <meta name="title" content={seo['gaza-appeal']['title']} />
          <meta name="description" content={seo['gaza-appeal']['description']} />
        </Helmet>
      )}

      <section className="flex justify-center">
        <img src={language === 'fr' ? bannerFr : banner} alt="Donate to Palestine" className="max-[500px]:hidden w-full" />
        <img src={language === 'fr' ? bannerMobileFr : banner} alt="Donations to Palestine" className="min-[501px]:hidden w-full" />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="xs:hidden md:block sticky top-16 lg:top-24 z-30">
        <WidgetHome defaultProduct={{ creator: 'EMR-DIS-PSE-2023-01', label: 'Save Lives In Palestine', value: 255 }} />
      </section>

      <ProductsSection products={products} productsRef={productsRef} />
      <section className="pt-9 lg:pt-[72px] pb-6 lg:pb-[45px] max-w-[1280px] px-4 lg:px-8 mx-auto text-[#78716C]  leading-[60%]">
        <h2 className="font-brandingBold text-pink text-3xl lg:text-[60px]">Gaza In Crisis</h2>
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-lg lg:text-[28px] font-brandingMedium">
          A mother presses her children close, her heart pounding as the walls shake with every explosion… She whispers prayers into their hair,
          trying to shield them from the world collapsing around them… Each moment feels like the last, as they wait for the next strike that could
          take everything away.
        </p>
        <br />
        <br />
        <br />
        <p className="text-lg lg:text-[28px] font-brandingBold">
          Imagine dealing with the constant sound of bombs dropping overhead, desperately searching for loved ones in the debris. 
        </p>
        <br />
        <br />
        <br />
        <p className="text-lg lg:text-[28px] font-brandingMedium">
          This is the harsh reality for millions of Palestinians in Gaza. Mothers cradle their injured children, fathers search through the wreckage
          for food, and families are left with nothing but devastation.
        </p>
        <h2 className="font-brandingBold text-pink text-2xl lg:text-[40px] my-6 lg:my-12 leading-[90%]">
          But you can give them hope through our Gaza Appeal.
        </h2>
        <img src={Palestinepreramadan1Img} alt="" />
        <br />
        <br />
        <br />
        <p className="text-lg lg:text-[28px] font-brandingBold">
          We may not stop the conflict anytime soon, but your donation could be the lifeline the Palestinians desperately need.
        </p>
        <br />
        <br />
        <br />
        <p className="text-lg lg:text-[28px] font-brandingMedium">
          Every pound you give delivers immediate relief and amplifies the global call for a ceasefire and peace.
        </p>
        <br />
        <br />
        <br />
        <p className="text-lg lg:text-[28px] font-brandingMedium">
          <span className="font-BrandingMediumItalic">Hesitation costs lives.</span> Now is the time to act.
        </p>
        <h3 className="text-3xl lg:text-[48px] font-brandingBold leading-[116%] text-center mt-16 lg:mt-24 mb-5 lg:mb-9">
          Donate today to our Gaza Appeal and save lives.
        </h3>
        <CustomButton
          onClick={() => {
            window.scrollTo({ behavior: 'smooth', top: productsRef.current.offsetTop - 120 });
          }}
          title="Help Gaza Now"
          className="mx-auto !w-[50%] !min-w-fit !px-6 lg:!text-[42px] !text-[24px] !h-12 lg:!h-20 !rounded-[100px] !normal-case"
        />
      </section>
    </Layout>
  );
};

export default GazaAppealLandingPage;
