import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import Accordion from '../../components/general/accordion';
import muslimAroundImg from '../../images/landings/qurban/muslim-arounds.jpg';
import { qurbanFaqs, qurbanFranceFaqs, qurbanArabicFaqs } from './faqs';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import { selectProducts } from '../../store/products';
// import QurbanPalestineTabs from './components/qurbanPalestineTabs';
import QurbanSlider from './components/qurban-slider';
import qurbanSlider1 from '../../images/landings/qurban/qurban-slider1.jpg';
import qurbanSlider2 from '../../images/landings/qurban/qurban-slider2.jpg';
import qurbanSlider3 from '../../images/landings/qurban/qurban-slider3.jpg';
import qurbanSlider4 from '../../images/landings/qurban/qurban-slider4.jpg';
import qurbanSlider5 from '../../images/landings/qurban/qurban-slider5.jpg';
import qurbanSlider6 from '../../images/landings/qurban/qurban-slider6.jpg';
// import CustomButton from '../../components/general/button';

const images = [
  { src: qurbanSlider1, alt: 'Donate to palestine, they need you' },
  { src: qurbanSlider2, alt: 'Palestine Charity to help the palestinian people' },
  { src: qurbanSlider3, alt: 'Palestine donation to make an impact' },
  { src: qurbanSlider4, alt: 'Palestine appeal, help them today' },
  { src: qurbanSlider5, alt: 'donate for palestine emergency appeal' },
  { src: qurbanSlider6, alt: 'donate for palestine emergency appeal' }
];

const QurbanPalestineLandingPage = ({ title, lang }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const seo = SeoData[selectedcountryCode];
  const [faqs, setFaqs] = useState(qurbanFaqs)
  const [foodAid, setFoodAid] = useState({
    // title: 'Food Aid',
    title: lang === 'fr' ? 'aide alimentaire' : lang === 'ar' ? 'المعونة الغذائية' : 'Food Aid',
    description: '',
    checkboxProducts: [],
    products: [],
  });
  const [zakat, setZakat] = useState({
    // title: 'Zakat',
    title: lang === 'fr' ? 'zakat' : lang === 'ar' ? 'زكاة' : 'Zakat',
    description: '',
    products: [],
  });
  const [qurban, setQurban] = useState({
    title: lang === 'fr' ? 'QURBANI' : lang === 'ar' ? 'الأضاحي' : title,
    description: '',
    products: [],
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempFoodAidProducts = [];
    let tempZakatProducts = [];
    let tempCheckBoxProducts = [];
    let tempQurbanProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Checkbox products
          if (inner_item_product.creator === 'TK-QB-2024-FA-00') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: '2kg Meat',
              name: lang === 'fr' ? `2KG de viande` : lang === 'ar' ? `2 كيلو لحم` : `2kg Meat`,
            });
            tempCheckBoxProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-FA-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: '10kg of Rice',
              name: lang === 'fr' ? `10 KG de riz` : lang === 'ar' ? `10 كيلو أرز` : `10 KG Rice`,
            });
            tempCheckBoxProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-EG28') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: `${title} Eid Gift`,
              name: lang === 'fr' ? `Cadeau de l’Aïd Qurbani` : lang === 'ar' ? `قربان هدية العيد` : `${title} Eid Gift`,
            });
            tempCheckBoxProducts[2] = inner_item_product;
          }

          // Qurban
          // if(inner_item_product.creator === 'QB-2024-S1'){
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: `${title} Cow`,
          //   });
          //   tempQurbanProducts[0] = inner_item_product;
          // }
          // if(inner_item_product.creator === 'QB-2024-S9'){
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: `${title} Cow Share`,
          //   });
          //   tempQurbanProducts[1] = inner_item_product;
          // }
          if (inner_item_product.creator === 'QB-2024-S2') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Vache Qurban (Égypte)` : lang === 'ar' ? `بقرة قربان (مصر)` : `Qurban Cow (Egypt)`,
              // nameDescription: lang === 'fr' ? `(pour les réfugiés palestiniens)` : lang === 'ar' ? `(لللاجئين الفلسطينيين)` : `(for Palestinian Refugees)`,
              name: lang === 'fr' ? `Réfugiés palestiniens` : lang === 'ar' ? `اللاجئين الفلسطينيين` : `Palestinian Refugees`,
              nameDescription: lang === 'fr' ? `(basé en Égypte)` : lang === 'ar' ? `(مقرها في مصر)` : `(based in Egypt)`,
              // showHeartIcon: true,
            });
            tempQurbanProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S10') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `Part de Vache Qurban (Égypte)` : lang === 'ar' ? `حصة بقرة قربان (مصر)` : `Qurban Cow Share (Egypt)`,
              // nameDescription: lang === 'fr' ? `(pour les réfugiés palestiniens)` : lang === 'ar' ? `(لللاجئين الفلسطينيين)` : `(for Palestinian Refugees)`,
              name: lang === 'fr' ? `Réfugiés palestiniens` : lang === 'ar' ? `اللاجئين الفلسطينيين` : `Palestinian Refugees`,
              nameDescription: lang === 'fr' ? `(basé en Égypte)` : lang === 'ar' ? `(مقرها في مصر)` : `(based in Egypt)`,
              // showHeartIcon: true,
            });
            tempQurbanProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S18') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: lang === 'fr' ? `MOUTONS/CHÈVRES QURBANI (Égypte)` : lang === 'ar' ? `الأضاحي الأغنام / الماعز (مصر)` : `${title} Sheep/Goat (Egypt)`,
              // nameDescription: lang === 'fr' ? `(pour les réfugiés palestiniens)` : lang === 'ar' ? `(لللاجئين الفلسطينيين)` : `(for Palestinian Refugees)`,
              name: lang === 'fr' ? `Réfugiés palestiniens` : lang === 'ar' ? `اللاجئين الفلسطينيين` : `Palestinian Refugees`,
              nameDescription: lang === 'fr' ? `(basé en Égypte)` : lang === 'ar' ? `(مقرها في مصر)` : `(based in Egypt)`,
            });
            tempQurbanProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S17') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: `${title} Sheep/Goat`,
              name: lang === 'fr' ? `MOUTONS/CHÈVRES QURBANI (Palestine)` : lang === 'ar' ? `الأضاحي الأغنام / الماعز (فلسطين)` : `${title} Sheep/Goat (Palestine)`,
            });
            tempQurbanProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S1') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Vache Qurban (Palestine)` : lang === 'ar' ? `بقرة قربان (فلسطين)` : `Qurban Cow (Palestine)`,
              isSoldOut: true,
            });
            tempQurbanProducts[4] = inner_item_product;
          }
          // if (inner_item_product.creator === 'QB-2024-S9') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: lang === 'fr' ? `Palestine` : lang === 'ar' ? `فلسطين` : `Palestine`,
          //     isSoldOut: true,
          //   });
          //   tempQurbanProducts[1] = inner_item_product;
          // }
          // if (inner_item_product.creator === 'QB-2024-S9') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: lang === 'fr' ? `vache qurban` : lang === 'ar' ? `الأضاحي بقرة` : `${title} Cow`,
          //     isSoldOut: true,
          //   });
          //   tempQurbanProducts[2] = inner_item_product;
          // }
          // Food Aid
          if (inner_item_product.creator === 'QB-2024-FA-03') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: lang === 'fr' ? `Paniers alimentaires Qurban` : lang === 'ar' ? `عبوات طعام الأضاحي` : `Qurban Food Packs`,
            });
            tempFoodAidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Feed 10 Orphans'
              name: lang === 'fr' ? `Nourrir 10 orphelins` : lang === 'ar' ? `إطعام 10 أيتام` : `Feed 10 Orphans`,
            });
            tempFoodAidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Feed 20 Orphans'
              name: lang === 'fr' ? `Nourrir 20 orphelins` : lang === 'ar' ? `إطعام 20 يتيما` : `Feed 20 Orphans`,
            });
            tempFoodAidProducts[2] = inner_item_product;
          }

          // Zakat
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Give Zakat Al Mal'
              name: lang === 'fr' ? `Verser la Zakat al Maal` : lang === 'ar' ? `اعطاء زكاة المال` : `Give Zakat Al Mal`,
            });
            tempZakatProducts[1] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              staticLink: '/zakat-calculator',
              // name: 'Calculate your Zakat',
              name: lang === 'fr' ? `Calculer votre Zakat` : lang === 'ar' ? `احسب زكاتك` : `Calculate your Zakat`,
              creator: 'RM24-ZM-013-'
            });
            tempZakatProducts[0] = inner_item_product;
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempFoodAidProducts = tempFoodAidProducts.filter(n => n)
    tempCheckBoxProducts = tempCheckBoxProducts.filter(n => n)
    tempZakatProducts = tempZakatProducts.filter(n => n)
    tempQurbanProducts = tempQurbanProducts.filter(n => n)

    setFoodAid({
      ...foodAid,
      checkboxProducts: tempCheckBoxProducts,
      products: tempFoodAidProducts
    });
    setZakat({
      ...zakat,
      products: tempZakatProducts
    })
    setQurban({
      ...qurban,
      checkboxProducts: tempCheckBoxProducts,
      products: tempQurbanProducts
    });
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    lang === 'fr'
      ? setFaqs(qurbanFranceFaqs)
      : lang === 'ar'
        ? setFaqs(qurbanArabicFaqs)
        : setFaqs(qurbanFaqs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white" showRegularImg={false}>
      {seo && (
        <Helmet>
          <title>{`${seo[title.toLowerCase()]['title']}`}</title>
          <meta name="title" content={`${seo[title.toLowerCase()]['title']}`} />
          <meta name="description" content={`${seo[title.toLowerCase()]['description']}`} />
        </Helmet>
      )}
      {/* /* -------------------------------------------------------------------------- */}

      {/* --------------------------- Video Section ----------------------------------- */}
      <div className="md:container mx-auto md:py-16">
        <div className="relative overflow-hidden flex flex-wrap">
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <YoutubeVideoHero src={`https://www.youtube.com/embed/IKvFUTs8Etc?controls=0&autoplay=1&loop=1&mute=1&playlist=IKvFUTs8Etc`} className={'md:!h-[800px] lg:!h-[1000px]'} />
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* ------------------------------- Products Section ---------------------------- */}
      {/* <QurbanPalestineTabs
        title={title}
        // headerText={`Help families in Palestine feel the joys of Eid by sharing your ${title}!`}
        headerText={lang === 'ar' ? `ساعد العائلات في فلسطين على الشعور ببهجة العيد من خلال مشاركة الأضاحي!` : lang === 'fr' ? `Aidez les familles palestiniennes à célébrer l’Aïd en partageant votre ${title}!` : `Help families in Palestine feel the joys of Eid by sharing your ${title}!`}
        qurbanProducts={qurban}
        foodAidProducts={foodAid}
        zakatProducts={zakat}
        lang={lang}
      /> */}
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- helped us ------------------------------- */}
      <div className="md:container md:mx-auto px-4" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <div className="flex flex-col py-8 md:py-16">
          <p className={`md:text-lg text-[#78716C] ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium text-left sm:pr-12'} pr-0`}>
            {lang === 'fr'
              ? <>
                Cette année, pour l’Aïd al-Adha, de nombreuses familles en Palestine pourraient se retrouver privées de la joie fondamentale de partager un repas sain. Le conflit actuel et les conditions de vie difficiles omniprésentes limitent l’accès aux ressources de base, notamment à une alimentation nutritive. Ensemble, nous pouvons changer les choses grâce à votre Qurban.
                <br />
                <br />
                Notre équipe MATW œuvre sans relâche pour fournir des ressources d’urgence sur le terrain. En collaboration avec la Jordanian Hashemite Charity Organisation (JHCO), nous avons envoyé à ce jour plus de 100 camions humanitaires, remplis de produits essentiels. Nous nous efforçons désormais de répandre la joie pendant cette période sacrée de l’Aïd al-Adha, en apportant une source vitale de protéines de haute qualité aux familles dans le besoin, aux prises avec l’insécurité alimentaire.
                <br />
                <br />
                Grâce à notre action, nous faisons en sorte que des familles entières puissent célébrer dans la gaieté en profitant de votre don de Qurban, qui sera livré frais pour l’Aïd. Que vous fassiez don d’un bœuf entier ou que vous décidiez de réaliser une simple contribution à l’achat d’une bête, votre participation aura des retombées tangibles.
                <br />
                <br />
                À travers elle, vous remplissez non seulement une obligation religieuse, mais vous nourrissez aussi votre foi, et êtes source de bonheur pour une famille palestinienne en cette période d’incertitude.
              </>
              : lang === 'ar'
                ? <>
                  في عيد الأضحى هذا العام، قد تجد العديد من الأسر في فلسطين أن فرحة المشاركة في وجبة لذيذة معًا خارجة عن نطاق الإمكانات. في ظل النزاع المستمر والصعوبات، يكون الوصول إلى الموارد الأساسية، بما في ذلك الطعام الغذائي الغني بالعناصر الغذائية، محدودًا. معًا، يمكننا تغيير ذلك من خلال تقديم ذبيحتكم.
                  <br />
                  <br />
                  فريقنا في MATW يعمل بجد على أرض الواقع لتقديم المساعدة العاجلة. حتى الآن، وبالتعاون مع منظمة الهاشمي الخيرية الأردنية، قمنا بإرسال أكثر من 100 شاحنة مساعدات محملة بالضروريات. الآن، نعمل على نشر السعادة خلال هذا الوقت المقدس من عيد الأضحى للعائلات المحتاجة من خلال توفير مصدر حيوي من البروتين عالي الجودة لأولئك الذين يعانون من نقص في الأمن الغذائي.
                  <br />
                  <br />
                  سيضمن توصيل ذبيحتكم أن تشارك الأسر في فرحة عيد الأضحى عندما يتلقون تبرعكم بذبيحة طازجة يوم عيد الأضحى. سواء كنتم تتبرعون ببقرة كاملة أو تساهمون في حصة من البقرة، فإن كل تبرع له تأثير معنوي عميق.
                  <br />
                  <br />
                  بملء هذه الواجبات الدينية، لا تقومون بتغذية إيمانكم فقط، بل أنتم أيضًا توفرون السعادة لعائلة فلسطينية في زمن الشك والتردد.
                </>
                : <>
                  This Eid Al-Adha, many families in Palestine may find that the basic joy of sharing a wholesome meal together is out of reach. Amidst the ongoing conflict and hardship, access to basic resources are limited including nutritious food. Together, we can change that through the delivery of your {title}.
                  <br />
                  <br />
                  Our MATW team has been actively working on the ground delivering urgent relief items. To date, our team, together with the Jordanian Hashemite Charity Organisation, have sent in more than 100 aid trucks full of necessities. Now, we’re working towards spreading happiness during the sacred time of Eid Al-Adha to families in need by delivering a vital source of high quality protein to families struggling with food-insecurity.
                  <br />
                  <br />
                  Our {title} delivery will ensure that families share in the joys of Eid when they receive your {title} donation delivered fresh on Eid. Whether you donate an entire cow or contribute to a cow share, every donation has a meaningful impact.
                  <br />
                  <br />
                  Not only are you fulfilling your obligation, you’re feeding your Iman and delivering happiness to a Palestinian family in a time of uncertainty.
                </>
            }
          </p>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap mt-8 justify-center gap-x-0 sm:gap-x-8 gap-y-8">
          <div className={`basis-full md:basis-1/2 sm:bg-[url('../src/images/landings/qurban/muslim-arounds.jpg')] bg-center bg-cover rounded-md`}>
            <img className="sm:hidden" src={muslimAroundImg} alt="img3" />
          </div>
          <div className={`basis-full md:basis-1/2 flex flex-col justify-center ${lang === 'ar' ? 'font-[AlmariMedium]' : ''}`}>
            <div className="text-[25px] md:text-[40px] text-[#253A7E] font-bold leading-[30px] md:leading-[45px]">
              {lang === 'fr'
                ? `Ayez confiance en nous pour votre Qurban`
                : lang === 'ar'
                  ? `مسلمون حول العالم (MATW) من أجل أضاحيكم`
                  : `Muslims Around The World (MATW) for YOUR ${title}`
              }
            </div>
            <p className={`md:text-lg text-[#F60362] mt-3 sm:mt-6 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium text-left'}`}>
              {lang === 'fr'
                ? `Cette année, compte tenu des coûts croissants, notre équipe MATW s’est engagée à proposer deux options pour vous aider à remplir votre obligation en Palestine.`
                : lang === 'ar'
                  ? `نظرًا لارتفاع التكاليف، التزم فريقنا في MATW بتقديم خيارين لأداء واجبك في فلسطين هذا العام.`
                  : `Due to rising costs, our MATW team has committed to offering two options to fulfill your obligation in Palestine this year.`
              }
            </p>
            <p className={`md:text-lg text-[#78716C] pr-0 ${lang === 'ar' ? 'font-[AlmariMedium] sm:pl-12' : 'font-brandingMedium text-left sm:pr-12'}`}>
              {lang === 'fr'
                ? <>
                  La première consiste à contribuer partiellement à l’achat d’un bœuf, et la seconde à faire don d’un bœuf entier.
                  <br />
                  <br />
                  Les familles les plus vulnérables, qui comptent notamment des orphelins, des veuves et des personnes âgées, seront prioritaires pour recevoir une viande sacrificielle de la plus haute qualité, fraîchement livrée pour l’Aïd.
                  <br />
                  <br />
                  Toutes les commandes de Qurban des donateurs de MATW sont traitées et préparées pendant l’Aïd, afin de garantir leur fraîcheur : aucune minute n’est ainsi perdue au cours de ces célébrations du dixième au treizième jour de Dhou al-hijja. Le Qurban est fraîchement préparé et livré aux plus démunis, chaque panier contenant 3 à 5 kg de viande destinés à nourrir une famille de 5 à 8 personnes.
                </>
                : lang === 'ar'
                  ? <>
                    الخيار الأول هو المساهمة في حصة من بقرة، والخيار الثاني هو التبرع ببقرة كاملة.
                    <br />
                    <br />
                    سيتم توصيل قرباننا بأعلى جودة ممكنة، طازجًا في يوم العيد. يُعطى الأولوية للأسر الأكثر ضعفًا، مثل الأيتام والأرامل وكبار السن، لاستلام لحم القربان بأفضل جودة.
                    <br />
                    <br />
                    جميع طلبات القربان التي نتلقاها من المتبرعين حول العالم تُعالج وتُجهز طازجة في يوم العيد، من اليوم العاشر إلى اليوم الثالث عشر من ذو الحجة. كل حزمة قربان تحتوي على ما بين 3 و 5 كيلوغرامات من اللحم، وتكفي لإطعام عائلة تتكون من 5 إلى 8 أشخاص.
                  </>
                  : <>
                    The first is the contribution to the share of a cow and the second is the donation of an entire cow.
                    <br />
                    <br />
                    Our {title} will be delivered with the highest quality, fresh on Eid. The most vulnerable families are prioritised to receive the best quality {title} meat. This includes orphans, widows and the elderly.
                    <br />
                    <br />
                    All of Muslim Around The World’s {title} orders received from donors are processed and done fresh on Eid. From the 10th to 13th days of Dhul Hijjah, not a single moment is spared. {title} is processed and delivered fresh to vulnerable families with each {title} pack containing 3-5kg of meat and feeding a family of 5-8 people.
                  </>
              }
            </p>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>
          {lang === 'fr'
            ? `Comment procède-t-on ?`
            : lang === 'ar'
              ? `كيف يعمل؟`
              : `How does it work?`
          }
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-lg space-y-4 ${lang === 'ar' ? 'font-[AlmariMedium] font-medium' : 'font-brandingMedium'}`}>
          <p>
            {lang === 'fr'
              ? <>
                Notre processus est simple. À l’approche du Qurban, faites simplement un don et notre équipe MATW s’occupera du reste. Sélectionnez d’abord l’endroit où vous souhaitez envoyer votre Qurban, ainsi que les quantités que vous voulez offrir. Notre équipe achète et contrôle le bétail à l’avance pour vérifier que les animaux répondent bien aux exigences. Votre Qurban est ensuite préparé pendant l’Aïd et distribué aux familles les plus pauvres.
                <br />
                <br />
                Récoltez les fruits de vos bonnes actions au cours des premiers jours bénis de Dhou al-hijja, les 10 jours les plus sacrés de l’année ! Faites don de votre Qurban dès aujourd’hui.
              </>
              : lang === 'ar'
                ? <>
                  تعتمد عمليتنا على البساطة. عندما يحين وقت التبرع بالقربان، ببساطة قم بالتبرع وسيقوم فريقنا في MATW بالبقية. ابدأ بتحديد مكان إرسال قربانك والكمية التي ترغب في التبرع بها. يتم شراء وفحص الماشية مسبقًا بعناية من قبل فريقنا للتأكد من أن الحيوانات تلبي كافة المتطلبات. يتم معالجة قربانك خلال عيد الأضحى وتوزيعه على أسر الأشد فقرًا!
                  <br />
                  <br />
                  اجنِ الأجور في أفضل الأيام العشرة في السنة - أيام العشر الأولى المباركة من ذو الحجة! قدم قربانك اليوم واحصُل على مكافأةٍ عظيمةٍ بفضل عطائك الكريم.
                </>
                : <>
                  Our process is simple. When the time comes to give your {title} donation, simply donate and our MATW team takes care of the rest. Start by selecting where you’d like to send your {title} and the quantity you’d like to donate. The livestock is purchased and checked ahead of time with our team ensuring the animals meet the checking requirements. Your {title} is then processed during Eid and distributed to the poorest families!
                  <br />
                  <br />
                  Reap the rewards of the 10 best days of the year - the blessed first days of Dhul Hijjah! Give your {title} today.
                </>
            }
          </p>
        </div>
      </div>

      {/* /* --------------------------------- Images --------------------------------- */}
      <QurbanSlider images={images} />
      {/* /* -------------------------------------------------------------------------- */}

      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h2 className={`text-[#253B7E] sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'text-left font-brandingBold'}`}>
          {/* {title} <span className="text-[#F60362]"> FAQ’s</span> */}
          {lang === 'fr'
            ? <><span className="text-[#F60362] mr-1">FAQ</span>sur le Qurban</>
            : lang === 'ar'
              ? <>الأسئلة الشائعة حول<span className="text-[#F60362] mr-1">الأضاحي</span></>
              : <>{title} <span className="text-[#F60362]"> FAQ’s</span></>
          }
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[0].question.replaceAll("Qurban", title)}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[0].answer.replaceAll("Qurban", title)}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[1].question.replaceAll("Qurban", title)}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[1].answer.replaceAll("Qurban", title)}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className={`text-[#253B7E] text-[18px] md:text-2xl ${lang === 'ar' ? 'font-bold font-[AlmariMedium]' : 'font-brandingBold'}`}>{faqs[2].question.replaceAll("Qurban", title)}</p>
            <p className={`text-base ${lang === 'ar' ? 'font-medium font-[AlmariMedium]' : 'font-brandingMedium'} mt-3 text-[#78716C]`}>{faqs[2].answer.replaceAll("Qurban", title)}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {faqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion
                    key={`keyallfaq${index}`}
                    shadow
                    title={item.question}
                    index={index}
                    level={1}
                    noBorder={true}
                    className="!text-[#253B7E] text-lg font-brandingBold"
                  >
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* -------------------------------------Impact------------------------------- */}
      {/* <section className='w-full py-4 bg-primaryMain'>
        <div className='md:container mx-auto flex flex-col xl:flex-row items-center gap-y-4 gap-x-3 justify-between'>
          <h2 className='md:w-[330px] font-gotcha text-[35px] leading-[50px] tracking-[2.1px] text-white'>Add impact to your inbox</h2>
          <input type="text" placeholder='Full Name' className='w-[350px] xl:w-[225px] px-5 py-3 rounded bg-transparent border border-white placeholder:text-white text-sm outline-none hover:opacity-90 transition text-white'/>
          <input type="text" placeholder='Enter Email' className='w-[350px] xl:w-[225px] px-5 py-3 rounded bg-transparent border border-white placeholder:text-white text-sm outline-none hover:opacity-90 transition text-white'/>
          <CustomButton
            className='min-w-[140px] w-[350px] xl:w-fit !h-[50px] !rounded-[10px] hover:!bg-[#f60362] hover:opacity-90 transition'
            title={'Subscribe'} />
          <div className="w-[350px] xl:w-[284px] flex items-start text-[10px]">
            <input
              className="relative float-left mt-1 mr-1 h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-[#00a3da] checked:bg-[#00a3da] checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-[#00a3da] checked:focus:bg-[#00a3da] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
              type="checkbox"
              onChange={(e) => 0}
              // value={value}
              // checked={checked}
              id={"subscribe-terms-conditions-checkbox"}
            />
            <label
              className={`hover:cursor-pointer text-white w-[320px] xl:w-[257px] leading-3 font-brandingMedium  flex items-start flex-col sm:flex-row`} htmlFor={"subscribe-terms-conditions-checkbox"}>
                By clicking 'Subscribe' I accept the terms and conditions of the privacy policy and consent to receive email updates and stories from MATW  in compliance with GDPR.
            </label>
          </div>
        </div>
      </section> */}
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default QurbanPalestineLandingPage;