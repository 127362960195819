import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage, selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import SeoData from '../../utils/seo.json';
import { Link } from 'react-router-dom';
import { selectLastOrders, selectProducts } from '../../store/products';
import { OurPromiseSection } from '../../components/general';
import iftarMealsImg from '../../images/landings/ramadan/iftar-meals.jpg';
import feedOrphanImg from '../../images/landings/ramadan/feed-orphan.jpg';
import waterWellImg from '../../images/home/water-well.jpg';
import { Helmet } from 'react-helmet';
import waterWellSubPhoto from '../../images/landings/ramadan/water-well-sub.png';
import orphansSubPhoto from '../../images/landings/ramadan/orphans-sub.png';
import projNoorSubPhoto from '../../images/landings/ramadan/projectnoor-sub.png';
import CarouselSection from './carouselSection';
import NewCountUp from '../../components/general/newCountUp';
import BlessingAndMercy from './components/blessing&mercy';
import SadaqahJariyahTabs from './components/sadaqaJariyahTabs';
import AliBanatLegacy from './components/aliBanatLegacy';
import GiveWithConfidence from '../ukHome/giveWithConfidence';
import CustomButton from '../../components/general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import WidgetHome from '../../components/widgets/widgetHome';
import AppealWidget from '../MATWAppeal/components/appealWidget';
import { appealProductsCodes, endDateOfAppealCampaign, startDateOfAppealCampaign } from '../MATWAppeal/details';
import refugeeImg from '../../images/landings/palestine-refugee/Help-Rebuild-Lives-for-Palestinians-escaping-conflict.jpg';
import limbsImg from '../../images/landings/palestine/big-Limbs_of_Hope_Campaign_Website_Image.jpg';
import services from '../../services';
import masjidPhoto from '../../images/home/general-masjid.jpg';
import waterImg from '../../images/landings/appeals/offrez_de_leau_offrez_lavie_PAGE ACCUEIL-.png';
import HomePageTabContent from './components/tab-content';
import ConvertUSD from "../../utils/convertUSD";

const HomePageUAT = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [addProduct] = useHandleAddProduct();
  const products = useSelector(state => selectProducts(state));
  const orders = useSelector(state => selectLastOrders(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [topAppealsProducts, setTopAppealsProducts] = useState([0, 0, 0, 0]);
  const [appealProducts, setAppealProducts] = useState([0, 0, 0]);
  const [basicWaterWellProducts] = useState({
    title: 'BASIC WATER WELL',
    description: 'Donate to provide clean water to communities that need it is a way to make a tangible and life-changing impact.',
    img: waterWellImg,
    products: [],
    alt: 'Muslim Charity to build a water well',
  });
  const [iftarMealsProducts] = useState({
    title: 'Iftar Meals',
    description: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan...',
    img: iftarMealsImg,
    products: [],
  });
  const [feedOrphanProducts] = useState({
    title: 'FEED AN ORPHAN FOR 30 DAYS',
    description: 'Help provide daily nutritious staple meals for an orphaned child every day this Ramadan!',
    img: feedOrphanImg,
    products: [],
  });
  const legacy = [
    {
      image: 'https://cdn.matwproject.org/static/media/view-our-reports.3611829cf531f62b84d3.jpg',
      title: 'VIEW OUR REPORTS',
      subTitle: '',
      bottomText: 'LEARN MORE',
      link: '/annual-reports',
      titleClasses: 'md:!text-[18px] !leading-[18px] md:font-bold !h-10',
      alt: 'Muslim Charity to help Muslims around the world',
    },
    {
      image: 'https://matwproject.org/static/media/volunteer-with-us.6070df240dea760bdc44.jpg',
      title: 'VOLUNTEER WITH US',
      subTitle: '',
      bottomText: 'SIGN UP',
      link: '/Volunteer',
      titleClasses: 'md:!text-[18px] !leading-[18px] md:font-bold !h-10',
      alt: 'donate to muslim charity to help those in need',
    },
    {
      image: 'https://cdn.matwproject.org/static/media/allAppeals.ca186416ea29cf46fad7.jpg',
      title: 'ALL APPEALS',
      subTitle: '',
      bottomText: 'VIEW ALL APPEALS',
      link: '/all-appeals',
      titleClasses: 'md:!text-[18px] !leading-[18px] md:font-bold !h-10',
      alt: 'Muslim Donation for our islamic appeals',
    },
    {
      image: 'https://cdn.matwproject.org/static/media/instaFollow.8c2d0e8ab5f7aff63450.jpg',
      title: 'FOLLOW US ON INSTAGRAM',
      subTitle: '',
      bottomText: 'STAY CONNECTED',
      link: 'https://www.instagram.com/matw_project/',
      titleClasses: 'md:!text-[18px] !leading-[18px] md:font-bold !h-10',
      alt: 'Islamic Donations to our islamic charity',
    },
  ];
  const language = useSelector(selectLanguage);
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSadaqahProducts = [];
    let tempFoodAidProducts = [];
    let tempZakateProducts = [];
    let tempOrphansProducts = [];
    let tempFeedOrphansProducts = [];
    let tempTopAppealsProducts = [];
    let tempBasicWaterWellProducts = [];
    let tempWaterWellProducts = [];
    let tempIftarMealsProducts = [];
    let tempSelectedProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // if (inner_item_product.creator === 'AO-EMR-WNT-GEN-24-06') {
          //   setHotMealsProduct(inner_item_product);
          // }
          // Subscription Tab Starts

          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            // Water Well
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Water Wells',
              subTitle:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              subTitleCopy:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              image_link: waterWellSubPhoto,
              navigationLink: '/sadaqah-jariyah/build-a-water-well',
            });
            tempSadaqahProducts[1] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
            // Food and Water Distribution
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'ORPHANS',
              subTitle:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              subTitleCopy:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              image_link: orphansSubPhoto,
              navigationLink: '/orphans',
            });
            tempSadaqahProducts[2] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
            // Sadaqah Jariyah
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Project Noor',
              subTitle: `Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.`,
              subTitleCopy:
                'Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.',
              status: '1',
              image_link: projNoorSubPhoto,
              navigationLink: '/sadaqah-jariyah/emergency',
            });
            tempSadaqahProducts[3] = inner_item_product;
          }
          // Subscription Tab Ends

          // Start Top Appeals
          // if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') tempTopAppealsProducts[0] = inner_item_product
          // if (inner_item_product.creator === 'GD-MP015') tempTopAppealsProducts[1] = inner_item_product
          // if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') tempTopAppealsProducts[3] = inner_item_product
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestine',
            });
            tempTopAppealsProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestinian refugees',
            });
            tempTopAppealsProducts[3] = inner_item_product;
          }

          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempBasicWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') tempBasicWaterWellProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-IBW-2023-01-031') tempBasicWaterWellProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') tempBasicWaterWellProducts[3] = inner_item_product;
          // End Top Appeals

          // Start Food Aid
          if (inner_item_product.creator === 'RM24-FA-010') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/ramadan/fidya-kaffarah?product=kafarrah',
              staticLinkText: 'CALCULATE YOUR KAFFARAH',
              // linkBtnClasses: 'lg:!text-base !font-normal'
            });
            tempFoodAidProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'RM24-FA-011') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/ramadan/fidya-kaffarah?product=fidya',
              staticLinkText: 'CALCULATE YOUR FIDYA',
              // linkBtnClasses: 'lg:!text-base !font-normal'
            });
            tempFoodAidProducts[3] = inner_item_product;
          }

          // End Food Aid

          // Start Zakat
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/zakat',
              staticLinkText: 'GIVE ZAKAT AL MAL',
            });
            tempZakateProducts[1] = inner_item_product;
            tempZakateProducts[0] = Object.assign({}, inner_item_product, {
              alternativeName: 'ZAKAT CALCULATOR',
              image_link: '/images/zakat/zakat-cal.jpg',
              isStaticProduct: true,
              staticLink: '/zakat-calculator',
              staticLinkText: 'CALCULATE YOUR ZAKAT',
              // linkBtnClasses: 'lg:!text-base !font-normal'
            });
          }
          // End Zakat

          // Start Orphan
          if (inner_item_product.creator === 'RM24-OP-014') tempOrphansProducts[1] = inner_item_product;
          // End Orphan

          // Start Water well
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') tempWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-02-028') tempWaterWellProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempWaterWellProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-PSPW-2023-01-027') tempWaterWellProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-L2W-2023-01-027') tempWaterWellProducts[4] = inner_item_product;
          // End Water well

          // palestineEmergencyProducts
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            tempSelectedProducts[0] = Object.assign({}, inner_item_product, {
              alternativeName: 'Construire un puits',
              isStaticProduct: true,
              staticLink: '/construire-un-puits',
              staticLinkText: 'En savoir +',
            });
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-23-01-204') {
            tempSelectedProducts[1] = Object.assign({}, inner_item_product, {
              alternativeName: 'Soutien aux orphelins',
              isStaticProduct: true,
              staticLink: '/soutien-aux-orphelins',
              staticLinkText: 'En savoir +',
            });
          }
          if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') {
            tempSelectedProducts[2] = Object.assign({}, inner_item_product, {
              alternativeName: 'Aide alimentaire',
              isStaticProduct: true,
              staticLink: '/aide-alimentaire',
              staticLinkText: 'En savoir +',
            });
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            tempSelectedProducts[3] = Object.assign({}, inner_item_product, {
              alternativeName: 'Urgence Palestine',
              isStaticProduct: true,
              staticLink: '/sauver-des-vies-a-gaza',
              staticLinkText: 'En savoir +',
            });
          }
          if (inner_item_product.creator === 'MKD-MN-001') {
            tempSelectedProducts[4] = Object.assign({}, inner_item_product, {
              alternativeName: 'Faire un don libre',
              isStaticProduct: true,
              staticLink: '/appeals/where-most-needed',
              staticLinkText: 'En savoir +',
            });
          }
          if (inner_item_product.creator === 'RM24-ZM-013') {
            tempSelectedProducts[5] = Object.assign({}, inner_item_product, {
              alternativeName: 'Donner sa Zakat Al Maal',
              isStaticProduct: true,
              staticLink: '/zakat-al-maal',
              staticLinkText: 'En savoir +',
            });
          }
          // if (inner_item_product.creator === 'ORP-DIS-SOY-2023-01-032') {
          //   tempSelectedProducts[1] = inner_item_product;
          // }
          // if (inner_item_product.creator === 'FWD-DIS-AR-GN-003') {
          //   tempSelectedProducts[2] = inner_item_product;
          // }

          // if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
          //   tempSelectedProducts[4] = inner_item_product;
          // }
          // if (inner_item_product.creator === 'MD-AA-2024-03') {
          //   tempSelectedProducts[5] = inner_item_product;
          // }

          // Start Zakat

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempTopAppealsProducts[2] = basicWaterWellProducts;
    tempTopAppealsProducts[2].products = tempBasicWaterWellProducts;
    tempFoodAidProducts[0] = iftarMealsProducts;
    tempFoodAidProducts[0].products = tempIftarMealsProducts;
    tempOrphansProducts[0] = feedOrphanProducts;
    tempOrphansProducts[0].products = tempFeedOrphansProducts;

    tempSadaqahProducts = tempSadaqahProducts.filter(n => n);
    tempTopAppealsProducts = tempTopAppealsProducts.filter(n => n);
    tempFoodAidProducts = tempFoodAidProducts.filter(n => n);
    tempZakateProducts = tempZakateProducts.filter(n => n);
    tempOrphansProducts = tempOrphansProducts.filter(n => n);
    tempWaterWellProducts = tempWaterWellProducts.filter(n => n);
    tempSelectedProducts = tempSelectedProducts.filter(n => n);

    setSelectedProducts(tempSelectedProducts);
    // setIftarProducts(tempIftarProducts);
    // setFeedOrphanProducts(tempFeedOrphansProducts);

    setTopAppealsProducts(tempTopAppealsProducts);
  };
  const getLatestOrders = async _currency => {
    let req = {
      products: appealProductsCodes,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      end_date: endDateOfAppealCampaign,
    };
    let tempAppealProducts = [];
    let tempPalestineTotalRaised = 0;
    let tempPalestineTotalSupporters = 0;
    let tempEgyptianTotalRaised = 0;
    let tempEgyptianTotalSupporters = 0;
    let tempLimbsTotalRaised = 0;
    let tempLimbsTotalSupporters = 0;
    let tempPalestineEmergency = {
      product_id: 592,
      product_category_id: 65,
      product_name: `ACCÈS À L'EAU`,
      product_creator: 'EMR-DIS-PSE-2023-01',
      product_description: `L'accès à l'eau potable est essentiel à la survie humaine et constitue un droit humain fondamental.`,
      product_image_link: waterImg,
      total_count: '',
      total_sale: '',
      days: null,
      total_supporters: '',
      currency: _currency,
      goal: 1000000,
      campaign: 'water',
      link: 'https://www.launchgood.com/v4/campaign/offrez_de_leau_offrez_lavie',
    };
    let tempPalestineRefuggeSettlement = {
      product_id: 601,
      product_category_id: 66,
      product_name: 'GAZA NE PEUT PAS ATTENDRE!',
      product_creator: 'EMR-DIS-PSE-2023-01',
      product_description: `Depuis près d'un an, trop de personnes souffrent depuis bien trop longtemps en raison de la dévastation laissée par le conflit en Palestine.`,
      product_image_link: 'https://cdn.matwproject.org/images/products/1723456165_large-palestineemergency.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 500000,
      campaign: 'orphan',
      link: 'appeals/save-lives-in-gaza',
    };
    let tempLimbsOfHope = {
      product_id: 638,
      product_category_id: 73,
      product_name: `LES PROTHESES DE L'ESPOIR`,
      product_creator: 'EMR-PAL-MASU-210',
      product_description: `Votre générosité peut transformer une vie. En faisant un don à notre collecte de fonds pour les prothèses, vous contribuez à fournir un membre artificiel à une personne dans le besoin.`,
      product_image_link: 'static/media/big-Limbs_of_Hope_Campaign_Website_Image.ca145fb659ac850ec7b9.jpg',
      total_count: '',
      total_sale: '',
      total_supporters: '',
      currency: _currency,
      goal: 1000000,
      campaign: 'food',
      link: 'appeals/100%-of-a-prosthetic-limb',
    };
    try {
      const response = await services.getLatestOrdersV2(req);
      const launchgoodResponse = await services.getLaunchgoodNumbers();
      // setIsLoadedLatestOrders(true)
      let waterAidProducts = [
        'EM-SDQ-EM-02-2023',
        'EMR-DIS-BFPL-23-01-204',
        'FST-DEL-F50-2023-01-009',
        'MKD-GEN-MED-2023-01-122',
        'ORP-DIS-OMT-2023-01-042']
      let allOrders = response.data;
      let launchgoodData = launchgoodResponse.data;
      tempPalestineTotalRaised += Number(ConvertUSD("EUR", launchgoodData.donated.replace(",", ""), false));
      tempPalestineTotalSupporters += Number(launchgoodData.supporters.replace(",", ""));
      allOrders.map(item => {
        if (item.product_category_id === 66) {
          tempEgyptianTotalRaised += item.total_sale;
          tempEgyptianTotalSupporters += item.total_supporters;
        }
        if (item.product_category_id === 73) {
          tempLimbsTotalRaised += item.total_sale;
          tempLimbsTotalSupporters += item.total_supporters;
        }
        return item;
      });
      tempPalestineEmergency.total_sale = tempPalestineTotalRaised;
      tempPalestineEmergency.total_supporters = tempPalestineTotalSupporters;
      tempPalestineEmergency.goal = Number(launchgoodData.goal.replace(",", ""));
      tempPalestineEmergency.days = Number(launchgoodData.daysLeft.replace(",", ""))
      tempPalestineRefuggeSettlement.total_sale = tempEgyptianTotalRaised;
      tempPalestineRefuggeSettlement.total_supporters = tempEgyptianTotalSupporters;
      tempLimbsOfHope.total_sale = tempLimbsTotalRaised;
      tempLimbsOfHope.total_supporters = tempLimbsTotalSupporters;
      tempAppealProducts[0] = tempPalestineEmergency;
      tempAppealProducts[1] = tempLimbsOfHope;
      tempAppealProducts[2] = tempPalestineRefuggeSettlement;
      // console.log("appeals v2=",tempAppealProducts)
      setAppealProducts(tempAppealProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    selectedCurrencyStore && getLatestOrders(selectedCurrencyStore);
    // getOrders(selectedCurrencyStore)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore, products]);
  /* -------------------------------------------------------------------------- */
  return (
      <Layout className="bg-white" isRamadanPage>
        {seo && (
            <Helmet>
              <title>{`${seo['home']['title']}`}</title>
              <meta name="title" content={`${seo['home']['title']}`}/>
              <meta name="description" content={`${seo['home']['description']}`}/>
            </Helmet>
        )}

        <div className="md:container md:mx-auto mt-0 md:mt-2- md:mb-4">
          <CarouselSection/>
        </div>
        <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)}/>

        {/* /* ------------------------------ Appeal Widget ----------------------------- */}
        {/* <AppealWidget fundraiserProducts={fundraiserProducts}/> */}
        {/* /* ------------------------------- Tab -------------------------------------- */}
        <section className="bg-white">
          <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
            <div
                className='text-[#253B7E] text-[45px]  text-center sm:text-[50px] font-gotcha leading-[50px] mb-2 relative'>
              Parmi nos actions
            </div>
            <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
              <HomePageTabContent showArrows={true} lang="fr" products={selectedProducts} currency={selectedCurrency}/>
            </div>
          </div>
        </section>
        {/* /* ------------------------------- Our Promise ------------------------------ */}
        <OurPromiseSection alt={'Muslim Charity with 100% donation policy'} lang={'fr'}/>

        <section className="bg-[#253B7E]">
          <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
            <div
                className='text-white  text-[45px]  text-center sm:text-[50px] font-gotcha leading-[50px] mb-2 relative'>
              Nos collectes en cours
            </div>
            <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
              <AppealWidget className="bg-transparent !py-0" appealProducts={appealProducts} isHomePage/>
            </div>
          </div>
        </section>
        {/* /* -------------------------------- Ramadan Message ------------------------- */}
        {/* <BlessingAndMercy
        lang={'fr'}
        buttons={
          <>
            <CustomButton
              link={`../pdf/MATW_Ramadan_2024_Mid-Report_3_FINAL.pdf`}
              target="_blank"
              title="Voyez votre impact ici"
              className="min-w-[200px]"
              lang={language}
            />
          </>
        }
      /> */}
        {/* /* -------------------------------- Why Zakat ------------------------------- */}
        {/* <div className="w-full flex flex-wrap justify-center items-center md:container mx-auto pt-6 px-4 md:px-0">
        <div className='basis-full flex gap-x-10 flex-wrap sm:flex-nowrap'>
          <div className="basis-full sm:basis-1/2"><img
            src="https://cdn.matwproject.org/static/media/zakat-image.58875a1bc45f57c483d6.jpg"
            alt="Islamic Donation for emergency appeals" className='rounded-lg' /></div>
          <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
            <p className='text-[#253B7E] text-[25px] leading-[30px] my-4 font-bold'>
              La Palestine a encore besoin<span className="text-[#F60362] ml-1">de vous!</span>
            </p>
            <p className='text-[#78716C] leading-[19px] sm:leading-6 md:text-[18px] font-brandingMedium-'>
              Le peuple palestinien continue de souffrir alors qu'une famine se profile et que la faim devient généralisée.
              Les familles sont forcées de vivre avec moins d'une boîte de haricots par jour. Des rapports montrent que des nouveau-nés malnutris sont si petits qu'ils pèsent un peu plus d'un kilo. La désespérance face à la nourriture est répandue, touchant d'abord les plus vulnérables, y compris les enfants orphelins. L'assistance alimentaire pour lutter contre la famine est désespérément nécessaire.
            </p>
            <p className='text-[#253B7E] text-[18px] leading-[25px] my-4 font-bold'>
              Aidez-nous à sauver les familles et les orphelins palestiniens avec votre Sadaqah aujourd'hui.
            </p>
            <div className="flex items-center flex-col sm:flex-row mt-6 w-full">
              <CustomButton onClick={() => {
                addProduct({ product: topAppealsProducts[0], currency: selectedCurrency })
              }} title='Faire un don' className='mb-2 sm:mb-0 min-w-[226px]' />
            </div>
          </div>
        </div>
      </div> */}
        {/* /* ------------------------------- Tabs ------------------------------------- */}

        {/* /* -------------------------------- Ali Banat -------------------------------  */}
        {/* <AliBanatLegacy lang={language} /> */}
        {/* /* --------------------------------- YouTube --------------------------------- */}
        <section
            className="w-full px-4 sm:px-20"
            style={{
              backgroundImage: 'url(/images/banners/Ali_Banat.webp)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            dir={language === 'ar' ? 'rtl' : 'ltr'}
        >
          <div className="md:container md:mx-auto flex flex-col flex-wrap">
            <div className="basis-full rounded-xl mt-6  p-2 lg:p-10 text-center">
              <p
                  className={`sm:px-10 text-center ${
                      language === 'ar' ? 'font-[AlmariMedium]' : 'font-gotcha'
                  } mb-10 text-[30px] sm:text-[60px] text-[#f60362]`}
              >
                <Link to={'/l-histoire-d-ali-banat'}>
                  Ali Banat <span className="text-[#000]">, sa Vie, son Héritage, son Impact.</span>
                </Link>
              </p>
              <div className="col-span-12 h-[200px]  sm:h-[300px] md:h-[400px] lg:h-[600px]">
                <iframe
                    className="video"
                    width={'100%'}
                    height={'100%'}
                    title={'youtube'}
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={`https://youtube.com/embed/Duf77T3uQnI?autoplay=0?feature=oembed`}
                />
              </div>
            </div>
          </div>
        </section>

        <NewCountUp/>
        {/* /* ----------------------------------------------------------------------- */}
        {/* <section className="md:container md:mx-auto mt-5 sm:mt-20 px-4 sm:px-10 md:px-20"
        dir={language === 'ar' ? 'rtl' : 'ltr'}>
        <div
          className="text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-3xl md:text-[40px] md:px-4 basis-full md:basis-10/12 mb-3">
          Get<span className="text-[#F60362] mx-1">involved</span>today!
        </div>
        <p className={`md:leading-7 text-[#78716C] text-center ${language === 'ar' ? 'font-[AlmariMedium]' : "font-['Montserrat']"} text-md lg:text-lg px-4 sm:px-20 md:px-30 lg:px-32`}>
          Build your legacy with MATW! Create a meaningful impact in this world and the hereafter through voluntary
          work, compassionate donations, and staying connected.
        </p>
        <div className="grid grid-cols-4 gap-2 md:gap-4 xl:gap-6 md:gap-y-10 mt-8">
          {legacy.map((item, index) => (
            <div key={index} className="col-span-2 sm:col-span-2 md:col-span-1">
              <GiveWithConfidence
                minHeight={'!min-h-[160px]'}
                alt={item.alt}
                image={item.image}
                subTitle={item.subTitle}
                title={item.title}
                des={item.bottomText}
                link={item.link}
                titleClasses={item.titleClasses}
              />
            </div>
          ))}
        </div>
      </section> */}
        {/* -------------------------------------Impact------------------------------- */}
      </Layout>
  );
};

export default HomePageUAT;
