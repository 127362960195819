import axios from 'axios';
const Base = process.env.REACT_APP_BASE_URL;
const Header = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    "X-user-matw": Header
  },
};
function monthlyTotalReport() {
  return axios.post(`${Base}/checkout/reports/monthly-transaction-total`,{status:'Paid'},config);
}
function monthlyPaymentMethodReport() {
  return axios.post(`${Base}/checkout/reports/monthly-transaction-count`,'',config);
}
function monthlyCurrenciesReport() {
  return axios.post(`${Base}/checkout/reports/monthly-currency-count`,'',config);
}
function monthlySubscriptionTypeReport() {
  return axios.post(`${Base}/checkout/reports/monthly-subscription-type-count`,'',config);
}
function monthlyHostTypeReport() {
  return axios.post(`${Base}/checkout/reports/monthly-host-type-count`,'',config);
}
function monthlyHostTransactionsReport() {
  return axios.post(`${Base}/checkout/reports/monthly-host-transactions`,'',config);
}
function monthlyProductPurchaseReport(product_id) {
  return axios.post(`${Base}/checkout/reports/monthly-product-purchase-count`,product_id,config);
}
function dailyProductsPurchaseReport(info) {
  return axios.post(`${Base}/checkout/reports/daily-qurban-product-purchase-count`,info,config);
}
function dailyProductsTransactionTotalReport(info) {
  return axios.post(`${Base}/checkout/reports/qurban-product-transaction-total`,info,config);
  // return axios.post(`${Base}/checkout/reports/daily-qurban-product-transaction-total`);
}
function dailyQurbanProductsPurchaseReport(info) {
  return axios.post(`${Base}/checkout/reports/daily-qurban-product-purchase-count`,info,config);
}
function dailyQurbanProductsTransactionTotalReport(info) {
  return axios.post(`${Base}/checkout/reports/qurban-product-transaction-total`,info,config);
  // return axios.post(`${Base}/checkout/reports/daily-qurban-product-transaction-total`);
}
function zohoReport(info) {
  return axios.post(`${Base}/checkout/zoho/multi-combine-export`,info,config);
}

function getFailedTrx(info) {
  return axios.post(`${Base}/checkout/stripe/failed-transactions?${info}`,config);
}
function getTrxs(info) {
  return axios.post(`${Base}/checkout/stripe/combine-export`,info,config);
}
function getAllTrxs(info) {
  return axios.post(`${Base}/checkout/stripe/multi-combine-order-export`,info,config);
}
function getMultiProducts(info) {
  return axios.post(`${Base}/checkout/stripe/multi-combine-products-export`,info,config);
}
function getMultiQurbanProducts(info) {
  return axios.post(`${Base}/checkout/reports/multi-combine-qurban-products-export`,info,config);
}
function sendReceipt(info) {
  return axios.post(`${Base}/checkout/stripe/send-receipt`,info,config);
}
function getSubscriptions() {
  // return axios.get(`${Base}/checkout/stripe/subscriptions`);
  return axios.get(`http://127.0.0.1:8000/subscriptions`,config);
}
function getTop20Products(info) {
  return axios.post(`${Base}/checkout/stripe/top-sale-products?domain=matwprojectfr.org`, info);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  monthlyTotalReport,
  monthlyPaymentMethodReport,
  monthlyCurrenciesReport,
  monthlySubscriptionTypeReport,
  monthlyHostTypeReport,
  monthlyProductPurchaseReport,
  dailyQurbanProductsPurchaseReport,
  dailyQurbanProductsTransactionTotalReport,
  zohoReport,
  monthlyHostTransactionsReport,
  dailyProductsPurchaseReport,
  dailyProductsTransactionTotalReport,
  getFailedTrx,
  getTrxs,
  sendReceipt,
  getAllTrxs,
  getMultiProducts,
  getMultiQurbanProducts,
  getSubscriptions,
  getTop20Products,
};
