import React from 'react';
import MaxWidthContainer from '../../../components/general/maxWidthContainer';

const BlessingAndMercy = props => {
  /* ----------------------------- Start variables ---------------------------- */

  /* -------------------------------------------------------------------------- */
  return (
    <section className="mb-0 md:mb-8 px-5 md:px-[40px] py-5 md:py-[40px] bg-[url('../src/images/ali-banat/feel-pattern.png')] bg-[#14a3dc2c]">
      <MaxWidthContainer className="bg-white p-5 md:p-[40px] md-p-[40px] rounded-[10px]">
        <p className="mx-auto mb-5 md:w-10/12 text-[25px] leading-7 md:leading-[50px] md:text-[40px] text-center text-[#093686] font-brandingBold">
          La vision d'Ali Banat était claire : bâtir un héritage pour aider chaque musulman dans le besoin à travers le monde.
        </p>
        <div className="space-y-3 md:space-y-5 text-[#78716C] font-BrandingMediumItalic md:text-lg md:text-center md:w-10/12 md:mx-auto mt-4">
          <p>
          Le projet MATW, initié par Ali Banat en tant qu'héritage de compassion, a grandi pour atteindre des millions de Muslims Around The World. Grâce à nos efforts collectifs, nous avons pu fournir une aide vitale, atteignant plus de <span className="text-[#093686] mx-1 font-bold">19 millions</span> de musulmans dans certaines des régions les plus pauvres et les plus vulnérables. Avec notre aide, MATW continue de fournir de la nourriture, de l'eau potable, des soins médicaux et un abri à ceux qui en ont désespérément besoin. Notre soutien transforme des vies, restaure l'espoir et construit un avenir meilleur pour l'Ummah. Ensemble, nous pouvons avoir un impact encore plus grand. Découvrez nos rapports d'impact, voyez la différence que nous faisons et continuez à soutenir cette cause qui change des vies. Chaque don compte.
          </p>
        </div>
        <div className="mx-auto mt-6 w-full flex flex-col md:flex-row justify-center items-center">{props.buttons}</div>
      </MaxWidthContainer>
    </section>
  );
};
export default BlessingAndMercy;
