import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import ProductBox from '../../components/general/productBox';
import ZakatWidget from '../zakat/zakatWidget';
import services from '../../services';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';

const MoroccoEmergencyLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [products, setProducts] = useState([0, 0, 0]);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getProductsByName = async name => {
    let tempCritical = [];
    try {
      const response = await services.categoryProductsByName(name);
      console.log('response get getProductsByName', response);
      setProducts(response.data.products);
      response.data.products.map(item => {
        if (Number(item.status) === 1) {
          if (item.name === 'Morocco Appeal') tempCritical.push(item);
          if (item.name === 'Tent for Morocco') tempCritical.push(item);
          if (item.name === 'Gas Heating for Morocco') tempCritical.push(item);
        }
        return item;
      });
      setProducts(tempCritical);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    getProductsByName('earthquake appeal');
    // window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white-">
      {seo && (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['morocco']['title']}`}</title>
          <meta name="title" content={`${seo['crisisAndEmergencies']['morocco']['title']}`} />
        </Helmet>
      )}
      <div className="w-full flex flex-wrap bg-[url('../public/images/landings/morocco/large-morocco-emergency-hero-banner-mobile.jpg')] 
      md:bg-[url('../public/images/landings/morocco/large-morocco-emergency-hero-banner-desktop.jpg')]
       bg-center sm:bg-center bg-cover md:py-8 max-[500px]:min-h-[150px] min-[501px]:min-h-[280px] sm:min-h-[340px] 
       lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0"></div>
      {/* /* ------------------------------- Widget on Mobile ---------------------------- */}
      <div className="w-full bg-[#F60362] p-4 flex justify-center items-center lg:hidden">
        <ZakatWidget
          hasSuggestPrice
          defaultProduct={{
            creator: 'EMR-MR-WMN-001-2023',
            label: 'Morocco Appeal',
            value: 293,
          }}
        />
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ---------------------- Section One - Title & Product --------------------- */}
      <div className="w-full flex flex-wrap justify-center md:container md:mx-auto px-4 md:px-0 py-8 md:pt-16">
        <h1 className="w-full text-2xl md:text-4xl text-[#253B7E] mb-6 text-center font-medium">Morocco Emergency Appeal</h1>
        <p className="w-full text-center mb-4 md:mb-8 text-lg md:text-xl">
          A 6.8-magnitude earthquake has devastated the people of Morocco. In its wake, it has left 1000+ people dead and many more homeless.
          <br />
          Donate now to support our brothers and sisters in Morocco
        </p>
        <div className="flex flex-row justify-center flex-wrap">
          {products.map((item, index) => {
            return (
              <div key={`products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
              </div>
            );
          })}
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------- Section Two - Most Needed Products ------------------- */}
      <div className="w-full flex flex-wrap justify-center md:container md:mx-auto rounded-xl mt-6 lg:min-h-[400px] bg-white p-6 lg:p-12 text-center shadow-lg px-4 md:px-0 py-8 md:py-16">
        <h1 className="w-full text-2xl md:text-4xl text-[#253B7E] mb-6 text-center font-medium">
          The earthquake in Morocco has resulted in loss of life and property,
          <br /> leaving thousands of people dead, homeless and critically injured.
        </h1>
        <p className="w-full text-center mb-4 md:mb-8 text-lg md:text-xl">
          A disaster leaves behind it a trail of grief, trauma, tragedy and loss for those who survive. The ones affected need our support at the
          earliest so they may get the chance to restart.
          <br />
          <br />
          No matter how little or big, every bit of help from you will make a huge impact in restoring the lives of those displaced. With your
          donations, we can provide them with essential needs such as food and water, shelter and healthcare. Let’s be their ray of hope and the
          answer to their duas by coming together in these hard times.
          <br />
          <br />
          Your support is their strength.
          <br />
          Donate now!
        </p>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};

export default MoroccoEmergencyLandingPage;
