import { useEffect, useRef, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import services from '../../services';
import { selectLanguage, selectSelectedCurrency, setSelectedCurrencyInStore } from '../../store/user';
import { StepHeader } from '../general';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
// import ShowCurrencies from '../../utils/showCurrencies';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import SelectCurrency from '../general/selectCurrency';
import 'react-toastify/dist/ReactToastify.css';
import { BASKET_CACHE_KEY, HandleLocalStorage, IsExpired } from '../../utils/handleLocalStorage';
import HandleShowRecurring from '../../utils/showRecurringOnDataLayer';
import AddOnProducts from './components/add-on-products';
import SubscriptionTypes from './components/subscriptionTypes';
// import StepOneProducts from './components/products';
import { toast } from 'react-toastify';
import Sticky from 'react-sticky-el';
import { Base64 } from 'js-base64';
import AdminCosts from './components/adminCosts';
import Select from 'react-select';
import profanityList from '../../utils/profanityList';
import Swal from 'sweetalert2';
import IsGTMScriptLoaded from '../../utils/isGTMScriptLoaded';
import { CloseIcon, InfoIcon, LeftArrowToolTipIcon, PlusIcon, RightArrowIcon } from '../general/customIcons';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { checkDomain } from '../../utils/checkDomain';
const styles = {
  control: base => ({
    ...base,
    border: 'none',
    boxShadow: '0px 0px 2px 0px rgb(220, 220, 220)',
    ':hover': {
      boxShadow: '0px 0px 8px 0px rgb(216, 216, 216)',
    },
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const StepOneWithLocalStorage = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const ProductID = params.get('id');
  const ProductCreatorParam = params.get('creator');
  const ProductCurrencyParam = params.get('currency');
  const ProductQuantityParam = params.get('quantity');
  const ProductRecurringParam = params.get('recurring');
  const [whereMostNeeded, setWhereMostNeeded] = useState();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [userProductsCount, setUserProductsCount] = useState(0);
  const [selectedSubscribe, setSelectedSubscribe] = useState('one-off');
  const [isBasketEmpty, setIsBasketEmpty] = useState(false);
  // const pixelID = localStorage.getItem('TikTokID')
  const [basketStates, setBasketStates] = useState({
    timestamp: Date.now() + 24 * 60 * 60 * 7 * 1000,
    stripe_customer_id: '',
    currency: selectedCurrencyStore,
    mode: selectedSubscribe === 'one-off' ? 'payment' : 'subscription',
    adminCost: 0,
    grandTotal: 0,
    total: 0,
    recurring: selectedSubscribe,
    paymentFlow: '',
    donor: {
      name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      postal_code: '',
      countryCode: '',
      country: '',
    },
    user: {
      currency: selectedCurrencyStore,
      email: '',
      id: '',
      name: '',
      phone: '',
      plaque_name: '',
    },
    products: [
      {
        product: null,
        quantity: 1,
        amount: 1,
        total: 1,
        currency: selectedCurrencyStore,
        recurring: selectedSubscribe,
        landing_page: '',
        onBehalf: 'Pour l’amour d’Allah SWT',
      },
    ],
  });
  const [productHasChangedIndex, setProductHasChangedIndex] = useState(0);
  const [allProducts, setAllProducts] = useState([]);
  const selectedSubscribeMessages = {
    year: (
      <span>
        You will donate this amount every year, you can{' '}
        <Link className="text-red-500" to="/cancel">
          cancel
        </Link>{' '}
        anytime.
      </span>
    ),
    month: (
      <span>
        You will donate this amount every month, you can{' '}
        <Link className="text-red-500" to="/cancel">
          cancel
        </Link>{' '}
        anytime.
      </span>
    ),
    week: (
      <span>
        You will donate this amount every week, you can{' '}
        <Link className="text-red-500" to="/cancel">
          cancel
        </Link>{' '}
        anytime.
      </span>
    ),
    // 'day': <span>You will donate this amount every day, you can <Link className='text-red-500' to='/cancel'>cancel</Link> anytime.</span>,
    'one-off': 'Ceci est un don unique, vous ne donnerez qu\'une seule fois.',
  };
  const refQuantity = useRef(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'].map(() => createRef()));
  const [isShowTooltip, setIsShowTooltip] = useState(-1);
  const language = useSelector(selectLanguage);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  const handleCurrency = (currency, tempBasket) => {
    if (tempBasket.products.length && tempBasket.products[0].product) {
      tempBasket.products.map(obj => handleRemoveFromCartDataLayer(obj));
      // let newTempGrandTotal = 0
      tempBasket.products.map((item, index) => {
        item.currency = currency;
        item.product.currency = currency;
        item.total = item.quantity * item.product[currency];
        item.amount = item.product[currency];
        // newTempGrandTotal += item.quantity * item.product[currency];
        handleAddToCartDataLayer(item, currency);
        return item;
      });
      tempBasket.currency = currency;
      // tempBasket.adminCost = isAdminCost ? Number(HandleShowAdminCost(currency)) : 0;
      // newTempGrandTotal = isAdminCost ? Number(newTempGrandTotal) + Number(HandleShowAdminCost(currency)) : Number(newTempGrandTotal);
      // tempBasket.grandTotal = newTempGrandTotal;
      let tempTotal = 0;
      tempBasket.products.map(item => {
        tempTotal += item.total;
        return null;
      });
      tempBasket.total = tempTotal;
      setBasketStates(tempBasket);
      HandleLocalStorage(tempBasket);
    } else {
      // let newTempGrandTotal = 0
      tempBasket.currency = currency;
      // tempBasket.adminCost = isAdminCost ? Number(HandleShowAdminCost(currency)) : 0;
      // newTempGrandTotal = isAdminCost ? Number(newTempGrandTotal) + Number(HandleShowAdminCost(currency)) : Number(newTempGrandTotal);
      // tempBasket.grandTotal = newTempGrandTotal;
      setBasketStates(tempBasket);
      HandleLocalStorage(tempBasket);
    }
  };
  // const handleAdminCost = (value) => {
  //     let tempBasket = basketStates;
  //     if (value) {
  //         tempBasket.adminCost = Number(HandleShowAdminCost(tempBasket.currency));
  //         tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(HandleShowAdminCost(tempBasket.currency))
  //     } else {
  //         tempBasket.adminCost = 0;
  //         tempBasket.grandTotal = Number(tempBasket.grandTotal) - Number(HandleShowAdminCost(tempBasket.currency))
  //     }
  //     setBasketStates(tempBasket);
  //     HandleLocalStorage(tempBasket);
  //     setUserProductsCount(userProductsCount + 1);
  //     setIsAdminCost(value);
  // }
  const handleAdminCost = value => {
    let tempBasket = basketStates;
    // console.log('on handle admin=', tempBasket, value, selectedCurrencyStore);
    if (tempBasket.products.length && tempBasket.products[0].product) {
      let tempGrandTotal = value;
      let tempTotal = 0;
      tempBasket.products.map(item => {
        item.product ? (tempGrandTotal += item.total) : (tempGrandTotal = value);
        item.product ? (tempTotal += item.total) : (tempTotal = 0);
        return null;
      });
      tempBasket.adminCost = Number(value);
      tempBasket.grandTotal = Number(tempGrandTotal);
      tempBasket.total = Number(tempTotal);
      if (selectedCurrencyStore && !tempBasket.currency) {
        tempBasket.currency = selectedCurrencyStore;
      }
      setBasketStates(tempBasket);
      HandleLocalStorage(tempBasket);
      setUserProductsCount(userProductsCount + 1);
      // setIsAdminCost(value ? true : false);
    }
  };
  const getProduct = async ({ product, currency = selectedCurrencyStore, quantity, index = 0, plaque_name = 'Pour l’amour d’Allah SWT' }) => {
    try {
      const response = await services.getProduct({ product: product, currency: currency });
      let tempBasket = basketStates;
      let tempSubscribe = selectedSubscribe;
      handleAddToCartDataLayer(response.data, currency);
      if (response.data.creator === 'GEN-SUB-FRI-2023-01-01') tempSubscribe = 'week';
      let tempQuantity = 1;
      let tempTotal = 1;
      if (quantity === 1) {
        if (response.data.unit_amount < 5) {
          tempQuantity = 200;
          tempTotal = 200 * response.data.unit_amount;
        } else {
          tempQuantity = 1;
          tempTotal = response.data.unit_amount;
        }
      } else {
        tempQuantity = quantity;
        tempTotal = quantity * response.data.unit_amount;
      }
      console.log('quantity=', tempQuantity, tempTotal);
      tempBasket.products[index] = {
        product: response.data,
        quantity: tempQuantity,
        amount: response.data.unit_amount,
        total: tempTotal,
        currency: currency,
        recurring: tempSubscribe,
        landing_page: window.location.pathname === '/' ? '/home' : window.location.pathname,
        onBehalf: plaque_name,
      };
      response.data.price_points.map(item => (tempBasket.products[index].product[item.currency] = item.amount));
      // tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempBasket.products[index].total);
      tempBasket.recurring = tempSubscribe;
      tempBasket.currency = currency;
      setBasketStates(tempBasket);
      HandleLocalStorage(tempBasket);
      setProductHasChangedIndex(-1);
      setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleProductInfo = () => {
    if (basketStates.grandTotal < 5) {
      Swal.fire({
        icon: 'warning',
        title: 'Maximise your donation!',
        text: `Payment processors charge more for lower donations and makes it harder to meet our 100% donation policy and limits its impact. Please donate more than  5 ${basketStates.currency} to make a greater impact.`,
        showCancelButton: true,
        cancelButtonText: 'Donate more',
        confirmButtonText: 'Continue',
        confirmButtonColor: '#F60362',
        cancelButtonColor: '#00A3DA',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          handleBeginCheckoutDataLayer(basketStates);
          props.nextStep();
        }
      });
      return;
    }
    handleBeginCheckoutDataLayer(basketStates);
    props.nextStep();
  };
  const searchProduct = async () => {
    try {
      const response = await services.searchProducts();
      function compare(a, b) {
        if (a.name.trim() < b.name.trim()) {
          return -1;
        }
        if (a.name.trim() > b.name.trim()) {
          return 1;
        }
        return 0;
      }
      let temp = response.data.sort(compare);
      temp = temp.filter(n => Number(n.status) === 1 && (checkDomain('matwprojectfr.org') ? !n.name.toLowerCase().includes('masjid') : true));
      setAllProducts(temp);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleBack = () => {
    // navigate(-1);
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(generateLinkWithLang('/', lang), { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };
  const handleSelectProduct = (creator, index, plaque_name) => {
    handleRemoveFromCartDataLayer(basketStates.products[index]);
    let tempBasket = basketStates;
    // tempBasket.grandTotal = Number(tempBasket.grandTotal) - Number(tempBasket.products[index].total);
    // tempBasket.products[index].quantity = 1;
    setBasketStates(tempBasket);
    HandleLocalStorage(tempBasket);
    setProductHasChangedIndex(index);
    getProduct({
      product: creator,
      // currency: selectedCurrencyStore,
      currency: basketStates.currency ? basketStates.currency : 'AUD',
      // quantity: tempBasket.products[index].quantity,
      quantity: 1,
      index: index,
      plaque_name: plaque_name,
    });
  };
  const handleAddToCartDataLayer = (product, currency = selectedCurrencyStore, addAgain) => {
    console.log('datalayer add', product);
    let tempProduct = product.product ? product.product : product;
    let tempAmount = tempProduct.unit_amount ? tempProduct.unit_amount : tempProduct[currency.toLowerCase()];
    let tempQuantity = product.quantity
      ? product.quantity
      : tempProduct.unit_amount
        ? tempProduct.unit_amount < 3
          ? 200
          : 1
        : tempProduct[currency.toLowerCase()] < 3
          ? 200
          : 1;
    let dataLayerTemp = {
      event: 'add_to_cart',
      ecommerce: {
        value: tempAmount * tempQuantity,
        currency: currency.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || '',
            item_category: tempProduct.category || '',
            item_description: tempProduct.description || '',
            price: tempProduct.unit_amount ? tempProduct.unit_amount : tempProduct[currency.toLowerCase()],
            quantity: tempQuantity,
          },
        ],
      },
    };
    window.dataLayer = window.dataLayer || [];
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
    let obj = {
      content_type: 'product',
      content_id: tempProduct.creator,
      currency: currency.toUpperCase(),
      value: tempAmount * tempQuantity,
      price: tempProduct.unit_amount ? tempProduct.unit_amount : tempProduct[currency.toLowerCase()],
      quantity: tempQuantity,
      content_category: product.category ? product.category : 'donation',
      content_name: tempProduct.name,
      description: tempProduct.description,
    };
    if (addAgain) {
      // window.ttq && window.ttq.instance(pixelID).track('ClickButton', {
      window.ttq &&
        window.ttq.track('ClickButton', {
          ...obj,
        });
    } else {
      // window.ttq && window.ttq.instance(pixelID).track('AddToCart', {
      window.ttq &&
        window.ttq.track('AddToCart', {
          ...obj,
        });
    }
  };
  const handleRemoveFromCartDataLayer = product => {
    let tempProduct = product.product ? product.product : product;
    let tempAmount = tempProduct.unit_amount ? tempProduct.unit_amount : tempProduct[selectedCurrencyStore.toLowerCase()];
    let tempQuantity = tempProduct.unit_amount
      ? tempProduct.unit_amount < 3
        ? 200
        : 1
      : tempProduct[selectedCurrencyStore.toLowerCase()] < 3
        ? 200
        : 1;
    let dataLayerTemp = {
      event: 'remove_from_cart',
      ecommerce: {
        value: tempAmount * tempQuantity,
        currency: selectedCurrencyStore.toUpperCase(),
        items: [
          {
            item_id: tempProduct.creator,
            item_name: tempProduct.name,
            item_brand: tempProduct.category || '',
            item_category: tempProduct.category || '',
            item_description: tempProduct.description || '',
            price: tempProduct.unit_amount ? tempProduct.unit_amount : tempProduct[selectedCurrencyStore.toLowerCase()],
            quantity: tempQuantity,
          },
        ],
      },
    };
    window.dataLayer = window.dataLayer || [];
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
  };
  const handleBeginCheckoutDataLayer = tempProducts => {
    let tempItems = tempProducts.products.map(item => {
      return {
        item_id: item.product.creator,
        item_name: item.product.name,
        item_brand: item.product.category || '',
        item_category: item.product.category || '',
        item_description: item.product.description || '',
        price: item.amount,
        quantity: item.quantity,
      };
    });
    let dataLayerTemp = {
      event: 'begin_checkout',
      frequency: HandleShowRecurring(selectedSubscribe),
      ecommerce: {
        value: tempProducts.grandTotal,
        tax: tempProducts.adminCost,
        currency: tempProducts.currency.toUpperCase(),
        items: tempItems,
      },
    };
    window.dataLayer = window.dataLayer || [];
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
    let tempContents = tempProducts.products.map(item => {
      return {
        price: item.amount,
        quantity: item.quantity,
        content_id: item.product.creator,
        content_category: item.product.category ? item.product.category.name : '',
        content_name: item.product.name || '',
        brand: item.product.category ? item.product.category.name : '',
      };
    });
    let obj = {
      content_type: 'product',
      contents: tempContents,
      currency: tempProducts.currency.toUpperCase(),
      value: Number(tempProducts.grandTotal),
      description: '',
    };
    // let contentids = [];
    // tempProducts.products.forEach(element => {
    //     contentids.push(element.product.creator);
    // });
    // window.ttq && window.ttq.instance(pixelID).track('InitiateCheckout', {
    window.ttq &&
      window.ttq.track('InitiateCheckout', {
        ...obj,
      });
    // window.ttq && window.ttq.track('InitiateCheckout', {
    //     contents: tempItems,
    //     value: tempProducts.grandTotal,
    //     currency: tempProducts.currency.toUpperCase(),
    //     content_id: contentids
    // });
  };
  const handleViewCartDataLayer = tempProducts => {
    if (tempProducts.products.product) {
      let tempItems = tempProducts.products.map(item => {
        return {
          item_id: item.product.creator,
          item_name: item.product.name,
          item_brand: item.product.category || '',
          item_category: item.product.category || '',
          item_description: item.product.description || '',
          price: item.amount,
          quantity: item.quantity,
        };
      });
      let dataLayerTemp = {
        event: 'view_cart',
        ecommerce: {
          value: tempProducts.grandTotal,
          currency: tempProducts.currency.toUpperCase(),
          items: tempItems,
        },
      };
      window.dataLayer = window.dataLayer || [];
      IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
    }
  };
  const getProductByCode = async (creator, currency, product_name, basketStates) => {
    const temp = { product: creator, currency: currency };
    try {
      const response = await services.getProduct(temp);
      if (product_name === 'where most needed') {
        setWhereMostNeeded(response.data);
      } else {
        handleComeProductFromURL(response.data, currency, basketStates);
        // setComeProductFromURL(response.data)
      }
    } catch (error) {
      toast.error("The product didn't find!");
      handleCheckLocalStorage(basketStates);
    }
  };
  const handleWhereMostNeeded = () => {
    console.log('where product', whereMostNeeded, basketStates, selectedCurrencyStore);
    let tempBasket = basketStates;
    // let tempCurrency = tempBasket.currency ? tempBasket.currency.toLowerCase() : 'aud';
    let tempCurrency = selectedCurrencyStore ? selectedCurrencyStore.toLowerCase() : tempBasket.currency ? tempBasket.currency.toLowerCase() : 'aud';
    // let tempAdminCost = Number(HandleShowAdminCost(tempCurrency));
    let tempProduct = {
      product: {
        AUD: whereMostNeeded.aud,
        CAD: whereMostNeeded.cad,
        EUR: whereMostNeeded.eur,
        GBP: whereMostNeeded.gbp,
        MYR: whereMostNeeded.myr,
        SGD: whereMostNeeded.sgd,
        USD: whereMostNeeded.usd,
        AED: whereMostNeeded.aed,
        IDR: whereMostNeeded.idr,
        category: '',
        category_id: whereMostNeeded.category_id,
        creator: whereMostNeeded.creator,
        currency: tempCurrency,
        custom_amount: whereMostNeeded.custom_amount,
        description: whereMostNeeded.description,
        image_link: whereMostNeeded.image_link,
        name: whereMostNeeded.name,
        paypal_plans: whereMostNeeded.paypal_product_id,
        paypal_subscription_plans: whereMostNeeded.paypal_subscription_plans,
        recurring: whereMostNeeded.recurring,
        status: whereMostNeeded.status,
        unit_amount: whereMostNeeded[tempCurrency],
      },
      amount: whereMostNeeded[tempCurrency],
      quantity: whereMostNeeded['aud'] === 1 ? 200 : 1,
      currency: tempCurrency,
      recurring: tempBasket.recurring,
      total: whereMostNeeded['aud'] === 1 ? 200 : whereMostNeeded[tempCurrency],
      landing_page: window.location.pathname === '/' ? '/home' : window.location.pathname,
      onBehalf: 'Pour l’amour d’Allah SWT',
    };
    if (!tempBasket.products[0].product) {
      tempBasket.products[0] = tempProduct;
      // tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempBasket.products[0].total) + tempAdminCost;
      tempBasket.total = Number(tempBasket.products[0].total);
      tempBasket.currency = tempCurrency.toUpperCase();
      // tempBasket.adminCost = tempAdminCost;
      // tempBasket.donor = {
      //   name: '',
      //   email: '',
      //   phone: '',
      //   address: '',
      //   city: '',
      //   postal_code: '',
      //   countryCode: '',
      //   country: '',
      // };
    } else {
      tempBasket.products.push({ ...tempBasket.products[tempBasket.products.length - 1] });
      tempBasket.products[tempBasket.products.length - 1] = tempProduct;
      // tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempBasket.products[tempBasket.products.length - 1].total) + tempAdminCost;
      tempBasket.total = Number(tempBasket.grandTotal) - Number(tempBasket.adminCost) + Number(tempBasket.products[0].total);
      // tempBasket.adminCost = tempAdminCost;
    }
    console.log('before set', tempBasket, whereMostNeeded);
    setBasketStates(tempBasket); // Add new record to userProducts
    HandleLocalStorage(tempBasket);
    setProductHasChangedIndex(-1);
    setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts
    handleAddToCartDataLayer(whereMostNeeded);
    // setIsAdminCost(true);
  };
  const handleComeProductFromURL = (_product, _currency, _basketStates) => {
    console.log('handle-ComeProductFromURL=', _basketStates, _product, _currency);
    let isExist = false;
    let tempBasket = '';
    if (_basketStates && !IsExpired(_basketStates)) {
      tempBasket = _basketStates;
      _basketStates.products.map(item => {
        if (item.product && item.product.creator === _product.creator) isExist = true;
        return item;
      });
      console.log('empty on if=', tempBasket);
    } else {
      tempBasket = basketStates;
    }
    // let tempAdminCost = Number(HandleShowAdminCost(_currency));
    let tempQTY = Number(ProductQuantityParam) ? Number(ProductQuantityParam) : _product['aud'] === 1 ? 200 : 1;
    let tempRec = '';
    console.log('empty=', tempBasket);
    if (ProductRecurringParam) {
      switch (ProductRecurringParam.toLowerCase()) {
        case 'single':
          tempRec = 'one-off';
          break;
        case 'one-off':
          tempRec = 'one-off';
          break;
        case 'week':
          tempRec = 'week';
          break;
        case 'month':
          tempRec = 'month';
          break;
        case 'year':
          tempRec = 'year';
          break;
        default:
          tempRec = '';
          break;
      }
    }
    tempRec = tempRec ? tempRec : basketStates.recurring;
    let tempProduct = {
      product: {
        AUD: _product.aud,
        CAD: _product.cad,
        EUR: _product.eur,
        GBP: _product.gbp,
        MYR: _product.myr,
        SGD: _product.sgd,
        USD: _product.usd,
        AED: _product.aed,
        IDR: _product.idr,
        category: '',
        category_id: _product.category_id,
        creator: _product.creator,
        currency: _currency,
        custom_amount: _product.custom_amount,
        description: _product.description,
        image_link: _product.image_link,
        name: _product.name,
        paypal_plans: _product.paypal_product_id,
        paypal_subscription_plans: _product.paypal_subscription_plans,
        recurring: tempRec,
        status: _product.status,
        unit_amount: _product[_currency.toLowerCase()],
      },
      amount: _product[_currency.toLowerCase()],
      quantity: tempQTY,
      currency: _currency,
      recurring: tempRec,
      total: tempQTY * _product[_currency.toLowerCase()],
      landing_page: window.location.pathname === '/' ? '/home' : window.location.pathname,
      onBehalf: 'Pour l’amour d’Allah SWT',
    };

    if (!tempBasket.products[0].product) {
      tempBasket.products[0] = tempProduct;
      // tempBasket.grandTotal = Number(tempProduct.total) + tempAdminCost;
      // tempBasket.donor = {
      //   name: '',
      //   email: '',
      //   phone: '',
      //   address: '',
      //   city: '',
      //   postal_code: '',
      //   countryCode: '',
      //   country: '',
      // };
      tempBasket.total = Number(tempBasket.products[0].total);
    } else {
      tempBasket.products.map(item => {
        item.currency = _currency;
        item.recurring = tempRec;
        item.product.currency = _currency;
        item.amount = item.product[_currency];
        item.total = item.quantity * item.product[_currency];
        return item;
      });
      // let tempGrandTotal = tempAdminCost;
      let tempTotal = 0;
      if (!isExist) {
        tempBasket.products.push(tempProduct);
      }
      tempBasket.products.map(item => {
        tempTotal += item.total;
        return null;
      });
      tempBasket.total = tempTotal;
    }
    tempBasket.currency = _currency;
    // tempBasket.adminCost = tempAdminCost;
    tempBasket.recurring = tempRec;
    tempBasket.paymentFlow = '';
    tempBasket.mode = tempRec === 'one-off' ? 'payment' : 'subscription';
    setSelectedSubscribe(tempRec);
    setBasketStates(tempBasket); // Add new record to userProducts
    HandleLocalStorage(tempBasket);
    setProductHasChangedIndex(-1);
    setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts
    handleAddToCartDataLayer(_product);
    // setIsAdminCost(true);
    dispatch(setSelectedCurrencyInStore(_currency));
    // setComeProductFromURL(_product)
  };
  const handleCheckLocalStorage = _basketStatesFromLocalStorage => {
    // let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    console.log('//=========basketStatesFromLocalStorage', _basketStatesFromLocalStorage, IsExpired(_basketStatesFromLocalStorage), isBasketEmpty);
    let tempObj = {};
    ProductID
      ? (tempObj = JSON.parse(Base64.decode(ProductID))) // decode the selected product from URL
      : (tempObj = {
        product: ProductCreatorParam ? ProductCreatorParam : null,
        currency: ProductCurrencyParam ? ProductCurrencyParam.toUpperCase() : 'AUD',
        quantity: ProductQuantityParam ? Number(ProductQuantityParam) : 1,
        recurring: selectedSubscribe,
        plaque_name: 'Pour l’amour d’Allah SWT',
      });
    if (_basketStatesFromLocalStorage && !IsExpired(_basketStatesFromLocalStorage)) {
      // console.log("Is-Expired=", IsExpired(_basketStatesFromLocalStorage))
      if (_basketStatesFromLocalStorage.products[0].product) {
        if (!_basketStatesFromLocalStorage.adminCost) {
          // let tempGrandTotal = Number(HandleShowAdminCost(_basketStatesFromLocalStorage.currency));
          let tempGrandTotal = 0;
          // _basketStatesFromLocalStorage.adminCost = Number(HandleShowAdminCost(_basketStatesFromLocalStorage.currency));
          _basketStatesFromLocalStorage.products.map(item => {
            tempGrandTotal += item.total;
            return null;
          });
          _basketStatesFromLocalStorage.grandTotal = tempGrandTotal;
          _basketStatesFromLocalStorage.total = tempGrandTotal;
          HandleLocalStorage(_basketStatesFromLocalStorage);
          // setIsAdminCost(true);
          setUserProductsCount(userProductsCount + 1);
        }
        let isUrlProductExist = false;
        tempObj.product &&
          _basketStatesFromLocalStorage.products.map(item => {
            // console.log('isurlexist=', tempObj, tempObj.product, item);
            if (item.product.creator === tempObj.product) {
              isUrlProductExist = true;
            }
            return item;
          });
        !isUrlProductExist && tempObj.product && getProductByCode(tempObj.product, tempObj.currency, 'url product', _basketStatesFromLocalStorage);
      } else {
        tempObj.product &&
          getProduct({
            product: tempObj.product,
            currency: tempObj.currency ? tempObj.currency : 'AUD',
            quantity: tempObj.quantity,
            index: 0,
            plaque_name: tempObj.plaque_name,
          });
      }

      setSelectedSubscribe(_basketStatesFromLocalStorage.recurring ? _basketStatesFromLocalStorage.recurring : 'one-off');
      setBasketStates(_basketStatesFromLocalStorage);
      handleViewCartDataLayer(_basketStatesFromLocalStorage);
      if (_basketStatesFromLocalStorage.currency !== selectedCurrencyStore) {
        dispatch(setSelectedCurrencyInStore(_basketStatesFromLocalStorage.currency));
      }
      setProductHasChangedIndex(-1);
    } else {
      console.log('basekt is empty=', _basketStatesFromLocalStorage);
      tempObj.product && getProductByCode(tempObj.product, tempObj.currency, 'url product', _basketStatesFromLocalStorage);
      setIsBasketEmpty(true);
    }
  };
  const productRecurring = basketStatesFromLocalStorage => {
    let id = params.get('id');
    if (id) {
      let obj = JSON.parse(Base64.decode(id));
      console.log(obj.isLaylatuQadr);
      if (obj.isLaylatuQadr) {
        return;
      }
    }
    // update product to one-off from day
    if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.recurring === 'day') {
      let p = basketStatesFromLocalStorage.products;
      if (p?.length > 0) {
        p.forEach(e => {
          e.recurring = 'one-off';
          e.mode = 'payment';
        });
      }
      const index = p.findIndex(i => i.hasOwnProperty('start_date') && i.hasOwnProperty('end_date'));
      if (index !== -1) {
        let temp = p[0];
        // temp.recurring = 'one-off';
        delete temp.start_date;
        delete temp.end_date;
        delete temp.continue_donation;
        delete temp.grandTotal;
        p[0] = temp;
        basketStatesFromLocalStorage.recurring = 'one-off';
        basketStatesFromLocalStorage.mode = 'payment';
      }
      HandleLocalStorage(basketStatesFromLocalStorage);
    }
  };
  const handleFocus = e => {
    e.target.select();
  };
  const handleOnBlurOnBehalf = (value, index) => {
    if (value === '') {
      let tempBasket = basketStates;
      const newBasketProducts = basketStates.products.map((item, inner_index) => {
        if (inner_index === index) item.onBehalf = 'Pour l’amour d’Allah SWT';
        return item;
      });
      tempBasket.products = newBasketProducts;
      // onSetBasketStates(tempBasket)
      setBasketStates({ ...basketStates, products: newBasketProducts });
      HandleLocalStorage(tempBasket);
    }
  };
  const handleChangeOnBehalf = (value, index) => {
    let tempBasket = basketStates;
    let restrict = profanityList.includes(value.toLowerCase());
    if (restrict) {
      const newBasketProducts = basketStates.products.map((obj, inner_index) => {
        if (inner_index === index) {
          return { ...obj, restrictedText: true };
        }
        return obj;
      });
      tempBasket.products = newBasketProducts;
      // onSetBasketStates(tempBasket)
      setBasketStates({ ...basketStates, products: newBasketProducts });
      HandleLocalStorage(tempBasket);
      return;
    }
    // const newBasketProducts = basketStates.products.map((item, inner_index) => {
    //     if (inner_index === index) item.onBehalf = value;
    //     return item
    // })
    const newBasketProducts = basketStates.products.map((obj, inner_index) => {
      if (inner_index === index) {
        return { ...obj, onBehalf: value, restrictedText: false };
      }
      return obj;
    });
    tempBasket.products = newBasketProducts;
    // onSetBasketStates({ ...basketStates, products: newBasketProducts })
    setBasketStates({ ...basketStates, products: newBasketProducts });
    HandleLocalStorage(tempBasket);
  };
  const handleQty = (value, index, prd) => {
    // let tempBasket = basketStates;
    // let tempCount = tempBasket.products[index].quantity;
    // tempBasket.grandTotal = Number(tempBasket.grandTotal) - Number(tempBasket.products[index].total);
    // refQuantity.current[index].current.value = '';
    // if (parseInt(value) > 0) {
    //     tempCount = parseInt(value);
    // } else {
    //     tempCount = 0;
    // }
    // tempBasket.products[index].quantity = tempCount;
    // tempBasket.products[index].total = tempCount * tempBasket.products[index].amount;
    // tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempCount * tempBasket.products[index].amount);
    // // onSetBasketStates(tempBasket);
    // setBasketStates(tempBasket);
    // HandleLocalStorage(tempBasket);
    // setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts

    let tempBasket = basketStates;
    let tempCount = prd.quantity;
    tempBasket.grandTotal = Number(tempBasket.grandTotal) - Number(prd.total);
    refQuantity.current[index].current.value = '';
    if (parseInt(value) > 0) {
      tempCount = parseInt(value);
    } else {
      tempCount = 0;
    }
    const newUserProducts = basketStates.products.map((obj, inner_index) => {
      if (inner_index === index) {
        return { ...obj, quantity: tempCount, total: tempCount * prd.amount };
      }
      return obj;
    });
    tempBasket.products = newUserProducts;
    // tempBasket.grandTotal = Number(basketStates.grandTotal) + Number(tempCount * prd.amount);
    let tempGrandTotal = basketStates.adminCost;
    let tempTotal = 0;
    newUserProducts.map(item => {
      if (item.product) {
        tempGrandTotal += item.total;
        tempTotal += item.total;
      }
      return null;
    });
    tempBasket.grandTotal = tempGrandTotal;
    tempBasket.total = tempTotal;
    // setBasketStates({ ...basketStates, products: newUserProducts, grandTotal: Number(basketStates.grandTotal) + Number(tempCount * prd.amount) });
    setBasketStates({ ...basketStates, products: newUserProducts, grandTotal: tempGrandTotal, total: tempTotal });
    HandleLocalStorage(tempBasket);
  };
  const handleReduceQty = (index, prd) => {
    // let tempBasket = basketStates;
    // let tempCount = tempBasket.products[index].quantity;
    if (prd.quantity > 0) {
      // tempCount = tempCount - 1;
      // tempBasket.products[index].quantity = tempCount;
      // tempBasket.products[index].total = tempCount * tempBasket.products[index].amount;
      // tempBasket.grandTotal = Number(tempBasket.grandTotal) - Number(tempBasket.products[index].amount);
      // // onSetBasketStates(tempBasket);
      // setBasketStates(tempBasket);
      // HandleLocalStorage(tempBasket);
      // setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts
      const newUserProducts = basketStates.products.map((obj, inner_index) => {
        if (inner_index === index) {
          return { ...obj, quantity: prd.quantity - 1, total: (prd.quantity - 1) * prd.amount };
        }
        return obj;
      });
      let tempBasket = basketStates;
      tempBasket.products = newUserProducts;
      // tempBasket.grandTotal = Number(basketStates.grandTotal) - Number(prd.amount);
      let tempGrandTotal = basketStates.adminCost;
      let tempTotal = 0;
      newUserProducts.map(item => {
        if (item.product) {
          tempGrandTotal += item.total;
          tempTotal += item.total;
        }
        return null;
      });
      tempBasket.grandTotal = tempGrandTotal;
      tempBasket.total = tempTotal;
      // setBasketStates({ ...basketStates, products: newUserProducts, grandTotal: Number(basketStates.grandTotal) - Number(prd.amount) });
      setBasketStates({ ...basketStates, products: newUserProducts, grandTotal: tempGrandTotal, total: tempTotal });
      HandleLocalStorage(tempBasket);
    }
  };
  const handleKeyDown = value => {
    let checkIfNum;
    if (value.key !== undefined) {
      checkIfNum = value.key === '.';
    }
    return checkIfNum && value.preventDefault();
  };
  const handleOnBlurQty = (value, index, prd) => {
    if (Number(value) === 0) {
      handleQty(1, index, prd);
    }
  };
  const handleAddQty = (index, prd) => {
    console.log('hand-leAddQty before settt=', index, prd);
    console.log('hand-leAddQty before settt=', basketStates.products);
    // console.log("hand-leAddQty before settt=",index,prd,basketStates.products[0])
    // let tempBasket = basketStates;
    // // let tempPrd = tempBasket.products[index];
    // let tempCount = prd.quantity;
    // tempCount = tempCount + 1;
    // prd.quantity = tempCount;
    // prd.total = tempCount * prd.amount;
    // tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(prd.amount);
    // tempBasket.products[index] = prd;
    // // prd.quantity = 4;
    // // prd.total = 4 * prd.amount;
    // // tempBasket.products[0] = prd;
    // console.log("hand-leAddQty before set=",index,prd,tempBasket.products)
    // // console.log("hand-leAddQty before set=",tempBasket.products)
    // // console.log("hand-leAddQty before set=",index,tempPrd,tempBasket.products[index],tempBasket.products)
    // // onSetBasketStates(tempBasket);
    const newUserProducts = basketStates.products.map((obj, inner_index) => {
      if (inner_index === index) {
        return { ...obj, quantity: prd.quantity + 1, total: (prd.quantity + 1) * prd.amount };
      }
      return obj;
    });
    let tempBasket = basketStates;
    tempBasket.products = newUserProducts;
    // tempBasket.grandTotal = Number(basketStates.grandTotal) + Number(prd.amount);
    let tempGrandTotal = basketStates.adminCost;
    let tempTotal = 0;
    newUserProducts.map(item => {
      if (item.product) {
        tempGrandTotal += item.total;
        tempTotal += item.total;
      }
      return null;
    });
    tempBasket.grandTotal = tempGrandTotal;
    tempBasket.total = tempTotal;
    // setBasketStates({...basketStates, products: newUserProducts});
    // setBasketStates({ ...basketStates, products: newUserProducts, grandTotal: Number(basketStates.grandTotal) + Number(prd.amount) });
    setBasketStates({ ...basketStates, products: newUserProducts, grandTotal: tempGrandTotal, total: tempTotal });
    HandleLocalStorage(tempBasket);
    setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts
  };
  const handleRemoveProduct = index => {
    if (basketStates.products.length === 1) {
      handleRemoveFromCartDataLayer(basketStates.products[index]);
      handleAdminCost(false);
      let tempBasket = basketStates;
      tempBasket.grandTotal = Number(tempBasket.grandTotal) - Number(tempBasket.products[index].total);
      tempBasket.total = Number(tempBasket.total) - Number(tempBasket.products[index].total);
      tempBasket.products = [
        {
          product: null,
          quantity: 1,
          amount: 0,
          total: 0,
          currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD',
          recurring: 'one-off',
          onBehalf: 'Pour l’amour d’Allah SWT',
        },
      ];
      // onSetBasketStates(tempBasket);
      setBasketStates(tempBasket);
      HandleLocalStorage(tempBasket);
      setUserProductsCount(userProductsCount - 1); // create temp for update the userProducts
    } else {
      handleRemoveFromCartDataLayer(basketStates.products[index]);
      let tempBasket = basketStates;
      tempBasket.grandTotal = Number(tempBasket.grandTotal) - Number(tempBasket.products[index].total);
      tempBasket.total = Number(tempBasket.total) - Number(tempBasket.products[index].total);
      tempBasket.products.splice(index, 1);
      // onSetBasketStates(tempBasket);
      setBasketStates(tempBasket);
      HandleLocalStorage(tempBasket);
      setUserProductsCount(userProductsCount - 1); // create temp for update the userProducts
    }
  };
  const handleAddProduct = index => {
    let tempBasket = basketStates;
    if (index === tempBasket.products.length - 1) {
      tempBasket.products.push(tempBasket.products[tempBasket.products.length - 1]);
      tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempBasket.products[tempBasket.products.length - 1].total);
      tempBasket.total = Number(tempBasket.total) + Number(tempBasket.products[tempBasket.products.length - 1].total);
      handleAddToCartDataLayer(tempBasket.products[tempBasket.products.length - 1], basketStates.currency, true);
    } else {
      tempBasket.products.splice(index, 0, tempBasket.products[index]);
      tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempBasket.products[index].total);
      tempBasket.total = Number(tempBasket.total) + Number(tempBasket.products[index].total);
      handleAddToCartDataLayer(tempBasket.products[index], basketStates.currency, true);
    }
    console.log('handle-AddProduct=', index, tempBasket.products);
    setProductHasChangedIndex(-1);
    // onSetBasketStates(tempBasket); // Add new record to userProducts
    setBasketStates(tempBasket); // Add new record to userProducts
    HandleLocalStorage(tempBasket);
    setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts
  };
  const getCurrencyFromAPI = (ProductCreatorParam, basketStatesFromLocalStorage) => {
    let _tempCurrency = '';
    fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
      .then(res => res.json())
      .then(response => {
        // console.log("response.countryCode on method=", response.countryCode)
        switch (response.countryCode) {
          case 'AU':
            _tempCurrency = 'AUD';
            break;
          case 'US':
            _tempCurrency = 'USD';
            break;
          case 'MY':
            _tempCurrency = 'MYR';
            break;
          case 'SG':
            _tempCurrency = 'SGD';
            break;
          case 'CA':
            _tempCurrency = 'CAD';
            break;
          default:
            _tempCurrency = 'AUD';
        }
        if (response.timezone.includes('Europe')) {
          if (response.countryCode === 'GB') {
            _tempCurrency = 'GBP';
          } else {
            _tempCurrency = 'EUR';
          }
        }
        // console.log("temp_currency on method=", _tempCurrency);
        getProductByCode(ProductCreatorParam, _tempCurrency, 'url product', basketStatesFromLocalStorage);
        getProductByCode('MKD-MN-001', _tempCurrency, 'where most needed');
        // return _tempCurrency;
      })
      .catch((data, status) => {
        console.log('Request failed:', data);
      });
    // console.log("temp_currency on method=", _tempCurrency)
    // return _tempCurrency;
  };
  const ShowCurrencies2 = (currency, _price) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'AUD':
        _final = `$${price} AUD`;
        break;
      case 'CAD':
        _final = `$${price} CAD`;
        break;
      case 'MYR':
        _final = `${price} MYR`;
        break;
      case 'SGD':
        _final = `$${price} SGD`
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* -------------------------------------------------------------------------- */

  /* -------------------------------- Useeffect ------------------------------- */
  useEffect(() => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    // let temp_currency = '';
    if (ProductCreatorParam) {
      // console.log("temp_currency===", ProductCurrencyParam, basketStatesFromLocalStorage)
      // let temp_currency = ProductCurrencyParam ? ProductCurrencyParam.toUpperCase() : basketStatesFromLocalStorage ? basketStatesFromLocalStorage.currency : getCurrencyFromAPI();
      let getPrds = temp_currency => {
        getProductByCode(ProductCreatorParam, temp_currency, 'url product', basketStatesFromLocalStorage);
        getProductByCode('MKD-MN-001', temp_currency, 'where most needed');
      };
      ProductCurrencyParam
        ? getPrds(ProductCurrencyParam.toUpperCase())
        : basketStatesFromLocalStorage
          ? getPrds(basketStatesFromLocalStorage.currency)
          : getCurrencyFromAPI(ProductCreatorParam, basketStatesFromLocalStorage);
      // switch (ProductCurrencyParam ? ProductCurrencyParam.toUpperCase() : basketStatesFromLocalStorage ? basketStatesFromLocalStorage.currency : 'AUD') {
      //     case 'AUD':
      //         temp_currency = 'AUD'
      //         break;
      //     case 'USD':
      //         temp_currency = 'USD'
      //         break;
      //     case 'SGD':
      //         temp_currency = 'SGD'
      //         break;
      //     case 'EUR':
      //         temp_currency = 'EUR'
      //         break;
      //     case 'CAD':
      //         temp_currency = 'CAD'
      //         break;
      //     case 'MYR':
      //         temp_currency = 'MYR'
      //         break;
      //     case 'GBP':
      //         temp_currency = 'GBP'
      //         break;
      //     default:
      //         temp_currency = ''
      //         break;
      // }
      // temp_currency = temp_currency ? temp_currency : basketStates.currency ? basketStates.currency : 'AUD';
      // console.log("temp_currency=", temp_currency)
      // getProductByCode(ProductCreatorParam, temp_currency, 'url product', basketStatesFromLocalStorage)
      // getProductByCode('MKD-MN-001', temp_currency, 'where most needed');
    } else {
      // if recurring is day in localstorage  set by laylatulqadr product as we skip this step for laylatulqadr
      // we switch products to one-time on this step by default
      productRecurring(basketStatesFromLocalStorage);
      //  -----------------------end if -----------------
      handleCheckLocalStorage(basketStatesFromLocalStorage);
      getProductByCode('MKD-MN-001', basketStates.currency ? basketStates.currency : 'AUD', 'where most needed');
    }
    searchProduct();
    // console.log("language=", language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   console.log("baskets updated=", basketStates)
  // }, [basketStates])
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full pt-4 md:mt-0" id="checkout-step-one">
      <StepHeader title="">
        <div className="w-1/2 sm:w-1/3 lg:w-1/4 pb-2 pr-2">
          <button
            type="button"
            className="w-full inline-block rounded bg-[#00a3da] text-white px-1 sm:px-2 font-medium text-sm md:text-base h-[38px] transition-all duration-200 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
            onClick={() => handleBack()}
          >
            Ajouter plus d'articles
          </button>
        </div>
        <div className="w-1/2 sm:w-1/3 lg:w-1/4 pb-2">
          <SelectCurrency onChangeCurrency={e => handleCurrency(e.value, basketStates)} />
        </div>
      </StepHeader>
      <div className="content px-2 md:px-0 md:pt-5">
        {/* /* ----------------------------- Start products -----------------------------  */}
        {basketStates.products.length && basketStates.products[0].product ? (
          // ? <StepOneProducts
          //     basketStates={basketStates}
          //     allProducts={allProducts}
          //     currency={selectedCurrencyStore}
          //     productHasChangedIndex={productHasChangedIndex}
          //     onHandleAdminCost={(e) => handleAdminCost(e)}
          //     onGetProduct={(e) => getProduct(e)}
          //     onSetProductHasChangedIndex={(e) => setProductHasChangedIndex(e)}
          //     onHandleRemoveFromCartDataLayer={(e) => handleRemoveFromCartDataLayer(e)}
          //     onHandleAddToCartDataLayer={(tempNewProduct) => handleAddToCartDataLayer(tempNewProduct)}
          //     onHandleSelectProduct={(value, label, index, onBehalf) => handleSelectProduct(value, label, index, onBehalf)}
          //     onSetBasketStates={(stateBasket,localStorageBasket) => {
          //         setBasketStates(stateBasket);
          //         HandleLocalStorage(localStorageBasket);
          //         setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts
          //     }}
          // />
          <>
            {basketStates.products.map((prd, index) => {
              return (
                <div key={`prdocuts${index}`} className="rounded-md border border-blue-200 px-3 py-3 flex flex-col md:flex-row mb-4">
                  <div className="flex flex-wrap flex-row basis-full- md:w-[123px]">
                    <div className="flex w-[90px] h-[90px] sm:w-[123px] sm:h-[123px] overflow-hidden">
                      {productHasChangedIndex !== index && productHasChangedIndex !== -2 ? (
                        <img
                          src={prd.product.creator === 'INT-ADM-OVH-2023' ? '/images/general/logo-replace.png' : prd.product.image_link}
                          className="w-full rounded-md"
                          alt="matw"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/images/general/logo-replace.png';
                          }}
                        />
                      ) : (
                        <Skeleton height={135} width={135} />
                      )}
                    </div>
                    <div className="flex flex-col justify-between md:hidden pl-2 w-[calc(100%_-_90px)] sm:w-[calc(100%_-_123px)]">
                      <div className="w-full text-xs min-[500px]:text-sm h-10">
                        <Select
                          options={allProducts.map((item, index) => {
                            return { value: item.creator, label: item.name };
                          })}
                          placeholder="Select product"
                          value={
                            basketStates.products[index].product && {
                              value: basketStates.products[index].product.creator,
                              label: basketStates.products[index].product.name,
                            }
                          }
                          styles={styles}
                          onChange={e => {
                            handleSelectProduct(e.value, index, prd.onBehalf);
                          }}
                          isSearchable={true}
                        />
                      </div>
                      <div className="w-full pl-2 flex items-center h-10 rounded shadow-[0px_0px_2px_rgb(220,220,220)] hover:shadow-[0px_0px_8px_rgb(216,216,216)]">
                        <a
                          href="#!"
                          className="transititext-primary relative text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 "
                          tabIndex={0}
                          onMouseOut={() => setIsShowTooltip(-1)}
                          onFocus={() => setIsShowTooltip(index)}
                          onMouseOver={() => setIsShowTooltip(index)}
                        >
                          <InfoIcon className="w-6 h-6 text-[#00a3da]" />
                          <div
                            className={`z-20 -left-7 sm:left-0 w-36 min-[500px]:w-64 absolute transition duration-150 ease-in-out -right-full top-8 shadow-lg bg-[#00a3da] text-[#00a3da] p-2 sm:p-4 rounded ${isShowTooltip === index ? 'flex' : 'hidden'
                              }`}
                          >
                            <LeftArrowToolTipIcon className="absolute hidden md:block left-0 -ml-2 bottom-0 top-0 h-full" />
                            <div className="text-white text-xs">
                              <p className="border-b border-white mb-1 text-xs min-[500px]:text-sm pb-1">Au nom de qui est ce don?</p>
                              Veuillez saisir le nom de la personne pour qui ce don est fait. Cela peut être plus d'une personne ou un message pour la plaque des mosquées, orphelinats ou puits d'eau, mais idéalement un seul nom si c'est pour un sacrifice.
                              <div className="border-t border-white mt-1 text-xs min-[500px]:text-sm pt-1">
                                <span className="text-xs h-4">
                                  {basketStates.products[index].product ? basketStates.products[index].product.creator : ''}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                        <input
                          type="text"
                          name="onBehalf"
                          id="onBehalf"
                          maxLength={150}
                          placeholder="Enter on behalf of"
                          className="min-h-[40px] text-[#777] w-full text-xs min-[500px]:text-sm bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                          value={prd.onBehalf}
                          onFocus={e => handleFocus(e)}
                          onBlur={e => handleOnBlurOnBehalf(e.target.value, index)}
                          onChange={e => {
                            handleChangeOnBehalf(e.target.value, index);
                          }}
                        />
                        {prd.restrictedText && <div className="text-red-500 text-[12px]">*Restricted words are not allowed</div>}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full pl-0 md:pl-2 pt-2 md:pt-0">
                    <div className="grid grid-cols-6 lg:grid-cols-5 gap-1 sm:gap-2 xl:gap-6">
                      <div className="flex col-start-1 col-span-6 lg:col-span-2 items-end order-1">
                        <div className="hidden md:flex flex-col relative w-full">
                          <Select
                            options={allProducts.map((item, index) => {
                              return { value: item.creator, label: item.name };
                            })}
                            placeholder="Select product"
                            value={
                              basketStates.products[index].product && {
                                value: basketStates.products[index].product.creator,
                                label: basketStates.products[index].product.name,
                              }
                            }
                            styles={styles}
                            onChange={e => {
                              handleSelectProduct(e.value, index, prd.onBehalf);
                            }}
                            isSearchable={true}
                          />
                          <span className="text-stone-400 text-xs h-4 absolute -bottom-5">
                            {basketStates.products[index].product ? basketStates.products[index].product.creator : ''}
                          </span>
                        </div>
                      </div>
                      <div className="col-span-2 sm:col-span-2 lg:col-span-1 lg:col-start-3 order-3 md:order-2 md:mt-4 xl:mt-0">
                        {productHasChangedIndex !== index && productHasChangedIndex !== -2 ? (
                          <div className="rounded shadow-[0px_0px_2px_rgb(220,220,220)] hover:shadow-[0px_0px_8px_rgb(216,216,216)] transition-all duration-200 flex justify-between p-1.5">
                            <div
                              className={`rounded flex justify-center items-center text-lg transition-all duration-200 pb-px hover:bg-[#00a3da] hover:text-white w-6 h-6 ${basketStates.products[index].quantity >= 1 ? 'cursor-pointer' : 'cursor-not-allowed'
                                }`}
                              onClick={() => basketStates.products[index].quantity >= 2 && handleReduceQty(index, prd)}
                            >
                              -
                            </div>
                            <input
                              ref={refQuantity.current[index]}
                              type="number"
                              value={basketStates.products[index] ? basketStates.products[index].quantity : 1}
                              className="center text-[#777] max-w-[70%] focus:outline-none focus:border-0"
                              onChange={e => handleQty(e.target.value, index, prd)}
                              onKeyDown={e => handleKeyDown(e)}
                              onBlur={e => handleOnBlurQty(e.target.value, index, prd)}
                            />
                            <div
                              className="rounded flex justify-center items-center text-lg transition-all duration-200 pb-px hover:bg-[#00a3da] hover:text-white w-6 h-6 cursor-pointer"
                              onClick={() => handleAddQty(index, prd)}
                            >
                              +
                            </div>
                          </div>
                        ) : (
                          <Skeleton height={36} />
                        )}
                      </div>
                      <div className="col-span-3 sm:col-span-2 lg:col-span-1 order-4 md:order-3 md:mt-4 xl:mt-0 lg:relative pl-1 flex justify-between lg:justify-center">
                        <div className="min-h-[36px] md:w-full w-[calc(100%_-_36px)] rounded shadow-[0px_0px_2px_rgb(220,220,220)]">
                          {productHasChangedIndex !== index && productHasChangedIndex !== -2 ? (
                            <div className="h-[36px] rounded-md text-[#777] text-lg font-medium flex items-center justify-center">
                              {ShowCurrencies2(basketStates.currency, prd.total)}
                            </div>
                          ) : (
                            <Skeleton height={36} />
                          )}
                        </div>
                        <button
                          // disabled={basketStates.products.length === 1}
                          onClick={() => handleRemoveProduct(index)}
                          // className={`w-9 h-9 md:hidden rounded flex justify-center items-center ${basketStates.products.length === 1 ? 'bg-stone-200 text-stone-400 cursor-not-allowed' : 'bg-[#F60362] text-white'}`}
                          className={`w-9 h-9 md:hidden rounded flex justify-center items-center bg-[#F60362] text-white`}
                        >
                          <CloseIcon className="w-5 h-5" />
                        </button>
                      </div>
                      <div className="col-span-1 sm:col-span-2 lg:col-span-1 flex items-center md:justify-end order-2 md:order-4 md:mt-4 xl:mt-0">
                        <button
                          onClick={() => handleAddProduct(index)}
                          className="w-9 h-9 mr-2 px-1 xl:px-2 rounded bg-[#00A3DA] text-white flex justify-center items-center md:w-[calc(100%_-_36px)]"
                        >
                          <PlusIcon className="w-6 h-6 md:hidden" />
                          <span className={`hidden md:block ${language === 'fr' ? 'text-sm' : ''}`}>Ajouter encore</span>
                        </button>
                        <button
                          //disabled={basketStates.products.length === 1}
                          onClick={() => handleRemoveProduct(index)}
                          // className={`w-9 h-9 rounded hidden md:flex justify-center items-center ${basketStates.products.length === 1 ? 'bg-stone-200 text-stone-400 cursor-not-allowed' : 'bg-[#F60362] text-white'}`}
                          className={`w-9 h-9 rounded hidden md:flex justify-center items-center bg-[#F60362] text-white`}
                        >
                          <CloseIcon className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                    <div className="col-span-6 hidden md:flex flex-col sm:flex-row sm:items-center text-[#777] mt-6">
                      <label className="text-sm md:text-md text-[#777] flex items-center mb-1 md:min-w-[250px]">
                        Au nom de qui est ce don?
                        <a
                          href="#!"
                          className="transititext-primary relative text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 "
                          tabIndex={0}
                          onMouseOut={() => setIsShowTooltip(-1)}
                          onFocus={() => setIsShowTooltip(index)}
                          onMouseOver={() => setIsShowTooltip(index)}
                        >
                          <InfoIcon className="w-6 h-6 ml-1 text-[#00a3da]" />
                          <div
                            className={`z-20 md:-mt-[75px] w-64 absolute transition duration-150 ease-in-out -right-full top-8 md:top-0 md:right-0 md:left-0 md:ml-8 shadow-lg bg-[#00a3da] text-[#00a3da] p-4 rounded ${isShowTooltip === index ? 'flex' : 'hidden'
                              }`}
                          >
                            <LeftArrowToolTipIcon className="absolute hidden md:block left-0 -ml-2 bottom-0 top-0 h-full" />
                            <span className="text-white">
                              Veuillez saisir le nom de la personne pour qui ce don est fait. Cela peut être plus d'une personne ou un message pour la plaque des mosquées, orphelinats ou puits d'eau, mais idéalement un seul nom si c'est pour un sacrifice.
                            </span>
                          </div>
                        </a>
                      </label>
                      <input
                        type="text"
                        name="onBehalf"
                        id="onBehalf"
                        maxLength={150}
                        placeholder="Enter on behalf of"
                        className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded shadow-[0px_0px_2px_rgb(220,220,220)] hover:shadow-[0px_0px_8px_rgb(216,216,216)] border- border-stone-300- bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                        value={prd.onBehalf}
                        onFocus={e => handleFocus(e)}
                        onBlur={e => handleOnBlurOnBehalf(e.target.value, index)}
                        onChange={e => {
                          handleChangeOnBehalf(e.target.value, index);
                        }}
                      />
                      {prd.restrictedText && <div className="text-red-500 text-[12px]">*Restricted words are not allowed</div>}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="border border-stone-300 rounded-md flex flex-col justify-center items-center py-4 mb-4">
            {/* <img src={emptyImg} alt="empty cart" className='w-96' /> */}
            <p className="text-stone-500 text-xl my-3 text-center px-1">Please choose a donation</p>
            <div className="flex flex-col md:flex-row">
              <button
                type="button"
                className="inline-block rounded mb-2 md:mb-0 md:mr-2 bg-[#00a3da] text-white px-2 sm:px-6 font-medium text-sm md:text-base h-[38px] transition-all duration-200 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
                onClick={() => navigate(generateLinkWithLang('/', lang))}
              >
                Home Page
              </button>
              <button
                type="button"
                className="inline-block rounded bg-[#00a3da] text-white px-2 sm:px-6 font-medium text-sm md:text-base h-[38px] transition-all duration-200 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
                onClick={() => handleWhereMostNeeded()}
              >
                Donate to Where Most Needed
              </button>
            </div>
          </div>
        )}
        {/* /* --------------------------------------------------------------------------  */}

        {/* /* ------------------------ Start Suggestion Products -----------------------  */}
        <AddOnProducts
          basketStates={basketStates}
          currency={selectedCurrencyStore}
          onHandleAddToCartDataLayer={tempNewProduct => handleAddToCartDataLayer(tempNewProduct)}
          onSetBasketStates={tempBasket => {
            setBasketStates(tempBasket);
            HandleLocalStorage(tempBasket);
            // let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
            // setBasketStates(basketStatesFromLocalStorage);
            setProductHasChangedIndex(-1);
            setUserProductsCount(userProductsCount + 1); // create temp for update the userProducts
            // setIsAdminCost(true);
          }}
        />
        {/* /* --------------------------- Start subscription ---------------------------  */}
        <SubscriptionTypes
          basketStates={basketStates}
          selectedSubscribe={selectedSubscribe}
          onSelectedSubscribe={e => setSelectedSubscribe(e)}
          onSetBasketStates={tempBasket => {
            setBasketStates(tempBasket);
            HandleLocalStorage(tempBasket);
          }}
        />
        {/* /* --------------------------------------------------------------------------  */}

        {/* /* ---------------------------- Start admin cost ----------------------------  */}
        <div className="grid grid-cols-6 mt-4 pt-3 items-center border-t border-stone-200">
          <div className="col-start-1 col-span-6 flex flex-col">
            {/* <div className="w-full flex items-center justify-end">
                            <label className="hover:cursor-pointer text-[#777] text-xs sm:text-base flex items-start flex-col sm:flex-row" htmlFor="checkboxDefault">
                                <span className='font-medium'>Contribute {ShowCurrencies(selectedCurrencyStore ? selectedCurrencyStore : basketStates.currency, HandleShowAdminCost(selectedCurrencyStore ? selectedCurrencyStore : basketStates.currency))} towards admin costs </span>
                                {selectedSubscribe !== 'one-off' && <small className='ml-0 sm:ml-1'>(uncheck this if subscribing by PayPal)</small>}
                            </label>
                            <input
                                className="relative float-left ml-1 h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-[#00a3da] checked:bg-[#00a3da] checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-[#00a3da] checked:focus:bg-[#00a3da] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                                type="checkbox"
                                onChange={(e) => handleAdminCost(e.target.checked)}
                                value={isAdminCost}
                                checked={isAdminCost}
                                disabled={!basketStates.grandTotal}
                                id="checkboxDefault" />
                        </div> */}
            <div className="w-full flex items-center text-right justify-end text-[#777] text-xs pt-1">
              <AdminCosts
                basketStates={basketStates}
                selectedSubscribe={selectedSubscribe}
                comeFromHome={props.comeFromHome}
                currency={selectedCurrencyStore ? selectedCurrencyStore : basketStates.currency}
                onChange={e => handleAdminCost(e)}
              />
            </div>
            <div className="w-full flex items-center text-right justify-end text-[#777] text-xs pt-1">
              <Link
                to="/admin-fee"
                target="_blank"
                className="mr-1 transititext-primary relative text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 "
              >
                <InfoIcon className="w-6 h-6 text-[#00a3da]" />
              </Link>
              <span className="font-medium">Cela nous aide vraiment à maintenir notre politique de dons à 100 %.</span>
            </div>
          </div>
        </div>
        {/* /* --------------------------------------------------------------------------  */}

        {/* /* ------------------------------- Total général ------------------------------ */}
        <div className="hidden md:grid grid-cols-2 mt-3 py-3 border-t border-stone-200">
          {/* <div className="col-span-2 flex justify-end">
                        <h1 className="text-[#777] text-2xl mr-1">Total général</h1>
                        <h2 className='text-[#777] text-2xl'>
                            {basketStates.grandTotal
                                ? productHasChangedIndex === -2 || productHasChangedIndex >= 0
                                    ? <Skeleton height={36} width={100} />
                                    : ShowCurrencies(selectedCurrencyStore, basketStates.grandTotal)
                                : ShowCurrencies(selectedCurrencyStore, 0)}
                        </h2>
                    </div> */}
          <div
            // className="w-full- shadow-lg md:shadow-none sticky bottom-24 bg-white rounded-md p-2 md:p-0 right-6 col-span-2 flex justify-end items-center"
            className="col-span-2 flex justify-end items-center"
          >
            <div className="hidden md:flex">
              <h1 className="text-[#777] text-sm sm:text-2xl mr-1">Total général</h1>
              <h2 className="notranslate text-[#777] font-bold text-3xl">
                {basketStates.grandTotal ? (
                  productHasChangedIndex === -2 || productHasChangedIndex >= 0 ? (
                    <Skeleton height={36} width={100} />
                  ) : (
                    ShowCurrencies2(selectedCurrencyStore, basketStates.grandTotal)
                  )
                ) : (
                  ShowCurrencies2(selectedCurrencyStore, 0)
                )}
              </h2>
            </div>
            <div className="flex md:hidden bg-white w-full justify-end">
              <Sticky
                stickyStyle={{ bottom: '90px', top: 'unset', right: '20' }}
                // stickyClassName='col-span-2 flex justify-end items-center'
                stickyClassName="w-full flex justify-end items-center"
                mode="bottom"
              >
                <div className="bg-white rounded p-2 flex items-center justify-end shadow-lg- min-w-[250px]">
                  <h1 className="text-[#777] text-sm sm:text-2xl mr-1">Total général</h1>
                  <h2 className="notranslate text-[#777] font-bold text-3xl">
                    {basketStates.grandTotal ? (
                      productHasChangedIndex === -2 || productHasChangedIndex >= 0 ? (
                        <Skeleton height={36} width={100} />
                      ) : (
                        ShowCurrencies2(selectedCurrencyStore, basketStates.grandTotal)
                      )
                    ) : (
                      ShowCurrencies2(selectedCurrencyStore, 0)
                    )}
                  </h2>
                </div>
              </Sticky>
            </div>
          </div>
          <div className="col-span-2 flex items-center mt-3 justify-end">
            <CheckCircleIcon className="mr-2 w-6 h-6 text-[#f60362]" />
            <span className="text-xs sm:text-sm text-[#777]">{selectedSubscribeMessages[selectedSubscribe]}</span>
          </div>
        </div>
        {/* /* --------------------------------------------------------------------------  */}

        {/* /* ------------------------------ Start buttons -----------------------------  */}
        <div className="hidden md:grid grid-cols-6 mt-2 gap-2 md:gap-4">
          <div className="col-start-1 col-span-2 md:col-span-1 back-btn">
            <div className="w-full flex md:hidden">
              <Sticky stickyStyle={{ bottom: '40px', top: 'unset' }} mode="bottom">
                <button
                  type="button"
                  // className="w-[120px] md:w-full shadow-lg md:shadow-none fixed bottom-10 left-6 flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-200 bg-[#00a3da] text-white sm:px-2 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
                  className="w-full min-w-[82px] flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-200 bg-[#00a3da] text-white sm:px-2 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
                  onClick={() => handleBack()}
                >
                  Retour
                </button>
              </Sticky>
            </div>
            <button
              type="button"
              className="w-full hidden md:flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-200 bg-[#00a3da] text-white sm:px-2 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
              onClick={() => handleBack()}
            >
              Retour
            </button>
          </div>
          <div className={`col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2`}>
            <div className="w-full flex justify-end md:hidden">
              <Sticky
                stickyStyle={{ bottom: '40px', top: 'unset', right: '20' }}
                stickyClassName="w-full flex justify-end items-center"
                mode="bottom"
              >
                <button
                  type="submit"
                  // disabled={!isLoadedProduct || qty > 1}
                  // disabled={isDisabledNextBtn}
                  // disabled={!grandTotal}
                  disabled={basketStates.grandTotal ? (basketStates.products[0].product ? false : true) : true}
                  // className={`w-[120px] md:w-full shadow-lg md:shadow-none fixed bottom-10 right-6 flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${!isDisabledNextBtn ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]' : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'}`}
                  // className={`w-full min-w-[106px] flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${!isDisabledNextBtn ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]' : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'}`}
                  className={`w-full min-w-[106px] flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out
                                        ${basketStates.grandTotal
                      ? basketStates.products[0].product
                        ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]'
                        : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'
                      : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'
                    }
                                        `}
                  onClick={() => handleProductInfo()}
                >
                  <span>Suivant</span>
                  <RightArrowIcon className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in" />
                </button>
              </Sticky>
            </div>
            <button
              type="submit"
              // disabled={!isLoadedProduct || qty > 1}
              // disabled={isDisabledNextBtn}
              disabled={basketStates.grandTotal ? (basketStates.products[0].product ? false : true) : true}
              // className={`w-[120px] md:w-full shadow-lg md:shadow-none fixed bottom-10 right-6 flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${!isDisabledNextBtn ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]' : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'}`}
              // className={`w-full hidden md:flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${!isDisabledNextBtn ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]' : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'}`}
              className={`w-full hidden md:flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out
                                        ${basketStates.grandTotal
                  ? basketStates.products[0].product
                    ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]'
                    : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'
                  : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'
                }
                                        `}
              onClick={() => handleProductInfo()}
            >
              <span>Suivant</span>
              <RightArrowIcon className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in" />
            </button>
          </div>
        </div>
        {/* /* --------------------------------------------------------------------------  */}

        {/* /* ------------------------------- Sticky Card ------------------------------  */}
        <div className="flex md:hidden [&>div]:w-full">
          <Sticky
            // stickyStyle={{ bottom: '0px', top: 'unset', right: '20px' }}
            stickyStyle={{ bottom: '0px', top: 'unset' }}
            stickyClassName="w-full flex justify-end items-center -shadow-md- shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] z-[99]"
            mode="bottom"
          >
            <div className="w-full grid grid-cols-6 mt-2- gap-1 md:gap-4 bg-white rounded-t-md p-2">
              <div className="col-span-6 flex justify-end items-center">
                <h1 className="text-[#777] text-sm sm:text-2xl mr-2">Total général</h1>
                <h2 className="notranslate text-[#777] font-bold text-xl h-6 flex items-center">
                  {basketStates.grandTotal ? (
                    productHasChangedIndex === -2 || productHasChangedIndex >= 0 ? (
                      <Skeleton height={36} width={100} />
                    ) : (
                      ShowCurrencies2(selectedCurrencyStore, basketStates.grandTotal)
                    )
                  ) : (
                    ShowCurrencies2(selectedCurrencyStore, 0)
                  )}
                </h2>
              </div>
              <div className="col-span-6 flex items-center sm:mt-3 justify-end mb-1">
                <CheckCircleIcon className="mr-1 sm:mr-2 w-4 h-4 sm:w-6 sm:h-6 text-[#f60362]" />
                <span className="text-[11px] sm:text-sm text-[#777]">{selectedSubscribeMessages[selectedSubscribe]}</span>
              </div>
              <div className="col-start-1 col-span-2 md:col-span-1">
                <button
                  type="button"
                  className="w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-200 bg-[#00a3da] text-white sm:px-2 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
                  onClick={() => handleBack()}
                >
                  Retour
                </button>
              </div>
              <div className={`col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2`}>
                <button
                  type="submit"
                  disabled={basketStates.grandTotal ? (basketStates.products[0].product ? false : true) : true}
                  className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out
                                        ${basketStates.grandTotal
                      ? basketStates.products[0].product
                        ? 'bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]'
                        : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'
                      : 'bg-stone-500 text-white opacity-50 cursor-not-allowed'
                    }
                                        `}
                  onClick={() => handleProductInfo()}
                >
                  <span>Next</span>
                  <RightArrowIcon className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in" />
                </button>
              </div>
            </div>
          </Sticky>
        </div>
        {/* /* --------------------------------------------------------------------------  */}
      </div>
    </div>
  );
};

export default StepOneWithLocalStorage;
