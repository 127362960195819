import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import services from '../../services/index.js';
import { useSelector } from 'react-redux';
import { selectLanguage, selectSelectedCurrency } from '../../store/user.js';
import CustomModal from './modal.jsx';
import useHandleAddProduct from '../../utils/handleAddProduct.js';
import CustomButton from './button.jsx';
import { CloseIcon, TimeIcon, TriangleRightArrowIcon } from './customIcons.jsx';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { selectNavbarMenu } from '../../store/menu.js';
import { selectProducts } from '../../store/products.js';

const styles = {
  textShadow: {
    textShadow: `1px 2px 0 rgba(0, 0, 0, 0.15)`,
  },
  textHovers: {},
  textHover: {
    opacity: 1,
  },
  textNotHover: {
    opacity: 0.5,
  },
};

const CustomNavbar = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { isUk, isRamadanPage } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const menus = useSelector(state => selectNavbarMenu(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const [mainMenuIsOpen, setMainMenuIsOpen] = useState(false);
  const [allProducts, setAllProducts] = useState();
  const [allMenus, setAllMenus] = useState([0, 0, 0, 0, 0, 0]);
  const [searchBoxIsOpen] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [searchResults, setSearchResults] = useState();
  const [hoveredMenu, setHoveredMenu] = useState({ id: -1, hovered: false });
  const [showModal, setShowModal] = useState(false);
  const [subMenuActive, setSubMenuActive] = useState(-1);
  const [addProduct] = useHandleAddProduct();
  const language = useSelector(selectLanguage);
  const products = useSelector(state => selectProducts(state));
  // const language = 'en';
  /* -------------------------------------------------------------------------- */
  /* ------------------------------ Start methods ----------------------------- */
  const handleSubMenu = index => {
    subMenuActive === index ? setSubMenuActive(-1) : setSubMenuActive(index);
  };
  const handleActiveMenuHover = id => {
    setHoveredMenu({ ...hoveredMenu, id: id, hovered: true });
  };
  const handleActiveMenuHoverOut = () => {
    setHoveredMenu({ ...hoveredMenu, id: -1, hovered: false });
  };
  const getAllProducts = async () => {
    try {
      const response = await services.searchProducts();
      let temp = response.data.filter(n => Number(n.status) === 1);
      setAllProducts(temp);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleSearch = value => {
    let searchResult = [];
    setSearchResults('');
    if (value.length > 2) {
      allProducts.map(product => {
        if (product.name.toLowerCase().includes(value.toLowerCase()) && !product.name.toLowerCase().includes('qurban')) {
          searchResult.push(product);
        }
        return searchResult;
      });
      setSearchResults(searchResult);
    }
  };
  const handleSelectedProduct = code => {
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === code) {
            // console.log('found', code, inner_item_product);
            addProduct({ product: inner_item_product, currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD' });
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
  };
  const handleClearSearch = () => {
    setSearchedText('');
    setSearchResults('');
  };
  const getAllMenus = () => {
    setIsLoaded(true);
    // let temp = [...menus];
    // temp[0].children[0].status = true;
    // temp[0].children[0] = Object.assign({}, temp[0].children[0], {
    //   status: true
    // });
    // temp[0] = Object.assign({}, temp[0], {
    //   status: 0
    // });
    // console.log("menus on navbar=",temp,temp[0].children[0])
    // setAllMenus(temp);
    setAllMenus(menus);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    menus && Object.keys(menus).length && !isLoaded && getAllMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus]);
  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <nav
      className={`bg-gradient-to-r from-[#00a3da] to-[#093484] transition-all duration-300 ${
        isRamadanPage ? 'sticky top-0 w-full z-40' : !isRamadanPage ? 'sticky top-0 w-full z-40' : 'relative'
      }`}
    >
      <div className="xl:container mx-auto  lg:px-3 xl:px-0">
        <div className="relative flex h-16 lg:h-24 items-center justify-between">
          <div className="flex items-center justify-start md:justify-start sm:items-stretch w-full h-full">
            {/* /* ------------------------------- Start logo ------------------------------- */}
            <div className="flex w-full lg:w-auto lg:flex-shrink-0 lg:hidden items-center justify-between px-0 xl:px-3">
              <div className="flex items-center">
                <button
                  type="button"
                  className="inline-flex items-center p-2 mr-2 text-md text-white bg-[#f60362] focus:outline-none h-16"
                  onClick={() => setMainMenuIsOpen(!mainMenuIsOpen)}
                >
                  <svg
                    className={`${mainMenuIsOpen ? 'hidden' : 'block'} h-6 w-6`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                  <svg
                    className={`${mainMenuIsOpen ? 'block' : 'hidden'} h-6 w-6`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <Link to="/">
                  <div className="flex items-center md:w-auto h-16">
                    <img
                        className="w-[41px] h-[40px] sm:w-[49px] sm:h-[48px]"
                        src="https://cdn.matwproject.org/images/general/logo-transparent.png"
                        alt="Muslim Charity MATW"
                        title="Islamic Charity MATW"
                    />
                    <h5 className="hidden- lg:block text-white ml-2 text-[10px] sm:text-[14px] uppercase">MATW Project</h5>
                  </div>
                </Link>
              </div>
              <div className="h-16 flex items-end cursor-pointer" onClick={() => setShowModal(true)}>
                {/* <img src={youtubeImg} alt="matw" className="max-h-[64px] w-[50px] h-[46px] min-[450px]:w-[69px] min-[450px]:h-[64px]" /> */}
              </div>
              <div className="flex items-center h-16 pr-2-">
                {props.navigation !== 'tiktok' && (
                  <div
                    className={`relative flex items-center px-2 h-16 bg-[#f60362]- justify-center text-white cursor-pointer border-l border-white border-opacity-10`}
                  >
                    <CustomButton link="/top-10" title={'Faire un don'} className="h-[38px] blob red !px-2" />
                  </div>
                )}
              </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}
            {/* -------------------------- Start Menu on mobile ------------------------- */}
            <div className="flex lg:hidden h-full">
              <ul
                className={`flex flex-col absolute bg-[#00a3da] left-0 w-full top-16 transition-all duration-200 ease-linear z-[99] overflow-hidden ${
                  mainMenuIsOpen ? 'open navbar' : 'navbar'
                }`}
              >
                <li className={`relative w-full py-2 px-4 border-b border-[#81c3ed]`}>
                  <div className="bg-white rounded w-full basis-full- md:basis-3/4- flex px-2 items-center py-1">
                    <p className="text-stone-400 font-light border-r border-stone-300 h-8 w-max whitespace-nowrap pr-2 mr-2 flex items-center">
                      Search MATW:
                    </p>
                    <input
                      type="text"
                      name="searching"
                      className="rounded focus:border-0 focus:outline-none w-full h-10"
                      placeholder="Search for..."
                      value={searchedText}
                      onChange={e => {
                        setSearchedText(e.target.value);
                        handleSearch(e.target.value);
                      }}
                    />
                    <div className="flex items-center justify-center w-5 h-5">
                      {searchedText !== '' && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-5 h-5 cursor-pointer transition-all duration-300 hover:text-[#f60362] hover:rotate-90"
                          onClick={() => handleClearSearch()}
                        >
                          <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className="flex w-full flex-col max-h-[200px] overflow-y-scroll">
                    {searchResults &&
                      searchResults.map((item, index) => {
                        return (
                          <div
                            key={`searchResult${index}`}
                            className="bg-white cursor-pointer py-2 w-full border-b border-stone-200 px-2 transition-all duration-300 hover:pl-4 block"
                            // to={`/checkout?id=${Base64.encode(JSON.stringify({ product: item.creator, currency: selectedCurrencyStore ? selectedCurrencyStore : 'AUD' }))}`}
                            onClick={() => handleSelectedProduct(item.creator)}
                          >
                            {item.name}
                          </div>
                        );
                      })}
                  </div>
                </li>
                {allMenus.map((item, index) => {
                  if (item) {
                    if (item.children.length) {
                      return (
                        (item.status === 1 || item.status === true) && (
                          <React.Fragment key={`top-${index}`}>
                            {item.children.map((level2Item, level2Index) => {
                              return (
                                (level2Item.status === 1 || level2Item.status === true) && (
                                  <li
                                    key={`nav_${level2Index}`}
                                    className={`relative w-full py-2 px-4 border-b border-[#81c3ed] ${
                                      language === 'ar' ? 'font-[AlmariMedium]' : ''
                                    }`}
                                  >
                                    {level2Item.children.length ? (
                                      <>
                                        <div
                                          className="group relative flex w-full text-white items-center"
                                          onClick={() => handleSubMenu(level2Index)}
                                        >
                                          <TriangleRightArrowIcon
                                            className={`w-3 h-3 transition-all duration-300 ${
                                              subMenuActive === level2Index ? 'transform rotate-90' : ''
                                            }`}
                                          />
                                          <span className="text-white px-3 text-[15px] font-medium cursor-pointer flex">{level2Item.title}</span>
                                        </div>
                                        <ul
                                          className={`flex-col transition-all duration-200 ease-linear bg-[#65bff7] rounded-md px-4 min-w-[270px] z-[99] overflow-hidden ${
                                            subMenuActive === level2Index ? 'h-auto my-2 py-4' : 'h-0'
                                          }`}
                                        >
                                          {level2Item.children.map((innerItem, innerIndex) => {
                                            return innerItem.children.length && (innerItem.status === 1 || innerItem.status === true) ? (
                                              <div key={innerIndex} className="flex items-center gap-1">
                                                {innerItem.children.map((item, index) => (
                                                  <React.Fragment key={index}>
                                                    <li className="text-white cursor-pointer py-1 block transition-all duration-200 px-3 text-[15px] hover:text-[#00a3da]">
                                                      <Link to={item.url} aria-current="page" title={item.title}>
                                                        {item.title}
                                                      </Link>
                                                    </li>
                                                    {index !== innerItem.children.length - 1 && <span className="text-white">|</span>}
                                                  </React.Fragment>
                                                ))}
                                              </div>
                                            ) : (
                                              (innerItem.status === 1 || innerItem.status === true) && (
                                                <li key={`innernav_${innerIndex}`} className="transition-all duration-200 hover:bg-stone-100">
                                                  <Link
                                                    className="text-white cursor-pointer py-1 block transition-all duration-200 px-3 text-[15px] hover:text-[#00a3da]"
                                                    aria-current="page"
                                                    to={innerItem.url}
                                                    title={innerItem.title}
                                                  >
                                                    {innerItem.title}
                                                  </Link>
                                                </li>
                                              )
                                            );
                                          })}
                                        </ul>
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          to={level2Item.url}
                                          className="text-white px-3 text-[15px] font-medium"
                                          aria-current="page"
                                          onClick={() => {
                                            setMainMenuIsOpen(false);
                                            handleSubMenu(-1);
                                          }}
                                        >
                                          {level2Item.title}
                                        </Link>
                                        {level2Item.secondHref && (
                                          <>
                                            <span className="text-white">|</span>
                                            <Link
                                              to={level2Item.secondHref}
                                              className="text-white px-3 text-[15px] font-medium"
                                              aria-current="page"
                                              onClick={() => {
                                                setMainMenuIsOpen(false);
                                                handleSubMenu(-1);
                                              }}
                                            >
                                              {level2Item.secondName}
                                            </Link>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </li>
                                )
                              );
                            })}
                          </React.Fragment>
                        )
                      );
                    } else {
                      return (
                        (item.status === 1 || item.status === true) && (
                          <li key={`nav__${index}`} className="relative w-full py-2 px-4 border-b border-[#81c3ed]">
                            <Link
                              to={item.url}
                              className="text-white px-3 text-[15px] font-medium"
                              aria-current="page"
                              onClick={() => {
                                setMainMenuIsOpen(false);
                                handleSubMenu(-1);
                              }}
                            >
                              {item.title}
                            </Link>
                            {item.secondHref && (
                              <>
                                <span className="text-white">|</span>
                                <Link
                                  to={item.secondHref}
                                  className="text-white px-3 text-[15px] font-medium"
                                  aria-current="page"
                                  onClick={() => {
                                    setMainMenuIsOpen(false);
                                    handleSubMenu(-1);
                                  }}
                                >
                                  {item.secondName}
                                </Link>
                              </>
                            )}
                          </li>
                        )
                      );
                    }
                  } else {
                    return (
                      <li key={`nav__${index}`} className="relative w-full py-2 px-4 border-b border-[#81c3ed]">
                        <Link to={'#'} className="text-white px-3 text-[15px] font-medium" aria-current="page" onClick={() => {}}>
                          'title'
                        </Link>
                      </li>
                    );
                  }
                })}
                <li className="transition-all duration-200 flex flex-col justify-center items-center hover:bg-stone-100-">
                  {isUk ? (
                    <>
                      <a
                        href="tel:+44 2080895843"
                        target="_self"
                        className="text-white cursor-pointer py-1 block transition-all duration-200 px-3 text-xl hover:text-[#00a3da]-"
                        rel="noopener noreferrer"
                      >
                        0208 089 5843
                      </a>
                      <span className="text-white flex items-center px-3 transition-all duration-300">
                        <TimeIcon className="w-4 h-4 mr-1" />
                        10am-8pm
                      </span>
                    </>
                  ) : (
                    <>
                      <a
                        href={`tel:${window.location.hostname === 'matwprojectusa.org' ? '+1 7035403446' : '+61 297589037'}`}
                        target="_self"
                        className="text-white cursor-pointer py-1 block transition-all duration-200 px-3 text-xl hover:text-[#00a3da]-"
                        rel="noopener noreferrer"
                      >
                        {window.location.hostname === 'matwprojectusa.org' ? '+1 (703) 540-3446' : '+61 2 9758 9037'}
                      </a>
                      <span className="text-white items-center flex px-3 transition-all duration-300">
                        <TimeIcon className="w-4 h-4 mr-1" />
                        9am-10pm
                      </span>
                    </>
                  )}
                </li>
              </ul>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* -------------------------- Start Menu on desktop -------------------------- */}
            <div className="hidden !w-full lg:flex items-center justify-between md:w-auto">
              <Link className="" to="/">
                <div className="flex items-center md:w-auto h-24">
                  <img
                      className="w-[66px] h-[64px]"
                      src="https://cdn.matwproject.org/images/general/logo-transparent.png"
                      alt="Muslim Charity MATW"
                      title="Islamic Charity MATW"
                  />
                  <h5 className="text-white ml-2 text-[10px] sm:text-[14px] uppercase">MATW Project</h5>
                </div>
              </Link>
              <ul className="flex flex-col md:flex-row h-full- h-24">
                {allMenus.map((item, index) => {
                  if (item) {
                    return (
                      (item.status === 1 || item.status === true) && (
                        <li
                          key={`menus${index}`}
                          style={hoveredMenu.hovered ? (hoveredMenu.id === index ? styles.textHover : styles.textNotHover) : styles.textHovers}
                          className={`relative flex items-center justify-center h-full transition-all duration-200 md:px-1- lg:px-2- xl:px-3- 2xl:px-7- border-r border-white border-opacity-10 ${
                            index === 0 && 'border-l'
                          } ${item.class} px-1 md:px-3 h-24`}
                          onMouseEnter={() => handleActiveMenuHover(index)}
                          onMouseLeave={() => handleActiveMenuHoverOut()}
                        >
                          {item.children.length ? (
                            <div className="group relative">
                              <span
                                style={styles.textShadow}
                                className="text-white text-[14px] transition-all duration-200 uppercase cursor-pointer flex items-center"
                              >
                                {item.title}
                                <ChevronDownIcon className={`w-4 h-4 transition-all duration-300 cursor-pointer ml-1 stroke-[4px]`} />
                              </span>
                              <ul className="hidden group-hover:flex flex-col absolute bg-white rounded-md p-4 shadow-md min-w-[270px] z-[99]">
                                {item.children.map((subMenuItem, subMenuIndex) => {
                                  return (
                                    (subMenuItem.status === 1 || subMenuItem.status === true) && (
                                      <li
                                        key={`submenus${subMenuIndex}`}
                                        className="group/submenu transition-all relative duration-200 hover:bg-stone-100 flex items-center justify-between"
                                      >
                                        {subMenuItem.children.length ? (
                                          <div className="flex items-center text-[#777]">
                                            {subMenuItem.url !== '#' ? (
                                              <Link
                                                to={subMenuItem.url}
                                                className="text-[#777] py-1 cursor-pointer block transition-all duration-200 px-3 text-[15px] hover:text-[#00a3da]"
                                                aria-current="page"
                                              >
                                                {subMenuItem.title}
                                              </Link>
                                            ) : (
                                              <span className="text-[#777] py-1 cursor-pointer block transition-all duration-200 px-3 text-[15px] hover:text-[#00a3da]">
                                                {subMenuItem.title}
                                              </span>
                                            )}
                                            {subMenuItem.secondHref && (
                                              <>
                                                <span>|</span>
                                                <Link
                                                  to={subMenuItem.secondHref}
                                                  className="py-1 cursor-pointer block transition-all duration-200 pl-2 pr-2 text-[15px] hover:text-[#00a3da]"
                                                  aria-current="page"
                                                >
                                                  {subMenuItem.secondName}
                                                </Link>
                                              </>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="flex items-center text-[#777]">
                                            <Link
                                              to={subMenuItem.url}
                                              className="py-1 cursor-pointer block transition-all duration-200 pl-3 pr-2 text-[15px] hover:text-[#00a3da]"
                                              aria-current="page"
                                            >
                                              {subMenuItem.title}
                                            </Link>
                                            {subMenuItem.secondHref && (
                                              <>
                                                <span>|</span>
                                                <Link
                                                  to={subMenuItem.secondHref}
                                                  className="py-1 cursor-pointer block transition-all duration-200 pl-2 pr-2 text-[15px] hover:text-[#00a3da]"
                                                  aria-current="page"
                                                >
                                                  {subMenuItem.secondName}
                                                </Link>
                                              </>
                                            )}
                                          </div>
                                        )}
                                        {subMenuItem.children.length ? (
                                          <>
                                            <ChevronDownIcon
                                              className={`w-4 h-4 transition-all duration-300 cursor-pointer ml-1 stroke-[2px] transform -rotate-90`}
                                            />
                                            <ul
                                              className={`absolute self-start left-full hidden group-hover/submenu:flex flex-col transition-all duration-200 ease-linear bg-white rounded-md px-4 min-w-[270px] z-[99] overflow-hidden h-auto py-4 shadow-xl`}
                                            >
                                              {subMenuItem.children.map((levelThreeItem, levelThreeIndex) => {
                                                return (levelThreeItem.status === 1 || levelThreeItem.status === true) &&
                                                  levelThreeItem.children.length ? (
                                                  <div key={`levelThreeIndex${levelThreeIndex}`} className="flex items-center gap-1">
                                                    {levelThreeItem.children.map((item, index) => (
                                                      <React.Fragment key={index}>
                                                        <li
                                                          className="text-[#777] cursor-pointer py-1 block transition-all duration-200 px-3 text-[15px] hover:text-[#00a3da]"
                                                          key={index}
                                                        >
                                                          <Link to={item.url} aria-current="page" title={item.title}>
                                                            {item.title}
                                                          </Link>
                                                        </li>
                                                        {index !== levelThreeItem.children.length - 1 && (
                                                          <span className="text-[#777] text-md">|</span>
                                                        )}
                                                      </React.Fragment>
                                                    ))}
                                                  </div>
                                                ) : (
                                                  (levelThreeItem.status === 1 || levelThreeItem.status === true) && (
                                                    <li
                                                      key={`submenuIndex${levelThreeIndex}`}
                                                      className="transition-all duration-200 hover:bg-stone-100"
                                                    >
                                                      <Link
                                                        className="text-[#777] cursor-pointer py-1 block transition-all duration-200 px-3 text-[15px] hover:text-[#00a3da]"
                                                        aria-current="page"
                                                        to={levelThreeItem.url}
                                                        title={levelThreeItem.title}
                                                      >
                                                        {levelThreeItem.title}
                                                      </Link>
                                                    </li>
                                                  )
                                                );
                                              })}
                                            </ul>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </li>
                                    )
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            <Link
                              to={item.url}
                              style={styles.textShadow}
                              title={item.title}
                              className="text-white flex items-center h-24 transition-all duration-200 text-[14px] uppercase"
                              aria-current="page"
                            >
                              {item.title}
                            </Link>
                          )}
                        </li>
                      )
                    );
                  } else {
                    return (
                      <li
                        key={`nav__${index}`}
                        className="relative min-w-[95px] flex items-center justify-center transition-all duration-200 md:px-1- lg:px-2- xl:px-3- 2xl:px-7- border-r border-white border-opacity-10 border-l undefined px-1 md:px-3 h-24"
                      >
                        <Link to={'#'} className="text-white px-3 text-[15px] font-medium" aria-current="page" onClick={() => {}}></Link>
                      </li>
                    );
                  }
                })}
                {/* /* ---------------------------------- cart ---------------------------------- */}
              </ul>
              {props.navigation !== 'tiktok' && (
                <div className="flex items-center h-24">
                  <div className="flex flex-col items-center h-20 justify-center group transition-all duration-300 hover:pb-5 md:p-1 md:pl-2">
                    <CustomButton link="/top-10" title={'Faire un don'} className="w-[130px] h-[38px] blob red !px-2" />
                  </div>
                </div>
              )}
            </div>
            {/* /* -------------------------------------------------------------------------- */}
          </div>
        </div>
        {/* /* ---------------------------- Start Search box ----------------------------  */}
        {searchBoxIsOpen && (
          <div className="w-full flex flex-wrap justify-center items-center bg-[#00a3da] min-h-32 z-50 absolute px:6 md:px-0 left-0 py-5">
            <div className="basis-full md:basis-3/4 flex flex-col justify-center px-2 lg:px-0">
              <div className="flex w-full">
                <div className="bg-white rounded w-full basis-full- md:basis-3/4- flex px-2 items-center py-1">
                  <p className="text-stone-400 font-light border-r border-stone-300 h-8 w-max whitespace-nowrap pr-2 mr-2 flex items-center">
                    Search MATW:
                  </p>
                  <input
                    type="text"
                    name="searchingMobile"
                    className="rounded focus:border-0 focus:outline-none w-full h-10"
                    placeholder="Search for..."
                    value={searchedText}
                    onChange={e => {
                      setSearchedText(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                  <div
                    className="flex items-center justify-center w-5 h-5"
                    onClick={() => {
                      if (searchedText !== '') {
                        setSearchedText('');
                        handleSearch('');
                      }
                    }}
                  >
                    {searchedText !== '' && (
                      <CloseIcon className="w-5 h-5 cursor-pointer transition-all duration-300 hover:text-[#f60362] hover:rotate-90" />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col max-h-[200px] overflow-y-scroll">
                {searchResults &&
                  searchResults.map((item, index) => {
                    return (
                      <div
                        key={`searchResult${index}`}
                        className="bg-white cursor-pointer py-2 w-full border-b border-stone-200 px-2 transition-all duration-300 hover:pl-4 block"
                        onClick={() => handleSelectedProduct(item.creator)}
                      >
                        {item.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {/* /* --------------------------------------------------------------------------  */}
      </div>
      {showModal && (
        <CustomModal show={showModal} onClose={() => setShowModal(false)} className="md:w-2/3" title="">
          <iframe
            className="video md:min-h-[400px]"
            width={'100%'}
            height={'100%'}
            title="Gifted with Cancer - Ali Banat"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`}
          ></iframe>
        </CustomModal>
      )}
      {/* FloatingButton */}
      {/* {showRegularImg && <Link to="/qurban" className="fixed top-1/2 -translate-y-[20%] md:-translate-y-1/2 right-0 z-[30] md:z-[50]">
        <img src={imgFloatingButtonDesk} alt="img" className="hidden md:block" />
        <img src={imgFloatingButtonMob} alt="img" className="md:hidden" />
      </Link>} */}
    </nav>
  );
};

export default CustomNavbar;
