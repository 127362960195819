import React, { useEffect } from 'react';
import Layout from '../../components/general/layout';
// import Layout from '../components/general/layout';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
export default function SocialMedia() {
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' });
    // window.scroll(0,0);
    window.scroll(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout>
      {seo && (
        <Helmet>
          <title>{`${seo['resourcesAndInformation']['socialMedia']['title']}`}</title>
          <meta name="title" content={`${seo['resourcesAndInformation']['socialMedia']['title']}`} />
          <meta name="description" content={`${seo['resourcesAndInformation']['socialMedia']['description']}`} />
        </Helmet>
      )}
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col items-center justify-center">
          <h1 className="text-[#23408f] text-center text-3xl mt-8 font-bold">
            <strong>Réseaux sociaux</strong>
          </h1>
          <p className="text-center mt-2 mb-6 text-[#777] text-base font-black ">
            <strong>Tout le soutien ne passe pas par la valeur monétaire !</strong>
          </p>
          <p className="text-center text-base text-[#777]">
            Les réseaux sociaux sont devenus une plateforme mondiale pour que nous puissions tous nous connecter, notre travail a atteint des milliers
            de personnes grâce à nos plateformes. Nous remercions tous nos soutiens pour leur soutien régulier à travers –
          </p>
        </div>

        <div className="mt-8	w-full flex justify-center">
          <div className="flex ">
              <a href="https://www.instagram.com/matw_projectfrance/profilecard/?igsh=MTF5bThsdmcweHRv" target="_blank"
                 title="Instagram"
                 rel="noreferrer">
                <img src="/images/social-media/instagram.png" alt="instagram" className=" w-10 h-10   mr-4"/>
              </a>

              <a href="https://www.tiktok.com/@matw_projectfrance?_t=8rO16p1G4WW&_r=1" target="_blank" title="Twitter"
                 rel="noreferrer">
                <img src="/images/social-media/tiktok.png" alt="twitter" className=" w-10 h-10 mr-4"/>
              </a>


              <a href="https://www.youtube.com/@matw_projectfrance" target="_blank" title="YouTube"
                 rel="noreferrer">
                <img src="/images/social-media/youtube.png" alt="youtube" className=" w-10 h-10 mr-4"/>
              </a>
            </div>
          </div>


        </div>
    </Layout>
);
}
