import { Link } from 'react-router-dom';
import Layout from '../../components/general/layout';
import banner from '../../images/landings/phishing/phishing-desktop-banner.jpg';
import bannerMobile from '../../images/landings/phishing/phishing-mobile-banner.jpg';
import phishingImg from '../../images/landings/phishing/phishing.jpg';

const PhishingLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  /* -------------------------------------------------------------------------- */
  return (
    <Layout className="bg-white">
      <section className="flex justify-center">
        <img src={banner} alt="ramadan 2024" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="ramadan 2024" className="min-[501px]:hidden w-full" />
      </section>
      <div className="md:container md:mx-auto mt-5 md:mt-10 px-4 md:px-0">
        <h2 className="text-[#23408f] text-[25px] md:text-[40px] my-4 w-full md:text-center font-bold">Hameçonnage et fraude</h2>
        <p className="w-full px-2 md:px-0 text-[#78716C] md:text-[18px]">
          La relation avec nos donateurs est d'une importance capitale pour nous ici à MATW. Leur soutien indéfectible nous aide à faire avancer notre
          mission. Nous faisons souvent appel aux dons pour permettre la réalisation de nos projets vitaux et nous sommes extrêmement reconnaissants
          envers les donateurs qui réagissent rapidement.
          <br />
          <br />
          Nous devons malheureusement aborder un problème préoccupant qui a attiré notre attention. Il a été porté à notre connaissance que certaines
          personnes, sans aucune affiliation avec MATW (Muslims Around The World), exploitent notre identité en utilisant notre nom et notre logo pour
          solliciter frauduleusement des dons à des fins personnelles. En se faisant passer pour MATW, ces individus tentent d'obtenir illégalement
          des informations financières et personnelles.
          <br />
          <br />
          Bien que nous nous efforcions de lutter contre de telles pratiques trompeuses, nous reconnaissons les défis liés à leur prévention totale.
          Ainsi, nous vous exhortons à rester vigilant et à faire preuve de prudence si vous recevez une demande que vous jugez suspecte. Pour
          signaler toute activité suspecte ou toute utilisation non autorisée de notre identité, veuillez utiliser le canal sécurisé suivant :
          <Link to={'/contact'} className="ml-1 text-[#23408f]">
            contactez-nous
          </Link>
          .
          <br />
          <br />
          Dans un souci de transparence et afin d'illustrer la gravité de ce problème, nous souhaitons partager quelques exemples récents
          d'utilisation non autorisée de notre identité :
        </p>

        <div className="w-full bg-[#E1EFF2] rounded-lg flex justify-center items-center p-4 md:p-8 my-3 md:my-6">
          <img src={phishingImg} alt="Phishing scams & fraud" className="w-full rounded-md" />
        </div>
        <p className="w-full px-2 md:px-0 text-[#78716C] md:text-[18px]">
          Votre confiance et votre soutien continus sont inestimables pour nous, et nous nous engageons à préserver l'intégrité de notre organisation.
          Ensemble, nous pouvons œuvrer pour combattre les activités frauduleuses et garantir la sécurité de nos soutiens.
          <br />
          <br />
          Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter directement.
        </p>
      </div>
    </Layout>
  );
};

export default PhishingLandingPage;
