import { useEffect, useRef, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react';
import VideosSection from '../../components/general/videosSection';
import { selectProducts } from '../../store/products';
import WidgetHome from '../../components/widgets/widgetHome';
// import banner from '../../images/landings/palestine/anniversary-landing-banner-gaza.jpg';
import matwImpactPhoto from '../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import { OurPromiseSection } from '../../components/general';
import { impactInfo, impactSliderData, winterVideos as videos } from '../crisis-and-emergencies/fixtures';
import WinterTabs from './winterTabs';
import ImagesSection from '../crisis-and-emergencies/components/common-sections/images';
import { winterFaqs } from './faq';
import Accordion from '../../components/general/accordion';
import winterSlider1 from '../../images/landings/winter/Winter-Landing-Page-Image-1.jpg';
import winterSlider2 from '../../images/landings/winter/Winter-Landing-Page-Image-2.jpg';
import winterSlider3 from '../../images/landings/winter/Winter-Landing-Page-Image-3.jpg';
import winterSlider4 from '../../images/landings/winter/Winter-Landing-Page-Image-4.jpg';
import winterSlider5 from '../../images/landings/winter/Winter-Landing-Page-Image-5.jpg';
import { useTranslation } from 'react-i18next';

const WinterLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [winterEmergencyProducts, setWinterEmergencyProducts] = useState([0, 0, 0, 0]);
  const [pakistanWinterEmergencyProducts, setPakistanWinterEmergencyProducts] = useState([0, 0, 0, 0]);
  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const contentRef = useRef(null);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  const images = [
    { src: winterSlider1, alt: 'Donate for winter emergency' },
    { src: winterSlider2, alt: 'Donate for winter emergency' },
    { src: winterSlider3, alt: 'Donate for winter emergency' },
    { src: winterSlider4, alt: 'Donate for winter emergency' },
    { src: winterSlider5, alt: 'Donate for winter emergency' },
  ];
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempWinterEmergencyProducts = [];
    let tempPakistanWinterEmergencyProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-01') tempWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-02') tempWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-03') tempWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-GEN-24-04') tempWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-01') tempPakistanWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-02') tempPakistanWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-03') tempPakistanWinterEmergencyProducts.push(inner_item_product);
          if (inner_item_product.creator === 'EMR-WNT-PAK-24-04') tempPakistanWinterEmergencyProducts.push(inner_item_product);
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempWinterEmergencyProducts = tempWinterEmergencyProducts.filter(n => n);
    tempPakistanWinterEmergencyProducts = tempPakistanWinterEmergencyProducts.filter(n => n);
    setWinterEmergencyProducts(tempWinterEmergencyProducts);
    setPakistanWinterEmergencyProducts(tempPakistanWinterEmergencyProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['winter']['title']}`}</title>
          <meta name="title" content={`${seo['winter']['title']}`} />
          <meta name="description" content={`${seo['winter']['description']}`} />
        </Helmet>
      )}

      <section className="xs:block md:hidden sticky top-16 z-30">
        <WidgetHome
          suggestedPrices={[50, 100, 250]}
          selectedPrice={50}
          defaultProduct={{ creator: 'EMR-WNT-GEN-24-01', label: 'Emergency Winter Appeal', value: 713 }}
        />
      </section>

      {/* <section className="flex justify-center">
        <img
          src={language === 'fr' ? bannerFr : banner}
          alt="Donate to Palestine"
          className="max-[500px]:hidden w-full"
        />
        <img
          src={language === 'fr' ? bannerMobileFr : banner}
          alt="Donations to Palestine"
          className="min-[501px]:hidden w-full"
        />
      </section> */}
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="xs:hidden md:block sticky top-16 lg:top-24 z-30">
        <WidgetHome
          suggestedPrices={[50, 100, 250]}
          selectedPrice={50}
          defaultProduct={{ creator: 'EMR-WNT-GEN-24-01', label: 'Emergency Winter Appeal', value: 713 }}
        />
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <div ref={contentRef} />
      <WinterTabs winterEmergencyProducts={winterEmergencyProducts} pakistanWinterEmergencyProducts={pakistanWinterEmergencyProducts} lang={lang} />
      {/* ----------------- videos section ---------------- */}
      <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
      {/* ----------------- videos section --END-------------- */}

      {/* ------------------------ Total Impact Section */}
      <section className="bg-[#E1EFF2] py-6 md:py-8 flex flex-col">
        <div className="flex items-center justify-center mb-4 md:mb-5">
          <img src={matwImpactPhoto} alt="Donate to Palestine to make your MATW impact" />
        </div>
        <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
          <h2
            className={`text-[18px] md:text-4xl ${
              lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
            } text-[#253B7E] text-center font-black`}
          >
            <span className="font-normal text-[18px] md:text-4xl text-[#78716C] uppercase mr-1">
              {lang === 'fr' ? 'IMPACT TOTAL:' : lang === 'ar' ? 'التأثير الكلي:' : 'Total Impact:'}
            </span>
            &nbsp; {impactInfo.total}
          </h2>
        </div>
        <div className="hidden md:container md:mx-auto sm:grid sm:grid-cols-4 xl:grid-cols-4 gap-16 justify-between items-center">
          {impactSliderData.map(({ img, value, title, frenchTitle, arabicTitle }, index) => (
            <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
              <img src={img.src} alt={img.alt} />
              <div className="flex flex-col items-center text-center">
                <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>{value}</h4>
                <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>
                  {lang === 'fr' ? frenchTitle : lang === 'ar' ? arabicTitle : title}
                </small>
              </div>
            </div>
          ))}
        </div>
        <div className={`sm:hidden px-6 md:px-0 relative`}>
          <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
            {impactSliderData.map(({ img, value, title, frenchTitle, arabicTitle }, index) => (
              <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                <img src={img.src} alt={img.alt} />
                <div className="flex flex-col items-center text-center">
                  <h4 className={`md:text-2xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-[#253B7E]`}>{value}</h4>
                  <small className={`text-xs md:text-base ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'} uppercase text-[#78716C]`}>
                    {lang === 'fr' ? frenchTitle : lang === 'ar' ? arabicTitle : title}
                  </small>
                </div>
              </div>
            ))}
          </div>
          {loadedImpact && instanceRefImpactSlider.current && (
            <>
              <Arrow
                left
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                className="bg-transparent"
                disabled={currentImpactSlide === 0}
                icon={impactSliderLeftIcon}
              />

              <Arrow
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                className="bg-transparent"
                icon={impactSliderRightIcon}
                rightMargin
              />
            </>
          )}
        </div>
      </section>
      {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1
          className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${
            lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'
          }`}
        >
          Millions Wait Patiently for Help
        </h1>
        <p
          className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 text-center ${
            lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'
          }`}
        >
          The devastating winter claims <strong className="text-LightBlue">thousands</strong> of lives every year.
          <br />
          <br />
          The <strong className="text-LightBlue">cold</strong> bites like hunger, seeping into every bone. For those caught in the{' '}
          <strong className="text-LightBlue">merciless</strong> grip of conflict, winter is not just a season—it’s a{' '}
          <strong className="text-LightBlue">deadly</strong> adversary.
          <br />
          <br />
          Temperatures are plummeting in <strong className="text-LightBlue">Gaza and Lebanon</strong> where millions are currently displaced, many of
          them living in the outdoors knowing that the <i>relentless cold</i> knows no mercy.
          <br />
          <br />
          <strong className="text-LightBlue">MATW</strong> are delivering winter relief to <i>Palestinians, Lebanese and Syrians</i> across the Middle
          East. We're also supporting the ummah in <i>Pakistan, Afghanistan and other countries</i> worldwide who face severe suffering in the winter
          months.
          <br />
          <br />
          <strong className="text-LightBlue">
            SHELTER
            <br />
            BLANKETS | CLOTHING HEATING
            <br />
            SUPPLIES | HOT MEALS
            <br />
            <br />
          </strong>
          Please donate whatever you can afford as <i>your generosity</i> can be the only shield orphans, widows and the elderly have this winter
        </p>
        <button
          type="submit"
          å
          onClick={() => {
            window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 220 });
          }}
          className={`mt-6 md:mt-8 w-fit flex-col mx-auto rounded-full bg-[#f60362] text-white  focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da] flex items-center justify-center px-6 pt-2 pb-2 font-normal text-xl md:text-2xl transition duration-150 ease-in-out`}
        >
          DONATE NOW
          <span className="text-xs md:text-sm font-brandingLight tracking-tighter">SAVE THEM FROM THE COLD</span>
        </button>
        <p
          className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 text-center ${
            lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'
          }`}
        >
          <strong className="text-LightBlue">Our Muslim Ummah</strong> reels in pain, especially in the Middle East, having endured relentless and
          catastrophic assaults from all sides. And now, as winter’s bitter chill descends, a new, silent threat lurks—the severe cold.
          <br />
          <br />
          In Gaza, <strong className="text-LightBlue">2.3 MILLION</strong> are at immediate risk.
          <br />
          In Lebanon, <strong className="text-LightBlue">MILLIONS</strong> will fight the severe cold.
          <br />
          <br />
          As temperatures plunge, <strong className="text-LightBlue">families</strong> huddle in thin tents,{' '}
          <strong className="text-LightBlue">children</strong> shiver with <i>no coats or blankets</i>, and the{' '}
          <strong className="text-LightBlue">elderly</strong> battle to stay alive through the harshest of nights.{' '}
          <strong className="text-LightBlue">Winter</strong>, which brings warmth to some, brings <strong className="text-LightBlue">terror</strong>{' '}
          to those with nothing.
          <br />
          <br />
          This <strong className="text-LightBlue">winter</strong>, survival is a daily <strong className="text-LightBlue">battle</strong>.
          <br />
          <br />
          Your <strong className="text-LightBlue">support</strong> can provide <i>warmth, shelter, and hope</i> to those clinging to life in the face
          of unimaginable hardship. A <strong className="text-LightBlue">donation</strong> today could mean the difference between{' '}
          <strong className="text-LightBlue">life and death.</strong>
          <br />
          <br />
          Please donate whatever you can afford as Your generosity can be the only shield orphans, widows and the elderly have this winter.
        </p>
        <button
          type="submit"
          å
          onClick={() => {
            window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 220 });
          }}
          className={`mt-6 md:mt-8 w-fit flex-col mx-auto rounded-full bg-[#f60362] text-white  focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da] flex items-center justify-center px-6 pt-2 pb-2 font-normal text-xl md:text-2xl transition duration-150 ease-in-out`}
        >
          DONATE NOW
          <span className="text-xs md:text-sm font-brandingLight tracking-tighter">SAVE THEM FROM THE COLD</span>
        </button>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection images={images} />
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* --------------------------------- FAQ’s --------------------------------- */}
      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h1 className="text-[#253B7E] md:text-center mb-5 md:mb-6 lg:mb-8 text-[30px] sm:text-[48px] font-montserratBold">
          Winter Emergency <span className="text-[#F60362]"> FAQ’s</span>
        </h1>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{winterFaqs[0].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{winterFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{winterFaqs[1].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{winterFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <h2 className="text-[#253B7E] font-medium text-xl leading-7">{winterFaqs[2].question}</h2>
            <p className="text-base font-brandingMedium mt-3">{winterFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {winterFaqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default WinterLandingPage;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${
        props.bg ? props.bg : 'bg-[#F60362]'
      } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${
        props.className ? props.className : ''
      } ${props.left ? 'arrow--left left-[8px]' : 'arrow--right left-auto right-[8px]'}`}
      onClick={props.onClick}
    >
      {props.icon ? (
        <img alt="arrow-img" src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-1' : '-ml-1'} `} />
      ) : (
        <svg
          className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
          {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
      )}
    </div>
  );
}
