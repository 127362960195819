import React from "react";

// Admin Imports
// import Reports from "views/admin/reports";
// import QurbanReports from "views/admin/reportsQurban";
// import ZohoReports from "views/admin/zoho";

// Auth Imports
// import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  // MdAutoAwesomeMotion,
  // MdBarChart,
  MdFileCopy,
  MdHome,
  MdLogout,
  MdMenuOpen,
  MdShoppingBasket,
} from "react-icons/md";
import BannersPage from "./pages/banners";
import ProductsPage from "./pages/products";
import PageBuilder from "./pages/pageBuilder";
import Menus from "./pages/menus";
// import PageBuilderV2 from "./pages/pageBuilder/index-v2";
// import SubscriptionsPage from "./pages/subscriptions";

const routes = [
  {
    name: "Home Banners",
    layout: "/",
    path: "/dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <BannersPage />,
  },
  {
    name: "Products",
    layout: "/",
    path: "/dashboard/manage-products",
    icon: <MdShoppingBasket className="h-6 w-6" />,
    component: <ProductsPage />,
    secondary: true,
  },
  {
    name: "Pages",
    layout: "/",
    path: "/dashboard/pages",
    icon: <MdFileCopy className="h-6 w-6" />,
    component: <PageBuilder />,
    // component: <PageBuilderV2 />,
    secondary: true,
  },
  {
    name: "Menus",
    layout: "/",
    path: "/dashboard/menus",
    icon: <MdMenuOpen className="h-6 w-6" />,
    component: <Menus />,
    secondary: true,
  },
  // {
  //   name: "Subscriptions",
  //   layout: "/",
  //   path: "/dashboard/subscriptions",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   component: <SubscriptionsPage />,
  //   secondary: true,
  // },
  {
    name: "Log Out",
    layout: "/",
    path: "/home",
    icon: <MdLogout className="h-6 w-6" />,
    component: '',
  },
  // {
  //   name: "Qurban",
  //   layout: "/",
  //   path: "qurban",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   component: <QurbanReports />,
  //   secondary: true,
  // },
  // {
  //   name: "Qurban List",
  //   layout: "/",
  //   path: "list",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   component: <QurbanReports />,
  //   secondary: true,
  // },
  // {
  //   name: "Zoho",
  //   layout: "/",
  //   path: "zoho",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   component: <ZohoReports />,
  //   secondary: true,
  // },
];
export default routes;
