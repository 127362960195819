import Policy from '../../images/landings/palestine/policy100.png';

const ourPromiseSection = ({ alt, arabic, childClasses, lang = 'en' }) => {
  const content = {
    heading: 'NOTRE POLITIQUE DE DON 100% GARANTIT',
    description: 'Fondé par Ali Banat en 2016, le projet Muslims Around The World (MATW) est une organisation caritative islamique mondiale qui travaille dans 30 pays à travers le monde. Notre politique de donation à 100% garantit que chaque don va plus loin en atteignant ceux qui sont le plus dans le besoin.'
  };
  return (
    <span></span>
  );
};

export default ourPromiseSection;
