import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import banner from '../../images/sadaqah/aqiqah-banner.jpg';
import bannerMobile from '../../images/sadaqah/aqiqah-banner-mobile.jpg';
import SheepGoatSacrificeImage from '../../images/sadaqah/sheep-goat-sacrifice.jpg';
import DonateGeneralSacrificeImage from '../../images/sadaqah/donate-general-sacrifice.jpg';
import MatwReportingTransparencyImage from '../../images/sadaqah/matw-reporting-transparency.jpg';
import React, { useEffect, useRef, useState } from 'react';
import AqiqahTabContent from './components/aqiqahTabContent';
import { selectProducts } from '../../store/products';
import { CustomButton, OurPromiseSection } from '../../components/general';
import { aqiqahFaqs } from './faqs';

const Aqiqah = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrency = useSelector(selectSelectedCurrency);
  const [isLoaded, setIsLoaded] = useState(false);
  const seo = SeoData[selectedcountryCode];
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const [sheepGoatSacrificeProducts, setSheepGoatSacrificeProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [cowSacrificeProducts, setCowSacrificeProducts] = useState([0]);
  const contentRef = useRef(null);
  const tabButtonsProducts = [
    {
      title: 'Sheep/Goat Sacrifice',
      description: '',
      items: [
        {
          title: 'sheep/Goat sacrifice',
          description:
            'Sadaqah is a voluntary act of charity in Islam, Providing meat to those in need is a noble cause. Support our Sadaqah sheep sacrifice program',
          products: sheepGoatSacrificeProducts,
          image: SheepGoatSacrificeImage,
        },
      ],
    },
    {
      title: 'cow sacrifice',
      description: '',
      items: [
        {
          title: 'Sadaqah Cow Sacrifice in Africa',
          description: 'Sadaqah in Islam refers to a charitable act of donation, The sacrifice of a cow will be remembered as an act of',
          products: cowSacrificeProducts,
          image: SheepGoatSacrificeImage,
        },
      ],
    },
  ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let sheepGoatSacrificeProductsList = [];
    let cowSacrificeProductsList = [];

    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'SAC-DEL-SSS-2023-01-001') {
            sheepGoatSacrificeProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'SAC-DEL-WSS-2023-01-003') {
            sheepGoatSacrificeProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'SAC-DEL-ASS-2023-01-002') {
            sheepGoatSacrificeProductsList[2] = inner_item_product;
          } else if (inner_item_product.creator === 'SAC-DEL-NSS-2023-01-004') {
            sheepGoatSacrificeProductsList[3] = inner_item_product;
          } else if (inner_item_product.creator === 'SAC-DEL-SGB-2023-01-007') {
            sheepGoatSacrificeProductsList[4] = inner_item_product;
          } else if (inner_item_product.creator === 'SAC-DEL-SSY-2023-01-006') {
            sheepGoatSacrificeProductsList[5] = inner_item_product;
          } else if (inner_item_product.creator === 'SAC-DEL-SCS-2023-01-005') {
            cowSacrificeProductsList[0] = inner_item_product;
          }
        });
      });
    });
    sheepGoatSacrificeProductsList = sheepGoatSacrificeProductsList.filter(n => n);
    cowSacrificeProductsList = cowSacrificeProductsList.filter(n => n);
    setSheepGoatSacrificeProducts(sheepGoatSacrificeProductsList);
    setCowSacrificeProducts(cowSacrificeProductsList);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white">
      {seo && (
        <Helmet>
          <title>{`${seo['aqiqah']['title']}`}</title>
          <meta name="title" content={`${seo['aqiqah']['title']}`} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: 'Where Most Needed',
            value: 255,
          }}
        />
      </section>

      <section className="flex justify-center">
        <img src={banner} alt="Donate to Palestine" className="hidden md:block w-full aspect-[5/1] object-cover" />
        <img src={bannerMobile} alt="Donations to Palestine" className="md:hidden w-full aspect-[2/1] object-cover" />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: 'Where Most Needed',
              value: 255,
            }}
          />
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Tabs Section --------------------------------- */}
      <section className="bg-[#253B7E]">
        <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
          <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
            {tabButtonsProducts.map(({ title }, index) => (
              <button
                key={`btn_${index}`}
                onClick={() => {
                  window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 300 });
                  setActiveTabProducts(index);
                }}
                className={`${
                  index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
                } text-[14px] rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase`}
              >
                {title}
              </button>
            ))}
          </div>
          <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
            <div className="flex flex-col w-full justify-center">
              {tabButtonsProducts.map(
                (tab, index) =>
                  index === activeTabProducts && (
                    <React.Fragment key={`description_${index}`}>
                      <div ref={contentRef}>
                        <AqiqahTabContent
                          items={tab.items}
                          showArrows={true}
                          currency={selectedCurrency}
                          showMultiProductsOnRow={false}
                          hasCustomPrice={tab.hasCustomPrice}
                        />
                      </div>
                    </React.Fragment>
                  ),
              )}
            </div>
          </div>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Our Promise Section --------------------------------- */}
      <OurPromiseSection />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Content Section --------------------------------- */}
      <section className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12 !max-w-[1080px]">
        <h1 className="text-[30px] leading-[32px] md:text-[40px] md:leading-[42px] font-brandingBold text-[#253B7E] text-center">
          As believers, the act of expressing gratitude toward Allah SWT is fundamental as part of our faith. While there are many ways we can do
          this, one of the most profound acts of Ibadah is by <span className="text-[#F60362]">offering a sacrifice.</span>
        </h1>
        <h2 className="text-center text-[25px] leading-[27px] md:text-[40px] md:leading-[42px] font-gotcha text-[#253B7E] py-5 md:py-8">
          This is often referred to as a Nidr sacrifice (a general sacrifice) or an Aqeeqah. 
        </h2>
        <p className="text-base md:text-lg font-brandingMedium text-[#78716C]">
          The Nidr or general sacrifice is offered to give thanks to Allah and could be for any reason. Not only is this act of worship and donation a
          form of Ibadah, it also ensures that families in impoverished communities receive fresh, nourishing meat. 
          <br />
          <br />
          The Aqeeqah sacrifice is a sacred Islamic rite observed upon the blessed arrival of a newborn. It stands as a testament to the family's
          gratitude to Allah S.W.T for the precious gift of the arrival of the latest addition to their family. Beyond mere gratitude, the Aqeeqah
          sacrifice embodies the cherished Sunnah tradition, where the meat of the sacrificial animal is graciously shared among family and friends
          and/or the less fortunate.
        </p>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Youtube Video Section ---------------------------------  */}
      <section className="md:container w-full mx-auto">
        <iframe
          className="video w-full aspect-[16/9] mt-10"
          width={'100%'}
          title={'youtube'}
          frameBorder={0}
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`https://www.youtube.com/embed/ZVQZp5PiiYE?controls=1&autoplay=0&mute=1&playlist=ZVQZp5PiiYE`}
        ></iframe>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- More about Aqeeqah Section --------------------------------- */}
      <section className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12 !max-w-[1080px]">
        <h1 className="text-center text-[30px] leading-[32px] md:text-[40px] md:leading-[42px] font-brandingBold">More about Aqeeqah:</h1>
        <div className="my-5 md:my-8 py-5 relative max-w-[340px] md:max-w-[732px] mx-auto">
          <span className="absolute top-0 left-0">
            <ApostrophStart />
          </span>
          <p className="max-w-[238px] md:max-w-[570px] mx-auto text-center text-[#14A2DC] text-lg md:text-2xl font-brandingBold">
            The Messenger of Allah (ﷺ) stated: “Whoever has a child born to him, and wants to offer a sacrifice on his behalf, let him do so...{' '}
            <span className="text-[#AAA] text-sm md:text-lg font-BrandingMediumItalic">(Hasan Hadith - Abi Dawood).</span>
          </p>
          <span className="absolute bottom-0 right-0">
            <ApostrophEnd />
          </span>
        </div>
        <p className="text-base md:text-xl font-brandingMedium text-[#78716C]">
          The Nidr or general sacrifice is offered to give thanks to Allah and could be for any reason. Not only is this act of worship and donation a
          form of Ibadah, it also ensures that families in impoverished communities receive fresh, nourishing meat. 
          <br />
          <br />
          The Aqeeqah sacrifice is a sacred Islamic rite observed upon the blessed arrival of a newborn. It stands as a testament to the family's
          gratitude to Allah S.W.T for the precious gift of the arrival of the latest addition to their family. Beyond mere gratitude, the Aqeeqah
          sacrifice embodies the cherished Sunnah tradition, where the meat of the sacrificial animal is graciously shared among family and friends
          and/or the less fortunate.
        </p>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Donate Section --------------------------------- */}
      <section className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12 !max-w-[1080px]">
        <div className="flex gap-x-10 flex-col md:flex-row">
          <div className="basis-full sm:basis-1/2">
            <img src={DonateGeneralSacrificeImage} alt="Islamic Donation for emergency appeals" className="rounded-lg w-full" />
          </div>
          <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
            <p className="text-[#253B7E] text-[25px] leading-[30px] md:text-[40px] md:leading-[42px] my-4 font-brandingBold">
              Donate a General Sacrifice or Aqeeqah throughout the year
            </p>
            <p className="text-[#78716C] leading-[19px] sm:leading-6 md:text-[18px] font-brandingMedium mb-5 md:my-5">
              In contrast to other acts of worship or Sadaqah gifts, MATW’s General Sacrifice (Nidr) and Aqeeqah can be donated at any time of the
              year, at the discretion of our donors.
            </p>
            <CustomButton
              onClicked={() => {
                  window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 300 });
              }}
              title="donate now"
              className="mb-2 sm:mb-0 !w-fit uppercase mx-auto md:mx-0"
              />
          </div>
        </div>
        <div className="flex gap-x-10 flex-col md:flex-row mt-5 md:mt-12">
          <div className="basis-full sm:basis-1/2">
            <img src={MatwReportingTransparencyImage} alt="Islamic Donation for emergency appeals" className="rounded-lg w-full" />
          </div>
          <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
            <p className="text-[#253B7E] text-[25px] leading-[30px] md:text-[40px] md:leading-[42px] my-4 font-brandingBold">
              MATW Reporting and Transparency
            </p>
            <p className="text-[#78716C] leading-[19px] sm:leading-6 md:text-[18px] font-brandingMedium mb-5">
              At MATW, delivering with Ihsan is an Amanah and of the utmost importance to us. Our General Sacrifice and Aqeeqah program is delivered with excellence and transparency. Once donors have made their donation and this has been processed, proof of delivery and sacrifice can be requested which includes images and in some cases, video.
            </p>
            <p className="text-[#253B7E] leading-[19px] sm:leading-6 md:text-[18px] font-brandingBold mb-5">Donate your Aqeeqah or Nidr today. </p>
            <CustomButton
              onClicked={() => {
                window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 300 });
              }}
              title="donate now"
              className="mb-2 sm:mb-0 !w-fit uppercase mx-auto md:mx-0"
              />
          </div>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* -------------------------------------FAQS--------------------------------- */}
      <section className='md:container w-full mx-auto  mt-10'>
          <h1 className='text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
            Nidr/Aqeeqah <span className='text-[#F60362]'> FAQ’s</span>
          </h1>
          <div className='grid grid-cols-3 gap-x-16 gap-y-8 px-5'>
              <div className='col-span-3 md:col-span-1'>
                  <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{aqiqahFaqs[0].question}</h1>
                  <p className='text-base font-montserratMedium leading-4 mt-3'>
                      {aqiqahFaqs[0].answer}
                  </p>
              </div>
              <div className='col-span-3 md:col-span-1'>
                  <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{aqiqahFaqs[1].question}</h1>
                  <p className='text-base font-montserratMedium leading-4 mt-3'>
                      {aqiqahFaqs[1].answer}
                  </p>
              </div>
              <div className='col-span-3 md:col-span-1'>
                  <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{aqiqahFaqs[2].question}</h1>
                  <p className='text-base font-montserratMedium leading-4 mt-3'>
                      {aqiqahFaqs[2].answer}
                  </p>
              </div>
          </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

    </Layout>
  );
};

const ApostrophStart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 44" fill="none" className="w-[35px] h-[25px] md:w-[60px] md:h-[44px]">
      <g clip-path="url(#clip0_10674_3022)">
        <path
          d="M13.1207 0.136719C14.12 0.136719 15.1259 0.136719 16.1251 0.136719C16.9566 0.322064 17.8016 0.461072 18.613 0.712612C28.7526 3.82376 31.4686 15.2887 25.8288 23.0996C24.7625 24.5824 23.3609 25.7077 21.5503 26.1843C20.1085 26.5616 19.2501 27.4685 18.7538 28.7791C18.0765 30.5928 18.4252 32.3139 19.3239 33.9555C20.4438 35.9943 22.1672 37.4638 24.1254 38.662C25.366 39.4166 26.6871 40.0322 27.968 40.714C28.6051 41.0516 28.8465 41.6209 28.6319 42.2431C28.4106 42.8852 27.8741 43.2162 27.1901 43.1235C23.5487 42.6336 20.0146 41.7665 16.6548 40.2837C12.363 38.3906 8.65451 35.7494 5.75078 32.069C2.71293 28.2165 1.04312 23.8344 0.613931 18.9757C0.32557 15.7123 0.566988 12.5085 1.73384 9.42382C3.28295 5.33961 5.95196 2.28804 10.2304 0.838381C11.1626 0.520647 12.1551 0.3684 13.1207 0.136719Z"
          fill="#14A2DC"
        />
        <path
          d="M59.5 41.5017V42.0975C59.0507 42.958 58.655 43.2162 57.9509 43.1235C54.3095 42.6336 50.7754 41.7665 47.4156 40.2837C43.1238 38.3906 39.4153 35.7494 36.5116 32.069C33.4737 28.2165 31.7972 23.8344 31.368 18.9757C31.0797 15.7123 31.3211 12.5085 32.4879 9.42382C34.037 5.33961 36.7061 2.29466 40.9845 0.845001C41.9234 0.520647 42.9159 0.3684 43.8815 0.136719C44.8808 0.136719 45.8867 0.136719 46.8859 0.136719C46.973 0.176435 47.0602 0.236011 47.1474 0.24925C53.0286 1.03697 57.629 5.08807 59.0641 10.7543C59.2519 11.5089 59.3591 12.29 59.5067 13.0579V15.0702C59.4664 15.2225 59.4061 15.3681 59.3927 15.527C59.2451 17.4135 58.769 19.214 57.9308 20.9152C56.6432 23.5299 54.8191 25.5687 51.8282 26.3564C51.3588 26.4822 50.8961 26.8264 50.5407 27.1706C49.6085 28.0642 49.2129 29.2292 49.186 30.4869C49.1391 32.6184 50.0981 34.3659 51.4996 35.9016C53.3572 37.9272 55.6775 39.2974 58.1588 40.436C58.7087 40.6875 59.225 40.9523 59.5134 41.5083L59.5 41.5017Z"
          fill="#14A2DC"
        />
      </g>
      <defs>
        <clipPath id="clip0_10674_3022">
          <rect width="59" height="43" fill="white" transform="translate(0.5 0.136719)" />
        </clipPath>
      </defs>
    </svg>
  );
};
const ApostrophEnd = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 43" fill="none" className="w-[35px] h-[25px] md:w-[60px] md:h-[44px]">
      <g clip-path="url(#clip0_10674_3027)">
        <path
          d="M46.3793 43C45.38 43 44.3741 43 43.3749 43C42.5434 42.8147 41.6984 42.6756 40.887 42.4241C30.7474 39.313 28.0314 27.8481 33.6713 20.0371C34.7375 18.5543 36.1391 17.429 37.9497 16.9524C39.3915 16.5751 40.2499 15.6683 40.7462 14.3576C41.4235 12.5439 41.0748 10.8228 40.1761 9.18119C39.0562 7.14239 37.3328 5.67287 35.3746 4.47475C34.134 3.72013 32.8129 3.10452 31.532 2.42272C30.8949 2.08513 30.6535 1.51585 30.8681 0.893625C31.0894 0.251535 31.6259 -0.0794396 32.3099 0.0132347C35.9513 0.503076 39.4854 1.37023 42.8452 2.85298C47.137 4.74615 50.8455 7.38731 53.7492 11.0677C56.7871 14.9203 58.4569 19.3023 58.8861 24.161C59.1744 27.4244 58.933 30.6282 57.7662 33.7129C56.2171 37.7971 53.548 40.8487 49.2696 42.2983C48.3374 42.6161 47.3449 42.7683 46.3793 43Z"
          fill="#14A2DC"
        />
        <path
          d="M2.26897e-05 1.63501L2.27418e-05 1.03925C0.449329 0.178728 0.84499 -0.0794358 1.54913 0.0132385C5.19052 0.50308 8.72462 1.37023 12.0844 2.85299C16.3762 4.74615 20.0847 7.38732 22.9884 11.0677C26.0263 14.9203 27.7028 19.3023 28.132 24.161C28.4203 27.4244 28.1789 30.6282 27.0121 33.7129C25.463 37.7971 22.7939 40.8421 18.5155 42.2917C17.5766 42.6161 16.5841 42.7683 15.6185 43C14.6192 43 13.6133 43 12.6141 43C12.527 42.9603 12.4398 42.9007 12.3526 42.8875C6.47138 42.0998 1.87101 38.0486 0.435914 32.3824C0.248145 31.6278 0.140851 30.8467 -0.00668222 30.0788L-0.00668204 28.0665C0.0335535 27.9143 0.0939077 27.7686 0.107318 27.6098C0.254852 25.7232 0.730983 23.9227 1.56924 22.2215C2.85681 19.6068 4.68086 17.568 7.67176 16.7803C8.14119 16.6546 8.60391 16.3103 8.95933 15.9661C9.89147 15.0725 10.2871 13.9075 10.314 12.6498C10.3609 10.5183 9.40193 8.77078 8.00036 7.23507C6.14278 5.20952 3.82248 3.83928 1.34124 2.70074C0.791338 2.4492 0.274973 2.18442 -0.0133879 1.62839L2.26897e-05 1.63501Z"
          fill="#14A2DC"
        />
      </g>
      <defs>
        <clipPath id="clip0_10674_3027">
          <rect width="59" height="43" fill="white" transform="translate(59 43) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Aqiqah;