import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { AsYouType } from 'libphonenumber-js';
import * as Yup from 'yup';
import { Formik } from 'formik';
import services from '../../../services';
const FundraisingForm = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const refPhone = useRef(null);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    location: '',
    project: '',
    goal: '',
    behalf_of: '',
    other_details: '',
  });
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // eslint-disable-next-line no-useless-escape
  const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const TeacherInfoSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Doit contenir au moins 3 caractères').required('Entrez le prénom'),
    surname: Yup.string().min(3, 'Doit contenir au moins 3 caractères').required('Entrez le nom de famille'),
    email: Yup.string().matches(emailRegExp, 'Adresse e-mail invalide').required("Entrez l'adresse e-mail"),
    phone: Yup.string()
      .matches(phoneRegExp, 'Numéro de téléphone invalide')
      .required('Entrez le numéro de téléphone')
      .min(10, 'Doit contenir 10 chiffres'),
    location: Yup.string().min(3, 'Doit contenir au moins 3 caractères').required("Entrez l'emplacement"),
    project: Yup.string().min(3, 'Doit contenir au moins 3 caractères').required('Entrez le projet'),
    goal: Yup.string().min(3, 'Doit contenir au moins 3 caractères').required("Entrez l'objectif"),
    behalf_of: Yup.string().min(3, 'Doit contenir au moins 3 caractères').required('Entrez au nom de'),
    other_details: Yup.string().min(3, 'Doit contenir au moins 3 caractères').required("Entrez d'autres détails"),
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const handleSubmit = () => {
    if (isPhoneValid) {
      // setIsloading(true);
      submitForm();
    }
  };
  const submitForm = async () => {
    try {
      let tempUser = userInfo;
      let phoneNumber = '';
      console.log('phone', userInfo.phone);
      if (userInfo.phone.charAt(0) === '0') {
        phoneNumber = `0${userInfo.phone}`;
      } else {
        phoneNumber = `00${userInfo.phone}`;
      }
      tempUser.phone = phoneNumber;
      const response = await services.postFormFundraising(tempUser);
      toast.success("Merci d'avoir complété le formulaire.");
      setIsloading(false);
      setUserInfo({
        ...userInfo,
        name: '',
        surname: '',
        email: '',
        phone: '',
        location: '',
        project: '',
        goal: '',
        behalf_of: '',
        other_details: '',
      });
    } catch (error) {
      setIsloading(false);
      toast.error('Something went wrong. Please try again later.');
    }
  };
  const handlePhoneValidation = (value, country, type) => {
    let temp = new AsYouType(country.countryCode).input(`+${value}`);
    // console.log("phone",value,country,temp, temp.charAt(1),)
    if (temp.split(' ')[1] && Number(temp.split(' ')[1].charAt(0)) === 0) {
      let temp3 = country.dialCode + value.substr(value.indexOf(country.dialCode) + country.dialCode.length + 1, value.length);
      console.log('temp3==', temp3);
      refPhone.current.value = temp3;
      setUserInfo({
        ...userInfo,
        phone: temp3,
      });
    } else {
      setUserInfo({
        ...userInfo,
        phone: value,
      });
    }
  };
  /* -------------------------------------------------------------------------- */
  return (
    <div className="lg:container mx-auto md:pt-6">
      <div className="bg-white rounded-md shadow-md p-4 lg:p-6">
        <Formik
          initialValues={{
            name: userInfo.name,
            surname: userInfo.surname,
            email: userInfo.email,
            phone: userInfo.phone,
            location: userInfo.location,
            project: userInfo.project,
            goal: userInfo.goal,
            behalf_of: userInfo.behalf_of,
            other_details: userInfo.other_details,
          }}
          validationSchema={TeacherInfoSchema}
          onSubmit={values => {
            console.log('values', values);
            handleSubmit(values);
          }}
        >
          {({ errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777]">
                    Prénom<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Entrez le prénom"
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                      onBlur={handleBlur}
                      value={userInfo.name}
                      onChange={e => {
                        handleChange(e);
                        setUserInfo({
                          ...userInfo,
                          name: e.target.value,
                        });
                      }}
                    />
                    {errors.name && touched.name && (
                      <small id="name-error" className="text-[#f60362] text-sm">
                        {errors.name}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777]">
                    Nom<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <input
                      type="text"
                      name="surname"
                      id="surname"
                      placeholder="Entrez le nom"
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                      onBlur={handleBlur}
                      value={userInfo.surname}
                      onChange={e => {
                        handleChange(e);
                        setUserInfo({
                          ...userInfo,
                          surname: e.target.value,
                        });
                      }}
                    />
                    {errors.surname && touched.surname && (
                      <small id="surname-error" className="text-[#f60362] text-sm">
                        {errors.surname}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777] w-full">
                    Numéro de téléphone<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <PhoneInput
                      name="phone"
                      ref={refPhone}
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md even:min-h-[48px]"
                      id="phone"
                      placeholder="Entrez le numéro de téléphone"
                      onBlur={val => {
                        let e = { target: { value: val, name: 'phone', id: 'phone' } };
                        handleBlur(e);
                      }}
                      isValid={(value, country) => {
                        if (value.match(/12345/)) {
                          setIsPhoneValid(false);
                          return 'Valeur invalide : ' + value + ', ' + country.name;
                        } else if (value.match(/1234/)) {
                          return false;
                        } else {
                          setIsPhoneValid(true);
                          return true;
                        }
                      }}
                      country={'au'}
                      inputClass={'!w-full'}
                      value={userInfo.phone || ''}
                      onChange={(val, country) => {
                        let e = { target: { value: val, name: 'phone', id: 'phone' } };
                        handlePhoneValidation(val, country, 'phone');
                        handleChange(e);
                      }}
                    />
                    {errors.phone && touched.phone && (
                      <small id="phone-error" className="text-[#f60362] text-sm">
                        {errors.phone}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777]">
                    Adresse email<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Entrez l'adresse email"
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                      onBlur={handleBlur}
                      value={userInfo.email}
                      onChange={e => {
                        handleChange(e);
                        setUserInfo({
                          ...userInfo,
                          email: e.target.value,
                        });
                      }}
                    />
                    {errors.email && touched.email && (
                      <small id="email-error" className="text-[#f60362] text-sm">
                        {errors.email}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777]">
                    Localisation : Où vous collectez des fonds<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      placeholder="Entrez la localisation"
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                      onBlur={handleBlur}
                      value={userInfo.location}
                      onChange={e => {
                        handleChange(e);
                        setUserInfo({
                          ...userInfo,
                          location: e.target.value,
                        });
                      }}
                    />
                    {errors.location && touched.location && (
                      <small id="location-error" className="text-[#f60362] text-sm">
                        {errors.location}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777]">
                    Projet : Entrez le type de projet, par exemple, Mosquée, puits, général
                    <small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <input
                      type="text"
                      name="project"
                      id="project"
                      placeholder="Entrez le projet"
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                      onBlur={handleBlur}
                      value={userInfo.project}
                      onChange={e => {
                        handleChange(e);
                        setUserInfo({
                          ...userInfo,
                          project: e.target.value,
                        });
                      }}
                    />
                    {errors.project && touched.project && (
                      <small id="project-error" className="text-[#f60362] text-sm">
                        {errors.project}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777]">
                    Objectif : Montant en $/£/€<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <input
                      type="text"
                      name="goal"
                      id="goal"
                      placeholder="Entrez l'objectif"
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                      onBlur={handleBlur}
                      value={userInfo.goal}
                      onChange={e => {
                        handleChange(e);
                        setUserInfo({
                          ...userInfo,
                          goal: e.target.value,
                        });
                      }}
                    />
                    {errors.goal && touched.goal && (
                      <small id="goal-error" className="text-[#f60362] text-sm">
                        {errors.goal}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777]">
                    Au nom de : Entrez pour qui vous collectez des fonds, par exemple un être cher
                    <small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <input
                      type="text"
                      name="behalf_of"
                      id="behalf_of"
                      placeholder="Entrez au nom de"
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                      onBlur={handleBlur}
                      value={userInfo.behalf_of}
                      onChange={e => {
                        handleChange(e);
                        setUserInfo({
                          ...userInfo,
                          behalf_of: e.target.value,
                        });
                      }}
                    />
                    {errors.behalf_of && touched.behalf_of && (
                      <small id="behalf_of-error" className="text-[#f60362] text-sm">
                        {errors.behalf_of}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label className="text-sm md:text-md text-[#777]">
                    Autres détails<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                    <input
                      type="text"
                      name="other_details"
                      id="other_details"
                      placeholder="Entrez d'autres détails"
                      className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                      onBlur={handleBlur}
                      value={userInfo.other_details}
                      onChange={e => {
                        handleChange(e);
                        setUserInfo({
                          ...userInfo,
                          other_details: e.target.value,
                        });
                      }}
                    />
                    {errors.other_details && touched.other_details && (
                      <small id="other_details-error" className="text-[#f60362] text-sm">
                        {errors.other_details}
                      </small>
                    )}
                  </label>
                </div>
              </div>

              <div className="grid grid-cols-6 mt-8 gap-2 md:gap-4">
                <div className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2">
                  <button
                    type="submit"
                    // disabled={isLoading}
                    // className="group active:bg-blue-400 w-full flex items-center justify-center rounded bg-blue-600 text-white px-6 pt-2 pb-2 font-normal text-lg h-[48px] shadow-[0_4px_9px_-4px_#3b71ca]   hover:bg-blue-800 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-800 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]`}
                  >
                    {isLoading ? (
                      <>
                        Traitement...
                        <svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </>
                    ) : (
                      <>
                        <span>Soumettre</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FundraisingForm;
