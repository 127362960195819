import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { projectNoorFaqs } from './faqs';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import ProductBox from '../../components/general/productBox';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import ZakatWidget from '../zakat/zakatWidget';
import { useKeenSlider } from 'keen-slider/react'
import Noor1 from '../../images/sadaqah/noor1.jpg'
import Noor2 from '../../images/sadaqah/noor2.jpg'
import Noor3 from '../../images/sadaqah/noor3.jpg'
import Noor4 from '../../images/sadaqah/noor4.jpg'
import Noor5 from '../../images/sadaqah/noor5.jpg'
import banner from '../../images/sadaqah/Project-Noor-Generic-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/sadaqah/Project-Noor-Generic-Hero-Banner-Mobile.jpg';
import { useNavigate } from 'react-router-dom';
import { getProducts } from '../../store/products';
import { OurPromiseSection } from '../../components/general';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
const images = [
    Noor1,
    Noor2,
    Noor3,
    Noor4,
    Noor5,
];

const ProjectNoorUAT = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => getProducts(state, 'Sadaqah Jariyah', 'health'));
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [masjidProducts, setMasjidProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
            mode: "free-snap",
            loop: true,
            slideChanged(slider) {
                setCurrentSlideImages(slider.track.details.rel)
            },
            created() {
                setLoaded(true)
            },
            slides: {
                origin: "auto",
                perView: 1,
                spacing: 8,

            },
        },
        [
            slider => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 2000);
                }
                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on('dragStarted', clearNextTimeout);
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]

    )
    const { i18n } = useTranslation();
    const lang = i18n.language;
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */

    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'HLT-DIS-ERI-2023-01-062') tempProductsList.push(item);
            if (item.creator === 'HLT-DIS-ERv-2023-01-063') tempProductsList.push(item);
            if (item.creator === 'HLT-DIS-ERX-2023-01-064') tempProductsList.push(item);
        });
        setMasjidProducts(tempProductsList);
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {

        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['sadaqahJariyah']['health']['title']}`}</title>
                    <meta name="title" content={`${seo['sadaqahJariyah']['health']['title']}`} />
                    <meta name="description" content={`${seo['sadaqahJariyah']['health']['description']}`} />
                </Helmet>
            }
            {/* /* --------------------------------- Bannière ---------------------------------  */}

            <main className='max-w-[1440px] mx-auto'>

                <section className='xl:px-10'>
                    <div className='flex justify-center'>
                        <div
                            className='text-center pt-10 font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12'>
                            Project<span className='text-[#F60362] ml-2'>
                                Noor</span>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div
                            className='text-center pt-10 font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12'>
                            Votre<span className='text-[#F60362] ml-2'>
                                Sadaqa Jariya</span>, pourrait changer des vies.
                        </div>
                    </div>
                    <div className='px-4 md:px-6 mt-2 sm:mt-10  font- leading-5'>
                        <div className='text-[#78716C] leading-6 text-[18px] text-left px-4 font-brandingMedium'>
                            Que feriez-vous si vous ne pouviez pas regarder le visage de vos proches ou lire les beaux
                            mots du Coran ? Pourriez-vous vivre dans un monde d'obscurité ?
                            <br/><br/>
                            Imaginez être aveugle uniquement à cause de vos circonstances. Une vie vécue dans la
                            pauvreté pour ceux qui souffrent de cécité évitable signifie une vie vécue dans l'obscurité.
                            C’est impensable, pourtant des millions de personnes dans le monde en souffrent.
                            <br/><br/>
                            9 personnes sur 10 atteintes de déficience visuelle souffrent de perte de vue qui pourrait
                            être traitée et prévenue. Dans les pays en développement, le coût humain de cette situation
                            impacte tout, de l'accès à l'éducation aux moyens de subsistance, et bien plus encore. La
                            plupart de ceux qui souffrent n'ont pas accès aux besoins les plus élémentaires de la vie,
                            encore moins à des soins de santé et des soins oculaires de qualité.
                            <br/><br/>
                            <span className='text-[#F60362] font-bold'>C'est une énorme injustice. Une injustice que nous pouvons travailler ensemble pour changer.</span>
                            <br/><br/>
                            <span className='text-[#F60362] font-bold'>Sauvez la vue avec votre Sadaqa.</span>
                        </div>


                        <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 mb-6 sm:mb-10">
                            {masjidProducts && masjidProducts.map((item, index) => {
                                return (
                                    <div key={`makedonation${index}`}
                                         className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                        <ProductBox product={item} isStaticProduct={item.isStaticProduct || false}
                                                    currency={selectedCurrency ? selectedCurrency : 'AUD'}/>
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </section>


                <section className='xl:px-20  py-8 bg-[#F1F1F1]'>

                    <div>
                        <div className='flex justify-center pt-4'>
                            <div
                                className='text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12'>
                                MATW & Project Noor
                            </div>
                        </div>
                        <div className='px-4 md:px-28 text-[18px] mt-8 leading-5'>
                            <p className='text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] '>
                                Notre équipe "Muslims Around The World" travaille dur depuis des années au Bangladesh,
                                en Indonésie et au Pakistan pour restaurer la vue des personnes vulnérables vivant dans
                                des communautés défavorisées.
                                <br/>
                                <br/>
                                Nous nous concentrons sur la mise en place de camps médicaux de fortune dans des
                                communautés éloignées pour effectuer des contrôles de santé oculaire, des traitements et
                                des chirurgies de la cataracte.
                                <br/>
                                <br/>
                                À ce jour, MATW a réalisé 6 157 chirurgies de la cataracte, restaurant la vue grâce à la
                                générosité et à la Sadaqa de nos donateurs.
                            </p>
                            <p className="basis-full text-lg md:text-xl text-[#F60362] my-2 md:my-4">
                                Offrez cette belle Sadaqa Jariya aujourd'hui
                            </p>
                        </div>

                    </div>


                </section>


                {/* /* --------------------------------- Images --------------------------------- */}
                <section className='bg-[#F8F8F8]  py-10'>
                    <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">

                        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {

                                    return <div key={`carousel_images${index}`}
                                                className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                        {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                        <img src={item} alt={`carousel_images${index}`}
                                             className=' bg-top object-cover w-full object-top'/>
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="w-full  flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 pb-6">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {/* /* -------------------------------------------------------------------------- */}

                {/* -------------------------------------FAQS--------------------------------- */}
                <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h1 className='text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                        Project Noor donations <span className='text-[#F60362]'> FAQ’s</span>
                    </h1>

                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {projectNoorFaqs.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default ProjectNoorUAT;
