import ReactSelect from "react-select";
import eurImg from "../../images/flags/eu.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCurrency, setSelectedCurrencyInStore } from "../../store/user";

const styles = {
    menuList: (base) => ({
        ...base,

        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        },
        "& *": {
            cursor: "pointer"
        }
    })
}

const SelectCurrency = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const dispatch = useDispatch();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const { onChangeCurrency, hideFlag, classNamePrefix, className, onMenuOpen, onMenuClose } = props;
    const currencies = [
        { value: 'EUR', label: <span className='flex items-center'><img className={`mr-1 rounded-sm w-[25px] h-[16px] ${hideFlag ? 'hidden' : 'inline-block'}`} src={eurImg} width="25px" alt='eur' />EUR</span> },
    ];
    const [currentCurrency, setCurrentCurrency] = useState(currencies[0]);
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSelectCurrency = (currency) => {
        setCurrentCurrency(currency);
        onChangeCurrency(currency);
        dispatch(setSelectedCurrencyInStore(currency.value));
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        let temp = {};
        currencies.map((item) => {
            if (item.value === selectedCurrencyStore) {
                temp = item;
            }
            return null
        })
        temp.value && setCurrentCurrency(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="notranslate">
            <ReactSelect
                options={currencies}
                className={`max-h-[48px] [&>*]:!cursor-pointer w-full [&>div]:!rounded-md ${className}`}
                classNamePrefix={classNamePrefix ? classNamePrefix : ''}
                value={currentCurrency}
                isSearchable={false}
                isDisabled={true}
                styles={styles}
                onChange={(e) => handleSelectCurrency(e)}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
            />
        </div>
    );
}

export default SelectCurrency;