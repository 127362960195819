import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import VideosSection from '../../components/general/videosSection';

import AiaLogo from "../../images/landings/palestine/aia-logo.png"
import { OurPromiseSection } from '../../components/general';
import { videos } from './fixtures';
import DonateWidget from './components/donateWidget';
import DonateSection from './components/common-sections/donate';
import MuslimsAroundSection from './components/common-sections/muslimsAround';
import ImagesSection from './components/common-sections/images';
import FAQSection from './components/common-sections/faq';


const donateProducts = ["EMR-PA-AIA-24-01", "EMR-PA-AIA-24-02", "EMR-PA-AIA-24-03"];

const PalestineAiaPartnershipLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  // }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white pt-5-" childClasses="pb-[98px] md:pb-0" isRamadanPage hideNavbar hideHighlights hideFooter hideMainFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['palestine-aia-partnership']['title']}`}</title>
          <meta name="title" content={`${seo['palestine-aia-partnership']['title']}`} />
          <meta name="description" content={`${seo['palestine-aia-partnership']['description']}`} />
          {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
        </Helmet>
      )}

      <section className='flex justify-center items-center py-4 px-4 md:px-0 mb-12 bg-[url("../src/images/landings/palestine/palestine-partenship-banner.png")] bg-cover'>
        <div className="w-full md:container md:mx-auto flex items-center justify-center">
          <img src={AiaLogo} alt="Australian International Academy Logo" className="max-w-[180px] mr-2 md:mr-4" />
          <h1 className="text-2xl md:text-4xl text-white font-medium">
            AIAE Palestine Appeal
          </h1>
        </div>
      </section>

      <section className='max-w-[1280px] mx-auto relative'>
        {/* /* --------------------------------- Banner --------------------------------- */}
        <iframe
          className={`video aspect-[16/9]`}
          width={'100%'}
          title={'youtube'}
          frameBorder={0}
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={"https://www.youtube.com/embed/MngSeRmAjJo?controls=1&autoplay=0&playlist=MngSeRmAjJo"}
        ></iframe>
        {/* /* -------------------------------------------------------------------------- */}
        {/* /* --------------------------------- Widget --------------------------------- */}
        <div className='lg:absolute right-[100px] top-[50%] lg:-translate-y-[50%]'>
          <DonateWidget
            hasSuggestPrice
            useDonateProducts
            donateProducts={donateProducts}
            defaultProduct={{
              creator: 'EMR-PA-AIA-24-01',
              label: 'Lower Middle School',
              value: 382,
            }}
          />
        </div>
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* -------------------------------- Our Promise Section -------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <DonateSection />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Muslim Arounds ------------------------- */}
      <MuslimsAroundSection />
      {/* /* -------------------------------------------------------------------------- */}

      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* ----------------- videos section ---------------- */}
        <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
        {/* ----------------- videos section --END-------------- */}


        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection />
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* -------------------------------- FAQ's -------------------------------- */}
      <FAQSection />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Footer --------------------------------- */}
      <div className="bg-[#282828] py-6 px-4 lg:px-12 w-full mt-20">
        <p className="text-center text-[#aaa] text-[15px] copy-right">
          Muslim Around The World (MATW) Project
        </p>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default PalestineAiaPartnershipLandingPage;