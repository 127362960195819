import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { useRef } from 'react';
import SelectCurrency from '../../components/general/selectCurrency';
import ShowCurrencies from '../../utils/showCurrencies';
import { CustomButton, OurPromiseSection } from '../../components/general';
// import banner from '../../images/landings/zakat-calculator/zakat-calculator-hero-banner.jpg';
// import bannerMobile from '../../images/landings/zakat-calculator/zakat-calculator-hero-banner-mobile.jpg';

const ZakatCalculatorUATLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const refAssets = useRef(null);
    const refLiabilities = useRef(null);
    const [assets, setAssets] = useState(0);
    const [liabilities, setLiabilities] = useState(0);
    const [zakat, setZakat] = useState(0);
    const [selectedNisab, setSelectedNisab] = useState('Silver');
    const [goldNisabAmount, setGoldNisabAmount] = useState(0);
    const [silverNisabAmount, setSilverNisabAmount] = useState(0);
    const products = useSelector((state) => selectProducts(state));
    const [addProduct] = useHandleAddProduct();
    const [zakatAlMalProduct, setZakatAlMalProduct] = useState();
    const goldNisabGrams = 87.48;
    // const silverNisabGrams = 612.36;
    const silverNisabGrams = 595;
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSelectNisab = (value) => {
        console.log("radio", value)
        setSelectedNisab(value);
        handleCalculateZakat(assets, liabilities, value)
    }
    const handleCurrency = (currency) => {
        setSelectedCurrency(currency.value);
        // selectedNisab === 'Gold' ? handleGoldNisabAmount(currency.value) : handleSilverNisabAmount(currency.value)
        handleGoldNisabAmount(currency.value);
        handleSilverNisabAmount(currency.value);
    }
    const handleAssets = (value) => {
        refAssets.current.value = '';
        if (parseInt(value) >= 0) {
            setAssets(parseInt(value));
            handleCalculateZakat(parseInt(value), liabilities)
        } else {
            setAssets(0);
            setZakat(0)
        }
    }
    const handleLiabilities = (value) => {
        refLiabilities.current.value = '';
        if (parseInt(value) >= 0) {
            setLiabilities(parseInt(value));
            handleCalculateZakat(assets, parseInt(value))
        } else {
            handleCalculateZakat(assets, 0)
            setLiabilities(0);
        }
    }
    const handleCalculateZakat = (assets, liabilities, nisab = selectedNisab) => {
        let zakatableWealth = (parseInt(assets) - parseInt(liabilities));
        console.log("handle-CalculateZakat", parseInt(assets), parseInt(liabilities), silverNisabAmount)
        if (nisab === 'Gold') {
            // zakatableWealth = (parseInt(assets) - parseInt(liabilities));
            // zakatableWealth > goldNisabAmount ? setZakat(Math.round((zakatableWealth - parseInt(goldNisabAmount)) * 0.025)) : setZakat(0)
            zakatableWealth > goldNisabAmount ? setZakat(Math.round(zakatableWealth * 0.025)) : setZakat(0)
        } else {
            // zakatableWealth > silverNisabAmount ? setZakat(Math.round((zakatableWealth - parseInt(silverNisabAmount)) * 0.025)) : setZakat(0)
            zakatableWealth > silverNisabAmount ? setZakat(Math.round(zakatableWealth * 0.025)) : setZakat(0)
        }
    }
    const handleKeyDown = (value) => {
        let checkIfNum;
        if (value.key !== undefined) {
            checkIfNum = value.key === ".";
        }
        return checkIfNum && value.preventDefault();
    }
    const handleGoldNisabAmount = (currency) => {
        switch (currency) {
            case 'USD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_USD) * Number(goldNisabGrams))
                break;
            case 'AUD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_AUD) * Number(goldNisabGrams))
                break;
            case 'CAD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_CAD) * Number(goldNisabGrams))
                break;
            case 'GBP':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_GBP) * Number(goldNisabGrams))
                break;
            case 'EUR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_EUR) * Number(goldNisabGrams))
                break;
            case 'SGD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_SGD) * Number(goldNisabGrams))
                break;
            case 'MYR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_MYR) * Number(goldNisabGrams))
                break;
            case 'AED':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_AED) * Number(goldNisabGrams))
                break;
            case 'IDR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_IDR) * Number(goldNisabGrams))
                break;
            default:
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_USD) * Number(goldNisabGrams))
                break;
        }
    }
    const handleSilverNisabAmount = (currency) => {
        console.log("handle-SilverNisabAmount=", currency)
        switch (currency) {
            case 'USD':
                console.log("silver nissab usd=", Number(process.env.REACT_APP_SILVER_NISAB_USD), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams));
                handleCalculateZakat(assets, liabilities)
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams))
                break;
            case 'AUD':
                console.log("silver nissab aud=", Number(process.env.REACT_APP_SILVER_NISAB_AUD), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_AUD) * Number(silverNisabGrams))
                handleCalculateZakat(assets, liabilities)
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_AUD) * Number(silverNisabGrams))
                break;
            case 'CAD':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_CAD) * Number(silverNisabGrams))
                break;
            case 'GBP':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_GBP) * Number(silverNisabGrams))
                break;
            case 'EUR':
                console.log("silver nissab eur=", Number(process.env.REACT_APP_SILVER_NISAB_EUR), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_EUR) * Number(silverNisabGrams))
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_EUR) * Number(silverNisabGrams))
                break;
            case 'SGD':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_SGD) * Number(silverNisabGrams))
                break;
            case 'MYR':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_MYR) * Number(silverNisabGrams))
                break;
            case 'AED':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_AED) * Number(silverNisabGrams))
                break;
            case 'IDR':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_IDR) * Number(silverNisabGrams))
                break;
            default:
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams))
                break;
        }
    }
    const getAllProducts = () => {
        setIsLoaded(true);
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'RM24-ZM-013') setZakatAlMalProduct(inner_item_product); // Zakat Al Mal
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
    };
    const handleAddNewProduct = (creator, currency, zakat) => {
        let tempProduct = zakatAlMalProduct;
        let customAmount = Number(zakat) ? Number(zakat) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
        })
        addProduct({ product: tempProduct, currency: currency })
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' });
        // window.scroll(0, 0);
        handleGoldNisabAmount(selectedCurrency)
        handleSilverNisabAmount(selectedCurrency)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    useEffect(() => {
        handleCalculateZakat(assets, liabilities)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goldNisabAmount, silverNisabAmount])
    useEffect(() => {
        console.log("=========================temp in zakat calculator", selectedCurrencyStore)
        // selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
        if (selectedCurrencyStore) {
            handleGoldNisabAmount(selectedCurrencyStore);
            handleSilverNisabAmount(selectedCurrencyStore);
            setSelectedCurrency(selectedCurrencyStore);
        } else {
            handleGoldNisabAmount('AUD');
            handleSilverNisabAmount('AUD');
            setSelectedCurrency('AUD')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['zakat']['zakatCalculator']['title']}`}</title>
                    <meta name="title" content={`${seo['zakat']['zakatCalculator']['title']}`} />
                    <meta name="description" content={`${seo['zakat']['zakatCalculator']['description']}`} />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "HowTo",
                            "name": "How to Calculate Zakat",
                            "description": "This guide outlines the steps to calculate Zakat, ensuring you fulfill your Islamic financial obligations.",
                            "step": [
                                {
                                    "@type": "HowToStep",
                                    "name": "Determine Nisab",
                                    "text": "Calculate the current value of Nisab in your local currency to determine if you are eligible to pay Zakat."
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Calculate Assets",
                                    "text": "Total your assets that are subject to Zakat, including cash, gold, silver, and business commodities."
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Deduct Liabilities",
                                    "text": "Subtract any debts or liabilities from your total assets to find your net assets."
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Apply Zakat Rate",
                                    "text": "If your net assets exceed the Nisab, apply the Zakat rate of 2.5% to calculate the amount of Zakat due."
                                }
                            ],
                            "estimatedCost": {
                                "@type": "MonetaryAmount",
                                "value": "0",
                                "description": "Free"
                            },
                            "tool": {
                                "@type": "HowToTool",
                                "name": "Zakat Calculator 2024",
                                "url": "https://matwproject.org/zakat-calculator"
                            }
                        })}
                    </script>
                </Helmet>
            }

            {/* /* --------------------------------- Calculator ---------------------------------  */}
            <div className="w-full py-6 md:py-12 px-4 md:px-0 bg-[url('../src/images/ali-banat/pattern.png')]">
                <div className="w-full grid grid-cols-6 gap-4 md:gap-8 justify-center items-center md:container md:mx-auto bg-white rounded-md p-4 md:p-8 lg:p-16 xl:p-24">
                    <h1 className="col-span-6 md:col-span-4 md:col-start-2 text-[#253B7E] font-medium text-xl md:text-[25px] text-center mb-4 md:mb-6">
                        Calculateur de Zakat 2024 : Assurez-vous que votre contribution correspond au nisab avec notre calculateur de zakat al mal.
                    </h1>
                    <div className="col-span-6 flex justify-center mb-4 md:mb-8">
                        <div className="w-full md:w-[300px]">
                            <SelectCurrency onChangeCurrency={(e) => handleCurrency(e)} />
                        </div>
                    </div>
                    <div className="col-span-6 min-[400px]:col-span-3 py-2 rounded-md">
                        <div className={`w-full hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${selectedNisab === 'Silver' ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`}
                            onClick={() => handleSelectNisab('Silver')}>
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col items-center justify-center">
                                    <p className={`mb-0 text-lg sm:text-2xl mt-3 transition-all duration-100 ${selectedNisab === 'Silver' ? 'text-white font-medium' : 'text-[#00a3da] font-normal'}`}>NISAB en Argent</p>
                                    <p className={`${selectedNisab === 'Silver' ? 'text-white' : 'text-[#78716C]'} font-light transition-all duration-200 text-sm sm:text-base`}>
                                        Argent ({silverNisabGrams} grammes) ≅ {ShowCurrencies(selectedCurrency, silverNisabAmount.toFixed(2))}
                                    </p>
                                </div>
                            </div>
                            <div className=''>
                                <svg className={`transition-all duration-200 text-[#00a3da] ${selectedNisab === 'Silver' ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                                    <circle cx="24" cy="24" r="24" />
                                    <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 min-[400px]:col-span-3 py-2 rounded-md">
                        <div className={` w-full hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${selectedNisab === 'Gold' ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`}
                            onClick={() => handleSelectNisab('Gold')}>
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col items-center justify-center">
                                    <p className={`mb-0 text-lg sm:text-2xl mt-3 transition-all duration-100 ${selectedNisab === 'Gold' ? 'text-white font-medium' : 'text-[#00a3da] font-normal'}`}>NISAB en Or</p>
                                    <p className={`${selectedNisab === 'Gold' ? 'text-white' : 'text-[#78716C]'} text-center text-sm sm:text-base font-light transition-all duration-200`}>
                                        Or (87.48 grammes) ≅ {ShowCurrencies(selectedCurrency, goldNisabAmount.toFixed(2))}
                                        <br />
                                        <span className="text-sm">(Le Nisaab est applicable selon 21 carats.)</span>
                                    </p>
                                </div>
                            </div>
                            <div className=''>
                                <svg className={`transition-all duration-200 text-[#00a3da] ${selectedNisab === 'Gold' ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                                    <circle cx="24" cy="24" r="24" />
                                    <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 flex flex-wrap mt-4">
                        <div className="basis-full flex flex-col mb-4 md:mb-6">
                            <label className='text-xl md:text-[25px] font-medium text-[#253B7E] w-full'>Actifs</label>
                            <small className='w-full text-[#78716C] text-lg md:text-[18px] mb-1'>Incluez l'or, l'argent, les liquidités, les économies, les actifs commerciaux, etc.</small>
                            <input
                                ref={refAssets}
                                type="number"
                                value={assets}
                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                onChange={(e) => handleAssets(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                        </div>
                        <div className="basis-full flex flex-col">
                            <label className='text-xl md:text-[25px] font-medium text-[#253B7E] w-full'>Passifs</label>
                            <small className='w-full text-[#78716C] text-lg md:text-[18px] mb-1'>Incluez l'argent que vous devez, ainsi que d'autres dépenses dues.</small>
                            <div className='min-h-[40px] md:min-h-[48px] w-full rounded border border-stone-300 py-[0.32rem] px-3 flex'>
                                <input
                                    ref={refLiabilities}
                                    type="number"
                                    value={liabilities}
                                    className='text-base md:text-md ml-[10px] bg-transparent outline-none transition-all duration-200 ease-linear w-full'
                                    onChange={(e) => handleLiabilities(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 flex flex-wrap mt-4 h-full">
                        <div className="basis-full flex flex-col justify-between items-center rounded-md bg-stone-100 p-6">
                            <div className="flex flex-col items-center pt-4">
                                <h2 className="text-[#00a3da] font-medium text-2xl md:text-[30px]">
                                    {ShowCurrencies(selectedCurrency, zakat)}
                                </h2>
                                {/* <small className='text-[#78716C] text-lg md:text-2xl'>{selectedNisab} Nisab</small> */}
                                <small className='text-[#78716C] text-lg md:text-[25px]'>Zakat Dû</small>
                            </div>
                            <CustomButton disabled={!(zakat > 0)} title='Payer la Zakat Maintenant' onClicked={() => handleAddNewProduct('RM24-ZM-013', selectedCurrency, zakat)} className='mt-4 md:mt-8 uppercase' />
                        </div>
                    </div>
                </div>
            </div>
            <OurPromiseSection alt="Zakat Calculator 2024: Gold zakat calculator" />
            <div className="w-full bg-white p-4">
                <div className='w-full md:container md:mx-auto bg-white'>
                    <section className='xl:px-10 mt-8 md:mt-16'>
                        <h2 className="w-full text-center text-[25px] md:text-[40px] text-[#253b7e] font-medium mb-4 md:mb-8">
                            Calculez la zakat pour <span className="text-[#F60362]">2024</span> avec notre calculateur de zakat facile à utiliser
                        </h2>
                        <h2 className="text-[#14A2DC] text-xl md:text-[25px] text-center mb-3 md:mb-6 font-medium">
                            Comment calculer la Zakat : Utilisez notre calculateur de Zakat pour voir combien vous devez cette année.
                        </h2>
                        <p className="text-[#78716C]">
                            Pour utiliser notre calculateur de Zakat, entrez tous les actifs que vous avez possédés au cours d'une année lunaire dans le calculateur de Zakat.
                            Cela vous donnera ensuite le montant total de la Zakat que vous devez.
                            <br />
                            <br />
                            Décomposer vos actifs en différentes catégories facilite le processus de calcul de la Zakat. Le processus présenté sur le calculateur est divisé par actifs soumis à la Zakat, qui incluent l'or, l'argent,
                            les liquidités, les économies, les actifs commerciaux, etc., et les passifs déductibles, l'argent que vous devez, d'autres dépenses dues, afin que vous puissiez calculer facilement la Zakat que
                            vous devez. Le montant de la Zakat qui apparaît sera ce que vous devez payer.
                            <br />
                            <br />
                            La Zakat, l'un des Cinq Piliers de l'Islam, est une forme d'aumône considérée dans l'Islam comme une obligation religieuse ou un impôt, qui, selon le classement coranique, vient juste après la prière en importance.
                            En tant qu'obligation religieuse, elle est profondément ancrée dans la foi islamique, et son calcul est une pratique essentielle pour les musulmans du monde entier. Avec l'avènement de la technologie, le processus a été simplifié grâce à l'utilisation d'un calculateur de Zakat,
                            facilitant ainsi la tâche des musulmans pour remplir ce devoir crucial.
                        </p>
                        <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">
                            Apprenez à calculer la zakat, nous le rendons simple
                        </h2>
                        <p className="text-[#78716C]">
                            Calculer la Zakat implique plusieurs étapes et considérations pour s'assurer que l'on remplit cette obligation religieuse avec précision.
                            Le processus commence par l'évaluation de tous les actifs soumis à la Zakat, qui incluent les liquidités, l'or, l'argent, l'inventaire commercial et d'autres
                            actifs générateurs de revenus. Le calculateur de Zakat 2024 simplifie cette tâche en fournissant une interface conviviale où les individus
                            peuvent saisir la valeur de leurs actifs, et le calculateur calculera automatiquement la Zakat due.
                            <br />
                            <br />
                            Pour calculer la Zakat, il faut d'abord déterminer la valeur du Nisab, qui est le montant minimum de richesse qu'un musulman doit posséder avant d'être
                            tenu de payer la Zakat. Cette valeur est généralement basée sur le prix actuel de l'or ou de l'argent. Une fois le Nisab déterminé, les musulmans
                            qui possèdent une richesse supérieure à ce seuil doivent payer 2,5 % de leurs actifs qualifiants totaux en tant que Zakat.
                        </p>
                        <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">
                            Calculateur de zakat pour l'or : Utilisez notre calculateur de zakat sur l'or pour voir ce que vous devez
                        </h2>
                        <p className="text-[#78716C]">
                            L'or est un actif significatif dans le calcul de la Zakat en raison de sa valeur intrinsèque et de sa possession courante parmi les musulmans. Le calculateur de Zakat
                            sur l'or simplifie le calcul en tenant compte des prix actuels de l'or et du poids de l'or détenu. Que vous ayez de l'or sous forme de bijoux, de pièces ou de lingots, le calculateur de Zakat sur l'or vous permet de saisir le poids total en grammes ou en tolas et calcule
                            la Zakat due en fonction de la valeur actuelle du Nisab en or.
                        </p>
                        <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">
                            Calculateur de zakat sur l'argent : Utilisez notre calculateur de zakat sur l'argent pour voir ce que vous devez
                        </h2>
                        <p className="text-[#78716C]">
                            La Zakat sur les liquidités implique d'évaluer tous les actifs liquides, y compris l'argent sur les comptes bancaires, les économies et même l'argent liquide en main. Le calculateur de Zakat au Royaume-Uni
                            fournit une plateforme facile à utiliser pour les résidents du Royaume-Uni, garantissant qu'ils peuvent calculer leur Zakat sur les liquidités avec précision,
                            en tenant compte des considérations spécifiques à la région.
                            <br />
                            <br />
                            Pour calculer la Zakat sur les liquidités, il faut additionner tous les actifs liquides et appliquer le taux de 2,5 %. Cela inclut les soldes des comptes chèques et d'épargne,
                            l'argent à la maison et tout autre actif liquide. Il est crucial de s'assurer que le montant total atteint ou dépasse le seuil de Nisab pour que la Zakat
                            soit obligatoire.
                        </p>
                    </section>
                </div>
            </div>


        </Layout>
    )
}

export default ZakatCalculatorUATLandingPage;
