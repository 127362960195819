import React from 'react';
import Layout from '../../components/general/layout';
import FormSection from './FormSection';
import InfoSection from './infoSection';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { TbBrandTiktok, TbBrandInstagram, TbBrandFacebook } from "react-icons/tb";

export default function Contact() {
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  return (
    <Layout>
      {seo && (
        <Helmet>
          <title>{`${seo['aboutUs']['contact']['title']}`}</title>
          <meta name="title" content={`${seo['aboutUs']['contact']['title']}`} />
          <meta name="description" content={`${seo['aboutUs']['contact']['description']}`} />
        </Helmet>
      )}
      <div className="w-full bg-white p-4">
        <div className="mt-8">
          <figure className="flex justify-center">
            <img
              width="439"
              height="74"
              src="/images/banners/MATW-Home-Banner-TheMATWImpact-Heading.png"
              className="vc_single_image-img attachment-full"
              alt=""
              decoding="async"
              loading="lazy"
              title="MATW - Home - Banner - TheMATWImpact - Heading"
              srcSet="/images/banners/MATW-Home-Banner-TheMATWImpact-Heading.png"
              sizes="(max-width: 439px) 100vw, 439px"
            />
          </figure>
          <div className="flex justify-center">
            <h2
              style={{
                fontSize: '1.75rem',
              }}
              className="text-3xl text-primary bg-pr text-center"
            >
              Nous serions ravis d'avoir de vos nouvelles.
              <br />
            </h2>
          </div>
          {/* <div className="w-full py-11 px-2">
            {window.location.hostname === 'matwprojectusa.org' ? (
              <>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.3339025258474!2d-77.07481792425577!3d38.80190187174455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b1a42292a1cd%3A0xc65ab56f118be74a!2s2461%20Eisenhower%20Ave%202nd%20Floor%2C%20%23035%2C%20Alexandria%2C%20VA%2022331%2C%20USA!5e0!3m2!1sen!2saz!4v1721220241743!5m2!1sen!2saz"
                  width="100%"
                  height="400"
                  title=" "
                  allowFullScreen=""
                  style={{
                    border: 'none',
                  }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </>
            ) : (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d105953.35344765664!2d151.037564!3d-33.914311!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bc0e4d1410ed%3A0xbce1604f19c23f2!2sLevel%202%2F47%20Rickard%20Rd%2C%20Bankstown%20NSW%202200%2C%20Australia!5e0!3m2!1sen!2sus!4v1680419870535!5m2!1sen!2sus"
                width="100%"
                height="400"
                title=" "
                allowFullScreen=""
                style={{
                  border: 'none',
                }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}
          </div> */}

          <div className="w-full md:container md:mx-auto flex mt-8 flex-wrap justify-center flex-col items-center">
            <div className="w-full flex justify-center items-center flex-col">
              <div className="my-5 flex justify-center items-center">

                <div className="mt-8	w-full flex justify-center">
                  <div className="flex ">
                    <a href="https://www.instagram.com/matw_projectfrance/profilecard/?igsh=MTF5bThsdmcweHRv" target="_blank" title="Instagram"
                       rel="noreferrer">
                      <img src="/images/social-media/instagram.png" alt="instagram" className=" w-10 h-10   mr-4"/>
                    </a>

                    <a href="https://www.tiktok.com/@matw_projectfrance?_t=8rO16p1G4WW&_r=1" target="_blank" title="Twitter" rel="noreferrer">
                      <img src="/images/social-media/tiktok.png" alt="twitter" className=" w-10 h-10 mr-4"/>
                    </a>


                    <a href="https://www.youtube.com/@matw_projectfrance" target="_blank" title="YouTube"
                       rel="noreferrer">
                      <img src="/images/social-media/youtube.png" alt="youtube" className=" w-10 h-10 mr-4"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center mt-3 lg:mt-4">
              <FormSection/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
