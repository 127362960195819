import React, { useEffect } from 'react';
import Layout from '../../components/general/layout';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
import ToDoBox from './components/toDoBox';
import OtherBox from './components/otherBox';
import { AttendIcon, ShareIcon } from '../../components/general/customIcons';
import FundraisingForm from './components/fundraising-form';
import banner from '../../images/landings/fundraising/fundraising-banner.jpg';
import bannerMobile from '../../images/landings/fundraising/fundraising-banner-mobile.jpg';
import SocialBox from './components/socialBox';

const FundraisingLandingPageUAT = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout>
      {seo && (
        <Helmet>
          <title>{`${seo['aboutUs']['fundraising']['title']}`}</title>
          <meta name="title" content={`${seo['aboutUs']['fundraising']['title']}`} />
          <meta name="description" content={`${seo['aboutUs']['fundraising']['description']}`} />
        </Helmet>
      )}
      {/* --------------------------------- Banner --------------------------------- */}
      <section className="flex justify-center">
        <img src={banner} alt="banner" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="banner" className="min-[501px]:hidden w-full" />
      </section>
      <div className="lg:container mx-auto px-6 lg:px-0 pt-6 relative">
        <div className="w-full -mt-24 bg-white rounded-xl shadow-md p-4 lg:p-12">
          <h1 className="w-full text-center text-4xl sm:text-5xl md:text-6xl text-[#253b7e] py-2 md:pt-8 font-['Gotcha'] mt-6">
            Collectez des Fonds pour MATW
          </h1>
          <div className="flex justify-center text-[#14A2DC] mt-4">
            <span className="text-2xl sm:text-6xl">“</span>
            <div className="text-center italic">
              <h2 className="text-xl sm:text-4xl font-medium-">
                Abu Mas'ud a rapporté : Le Prophète, que la paix et les bénédictions soient sur lui, a dit : "Celui qui guide quelqu'un vers le bien
                obtiendra une récompense égale à celui qui l'accomplit."
              </h2>
              <h4 className="text-xl sm:text-2xl font-light mt-2">Source : Sahih Muslim 1893</h4>
            </div>
            <span className="text-2xl sm:text-6xl rotate-180">“</span>
          </div>
          <p className="text-[#78716C] mt-4 md:mt-8">
            Quand Ali Banat a fondé MATW en 2016, son objectif était de créer un héritage qui appartienne à tous les musulmans dans le monde. La
            Sadaqah Jariyah ne signifie pas toujours des dons monétaires. Il existe de nombreuses façons de contribuer, avec de nombreux outils pour
            aider à construire votre akhirah et briser le cycle de la pauvreté. Le plus puissant de ces outils est celui que vous avez maintenant dans
            votre main.
            <br />
            <br />
            C’est bien ça, VOUS, notre incroyable famille MATW, pouvez faire la différence en utilisant votre téléphone, votre tablette et votre
            réseau en quelques clics et en quelques minutes seulement.
            <br />
            <br />
            Que ce soit une collecte de fonds en ligne, une réunion de famille ou une vente de gâteaux au travail ou à l'école, il existe tant de
            moyens incroyables pour collecter des fonds et aider ceux qui en ont besoin !
          </p>
          {/* -------------------------- Boîte des tâches ------------------------------------ */}
          <div className="flex flex-wrap py-2 md:pt-8 mt-6 md:mt-12">
            <h1 className="w-full text-center text-4xl sm:text-5xl md:text-6xl text-[#253b7e] font-medium">Tout Ce Que Vous Devez Faire Est…</h1>
            <div className="w-full grid grid-cols-12 gap-2 md:gap-4 mt-12 md:mt-24">
              <ToDoBox
                number="1"
                title="Choisissez une plateforme et lancez une campagne !"
                description="Que vous ayez décidé de participer à une activité (ou de créer la vôtre), choisissez votre plateforme préférée, téléchargez une photo, dites à vos amis et à votre famille ce qui vous a inspiré, et lancez-vous !"
              />
              <ToDoBox
                number="2"
                title="Diffusez le message !"
                description="Encore une fois, l'outil le plus puissant est où ? C'est bien ça, dans votre main ! Envoyez un message, passez des appels, écrivez des e-mails et publiez sur les réseaux sociaux - parlez à autant de gens que possible de votre engagement à faire la différence !"
              />
              <ToDoBox
                number="3"
                isLast
                title="Voyez VOTRE impact."
                description="Une fois votre campagne terminée, nous nous assurerons que les fonds sont dirigés vers là où ils sont nécessaires et nous vous tiendrons informé de l'avancement du projet pour que vous puissiez montrer à vos amis et à votre famille l'impact que vous avez créé ensemble !"
              />
            </div>
          </div>
          {/* -------------------------- Qu'attendez-vous ? ------------------------- */}
          <div className="flex flex-wrap py-2 md:pt-8 mt-6 md:mt-12">
            <h1 className="w-full text-center text-3xl sm:text-4xl md:text-5xl text-[#253b7e] font-medium">
              Qu'attendez-vous ?
              <br />
              Prêt à Changer le Monde avec Nous ?
            </h1>
            <div className={`shadow-3xl w-full rounded-md shadow-cyan-500/50 transition-all duration-200 ease-in-out h-auto py-4 mb-6 `}>
              <h1 className="w-full mt-[50px] text-center text-4xl sm:text-5xl md:text-6xl text-[#F60362] font-medium font-['Gotcha']">
                Inscrivez-vous pour Collecter des Fonds ci-dessous
              </h1>
              <FundraisingForm />
            </div>
          </div>
          {/* -------------------------- Autres Moyens d'Aider ------------------------- */}
          <div className="flex flex-wrap py-2 md:pt-8 mt-6 md:mt-12">
            <h1 className="w-full text-center text-4xl sm:text-5xl md:text-6xl text-[#253b7e] font-medium font-['Gotcha']">Autres Moyens d'Aider</h1>
            <div className="w-full grid grid-cols-12 gap-4 md:gap-6 mt-6 md:mt-12">
              <SocialBox
                svg={<ShareIcon />}
                link="#"
                title="Partager sur les réseaux sociaux"
                description="Augmentez votre impact en partageant le message !"
              />
              <OtherBox
                svg={<AttendIcon />}
                link="https://events.matwcheckout.org/"
                title="Participez à nos événements"
                description="Connectez-vous avec des musulmans partageant les mêmes idées et inspirez-vous"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FundraisingLandingPageUAT;
