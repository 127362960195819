import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { useKeenSlider } from 'keen-slider/react';
import CrisisWidget from './components/crisisWidget';
import VideosSection from '../../components/general/videosSection';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import { selectProducts } from '../../store/products';
import banner from '../../images/landings/palestine/palestine-emergency-hero-banner-desktop-arabic.jpg';
import bannerMobile from '../../images/landings/palestine/palestine-emergency-hero-banner-mobile-arabic.jpg';
import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import sponsorFamilyImg from '../../images/landings/palestine/crisis-and-emergencies-palestine-palestinian-refugee-egypt-settlement.jpg';
import palestineRefugeeImg from '../../images/landings/palestine/crisis-and-emergencies-palestine-palestinian-refugee-settlement-support.jpg';
import shelterOrphanImg from '../../images/landings/palestine/shelter-for-orphan.jpg';
import feedOrphanImg from '../../images/landings/palestine/feed-a-palestine-orphan.jpg';
import supportFamilyImg from '../../images/landings/palestine/support-a-family-with-orphans.jpg';
import babyMilkImg from '../../images/landings/palestine/food-and-water-aid-food-aid-baby-milk-for-52-weeks.jpg';
import donateMealImg from '../../images/landings/palestine/donate-a-meal.jpg';
import donateCleanWaterImg from '../../images/landings/palestine/donate-clean-water.jpg';
import { OurPromiseSection } from '../../components/general';
import PalestineTabs from './components/palestineTabs';
import { impactInfo, impactSliderData, videos } from './fixtures'
import DonateSection from './components/common-sections/donate';
import MuslimsAroundSection from './components/common-sections/muslimsAround';
import ImagesSection from './components/common-sections/images';
import FAQSection from './components/common-sections/faq';

const PalestineArabicLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);
  const [saveOrphansProducts, setSaveOrphansProducts] = useState([0, 0, 0, 0]);
  const [palestineRefugeeSettlementProducts, setPalestineRefugeeSettlementProducts] = useState([0, 0]);
  const [palestineHungerProducts, setPalestineHungerProducts] = useState([0, 0]);
  const [sponsorFamily] = useState({
    title: 'اكفل عائلة فلسطينية',
    description: 'المساعدة في تقديم الدعم المستمر لعائلات اللاجئين الفلسطينيين المقيمين في مصر. سيقدم تبرعك مصروفا شهريا لمرة واحدة لتغطية الاحتياجات الأساسية المطلوبة بعد إعادة التوطين في بلد جديد ومنزل جديد. وهذا يشمل الغذاء والنفقات الطبية والإيجار وتكاليف التعليم.',
    img: sponsorFamilyImg,
    products: [],
  });
  const [palestineRefugee] = useState({
    title: 'دعم توطين اللاجئين الفلسطينيين',
    description: 'المساعدة في توفير الضروريات المطلوبة للاجئين من فلسطين لبدء حياة جديدة في مصر. سيساعد تبرعك في توفير الموارد اللازمة لهم لإعادة التشغيل وإعادة التوطين في مصر ، وتحقيق مستويات الراحة الأساسية في منازلهم الجديدة.',
    img: palestineRefugeeImg,
    products: [],
  });
  const [feedAPalestineOrphan] = useState({
    title: 'أطعم يتيم فلسطيني',
    description: 'ساعد في إطعام الأيتام في فلسطين! سيقدم تبرعك وجبات مغذية ساخنة للأيتام الذين يعانون من آثار المجاعة التي تلوح في الأفق ، مما يضمن عدم نومهم جائعين.',
    img: feedOrphanImg,
    products: [],
  });
  const [supportFamilyOrphan] = useState({
    title: 'ادعم عائلة لديها أيتام',
    description: 'لمساعدة في تقديم الدعم العاجل للأرامل وأطفالهم الأيتام في فلسطين. يمكن أن يوفر تبرعك لمرة واحدة الإغاثة التي تشمل احتياجاتهم الأساسية مثل الطعام والوجبات اليومية والحصول على المياه النظيفة والملابس والدعم النفسي والرعاية الصحية والمساعدة التعليمية.',
    img: supportFamilyImg,
    products: [],
  });
  const [shelterOrphan] = useState({
    title: 'مأوى الأيتام',
    description: `احم الأيتام من خلال إيوائهم بصدقتك! سيساعد تبرعك في توفير حزمة مأوى تتضمن خيمة ومجموعة أدوات النظافة والبطانيات والوسائد والمراتب والمزيد من الضروريات اللازمة للاستقرار المؤقت. تبرع الآن.`,
    img: shelterOrphanImg,
    products: [],
  });
  const [donateMeal] = useState({
    title: 'تبرع بوجبة',
    description: `قف مع العائلات في فلسطين التي تواجه الجوع. تبرع بوجبة اليوم. يوفر تبرعك العناصر الغذائية الأساسية لعائلة محتاجة.`,
    img: donateMealImg,
    products: [],
  });
  const [donateCleanWater] = useState({
    title: 'تبرع بالمياه النظيفة',
    description: `تبرع بالمياه النظيفة اليوم: كل دولار يوفر الماء الأساسي لعائلة محتاجة.`,
    img: donateCleanWaterImg,
    products: [],
  });
  const [babyMilk] = useState({
    title: 'حليب الأطفال',
    description: `توفير إمدادات عاجلة من حليب الأطفال للأسر التي لا تستطيع توفير هذا الغذاء الأساسي لأطفالها الرضع.`,
    img: babyMilkImg,
    products: [],
  });


  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempPalestineEmergencyProducts = [];
    let tempSaveOrphansProducts = [];
    let tempPalestineRefugeeSettlementProducts = [];
    let tempPalestineHungerProducts = [];
    let tempFeedOrphanProducts = [];
    let tempSupportFamilyProducts = [];
    let tempShelterOrphanProducts = [];
    // let tempPalestineRefugeeProducts = [];
    let tempSponsorFamilyProducts = [];
    let tempSupportRefugeeSettlementProducts = [];
    let tempDonateMealProducts = [];
    let tempDonateWaterProducts = [];
    let tempBabyMilkProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // palestineEmergencyProducts
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'نداء فلسطين',
              description: 'توفير الإغاثة الأساسية، بما في ذلك الغذاء والماء والرعاية الطبية والمأوى، حيث تشتد الحاجة إليها. إحداث فرق في حياة المتضررين من حالة الطوارئ في فلسطين.'
            });
            tempPalestineEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'الإغاثة الطبية الفلسطينية',
              description: 'تقديم المساعدة الطبية العاجلة للجرحى والمصابين في حالة الطوارئ الفلسطينية. سيساعد هذا في توفير مواد الإغاثة الطبية العاجلة ودعم المستشفى.'
            });
            tempPalestineEmergencyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'حزمة الغذاء العائلي الأساسية في فلسطين',
              description: 'توفير المستلزمات الأساسية للوجبات ، بما في ذلك 2 كيس أرز ، 2 كيس معكرونة ، 6 خضروات معلبة ، 1 فاكهة معلبة ، 4 لحوم وأسماك معلبة ، 1 زيت طبخ ، 1 عبوة عدس ، 1 كيس دقيق ، 2 عبوة سكر ، 2 كيس ملح ، 6 حليب أو حليب مجفف ، 2 عبوة شاي وقهوة ، و 4 مكونات لصنع الخبز والخبز.'
            });
            tempPalestineEmergencyProducts[2] = inner_item_product;
          }

          // Feed orphans
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-315') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'إطعام يتيم في فلسطين',
              description: ''
            });
            tempFeedOrphanProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'إطعام 10 أيتام في فلسطين',
            });
            tempFeedOrphanProducts[1] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'إطعام 20 يتيما في فلسطين',
            });
            tempFeedOrphanProducts[2] = inner_item_product
          };

          // Support family
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-311') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'دعم عائلة مع الأيتام لمدة 1 شهر',
            });
            tempSupportFamilyProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-312') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'دعم 5 أسر مع الأيتام لمدة 1 شهر',
            });
            tempSupportFamilyProducts[1] = inner_item_product
          };

          // Shelter orphans
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-313') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempShelterOrphanProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-314') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'حزمة المأوى للأيتام الفلسطينيين',
            });
            tempShelterOrphanProducts[1] = inner_item_product
          };

          // sponsorFamilyProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-12-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'اكفل أسرة فلسطينية مكونة من 12 فردا لمدة شهر',
            });
            tempSponsorFamilyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-8-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'اكفل أسرة فلسطينية مكونة من 8 أفراد لمدة 1 شهر',
            });
            tempSponsorFamilyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-4-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'اكفل عائلة فلسطينية مكونة من 4 أفراد لمدة 1 شهر',
            });
            tempSponsorFamilyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'أي كمية',
            });
            tempSponsorFamilyProducts[3] = inner_item_product;
          }

          // SupportRefugeeSettlementProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'مخيم للاجئين الفلسطينيين في مصر',
            });
            tempSupportRefugeeSettlementProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempSupportRefugeeSettlementProducts[1] = inner_item_product;
          }

          // Donate meal
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-401') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'تبرع بوجبة - 20 شخص',
            });
            tempDonateMealProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-402') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'تبرع بوجبة - 50 شخص',
            });
            tempDonateMealProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-400') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'أي كمية',
            });
            tempDonateMealProducts[2] = inner_item_product;
          }

          // Donate water
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-404') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'تبرع بمياه نظيفة - 500 شخص',
            });
            tempDonateWaterProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-405') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'تبرع بمياه نظيفة - 1500 شخص',
            });
            tempDonateWaterProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-406') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'تبرع بمياه نظيفة - 2500 شخص',
            });
            tempDonateWaterProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-403') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'أي كمية',
            });
            tempDonateWaterProducts[3] = inner_item_product;
          }

          // Baby milk
          if (inner_item_product.creator === 'FWD-DIS-BMW-2023-01-048') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'حليب أطفال لمدة أسبوع',
            });
            tempBabyMilkProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'حليب أطفال لمدة أربعة أسابيع',
            });
            tempBabyMilkProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'حليب أطفال لمدة 54 أسبوعًا',
            });
            tempBabyMilkProducts[2] = inner_item_product;
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSaveOrphansProducts[0] = feedAPalestineOrphan;
    tempSaveOrphansProducts[0].products = tempFeedOrphanProducts;
    tempSaveOrphansProducts[1] = supportFamilyOrphan;
    tempSaveOrphansProducts[1].products = tempSupportFamilyProducts;
    tempSaveOrphansProducts[2] = shelterOrphan;
    tempSaveOrphansProducts[2].products = tempShelterOrphanProducts;

    tempPalestineRefugeeSettlementProducts[0] = sponsorFamily;
    tempPalestineRefugeeSettlementProducts[0].products = tempSponsorFamilyProducts;
    tempPalestineRefugeeSettlementProducts[1] = palestineRefugee;
    tempPalestineRefugeeSettlementProducts[1].products = tempSupportRefugeeSettlementProducts;

    tempPalestineHungerProducts[0] = donateMeal;
    tempPalestineHungerProducts[0].products = tempDonateMealProducts;
    tempPalestineHungerProducts[1] = donateCleanWater;
    tempPalestineHungerProducts[1].products = tempDonateWaterProducts;
    tempPalestineHungerProducts[2] = babyMilk;
    tempPalestineHungerProducts[2].products = tempBabyMilkProducts;
    console.log("humger=", tempDonateMealProducts)
    console.log("humger=", tempDonateWaterProducts)

    tempPalestineEmergencyProducts = tempPalestineEmergencyProducts.filter(n => n)
    tempSaveOrphansProducts = tempSaveOrphansProducts.filter(n => n)
    tempPalestineRefugeeSettlementProducts = tempPalestineRefugeeSettlementProducts.filter(n => n)
    tempPalestineHungerProducts = tempPalestineHungerProducts.filter(n => n)
    setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
    setSaveOrphansProducts(tempSaveOrphansProducts);
    setPalestineRefugeeSettlementProducts(tempPalestineRefugeeSettlementProducts);
    setPalestineHungerProducts(tempPalestineHungerProducts);
    // setRefugeeSettlementProducts(tempSupportRefugeeSettlementProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  // }, [selectedCurrencyStore]);
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
          <meta name="title" content={`${seo['crisisAndEmergencies']['palestine']['title']}`} />
          <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
          {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: "EMR-DIS-PSE-2023-01",
            label: 'فلسطين أكثر ما يحتاج',
            value: 255
          }}
          arabic
          donateBtnText='تبرع سريع'
        />
      </section>

      <section className="flex justify-center">
        <img src={banner} alt="Donate to Palestine" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="Donations to Palestine" className="min-[501px]:hidden w-full" />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: "EMR-DIS-PSE-2023-01",
              label: 'فلسطين أكثر ما يحتاج',
              value: 255
            }}
            arabic
            donateBtnText='تبرع سريع'
          />
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <PalestineTabs
        arabic
        palestineEmergencyProducts={palestineEmergencyProducts}
        saveOrphansProducts={saveOrphansProducts}
        palestineRefugeeSettlementProducts={palestineRefugeeSettlementProducts}
        palestineHungerProducts={palestineHungerProducts}
      />
      {/* ------------------------ Total Impact Section */}
      <section className="bg-[#E1EFF2] py-6 md:py-8 flex flex-col" dir='rtl'>
        <div className="flex items-center justify-center mb-4 md:mb-5">
          <h2 className="text-[18px] md:text-4xl font-[AlmariMedium] text-[#253B7E] text-center font-black ">
          تبرع لفلسطين لإحداث تأثير في MATW الخاص بك
          </h2>
        </div>
        <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
          <h2 className="text-[18px] md:text-4xl font-[AlmariMedium] text-[#253B7E] text-center font-black ">
            <span className="font-normal text-[18px] md:text-4xl text-[#78716C] uppercase mr-1">التأثير الكلي:</span>
            &nbsp; {impactInfo.total}
          </h2>
        </div>
        <MaxWidthContainer className="!max-w-[1440px]">
          <div className="hidden sm:grid sm:grid-cols-4 xl:grid-cols-4 gap-16 justify-between items-center">
            {impactSliderData.map(({ img, value, arabicTitle }, index) => (
              <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                <img src={img.src} alt={img.alt} />
                <div className="flex flex-col items-center text-center">
                  <h4 className="md:text-2xl font-brandingBold text-[#253B7E]">{value}</h4>
                  <small className="text-xs md:text-base font-[AlmariMedium] uppercase text-[#78716C]">{arabicTitle}</small>
                </div>
              </div>
            ))}
          </div>
        </MaxWidthContainer>
        <MaxWidthContainer className="sm:hidden !max-w-[1440px]">
          <div className="relative">
            <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
              {impactSliderData.map(({ img, value, arabicTitle }, index) => (
                <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                  <img src={img.src} alt={img.alt} />
                  <div className="flex flex-col items-center text-center">
                    <h4 className="md:text-2xl font-brandingBold text-[#253B7E]">{value}</h4>
                    <small className="text-xs md:text-base font-[AlmariMedium] uppercase text-[#78716C]">{arabicTitle}</small>
                  </div>
                </div>
              ))}
            </div>
            {loadedImpact && instanceRefImpactSlider.current && (
              <>
                <Arrow
                  left
                  onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                  className="bg-transparent"
                  disabled={currentImpactSlide === 0}
                  icon={impactSliderLeftIcon}
                />

                <Arrow
                  onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                  disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                  className="bg-transparent"
                  icon={impactSliderRightIcon}
                  rightMargin
                />
              </>
            )}
          </div>
        </MaxWidthContainer>
      </section>
      {/* /* ---------------------------- Our Promise Section ---------------------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" arabic />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <DonateSection arabic />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Muslim Arounds ------------------------- */}
      <MuslimsAroundSection arabic/>
      {/* /* -------------------------------------------------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* ----------------- videos section ---------------- */}
        <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
        {/* ----------------- videos section --END-------------- */}
        {/* /* --------------------------------- Images --------------------------------- */}
        <ImagesSection />
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* --------------------------------- FAQ’s --------------------------------- */}
      <FAQSection arabic/>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default PalestineArabicLandingPage;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${props.bg ? props.bg : 'bg-[#F60362]'
        } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.className ? props.className : ''
        } ${props.left ? 'arrow--left left-[0px]' : 'arrow--right left-auto right-[0px]'}`}
      onClick={props.onClick}
    >
      {props.icon ? <img alt='arrow-img' src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-3' : '-ml-3'} `} />
        : (
          <svg
            className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
            {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
          </svg>
        )}
    </div>
  );
}