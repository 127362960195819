import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// import ReCAPTCHA from 'react-google-recaptcha';
import useMutation from '../../hooks/useMutaion';
import services from '../../services';
export default function FormSection() {
  const { error, isLoading, mutate, isSuccess } = useMutation(services.postFormContact);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const onSubmit = async data => {

    const ip = await services.getIp();
    const agent = navigator.userAgent;
    data.agent = agent;
    data.ip = ip.data;
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Merci de nous avoir contactés. Nous vous répondrons bientôt.');
      reset();

    }
    if (error) {
      toast.error(`Une erreur s'est produite. Veuillez réessayer plus tard.`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, error]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full  text-sm text-textPrimary gap-4 grid grid-cols-1 md:grid-cols-2">
      <div>
        <input
          {...register('name', { required: true })}
          placeholder="Nom"
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
        />
        {errors.name && <span className="text-sm text-red-600">Ce champ est requis.</span>}
      </div>
      <div>
        <input
          {...register('email', {
            required: {
              value: true,
              message: 'Ce champ est requis.',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'adresse e-mail invalide',
            },
          })}
          placeholder="E-mail"
          type="email"
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
        />
        {errors.email?.message && <span className="text-sm text-red-600">{errors.email.message}</span>}
      </div>
      <div>
        <input
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
          {...register('phone', { required: true })}
          type="number"
          placeholder="Téléphone"
        />
        {errors.phone && <span className="text-sm text-red-600">Ce champ est requis.</span>}
      </div>
      <div>
        <select
          defaultValue={''}
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
          {...register('purpose', { required: true })}
        >
          <option value="">--Veuillez choisir une option--</option>
          <option value="Complaint">Plainte</option>
          <option value="donationQuestion">Question sur les dons</option>
          <option value="progressQuery">Demande de progrès</option>
          <option value="Other">Demande générale</option>
        </select>
        {errors.purpose && <span className="text-sm text-red-600">Ce champ est requis.</span>}
      </div>
      <div className="md:col-span-2 col-span-1">
        <textarea
          {...register('message', { required: true })}
          placeholder="Message"
          className="w-full p-2 border rounded-lg text-base   border-gray-300  focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
        />
        {errors.message && <span className="text-sm text-red-600">Ce champ est requis.</span>}
      </div>
      <div className="md:col-span-2 col-span-1 flex justify-center items-center">
        <button type="submit" disabled={isLoading} className="rounded-xl bg-[#282828] text-white hover:opacity-7 transition-all duration-300 px-16 py-3">
          {isLoading ? 'Chargement...' : 'Envoyer un message'}
        </button>
      </div>
    </form>
  );
}
