import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { palestineSaveOrphansFaqs, palestineSaveOrphansFrenchFaqs, palestineSaveOrphansArabicFaqs } from './components/faqs';
import CrisisWidget from './components/crisisWidget';
import { selectProducts } from '../../store/products';
import banner from '../../images/orphans/Orphans-Generic-Hero-Banner-Desktop.jpg';
import KinzaImg from '../../images/landings/save-orphans/Kinza.jpg';
import CustomButton from '../../components/general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import SaveOrphansTabs from './components/saveOrphansTabs';
import VideosSection from '../../components/general/videosSection';
import { videos } from './fixtures';
import FAQSectionOtherPalestinePage from './components/common-sections/faqs';

const ChildrenInGazaLandingPage = ({ lang = 'en' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [addProduct] = useHandleAddProduct();
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [whereMostNeededProducts, setWhereMostNeededProducts] = useState([0]);
  const [orphanDailyCareProducts, setOrphanDailyCareProducts] = useState([0, 0, 0]);
  const [monthlyOrphanFoodPackProducts, setMonthlyOrphanFoodPackeProducts] = useState([0, 0, 0]);
  const [generalSupportProducts, setGeneralSupportProducts] = useState([0, 0, 0, 0]);
  const [FAQs, setFAQs] = useState(palestineSaveOrphansFaqs);

  const bannerFr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Desktop-FR.webp';
  const bannerMobilFr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Mobile-FR.webp';
  const bannerAr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Desktop-AR.webp';
  const bannerMobileAr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Mobile-AR.webp';
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempWhereMostProducts = [];
    let tempOrphanDailyCareProducts = [];
    let tempMonthlyOrphanFoodPackProducts = [];
    let tempGeneralSupportProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // where most needed
          if (inner_item_product.creator === 'LP-PAL-OR-1-001') tempWhereMostProducts[0] = inner_item_product;

          // Orphan daily care
          if (inner_item_product.creator === 'LP-PAL-OR-1-002') tempOrphanDailyCareProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'LP-PAL-OR-1-003') tempOrphanDailyCareProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'LP-PAL-OR-1-004') tempOrphanDailyCareProducts[2] = inner_item_product;

          // Monthly Orphan food pack
          if (inner_item_product.creator === 'LP-PAL-OR-1-005') tempMonthlyOrphanFoodPackProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'LP-PAL-OR-1-006') tempMonthlyOrphanFoodPackProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'LP-PAL-OR-1-007') tempMonthlyOrphanFoodPackProducts[2] = inner_item_product;

          // General Support

          if (inner_item_product.creator === 'MA-PAL-OR-1-008') tempGeneralSupportProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'MA-PAL-OR-1-009') tempGeneralSupportProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'LP-PAL-OR-1-010') tempGeneralSupportProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'LP-PAL-OR-1-011') tempGeneralSupportProducts[3] = inner_item_product;

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempWhereMostProducts = tempWhereMostProducts.filter(n => n);
    tempOrphanDailyCareProducts = tempOrphanDailyCareProducts.filter(n => n);
    tempMonthlyOrphanFoodPackProducts = tempMonthlyOrphanFoodPackProducts.filter(n => n);
    tempGeneralSupportProducts = tempGeneralSupportProducts.filter(n => n);
    setWhereMostNeededProducts(tempWhereMostProducts);
    setOrphanDailyCareProducts(tempOrphanDailyCareProducts);
    setMonthlyOrphanFoodPackeProducts(tempMonthlyOrphanFoodPackProducts);
    setGeneralSupportProducts(tempGeneralSupportProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    lang === 'ar'
      ? setFAQs(palestineSaveOrphansArabicFaqs)
      : lang === 'fr'
      ? setFAQs(palestineSaveOrphansFrenchFaqs)
      : setFAQs(palestineSaveOrphansFaqs);
  }, [lang]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
          <meta name="title" content={`${seo['crisisAndEmergencies']['palestine']['title']}`} />
          <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
          {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          lang={lang}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: lang === 'ar' ? 'حيث يحتاج الأكثر' : 'Where Most Needed',
            value: 255,
          }}
        />
      </section>

      <section className="flex justify-center">
        <img src={lang === 'fr' ? bannerFr : lang === 'ar' ? bannerAr : banner} alt="banner" className="max-[500px]:hidden w-full" />
        <img
          src={lang === 'fr' ? bannerMobilFr : lang === 'ar' ? bannerMobileAr : 'https://cdn.matwproject.org/images/banners/orphans-banner.jpg'}
          alt="banner"
          className="min-[501px]:hidden w-full"
        />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            lang={lang}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: lang === 'ar' ? 'حيث يحتاج الأكثر' : 'Where Most Needed',
              value: 255,
            }}
          />
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <SaveOrphansTabs
        whereMostNeededProducts={whereMostNeededProducts}
        orphanDailyCareProducts={orphanDailyCareProducts}
        monthlyOrphanFoodPackProducts={monthlyOrphanFoodPackProducts}
        generalSupportProducts={generalSupportProducts}
        lang={lang}
      />
      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1
          className={`text-2xl md:text-[40px] text-[#253b7e] ${
            lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
          } mb-5 md:mb-8 md:text-center`}
        >
          {lang === 'fr' ? (
            <>
              Les enfants de Gaza ont besoin de <span className="text-[#f60362]">VOUS.</span>
            </>
          ) : lang === 'ar' ? (
            <>
              أطفال غزة بحاجة إلى <span className="text-[#f60362]">أنت.</span>
            </>
          ) : (
            <>
              Children in Gaza need <span className="text-[#f60362]">YOU.</span>
            </>
          )}
        </h1>
        <div
          className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${
            lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'
          }`}
        >
          <p>
            {lang === 'fr' ? (
              <>
                Les enfants, qui représentent près de la moitié de la population palestinienne, ont vu leur vie brisée par le récent conflit qui a
                commencé le 7 octobre. En plus de ceux qui ont déjà perdu leur famille, on estime que 17 000 enfants à Gaza sont devenus orphelins ou
                ont été séparés de leurs proches.
                <br />
                <br />
                Blessés, affamés et seuls, leur lutte pour la survie alors que la famine menace représente une nouvelle menace.
                <br />
                1,1 million d’enfants en Palestine sont aujourd’hui menacés de famine. Beaucoup cherchent de la nourriture et des restes, tandis que
                d’autres ont tellement faim qu’ils sont obligés de manger de l’herbe.
                <br />
                <br />
                Les enfants de Gaza souffrent de la faim. Les médecins affirment que les enfants qui survivent au conflit, au manque de nourriture, à
                l’apparition de maladies infectieuses qui se propagent dans leurs camps et au traumatisme psychologique sont confrontés à la
                possibilité d’une vie de problèmes de santé - dont la malnutrition.
              </>
            ) : lang === 'ar' ? (
              <>
                لقد تحطمت حياة الأطفال، الذين يشكلون ما يقرب من نصف سكان فلسطين، بسبب الصراع الأخير الذي بدأ في 7 أكتوبر. وبالإضافة إلى أولئك الذين
                فقدوا أسرهم بالفعل، فإن ما يقدر بنحو 17,000 طفل في غزة قد تيتموا أو انفصلوا عن أحبائهم.
                <br />
                <br />
                ويشكل كفاحهم من أجل البقاء على قيد الحياة مع اقتراب المجاعة تهديدا جديدا.
                <br />
                1.1 مليون طفل في فلسطين يواجهون الآن المجاعة. يبحث الكثيرون عن الطعام والخردة بينما يعاني آخرون من الجوع الشديد ، ويضطرون إلى أكل
                العشب.
                <br />
                <br />
                أطفال غزة يعانون من الجوع. وتفيد التقارير أن الأطباء يقولون إن الأطفال الذين نجوا من النزاع، ونقص التغذية، وظهور الأمراض المعدية
                المنتشرة في جميع أنحاء مخيماتهم، والصدمات النفسية - يواجهون احتمال وجود مشاكل صحية مدى الحياة - سوء التغذية بينهم.
              </>
            ) : (
              <>
                Children, who make up almost half of Palestine’s population, have had their lives shattered by the recent conflict that began on
                October 7. In addition to those who have already lost their families, an estimated 17,000 children in Gaza have been orphaned or have
                been separated from their loved ones.
                <br />
                <br />
                Injured, hungry and alone, their struggle for survival as famine looms presents a new threat.
                <br />
                1.1 million children in Palestine are now facing starvation. Many scavenge for food and scraps whilst others are so hungry, they’re
                forced to eat grass.
                <br />
                <br />
                Gaza’s children are going hungry. It is reported that doctors say the children who survive the conflict, lack of nourishment, the
                onset of infectious diseases spreading throughout their camps and the psychological trauma — face the possibility of a lifetime of
                health problems - malnutrition among them.
              </>
            )}
          </p>
        </div>
        <h1
          className={`text-2xl md:text-[40px] text-[#f60362] ${
            lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
          } mt-5 md:mt-8 mb-2 md:text-center`}
        >
          {lang === 'fr' ? `"Tout en ce moment est triste"` : lang === 'ar' ? `"كل شيء الآن حزين"` : `"Everything right now is sad”`}
        </h1>
        <h4
          className={`text-[18px] md:text-[25px] text-[#253b7e] ${
            lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
          } mt-2 md:mt-4 mb-2 md:text-center`}
        >
          {lang === 'fr'
            ? `dit Kinza qui a perdu son père dans le conflit.`
            : lang === 'ar'
            ? `تقول كينزا التي فقدت والدها في الصراع`
            : `says Kinza who lost her father in the conflict.`}
        </h4>
        <img src={KinzaImg} alt="help the children in gaza." className="w-full rounded-lg my-2 md:my-4" />
        <div
          className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] ${
            lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'
          } space-y-4`}
        >
          <p>
            {lang === 'fr' ? (
              <>
                Le père de Kinza Hussein a quitté la maison pour trouver de la farine pour faire du pain et a été tué pendant le conflit.
                <br />
                <br />
                Il y a beaucoup d’enfants comme Kinza qui n’ont pas les moyens ou un tuteur pour les aider. Que feront-ils ?
              </>
            ) : lang === 'ar' ? (
              <>
                غادر والد كنزة حسين المنزل للبحث عن الدقيق لصنع الخبز وقتل خلال النزاع.
                <br />
                <br />
                هناك العديد من الأطفال مثل كينزا الذين ليس لديهم الوسائل أو الوصي للمساعدة في رعايتهم. ماذا سيفعلون؟
              </>
            ) : (
              <>
                Kinza Hussein's father left home to find flour to make bread and was killed during the conflict.
                <br />
                <br />
                There are many children like Kinza who don’t have the means or a guardian to help care for them. What will they do?
              </>
            )}
          </p>
        </div>
        <div className="mt-6 md:mt-8 space-y-4 flex flex-col md:flex-row justify-between items-center">
          <h4
            className={`text-[25px] md:text-[30px] text-[#253b7e] ${
              lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
            } mt-2 md:mt-4 mb-2 md:text-center`}
          >
            {lang === 'fr'
              ? `Voulez-vous nous aider à sauver des orphelins en Palestine ?`
              : lang === 'ar'
              ? `هل ستساعدنا في إنقاذ الأيتام في فلسطين؟`
              : `Will you help us save orphans in Palestine?`}
          </h4>
          <CustomButton
            onClick={() => {
              addProduct({ product: whereMostNeededProducts[0], currency: selectedCurrency });
            }}
            title={lang === 'fr' ? 'Faire un don' : lang === 'ar' ? 'تبرع الآن' : 'donate now'}
            className={`mb-2 sm:mb-0 min-w-[226px] ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}
          />
        </div>
      </div>

      {/* /* -------------------------------------------------------------------------- */}
      <section className={`bg-[#DFEEF2] mt-5 pb-5 sm:py-8`} dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <div className="w-full md:container md:mx-auto px-4 md:px-0">
          <h1
            className={`text-2xl md:text-[40px] md:leading-[48px] ${
              lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
            } text-[#253b7e] my-5 md:my-8 text-center md:w-9/12 md:mx-auto`}
          >
            {lang === 'fr'
              ? `Muslims Around The World (MATW) sur le terrain en Palestine :`
              : lang === 'ar'
              ? `المسلمون حول العالم (MATW) على الأرض في فلسطين:`
              : `Muslims Around The World (MATW) on the ground in Palestine:`}
          </h1>
          <div
            className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] ${
              lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'
            } space-y-4`}
          >
            {lang === 'fr' ? (
              <>
                <p>
                  Avec une présence de longue date en Palestine et des fournitures en attente d’une réponse immédiate, notre équipe MATW a été rapide
                  pour livrer des produits de première nécessité aux personnes directement touchées par le conflit en Palestine. Nos efforts
                  d’intervention directe se concentrent sur les personnes les plus vulnérables, notamment les orphelins, les veuves, les personnes
                  âgées et les familles.
                </p>
                <p>
                  Notre partenariat actuel avec l’Organisation caritative hachémite jordanienne, le Croissant-Rouge égyptien et le Conseil égyptien de
                  la jeunesse nous permet d’accroître encore l’impact de notre prestation et d’étendre notre portée dans les zones difficiles d’accès.
                  Cliquez ici pour en savoir plus sur l’intervention d’urgence de MATW en Palestine.
                </p>
                <p>
                  Jusqu’à présent, nous avons fourni des trousses médicales d’urgence, des colis alimentaires d’urgence, du carburant, des repas
                  chauds et bien plus encore aux personnes déplacées.
                </p>
                <p>
                  L’un de nos principaux objectifs est maintenant de veiller à ce que les personnes les plus vulnérables, y compris les enfants
                  orphelins, ne soient pas perdues dans ce conflit ou dans la famine qui en résulte.
                </p>
              </>
            ) : lang === 'ar' ? (
              <>
                <p>
                  مع وجود طويل الأمد في فلسطين والإمدادات على أهبة الاستعداد للاستجابة الفورية ، سارع فريق MATW لدينا إلى تقديم الضروريات العاجلة
                  للمتضررين بشكل مباشر من النزاع في فلسطين. وتركز جهودنا للاستجابة المباشرة على الفئات الأكثر ضعفا بما في ذلك الأيتام والأرامل
                  والمسنين والأسر.
                </p>
                <p>
                  إن شراكتنا الحالية مع الهيئة الخيرية الأردنية الهاشمية والهلال الأحمر المصري ومجلس الشباب المصري تمكننا من زيادة تأثيرنا على التسليم
                  وزيادة وصولنا إلى المناطق التي يصعب الوصول إليها. انقر هنا لقراءة المزيد عن استجابة ماتو لحالات الطوارئ في فلسطين.
                </p>
                <p>
                  وقد شملت استجابتنا حتى الآن تسليم مجموعات الطوارئ الطبية والطرود الغذائية العاجلة والوقود والوجبات الساخنة وأكثر من ذلك للنازحين.
                </p>
                <p>
                  ومن بين مجالات تركيزنا الأساسية الآن ضمان عدم فقدان الأشخاص الأكثر ضعفا، بمن فيهم الأطفال اليتامى، في هذا الصراع أو عواقبه للمجاعة.
                </p>
              </>
            ) : (
              <>
                <p>
                  With a long-standing presence in Palestine and supplies on standby for an immediate response, our MATW team has been quick to
                  deliver urgent essentials to those directly affected by the conflict in Palestine. Our direct response efforts are focused on those
                  who are most vulnerable including orphans, widows, the elderly and families.
                </p>
                <p>
                  Our current partnership with The Jordanian Hashemite Charity Organisation, The Egyptian Red Crescent and The Egyptian Youth Council
                  enables us to further increase our delivery impact and further our reach in areas that are difficult to access. Click here to read
                  more about MATW’s Palestine Emergency response.
                </p>
                <p>
                  Our response to date has included the delivery of emergency medical kits, urgent food packs, fuel, hot meals and more to those who
                  have been displaced.
                </p>
                <p>
                  Among our primary focuses now is to ensure that those who are most vulnerable, including orphaned children, are not lost to this
                  conflict or its consequence of famine.
                </p>
              </>
            )}
          </div>
          <h1
            className={`text-2xl md:text-[40px] md:leading-[48px] text-[#253b7e] ${
              lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'
            } my-5 md:my-8 text-center md:w-9/12 md:mx-auto`}
          >
            {lang === 'fr'
              ? `Aidez-nous à sauver des orphelins en Palestine.`
              : lang === 'ar'
              ? `ساعدونا في إنقاذ الأيتام في فلسطين.`
              : `Help us save orphans in Palestine.`}
          </h1>
          <CustomButton
            onClick={() => {
              addProduct({ product: whereMostNeededProducts[0], currency: selectedCurrency });
            }}
            title={lang === 'fr' ? 'Faire un don' : lang === 'ar' ? 'تبرع الآن' : 'donate now'}
            className={`mb-2 sm:mb-0 min-w-[226px] mx-auto ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}
          />
        </div>
      </section>
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* ----------------- videos section ---------------- */}
        <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
        {/* ----------------- videos section --END-------------- */}
      </section>

      <FAQSectionOtherPalestinePage lang={lang} FAQs={FAQs} />
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default ChildrenInGazaLandingPage;
