import { useEffect } from 'react';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
// import MainPageForm from '../components/general/mainPageForm';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
const PrivacyPolicyPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const items = [
    {
      title: 'LA PROTECTION DE VOTRE VIE PRIVÉE EST IMPORTANTE POUR NOUS',
      subtitle: 'Introduction',
      description: `
        MATW International met tout en œuvre pour respecter la vie privée de chaque individu qui visite notre site Web. À ce titre, nous avons développé une politique de confidentialité stricte qui reflète ce respect. Votre vie privée est importante pour nous, nous vous informons et vous offrons des choix concernant la collecte et l’utilisation de vos informations. Les informations contenues dans cet avis expliquent nos pratiques en matière d’informations en ligne.
        Sur notre site Web, nous ne collectons aucune information personnellement identifiable à moins que vous ne nous la fournissiez volontairement et en toute connaissance de cause. Cela signifie que nous ne vous demandons pas de vous inscrire ni de fournir des informations pour consulter notre site. MATW International ne recueille des données personnelles identifiables—telles que des noms, adresses postales et électroniques, informations de contact, informations de carte de crédit ou autres informations—que lorsque vous nous les soumettez volontairement. Ces informations sont utilisées uniquement à des fins internes par MATW International. Les listes de données de MATW International ne sont jamais vendues à des tiers. Nous ne partagerons vos informations personnelles avec des tiers que dans les cas suivants :
        La personne soumettant les informations nous autorise à les partager ;
        Le partage des informations est une opération nécessaire du site, par exemple pour traiter une transaction par carte de crédit/don ;
        Nous y sommes contraints par la loi.
        Cet avis s'applique à toutes les informations collectées ou soumises sur le site Web de MATW International situé à matwproject.org et sur tous les sous-domaines associés.
        En visitant le site Web de MATW International, vous acceptez les pratiques décrites dans cette politique de confidentialité.`,
    },
    {
      title: 'VOS INFORMATIONS',
      subtitle: 'Les informations que nous collectons',
      description: `
        MATW International ne collecte pas automatiquement vos informations personnellement identifiables lorsque vous visitez notre site Web. Nous reconnaissons le fournisseur de services Internet des visiteurs, ainsi que le nom de domaine ou l'adresse IP du serveur à partir duquel vous visitez. Ces informations sont utilisées pour analyser et prédire le trafic du site. Les données que nous collectons sont les totaux des visiteurs provenant d'un domaine, et non les informations des utilisateurs individuels.
        Nous ne pouvons pas reconnaître les données personnellement identifiables—comme les noms, adresses postales, adresses e-mail, informations de contact, informations de carte de crédit ou autres informations—concernant nos visiteurs, ce qui nous permettrait d’identifier les visiteurs spécifiques de notre site Web.
        Vous n'êtes pas obligé de fournir des informations personnelles sur notre site à moins que vous ne choisissiez de le faire. MATW International ne collecte des informations personnelles—comme votre nom, adresse postale et e-mail, informations de contact, informations de carte de crédit ou autres informations—que lorsque vous les soumettez volontairement via un formulaire en ligne. Si vous effectuez un don en ligne, des informations personnelles seront collectées pour traiter ce don.`,
    },
    {
      title: '',
      subtitle: 'Comment nous utilisons les informations',
      description: `
        MATW International est le seul propriétaire des informations collectées sur ce site. MATW International ne vend pas les informations personnelles de ses utilisateurs.
        MATW International déclare clairement qu'elle (a) ne vendra, échangera ni partagera les informations personnelles d'un donateur avec quiconque, ni enverra de courriers pour le compte d'autres organisations ; ou (b) ne partagera les informations personnelles qu'une fois que le donateur y aura donné son autorisation.
        Les informations personnelles que vous nous fournissez lors de l'utilisation de notre site Web—telles que votre nom, adresse postale et e-mail, informations de contact, informations de carte de crédit ou autres informations—seront strictement confidentielles et utilisées pour soutenir votre relation avec MATW International, ainsi que pour vous informer des informations mises à jour.
        Si vous nous le permettez, nous utiliserons les informations personnelles que vous soumettez dans un formulaire en ligne pour vous inscrire sur notre liste de diffusion pour des mises à jour régulières. Si vous ne souhaitez pas recevoir ces communications de la part de MATW International, vous aurez la possibilité, à tout moment, de demander à être retiré de nos envois en nous contactant ou en utilisant la fonction de désinscription dans nos courriels marketing.
        MATW International adhère strictement aux pratiques anti-spam dans ses envois d’e-mails. Tous les e-mails envoyés sur notre liste comportent une option facile de désinscription en bas de chaque e-mail, et les listes ne sont jamais partagées avec des tiers.`,
    },
    {
      title: '',
      subtitle: 'Sécurité des informations personnelles et des cartes de crédit',
      description: `MATW International respecte et soutient la sécurité de votre transaction, et prend toutes les précautions nécessaires pour protéger vos informations.
        Les dons effectués sur notre site sont sécurisés dans la mesure du possible. Nous utilisons un chiffrement de haute qualité, 128 bits, et le protocole de sécurité "https" via la technologie SSL (Secure Socket Layer) pour communiquer avec votre logiciel de navigateur. SSL protège les informations lors de leur transfert via Internet. Cette méthode est le protocole de sécurité standard de l'industrie, ce qui rend extrêmement difficile pour quiconque d’intercepter les informations de carte de crédit que vous nous envoyez. Les entreprises avec lesquelles nous travaillons pour traiter les transactions par carte de crédit utilisent également un chiffrement et des protocoles de sécurité de haute qualité.
        Vous pouvez reconnaître les pages sécurisées du site en vérifiant le symbole de sécurité en bas de votre écran de navigateur. Pour la plupart des navigateurs, ce symbole est soit un cadenas, soit une clé. Lorsque le symbole de cadenas est verrouillé, la page est sécurisée. Lorsqu’il est déverrouillé, la page est non sécurisée.
        De plus, toutes les données transactionnelles que nous collectons sont protégées contre tout accès non autorisé à l’aide de certificats numériques.
        Par ailleurs, ce site dispose de mesures de sécurité pour protéger la perte, l'utilisation abusive et la modification des informations sous notre contrôle.`,
    },
    {
      title: '',
      subtitle: 'Note aux parents et aux mineurs',
      description: `MATW International est soucieux de la protection de la vie privée en ligne de tous nos utilisateurs. Nous respectons particulièrement la vie privée de nos jeunes utilisateurs. Nous encourageons les parents à surveiller l'utilisation d'Internet par leurs enfants et à nous aider à protéger leur vie privée en leur demandant de ne jamais fournir d'informations personnelles sur ce site ou tout autre site sans autorisation. Nous n'essaierons jamais d'extraire des informations inutiles de votre part, quel que soit votre âge.
        MATW International invite les enfants à en apprendre davantage sur notre organisation et à participer à nos opportunités de bénévolat. Cependant, nous encourageons cette participation uniquement avec la connaissance et la supervision des parents. MATW International ne sollicite pas sciemment de données auprès des enfants, ni ne fait de marketing ciblé vers eux.`,
    },
    {
      title: '',
      subtitle: 'Comment accéder à vos informations, les corriger ou les mettre à jour',
      description: `Si vous souhaitez demander l'accès pour consulter, mettre à jour ou modifier les informations que vous avez fournies de quelque manière que ce soit, n'hésitez pas à contacter MATW International à tout moment. Vous pouvez corriger les erreurs factuelles dans vos informations personnelles en nous envoyant une demande qui indique l'erreur. Nous utilisons cette procédure pour mieux protéger vos informations. Veuillez noter que, pour protéger votre vie privée et votre sécurité, nous prendrons également des mesures pour vérifier votre identité avant de fournir des informations ou d’effectuer des corrections.`,
    },
    {
      title: '',
      subtitle: 'Désinscription',
      description: `MATW International vous offre la possibilité de vous désinscrire de la réception d’informations supplémentaires à tout moment. Si vous ne souhaitez plus recevoir de courriels ou de publicités de la part de MATW International, vous pouvez demander à être retiré de nos listes en envoyant un e-mail à info@matwcheckout.org avec « désinscription de la liste de diffusion » ou « désinscription de la liste de courriels » dans l'objet, ou en vous désinscrivant directement d'un e-mail que vous recevez de notre part (vous trouverez l'option « se désinscrire de cette liste » dans le pied de page de l'e-mail). Vous pouvez également demander à être retiré de nos listes en appelant le +61 (02) 9758 9037.`,
    },
    {
      title: 'INFORMATIONS COMPLÉMENTAIRES',
      subtitle: 'Gestion des liens',
      description: `Cette politique de confidentialité s'applique uniquement aux informations collectées via le site Web de MATW International, situé sur matwcheckout.org .
        Ce site Web contient des liens vers d'autres sites dont les politiques de confidentialité peuvent différer de la nôtre. Ces liens sont fournis pour la commodité des utilisateurs. Nous ne transmettrons pas vos informations aux sites liés.
        Nous faisons tout notre possible pour ne lier que des sites qui partagent nos normes élevées de transparence, de décence, d'équité et d'intégrité. Nous nous efforçons de garantir que ces liens sont sûrs pour les enfants et appropriés pour nos utilisateurs en général. Cependant, étant donné que nous ne pouvons pas garantir le contenu de chaque site auquel nous sommes liés, nous ne sommes pas responsables des pratiques de confidentialité ou du contenu de ces sites externes.
        Nous ne contrôlons ni ne garantissons l'exactitude, la pertinence, l'adéquation ou l'intégralité de toute information liée. L'inclusion de liens vers d'autres sites Web n'est pas destinée à approuver, recommander ou soutenir les opinions exprimées, ou les produits ou services offerts sur ces sites externes. Le fait que MATW International fournisse un lien vers un site ne constitue pas une approbation, une validation, un parrainage ou une affiliation à l'égard du site en question ou de tout autre site, de ses propriétaires ou de ses fournisseurs.
        Nous vous encourageons à être vigilant lorsque vous quittez notre site pour lire les déclarations de confidentialité de chaque site qui collecte des informations personnellement identifiables. Ces autres sites peuvent collecter ou solliciter des données personnelles ou envoyer leurs propres cookies à votre ordinateur. Soyez conscient que MATW International n'est pas responsable des pratiques de confidentialité des sites Web tiers.
        De plus, MATW International n'est pas responsable de tout site Web qui crée un lien vers le site Web de MATW International, ni n'approuve, recommande ou soutient les opinions exprimées sur de tels sites.`,
    },
    {
      title: '',
      subtitle: 'Modifications de la politique de confidentialité',
      description: `Si nos pratiques d'information changent à l'avenir, nous afficherons les modifications de la politique sur notre site Web pour vous en informer et vous permettre de vous désinscrire de ces nouvelles utilisations. Si vous êtes préoccupé par la manière dont vos informations sont utilisées, vous devez régulièrement consulter cette section de la politique de confidentialité sur notre site Web. Toute modification de cette politique sera publiée avant sa mise en œuvre.`,
    },
    {
      title: '',
      subtitle: 'Coordonnées',
      description: `Si vous avez des questions concernant notre politique de confidentialité, veuillez nous contacter :
        Niveau 2, 47 Rickard Road, Bankstown, NSW 2200, Australie
        Tél : +61 (02) 9758 9037
        E-mail : info@matwproject.org`,
    },
  ];

  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout>
      {seo && (
        <Helmet>
          <title>{`${seo['resourcesAndInformation']['privacyPolicy']['title']}`}</title>
          <meta name="title" content={`${seo['resourcesAndInformation']['privacyPolicy']['title']}`} />
          <meta name="description" content={`${seo['resourcesAndInformation']['privacyPolicy']['description']}`} />
        </Helmet>
      )}
      <WidgetHome selectedCurrency={e => {}} />
      <div className="md:container md:mx-auto px-1 sm:px-3 flex items-center">
        <div className="grid grid-cols-12 px-3 md:px-4">
          <div className="col-span-12 rounded-md bg-white p-6 mt-4">
            <h1 className="col-span-12 text-center text-6xl text-[#093484] py-2 md:py-8 font-['Gotcha']">Politique de confidentialité</h1>
            {items.map((item, index) => {
              return (
                <div key={`pri${index}`} className="grid grid-cols-12">
                  <div className="col-span-12 border-b border-stone-200 mb-6 pb-4">
                    <h2 className="text-[#00a3da] text-2xl mb-3">{item.title}</h2>
                    <h3 className="text-[#777] font-bold text-xl mb-2">{item.subtitle}</h3>
                    <p className="text-[#777] text-md">{item.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
