import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../components/general/layout';
import OrphanCrisisWidget from '../orphans/OrphanCrisesWidget';
import CrisisWidget from './components/crisisWidget';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import { CustomButton, OurPromiseSection } from '../../components/general';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import useHandleAddProduct from '../../utils/handleAddProduct';
import useWindowSize from '../../hooks/general/useWindowSize';
import banner from '../../images/landings/countries-crisis/countries-in-crisis-desktop-banner.jpg';
import CountriesCrisisTabs from './components/countries-crisis-tabs';
import matwGround1Img from '../../images/landings/countries-crisis/little-gril.jpg';
import matwGround2Img from '../../images/landings/countries-crisis/little-boy-1.jpg';
import matwGround3Img from '../../images/landings/countries-crisis/little-boy-2.jpg';

const CountriesInCrisisLandingPage = () => {
  const products = useSelector(state => selectProducts(state));
  const [whereMostNeededProducts, setWhereMostNeededProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [donateProduct, setDonateProduct] = useState();
  const [gazaEmergencyProduct, setGazaEmergencyProduct] = useState();
  const [lebanonProduct, setLebanonProduct] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [addProduct] = useHandleAddProduct();
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const getAllProducts = () => {
    setIsLoaded(true);
    let whereMostNeededProductsList = [];
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'MKD-MN-001') {
            setDonateProduct(inner_item_product);
          }
          if (inner_item_product.creator === 'EMR-LEB-24-000') {
            whereMostNeededProductsList[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-001') {
            whereMostNeededProductsList[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-002') {
            whereMostNeededProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-003') {
            whereMostNeededProductsList[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-004') {
            whereMostNeededProductsList[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-005') {
            whereMostNeededProductsList[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-006') {
            whereMostNeededProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-007') {
            whereMostNeededProductsList[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            setGazaEmergencyProduct(inner_item_product);
          }
          if (inner_item_product.creator === 'EMR-LEB-24-000') {
            setLebanonProduct(inner_item_product);
          }
        });
      });
    });

    whereMostNeededProductsList = whereMostNeededProductsList.filter(n => n);
    setWhereMostNeededProducts(whereMostNeededProductsList);
  };

  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const faqsRef = useRef(null);
  const moreInfoRef = useRef(null);
  const appealsRef = useRef(null);
  const startRef = useRef(null);
  const endRef = useRef(null);

  const handleAddProduct = () => {
    addProduct({ product: donateProduct, currency: selectedCurrencyStore || 'AUD' });
  };

  return (
    <Layout className={`bg-[#fff]`}>
      {seo && (
        <Helmet>
          <title>{seo['countries-in-crisis']['title']}</title>
          <meta name="title" content={seo['countries-in-crisis']['title']} />
          <meta name="description" content={seo['countries-in-crisis']['description']} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <OrphanCrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'EMR-LEB-24-000',
            label: 'Lebanon Emergency Appeal',
            value: 674,
          }}
        />
      </section>
      <section className="flex justify-center">
        <img src={banner} alt="banner" className="w-full" />
        {/* <img src={bannerMobile} alt="banner" className="min-[501px]:hidden w-full" /> */}
      </section>
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1280px] mx-auto">
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'EMR-LEB-24-000',
                label: 'Lebanon Emergency Appeal',
                value: 674,
              }}
            />
          </div>
        </section>
      </section>
      <section id="appeals" ref={appealsRef}>
        <CountriesCrisisTabs currentProducts={whereMostNeededProducts} />
      </section>
      <OurPromiseSection childClasses={'!bg-[#fff]'} />
      <section
        className="xl:px-10 pt-5 sm:pt-12 bg-[#fff]"
        id="more-info"
        ref={element => {
          moreInfoRef.current = element;
          startRef.current = element;
        }}
      >
        <MaxWidthContainer className="!max-w-[1280px] !px-4 md:!px-6">
          <div className="font-brandingMedium text-center text-[#78716C] text-xl md:text-4xl px-4 w-full">
            Millions are <span className="text-LightBlue font-bold">SUFFERING</span> worldwide. <br /> <i>Palestinians, Syrians and Lebanese</i> need
            urgent <span className="text-LightBlue font-bold">HELP</span>. <br /> Your priceless donations will help us save lives.
          </div>
          <CustomButton
            disabled={!lebanonProduct}
            onClicked={() => {
              addProduct({ product: lebanonProduct, currency: selectedCurrencyStore || 'AUD' });
            }}
            title="DONATE NOW"
            className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit mt-3 md:mt-10 !w-fit hover:!bg-[#f60362]"
          />
          <div className="pt-2 sm:pt-10 leading-5">
            <div className="text-[#78716C] leading-6 text-[18px] text-center font-brandingMedium">
              <strong className="text-LightBlue">The WORLD</strong> is facing a<strong className="text-LightBlue"> CRISIS</strong>.
              <br />
              This catastrophe is sadly impacting
              <strong className="text-LightBlue"> MILLIONS</strong>.<br />
              <i>Palestinians, Syrians and Lebanese </i> need our
              <strong className="text-LightBlue"> HELP!</strong>
              <br />
              <div className="pt-7 "> Allah says in the Qur'an:</div>
              <em>
                <strong>Whoever saves one life, it is as though he has saved all of mankind.</strong>
                <br />
                [Chapter 5, Verse 32]
              </em>
              <br />
              <div className="py-7">
                The devastating <strong className="text-LightBlue">nightmare</strong> which has impacted
                <strong className="text-LightBlue"> Gaza</strong> in <strong className="text-LightBlue">Palestine</strong> for more than a year has
                now entered <strong className="text-LightBlue">Lebanon.</strong>
                <br />
                <br />
                With <strong className="text-LightBlue">2.3 million</strong> lives changed forever in Palestine,{' '}
                <strong className="text-LightBlue">millions more</strong> are now at risk across the region. Men, women and innocent children are
                facing surreal circumstances which are claiming lives daily.
                <br />
                <br />
                <strong className="text-LightBlue">MATW Project</strong> are on the ground delivering vital relief across{' '}
                <strong className="text-LightBlue">Gaza</strong> daily, and in <strong className="text-LightBlue">Lebanon</strong> where the number of
                displaced and homeless people is rising every single hour.
                <br />
                <br />
                Please donate <strong className="text-LightBlue">urgently</strong> as this assistance will go a long way in relieving the pain and
                distress of orphans, widows and the elderly.
                <br />
                <br />
                We operate on a <strong className="text-LightBlue">100% Donation Policy</strong> so rest assured,{' '}
                <strong className="text-LightBlue">100%</strong> of your contributions will reach the Palestinians directly. This project is also{' '}
                <strong className="text-LightBlue">Zakat</strong> Applicable.
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      </section>

      <section className="bg-[#fff]">
        <MaxWidthContainer className="!max-w-[1280px] !px-0 md:!px-6">
          <div className="mt-4 lg:mt-8 flex flex-col items-center gap-y-5 lg:gap-y-10">
            <div className="text-[#78716C] leading-6 text-[18px] text-center font-brandingMedium">
              Palestinians, Syrians and Lebanese are desperately reliant on your generosity for survival. MATW are delivering vital relief in the form
              of food, warmth, water and medication in Gaza, Lebanon and many countries in need.
              <br />
              <br />
              By donating to our Emergency Relief Fund, you can help us respond to disasters immediately, giving many of our brothers and sisters some
              relief from their pain which they face daily.
            </div>
            <img src={matwGround1Img} alt="" className="w-full rounded-lg shadow-lg " />

            <CustomButton
              disabled={!donateProduct}
              onClicked={() => {
                addProduct({ product: donateProduct, currency: selectedCurrencyStore || 'AUD' });
              }}
              title="DONATE NOW"
              className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-0 !w-fit hover:!bg-[#f60362]"
            />
          </div>

          <div className="mt-8 lg:mt-16 flex flex-col items-center gap-y-5 lg:gap-y-10">
            <div className="text-[#78716C] leading-6 text-[18px] text-center font-brandingMedium">
              2.3 million people in Gaza have faced more than a year of pain, hunger, thirst, agony and displacement. Without immediate and constant
              relief, many people continue to suffer.
              <br />
              <br />
              MATW have been responding to this conflict every single day since the 7th of October and we continue to do so thanks to your generosity.
              <br />
              <br />
              Please donate urgently because when you give, they live, insha Allah.
            </div>
            <img src={matwGround2Img} alt="" className="w-full rounded-lg shadow-lg" />
            <CustomButton
              disabled={!gazaEmergencyProduct}
              onClicked={() => {
                addProduct({ product: gazaEmergencyProduct, currency: selectedCurrencyStore || 'AUD' });
              }}
              title="DONATE NOW"
              className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-0 !w-fit hover:!bg-[#f60362]"
            />
          </div>

          <div className="mt-8 lg:mt-16 flex flex-col items-center gap-y-5 lg:gap-y-10">
            <div className="text-[#78716C] leading-6 text-[18px] text-center font-brandingMedium">
              The Lebanese economy was on the brink of collapse for many years as millions were surviving on very little. Towards the end of
              September, the escalating conflict has forced more than a million to flee their homes, and they’re now reliant on humanitarian relief.
              <br />
              <br />
              MATW’s teams are working around the clock, day and night, delivering food, water, medication and shelter to Lebanese, Syrians and
              Palestinians desperate for help.
              <br />
              <br />
              Your donations today can bring respite tomorrow to many men, women and children including widows and orphans.
            </div>
            <img src={matwGround3Img} alt="" className="w-full rounded-lg shadow-lg" />
            <CustomButton
              disabled={!lebanonProduct}
              onClicked={() => {
                addProduct({ product: lebanonProduct, currency: selectedCurrencyStore || 'AUD' });
              }}
              title="DONATE NOW"
              className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-0 !w-fit hover:!bg-[#f60362]"
            />
          </div>
        </MaxWidthContainer>
      </section>

      <section className="xl:px-10 pt-5 sm:pt-12 bg-[#fff]">
        <MaxWidthContainer className="!max-w-[1280px] !px-4 md:!px-6">
          <div className="font-brandingMedium text-center text-[#78716C] text-xl md:text-4xl px-4 w-full">
            Middle Eastern <span className="text-LightBlue font-bold">CATASTROPHE</span> Unfolding
          </div>
          <div className="pt-2 sm:pt-10 leading-5">
            <div className="text-[#78716C] leading-6 text-[18px] text-center font-brandingMedium">
              What’s happening in the Middle East is truly <strong className="text-LightBlue">unprecedented</strong>. The levels of hunger, fear, and
              insecurity are beyond imagination.
              <br />
              <br />
              While we are blessed to eat whatever we want each day, the people of <strong className="text-LightBlue">Gaza and now</strong>{' '}
              <strong className="text-LightBlue">Lebanon</strong> are enduring unimaginable hardship—many going days without a{' '}
              <strong className="text-LightBlue">single meal</strong>. Children are <strong className="text-LightBlue">starving</strong>, and families
              are
              <strong className="text-LightBlue"> desperate</strong> for even a small piece of bread.
              <br />
              <br />
              We go to sleep each night in the comfort of warm homes, but there, <strong className="text-LightBlue">hundreds of thousands</strong> are
              shivering in the <strong className="text-LightBlue">cold</strong>, living in makeshift shelters with no{' '}
              <strong className="text-LightBlue">protection</strong> against the elements. The biting cold seeps into their bones as they struggle to
              survive.
              <br />
              <br />
              We enjoy safety and security every day, but those families live in constant <strong className="text-LightBlue">fear</strong>, with the
              threat of violence hanging over them at every moment. They don’t know if they’ll make it through the night, as conflict continues
              without warning.
              <br />
              <br />
              Putting aside our own blessings, here are <strong className="text-LightBlue">three</strong> urgent reasons why you should consider{' '}
              <strong className="text-LightBlue">donating</strong> to <i>MATW’s Emergency Appeal</i> —even if you’ve already contributed to this{' '}
              <strong className="text-LightBlue">life-saving</strong> cause before:
            </div>
            <CustomButton
              disabled={!donateProduct}
              onClicked={handleAddProduct}
              title="DONATE NOW"
              className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-10 !w-fit hover:!bg-[#f60360]"
            />
          </div>
        </MaxWidthContainer>
      </section>

      <section className="xl:px-10 py-5 sm:py-12 bg-[#253B7E]">
        <MaxWidthContainer className="!max-w-[1480px] !px-4 md:!px-6">
          <div className="font-brandingMedium text-xl md:text-4xl font-bold mb-2 md:mb-4 text-white text-center">WINTER IS COMING</div>
          <div className="pt-2 sm:pt-5 leading-5">
            <div className="font-brandingMedium text-center text-white w-full">
              Imagine standing in a flimsy tent, the biting <span className="font-bold font-brandingBold">winter</span> wind tearing through the thin
              fabric, while icy rain turns the ground into mud. Now imagine this is where you’re supposed to sleep, eat, and{' '}
              <span className="font-bold font-brandingBold">SURVIVE</span>—with no heating, no warm clothing, and no escape from the cold.
              <br />
              <br />
              This is the <span className="font-bold font-brandingBold">reality</span> for{' '}
              <span className="font-bold font-brandingBold">millions</span> of people in <span className="font-bold font-brandingBold">Gaza</span> and{' '}
              <span className="font-bold font-brandingBold">Lebanon</span> as they face the coming winter.
              <br />
              <br />
              In <span className="font-bold font-brandingBold">Gaza</span>, over <span className="font-bold font-brandingBold">2.3 million</span>{' '}
              people—about <span className="font-bold font-brandingBold">85%</span> of the population—have been forced to abandon their homes.
              Families huddle together in shelters made from scraps, their breath visible in the freezing air. Parents watch helplessly as their
              <span className="font-bold font-brandingBold"> children</span> shiver, unable to keep them warm. The risk of{' '}
              <span className="font-bold font-brandingBold">hypothermia</span> and deadly
              <span className="font-bold font-brandingBold"> illnesses</span> like respiratory{' '}
              <span className="font-bold font-brandingBold">infections</span> is growing by the day. Without proper food, clean water, or basic
              medical care, survival becomes a daily struggle. The relentless winter only makes things worse.
              <br />
              <br />
              In <span className="font-bold font-brandingBold">Lebanon</span>, the scene is just as{' '}
              <span className="font-bold font-brandingBold">heartbreaking</span>.{' '}
              <span className="font-bold font-brandingBold">More than a million</span> people displaced by conflict are living in shelters that can
              barely withstand the cold. As snow begins to fall in the mountainous regions, these communities will be cut off, isolated from help,
              with no way to protect themselves from the elements. Inside the shelters, families are left with nothing but the clothes on their backs,
              as freezing temperatures creep in and hope begins to fade.
              <br />
              <br />
              You can feel the cold, the hunger, the overwhelming fear that you won’t make it through the night. For{' '}
              <span className="font-bold font-brandingBold">children</span>, the <span className="font-bold font-brandingBold">elderly</span>, and the
              <span className="font-bold font-brandingBold">sick</span>, winter in these war-torn regions is not just difficult—it’s{' '}
              <span className="font-bold font-brandingBold">deadly</span>.
              <br />
              <br />
              But it doesn’t have to be this way. MATW is on the ground, working against the clock to provide life-saving winter relief. Your
              donations can save lives now.
              <CustomButton
                disabled={!donateProduct}
                onClicked={handleAddProduct}
                title="DONATE NOW"
                className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit my-10 !w-fit hover:!bg-[#f60362]"
              />
              <span className="font-bold font-brandingBold">
                We operate on a 100% Donation Policy so rest assured, 100% of your contributions will reach the people of Palestine and Lebanon
                directly. This project is also Zakat Applicable.
              </span>
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      <section
        id="faqs"
        ref={element => {
          faqsRef.current = element;
          endRef.current = element;
        }}
        className="bg-[#fff] px-4 md:container md:mx-auto mt-8 md:mt-12 pb-4"
      >
        <div className="faq-section text-center">
          <h1 className="font-brandingMedium text-center text-[#78716C] text-xl mt-4 md:mt-0 mb-4 md:text-4xl px-4 w-full">
            Why <strong className="text-LightBlue">DONATE?</strong>
          </h1>
          {/* <strong className='text-LightBlue'> */}
          <p className="text-[18px] text-[#78716C] font-light">
            What’s happening in the Middle East is truly <strong className="text-LightBlue">unprecedented</strong>. The levels of hunger, fear, and
            insecurity are beyond imagination.
            <br />
            <br />
            While we are blessed to eat whatever we want each day, the people of <strong className="text-LightBlue">Gaza and now Lebanon</strong> are
            enduring unimaginable hardship—many going days without a <strong className="text-LightBlue">single meal</strong>. Children are{' '}
            <strong className="text-LightBlue">starving</strong>, and families are <strong className="text-LightBlue">desperate</strong> for even a
            small piece of bread.
            <br />
            <br />
            We go to sleep each night in the comfort of warm homes, but there, <strong className="text-LightBlue">hundreds of thousands</strong> are
            shivering in the <strong className="text-LightBlue">cold</strong>, living in makeshift shelters with no{' '}
            <strong className="text-LightBlue">protection</strong> against the elements. The biting cold seeps into their bones as they struggle to
            survive.
            <br />
            <br />
            We enjoy safety and security every day, but those families live in constant <strong className="text-LightBlue">fear</strong>, with the
            threat of violence hanging over them at every moment. They don’t know if they’ll make it through the night, as conflict continues without
            warning.
            <br />
            <br />
            Putting aside our own blessings, here are <strong className="text-LightBlue">three</strong> urgent reasons why you should consider{' '}
            <strong className="text-LightBlue">donating</strong> to MATW’s Emergency Appeal —even if you’ve already contributed to this{' '}
            <strong className="text-LightBlue">life-saving</strong> cause before:
            <br />
            <br />
            <strong className="text-LightBlue">REWARDS:</strong> By donating today, you can earn amazing rewards for supporting the oppressed and
            needy who urgently require help.
            <br />
            <br />
            <strong className="text-LightBlue">RELIEF:</strong> Millions are in urgent need of this support. Your generosity can relieve their
            distress and save lives.
            <br />
            <br />
            <strong className="text-LightBlue">DUAS:</strong> By supporting the needy and destitute, they will be so grateful and will remember you in
            their duas every time they receive help.
            <br />
            <br />
            Give generously tomorrow, save lives tomorrow, insha Allah.
          </p>
          <CustomButton
            disabled={!donateProduct}
            onClicked={handleAddProduct}
            title="DONATE NOW"
            className="mx-auto !py-3 !px-10 !text-[24px] font-brandingBold !h-fit mt-10 !w-fit hover:!bg-[#f60362]"
          />
        </div>
      </section>
    </Layout>
  );
};

export default CountriesInCrisisLandingPage;
