import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout'
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { getProducts } from '../../store/products'
import { useKeenSlider } from 'keen-slider/react'
import KaffarahProduct from '../../images/fidya-kaffarah/kaf-product.jpg';
import FidyaProduct from '../../images/fidya-kaffarah/fid-product.jpg';
import Widget from './widget'
import { OurPromiseSection } from '../../components/general'
import { Helmet } from 'react-helmet'
import SeoData from '../../utils/seo.json';
import banner from '../../images/fidya-kaffarah/fidya-kaffarah-hero-banner.jpg';
import bannerMobile from '../../images/fidya-kaffarah/fidya-kaffarah-mobile-banner.jpg';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero'
import ImageCarousel1 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-1.jpg'
import ImageCarousel2 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-2.jpg'
import ImageCarousel3 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-3.jpg'
import ImageCarousel4 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-4.jpg'
import ImageCarousel5 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-5.jpg'

const images = [
    {src:ImageCarousel1, alt:'Fidya 2024, how much to pay on fidya for ramadan 2024?'},
    {src:ImageCarousel2, alt:'fidya calculation for 2024'},
    {src:ImageCarousel3, alt:'fidya for ramadan 2024'},
    {src:ImageCarousel4, alt:'fidyah calculator for ramadan 2024'},
    {src:ImageCarousel5, alt:'roza fidya ramadan 2024'},
];


const ShowMoreLessComponent = ({ image, name, desc, btnText,alt }) => {


    const [showMoreButton, setShowMoreButton] = useState(true);

    const toggleContent = () => {

        setShowMoreButton(!showMoreButton);
    };


    return (

        <div className="w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white   xl:min-h-[600px]">
            <div

                className="overflow-hidden img-hover-zoom rounded-md  w-full  tooltip"
                onClick={() => window.scrollTo(0, 500)}
            >
                <img className='w-full' src={image} alt={alt} />
            </div>
            <div className='text-2xl text-[#14A2DC] font-bold text-center my-6'> {name}</div>
            <div>
                <p className={`font-brandingMedium text-[#78716C] text-[16px] px-6 ${showMoreButton ? "line-clamp-3" : ""}`}>
                    {desc}
                </p>
            </div>
            <div className='flex flex-col items-center justify-center pb-4'>
                {desc?.length > 174 &&


                    <button
                        onClick={toggleContent}
                        className="mt-2 text-blue-500  text-sm  hover:underline cursor-pointer"
                    >
                        {showMoreButton ? "Show More" : "Show Less"}
                    </button>

                }
                <button onClick={() => window.scrollTo(0, 500)}
                    className=' bg-[#F60362] mt-6 rounded-full text-white font-brandingMedium w-[230px] h-[50px]'>
                    {btnText}
                </button>
            </div>


        </div>
    );
};

const kaffarh = {
    creator: "RM24-FA-010",
    label: "Kaffarah",
    value: 347
},
    fidya = {
        creator: "RM24-FA-011",
        label: "Fidya",
        value: 348
    }
const FidyaLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [ramadanProducts, setRamadanProducts] = useState([0, 0]);
    const [isLoaded, setIsLoaded] = useState(false);
    const products = useSelector((state) => getProducts(state, 'Ramadan', 'General'));
    const location = useLocation();
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];

        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'RM24-FA-010') {
                tempProductsList[0] = { ...item };
                tempProductsList[0].image = KaffarahProduct;
                tempProductsList[0].btnText = 'Pay your Kaffarah here';
                tempProductsList[0].alt = 'Fidya Calculation for 2024';
            }
            if (item.creator === 'RM24-FA-011') {
                tempProductsList[1] = { ...item };
                tempProductsList[1].image = FidyaProduct;
                tempProductsList[1].btnText = 'Pay your Fidya here';
                tempProductsList[1].alt = 'Fidya 2024, Calculate how much is your fidya for 2024';
            }
        });
        tempProductsList = tempProductsList.filter(n => n)
        setRamadanProducts(tempProductsList);
    };


    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    }, [selectedCurrencyStore]);
    useEffect(() => {

        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])

    /* -------------------------------------------------------------------------- */

    return (
        <Layout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['fidya']['title']}`}</title>
                    <meta name="title" content={`${seo['fidya']['title']}`} />
                    <meta name="description" content={`${seo['fidya']['description']}`} />
                </Helmet>
            }

            {/* /* --------------------------------- Banner ---------------------------------  */}

            <section className="flex justify-center" >
                <img src={banner} alt="Fidya 2024: How much is fidya for ramadan 2024" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="Fidya 2024: Ramadan Fidya 2024" className='min-[501px]:hidden w-full' />
            </section>

            {/* /* --------------------------------- Widget --------------------------------- */}

            <section className='bg-[#E1EFF2]'>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='w-full  lg:mx-auto  xl:px-10 py-3 sm:flex justify-center items-center'>
                        <Widget className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                            defaultProduct={location?.pathname.toLowerCase() === '/fidya' ? fidya : kaffarh}
                            selectedCurrency={selectedCurrency}
                        />
                    </div>
                </section>
            </section>
            <OurPromiseSection />
            <main className='max-w-[1440px] mx-auto'>
                <section className='xl:px-20 mb-8 sm:mt-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap rounded'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[315px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px] pt-8 w-full text-[30px] sm:text-[50px] md:text-[30px] lg:text-[50px] px-8 text-white text-center font-gotcha'>Fidya and Kaffarah</div>
                            <p className='text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center'>
                                Make sure to give your Fidya and Kaffarah for your missed or broken fasts.
                                <br /><br />
                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <YoutubeVideoHero src={`https://www.youtube.com/embed/bC7xyV8_r10?controls=0&autoplay=1&loop=1&mute=1&playlist=bC7xyV8_r10`} />

                    </div>
                </section>
                <section className='xl:px-20'>
                    <div className='flex justify-center'>
                        <h1 className='text-left sm:text-center font-bold text-[#093686] text-2xl sm:text-6xl px-4 w-full '>
                            {/* <span className='text-[#F60362]'>Fidya</span> and <span className='text-[#F60362] mr-2'>kaffarah</span>
                            donations are made when fasts during Ramadan are missed or broken. This is used to feed the poor and needy. */}
                            Fidya 2024: Find out how much is Fidya for Ramadan 2024.
                        </h1>
                    </div>
                    <div className='px-4  mt-8 sm:mt-16 text-[18px]  leading-5'>
                        <div className='text-[#14A2DC] text-left sm:text-center '>
                            <div className='text-[24px] font-bold'>In the Quran it states: </div>
                            <br /><br />
                            <div>
                                "[Observing fasts] for a fixed number of days, but if any of you is ill or on a journey, the same number
                                (should be made up) from other days. And as for those who can fast with difficulty, they have
                                (a choice either to fast or) to feed a Miskin (poor person) (for every day). But whoever does good
                                of his own accord, it is better for him. And that you fast, it is better for you if
                                only you know." — Quran: 2:184
                            </div>
                        </div>

                        <div className='flex flex-col  items-center mt-6'>
                            <h2 className='text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl'>
                                Understanding Fidya: An Essential Guide
                            </h2>
                            <p className='font-brandingMedium text-lg text-[#78716C] text-left sm:text-center sm:w-6/12'>
                                Fidya is a term that holds significant importance in Islamic tradition, especially during the month of Ramadan.
                            </p>
                        </div>
                        <div className='flex flex-col  items-center my-6'>
                            <h2 className='text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl'>
                                What is Fidya?
                            </h2>
                            <p className='font-brandingMedium text-lg text-[#78716C] text-left sm:text-center sm:w-8/12'>
                                Fidya is a form of compensation prescribed in Islam for individuals who are unable to fast during
                                Ramadan due to valid reasons such as illness, pregnancy, or old age. It is a way to fulfill
                                one's religious obligation when fasting is not possible.
                            </p>
                        </div>
                        <div className='flex flex-col  items-center my-6'>
                            <h2 className='text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl'>
                                Calculating Fidya for Ramadan 2024
                            </h2>
                            <p className='font-brandingMedium text-lg text-[#78716C] text-left sm:text-center sm:w-8/12'>
                                The calculation of Fidya involves providing a meal or its equivalent value to the needy for each
                                day of fasting missed. You can use our above calculator to easily help you see how much you
                                need to pay for the missed fasts due to illness or other excusable factors.
                            </p>
                        </div>
                        <div className='flex flex-col  items-center my-6'>
                            <h2 className='text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl'>
                                The Deeper Meaning of Fidya
                            </h2>
                            <p className='font-brandingMedium text-lg text-[#78716C] text-left sm:text-center sm:w-8/12'>
                                Beyond its monetary value, Fidya embodies the spirit of compassion and community support in
                                Islam. It serves as a reminder of our duties towards those less fortunate and the importance
                                of solidarity within the Ummah.
                                <br /><br />
                                Fidya represents a beautiful aspect of Islamic teaching, emphasising empathy, charity, and
                                flexibility in religious observance. As we move closer to <Link to="/ramadan" className='text-blue-500 underline'>Ramadan</Link> 2024, let us embrace the
                                essence of Fidya, ensuring that we fulfill our obligations with understanding and heart.
                            </p>
                        </div>

                        <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 sm:mb-16">
                            {ramadanProducts.map((item, index) => {
                                return (
                                    <div key={`makedonation${index}`} className="basis-full sm:basis-2/4 md:basis-1/3 xl:basis-2/5 p-2">
                                        <ShowMoreLessComponent image={item.image} name={item.name} alt={item.alt} desc={item.short_description || item.description} btnText={item.btnText} />
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </section>

                {/* /* --------------------------------- Images --------------------------------- */}
                <section className='bg-[#F8F8F8] mt-10 sm:mt-20 py-10'>
                    <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">

                        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {

                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                        <img src={item.src} alt={item.alt} className=' bg-top object-cover w-full object-top' />
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="w-full  flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 pb-6">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default FidyaLandingPage;