import React, { useEffect } from 'react';
import { GlobalDebug } from './utils/removeConsole';
import AllRoutes from './routes';
import { setSelectedCurrencyInStore } from './store/user';
import { useDispatch } from 'react-redux';

const App = () => {
  const dispatch = useDispatch();
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    (process.env.APP_ENV === 'production' || process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') && GlobalDebug(false);
    if (!window.location.href.includes('checkout')) {
      dispatch(setSelectedCurrencyInStore('EUR'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return <AllRoutes />;
};

export default App;