import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIP, setCreatePaypalOrder, setCreatePaypalSubscription, setUserIP } from '../../store/user';
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import services from '../../services';
import React from "react";
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASKET_CACHE_KEY } from '../../utils/handleLocalStorage';
// import { Base64 } from 'js-base64';
import HandleShowRecurring from '../../utils/showRecurringOnDataLayer';
import { sha256 } from 'js-sha256';
import { v4 as uuidv4 } from 'uuid';
import HandleShowAdminCost from '../../utils/handleAdminCost';
import ConfirmModal from './components/confirmModal';
import IsGTMScriptLoaded from '../../utils/isGTMScriptLoaded';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';

const StepThreeWithLocalStorage = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { isLaylatulQadr } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userIP = useSelector(selectUserIP);
    const uuid = uuidv4();
    // const paypalId = useSelector(selectPaypalPlanId);
    const [paypalId, setPaypalId] = useState();
    const [selectedMethod, setSelectedMethod] = useState('Card');
    const [subscriptionError, setSubscriptionError] = useState({ state: false, message: '' });
    const [isLoading, setIsloading] = useState(false);
    const [hasMultipleCurrencyError, setHasMultipleCurrencyError] = useState(false);
    const [isSubscription, setIsSubscription] = useState(false);
    const [isCryptoEnable, setIsCryptoEnable] = useState(true);
    // const [isPaypalEnable, setIsPaypalEnable] = useState(false);
    const [userProductsForStripePassing, setUserProductsForStripePassing] = useState();
    const [userProductsForPaypalPassing, setUserProductsForPaypalPassing] = useState();
    const [basketStates, setBasketStates] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [message] = useState('PayPal ne prend pas en charge les contributions administratives et les abonnements multiples. Veuillez utiliser une carte de crédit ou de débit, ou sélectionnez un don sans augmenter la quantité si vous souhaitez utiliser PayPal.')
    const [messageCrypto, setMessageCrypto] = useState(`Désolé, la crypto ne prend pas en charge les abonnements. Veuillez utiliser une carte de crédit ou de débit, ou sélectionnez un don unique si vous souhaitez utiliser la crypto.`)
    const paymentMethodsOnlyLayla = [
        {
            title: 'Card',
            imgName: '4logos'
        },
    ]

    const paymentMethods = [
        {
            title: 'Card',
            imgName: '4logos'
        },
        {
            title: 'Google Pay',
            imgName: 'payment-card-gpay-apple'
        },
        // {
        //     title: 'Google Pay',
        //     imgName: 'google-pay'
        // },
        // {
        //     title: 'Apple Pay',
        //     imgName: 'apple-pay'
        // },
        {
            title: 'Paypal',
            imgName: 'paypal'
        },
        {
            title: 'Crypto',
            imgName: 'crypto'
        },
    ];
    const stripe = useStripe();
    const notify = (text) => toast.error(text, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const { i18n } = useTranslation();
    const lang = i18n.language;
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getSessionId = async () => {

        setIsloading(true);
        let tempUserIP = userIP;
        if (userIP === '') {
            fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
                .then(res => res.json())
                .then(response => {
                    dispatch(setUserIP(response.query));
                    tempUserIP = response.query
                })
                .catch((data, status) => {
                    console.log('Request failed:', data);
                });
        }
        let tempProduct = {
            stripe_customer_id: basketStates.stripe_customer_id,
            currency: basketStates.currency,
            mode: basketStates.mode,
            products: userProductsForStripePassing,
            referal: document.referrer,
            plaque_name: '',
            payment_flow: basketStates.paymentFlow,
            host: window.location.protocol + "//" + window.location.host,
            ip: tempUserIP,
        };
        console.log("get-SessionId=", tempProduct)
        handlePaymentMethodDataLayer('Stripe', selectedMethod);
        try {
            let response = '';
            if (isLaylatulQadr) {
                tempProduct.start_date = basketStates.products[0].start_date
                tempProduct.end_date = basketStates.products[0].end_date
                tempProduct.continue_donation = basketStates.products[0].continue_donation ? 1 : 0
                tempProduct.total_amount = basketStates.grandTotal
                delete tempProduct.mode;
                response = await services.getSessionIdFuturePayment(tempProduct);
                console.log("response=", response)


            }
            else {
                response = await services.getSessionId(tempProduct);
            }
            // const response = await services.getSessionId(tempProduct);
            console.log('response getSessionId', response.data, response.data.checkout_session.id);
            handleStripe(response.data.checkout_session.id);
        } catch (error) {
            setIsloading(false);
            const err = error?.response?.data?.errors;
            console.log(err, error);
            if (error?.response?.status === 409) {
                setShowConfirmModal(true)
            }
            if (Object.keys(err).length > 0) {
                for (const key in err) {
                    notify(err[key][0]);
                }
            }
            else {

                notify('Something went wrong! please try later');
            }
            console.error('error========', error);
        }
    };
    const handleStripe = (id) => {
        // setIsloading(true);
        if (id) {
            // HandleLocalStorage(basketStates,true);
            stripe.redirectToCheckout({
                sessionId: id,
            });
            setIsloading(false);
        } else {
            setIsloading(false);
            console.log("error")
        }
    }
    const handleOptionChange = (value) => {
        let temp = value;
        if (isCryptoEnable && value === 'Crypto') {
            console.log("item select=", value, isCryptoEnable);
            setSelectedMethod(temp);
        }
        // if (!(isSubscription && value === 'Paypal' | !(!isCryptoEnable && value === 'Crypto'))) {
        if ((isSubscription && value === 'Paypal')) {
            notify(message);
        } else {
            if (value === 'Crypto' && !isCryptoEnable) {
                notify(messageCrypto);
            } else {
                setSelectedMethod(temp);
            }
        }
    }
    const handleApprovePaypalSubscription = async (data) => {
        let tempPaypalAccount = basketStates.paymentFlow ? basketStates.paymentFlow === 'USD' ? 'US' : basketStates.paymentFlow === 'AUD' ? 'AU' : 'UK' : 'UK'
        let tempData = {
            stripe_customer_id: basketStates.stripe_customer_id,
            paypal_plan_id: paypalId, // need to check ****************************************************************
            facilitator_access_token: data.facilitatorAccessToken,
            subscription_id: data.subscriptionID,
            order_id: data.orderID,
            payment_source: data.paymentSource,
            product: userProductsForPaypalPassing[0].id, // need to check ****************************************************************
            currency: basketStates.currency,
            referal: document.referrer,
            total_amount: basketStates.grandTotal,
            plaque_name: '',
            host: window.location.protocol + "//" + window.location.host,
            ip: userIP,
            products: userProductsForPaypalPassing,
            paypal_account: tempPaypalAccount
        };
        // console.error('tempData======== before send', tempData);
        try {
            const response = await services.createPaypalSubscription(tempData);
            // const response = await services.createPaypalSubscriptionStaging(tempData);
            // HandleLocalStorage(basketStates,true);
            dispatch(setCreatePaypalSubscription(response.data));
            navigate(generateLinkWithLang(`/success?gateway=paypal&mode=subscription&currency=${basketStates.currency}&id=${data.orderID}`, lang));
            console.log('response createPaypalOrder', response);
        } catch (error) {
            // toast.error('Your order has paid successfully, Something went wrong at this moment, we will send you your receipt soon!');
            console.error('error======== before notify', error);
            // notify('Your order has paid successfully, Something went wrong at this moment, we will send you your receipt soon!');
            console.error('error========', error);
        }
    }
    const handleApprovePaypalOrder = async (details) => {
        let tempPaypalAccount = basketStates.paymentFlow ? basketStates.paymentFlow === 'USD' ? 'US' : basketStates.paymentFlow === 'AUD' ? 'AU' : 'UK' : 'UK'
        // let tempPaypalAccount = basketStates.paymentFlow ? basketStates.paymentFlow === 'OTHER' ? 'UK' : basketStates.paymentFlow : 'UK'
        let tempUserIP = userIP;
        if (userIP === '') {
            fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
                .then(res => res.json())
                .then(response => {
                    dispatch(setUserIP(response.query));
                    tempUserIP = response.query
                })
                .catch((data, status) => {
                    console.log('Request failed:', data);
                });
        }
        let tempData = {
            // payer_id: details.payer.payer_id,
            name: `${details.payer.name.given_name} ${details.payer.name.surname}`,
            email_paypal: details.payer.email_address,
            email: basketStates.user.email,
            // paypal_order_id: details.id,
            // paypal_order_id: details.purchase_units[0].payments.captures[0].id,
            paypal_order_id: details.id,
            paypal_transaction_id: details.purchase_units[0].payments.captures[0].id,
            paypal_email_address: details.payer.email_address,
            paypal_user_id: details.payer.payer_id,
            total_amount: basketStates.grandTotal,
            currency: basketStates.currency,
            products: userProductsForPaypalPassing,
            referal: document.referrer,
            host: window.location.protocol + "//" + window.location.host,
            ip: tempUserIP,
            plaque_name: '',
            paypal_account: tempPaypalAccount
        };
        // console.log("tempData handleApprovePaypalOrder", tempData)
        try {
            const response = await services.createPaypalOrder(tempData);
            // HandleLocalStorage(basketStates,true);
            dispatch(setCreatePaypalOrder(response.data));
            navigate(generateLinkWithLang(`/success?gateway=paypal&mode=order&currency=${basketStates.currency}&id=${details.id}`, lang));
            console.log('response createPaypalOrder', response);
        } catch (error) {
            // notify('Your order has paid successfully, Something went wrong at this moment, we will send you your receipt soon!');
            console.error('error========', error);
        }
    }
    const checkIsIOS = () => {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            setSelectedMethod('Apple Pay');
        } else {
            setSelectedMethod('Card');
        }
    }
    const handlePaymentMethodDataLayer = (method, paymentType) => {
        // console.log("datalayer payment method", userProducts, createdUser, method, paymentType);
        let tempItems = basketStates.products.map(item => {
            return {
                item_id: item.product.creator,
                item_name: item.product.name,
                item_brand: item.product.category || '',
                item_category: item.product.category || '',
                item_description: item.product.description || '',
                price: item.amount,
                quantity: item.quantity,
            }
        })
        let dataLayerTemp = {
            event: 'add_payment_method',
            name: basketStates.user.name,
            email: basketStates.user.email,
            phone: basketStates.user.phone,
            user_email_hashed: sha256(basketStates.user.email),
            donor_id: basketStates.user.user_id,
            pay_id: basketStates.stripe_customer_id,
            frequency: HandleShowRecurring(basketStates.recurring),
            ecommerce: {
                value: basketStates.grandTotal,
                tax: basketStates.adminCost,
                currency: basketStates.currency.toUpperCase(),
                shipping_tier: method,
                payment_type: paymentType,
                items: tempItems
            }
        }
        window.dataLayer = window.dataLayer || [];
        IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
    }
    const handleCreateCryptoOrder = async () => {
        let tempData = {
            name: basketStates.user.name,
            email: basketStates.user.email,
            id: basketStates.user.id,
            total_amount: basketStates.grandTotal,
            currency: basketStates.currency,
            products: userProductsForPaypalPassing,
            referal: document.referrer,
            host: window.location.protocol + "//" + window.location.host,
            ip: userIP,
            uuid: uuid || uuidv4(),
            plaque_name: '',
        };
        handlePaymentMethodDataLayer('Crypto', selectedMethod);
        try {
            const response = await services.createCryptoOrder(tempData);
            console.log('response createCryptoOrder', response);
        } catch (error) {
            console.error('error========', error);
        }
    }
    const handleSaveCryptoWalletAddress = async (wallet_address) => {
        let tempData = {
            id: uuid || uuidv4(),
            address: wallet_address,
        };
        try {
            const response = await services.saveWalletAddress(tempData);
            console.log('response save crypto address', response);
        } catch (error) {
            console.error('error========', error);
        }
    }
    const handleCryptoCurrency = (event, elements) => {
        // let coin_name = ''
        // if (elements.querySelector(".section .mustsend strong")) {
        //     coin_name = elements.querySelector(".section .mustsend strong").textContent.split(' ');
        //     coin_name = coin_name[coin_name.length - 1];
        //     console.log("elements address=", coin_name[coin_name.length - 1])
        // }
        // elements.querySelector(".address") && console.log("elements address=",elements.querySelector(".address").textContent)
        if (event.target.className === 'btnpay') {
            handleSaveCryptoWalletAddress(elements.querySelector(".address").textContent)
            navigate(generateLinkWithLang('/processing-crypto', lang))
        }
        if (event.target.className === 'btncancel') {
            handleSaveCryptoWalletAddress(elements.querySelector(".address").textContent)
            navigate(generateLinkWithLang('/cancel-crypto', lang))
        }
        // console.log("elements clicked=",event, event.target,event.target.className)
    }
    // const handleClick = () => {
    //     handleCreateCryptoOrder();
    //     const elements = document.querySelector(".pay-modal");
    //     elements.addEventListener('click', (event) => handleCryptoCurrency(event, elements));
    //     console.log("elements=", elements)
    // }
    const handleClick = () => {
        handleCreateCryptoOrder();
        const elements = document.querySelector(".pay-modal");
        const elementsInner = document.querySelector(".pay-modal_inner");
        myObserver.observe(elementsInner);
        elements.addEventListener('click', (event) => handleCryptoCurrency(event, elements));
        console.log("elements=", elements)
    }
    const myObserver = new ResizeObserver(
        (entries, observer) => {
            const elementsInner = document.querySelector(".pay-modal_inner");
            for (let entry of entries) {
                console.log("size changed=", entry, entry.contentRect.height, observer)
                if (entry.contentRect.height > 370) {
                    handleSaveCryptoWalletAddress(document.querySelector(".pay-modal .address").textContent);
                    myObserver.unobserve(elementsInner)
                }
            }
        });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        let tempStripe = [];
        let tempPaypal = [];
        let tempPaypalId = "";
        // console.log("basketstates",basketStates)
        if (basketStates.products) {
            basketStates.products.map((obj, index) => {
                if (obj.product) {
                    tempStripe.push({
                        id: obj.product.creator,
                        quantity: obj.quantity,
                        recurring: obj.recurring,
                        plaque_name: obj.onBehalf,
                        url: obj.landing_page || '',
                    })
                    tempPaypal.push({
                        id: obj.product.creator,
                        quantity: obj.quantity,
                        value: obj.amount,
                        plaque_name: obj.onBehalf,
                        url: obj.landing_page || '',
                    })
                }
                return tempStripe;
            });
            console.log("tempstripe=", tempStripe)
            if (basketStates.products[0].product.paypal_plans instanceof Array) {
                tempPaypalId = basketStates.products[0].product.paypal_plans.filter((item) => {
                    return item.recurring === basketStates.recurring.toUpperCase();
                }).map((item) => {
                    let tempPaypalId = item.paypal_plan_id;
                    if (basketStates.paymentFlow === 'UK') tempPaypalId = item.uk_paypal_plan_id;
                    if (basketStates.paymentFlow === 'USD') tempPaypalId = item.us_paypal_plan_id;
                    return tempPaypalId
                })
            } else {
                tempPaypalId = basketStates.products[0].product.paypal_plans;
            }
            if (basketStates.products[0].product.paypal_subscription_plans && basketStates.products[0].product.paypal_subscription_plans instanceof Array) {
                tempPaypalId = basketStates.products[0].product.paypal_subscription_plans.filter((item) => {
                    // console.log("tempPaypalId in map=", item.recurring.toUpperCase(), basketStates.recurring.toUpperCase(), item.currency.name, basketStates.currency)
                    return item.recurring.toUpperCase() === basketStates.recurring.toUpperCase() && item.currency.name === basketStates.currency;
                }).map((item) => {
                    let tempPaypalId = item.paypal_plan_id;
                    if (basketStates.paymentFlow === 'UK' && item.uk_paypal_plan_id) tempPaypalId = item.uk_paypal_plan_id;
                    if (basketStates.paymentFlow === 'USD' && item.us_paypal_plan_id) tempPaypalId = item.us_paypal_plan_id;
                    return tempPaypalId
                })
            }
            if (basketStates.products[0].product.paypal_plans && basketStates.products[0].product.paypal_plans instanceof Array) {
                tempPaypalId = basketStates.products[0].product.paypal_plans.filter((item) => {
                    return item.recurring.toUpperCase() === basketStates.recurring.toUpperCase();
                }).map((item) => {
                    return item.paypal_plan_id
                })
            }
            if (tempPaypalId instanceof Array) {
                // console.log("tempPaypalId=", basketStates.products[0].product.paypal_subscription_plans, tempPaypalId)
                setPaypalId(tempPaypalId[0]);
            } else {
                setPaypalId(tempPaypalId);
            }
            console.log("temppaypalid", tempStripe, tempPaypalId, basketStates)
            const _adminCost = Number(HandleShowAdminCost(basketStates.currency));
            if (basketStates.adminCost) {
                tempStripe.push({
                    id: 'INT-ADM-OVH-2023',
                    // quantity: basketStates.adminCost / _adminCost,
                    quantity: basketStates.adminCost,
                    recurring: basketStates.recurring,
                    plaque_name: 'Pour l’amour d’Allah SWT',
                })
                tempPaypal.push({
                    id: 'INT-ADM-OVH-2023',
                    quantity: basketStates.adminCost / _adminCost,
                    // value: basketStates.adminCost,
                    value: _adminCost,
                    plaque_name: 'Pour l’amour d’Allah SWT',
                })
            }
            console.log("temppaypalid", tempStripe, tempPaypalId, basketStates)
            setUserProductsForStripePassing(tempStripe);
            setUserProductsForPaypalPassing(tempPaypal);
            if (basketStates.mode === 'payment') {
                let acceptableCurrenciesForCrypto = () => {
                    if (basketStates.currency === 'USD' || basketStates.currency === 'CAD' || basketStates.currency === 'EUR' || basketStates.currency === 'GBP') {
                        return true;
                    }
                    return false;
                }
                if (basketStates.currency === 'USD' || basketStates.currency === 'CAD' || basketStates.currency === 'EUR' || basketStates.currency === 'GBP') {
                    setIsCryptoEnable(true)
                } else {
                    setMessageCrypto(`Désolé, la crypto ne prend pas en charge ${basketStates.currency}. Veuillez utiliser une carte de crédit ou de débit, ou utiliser (USD, CAD, EUR, GBP) si vous souhaitez utiliser la crypto.`)
                    setIsCryptoEnable(false)
                    console.log("on currency check",basketStates.currency)
                }
                if (basketStates.currency === 'MYR' || basketStates.currency === 'AED' || basketStates.currency === 'IDR') {
                    setIsSubscription(true);
                } else {
                    setIsSubscription(false);
                }
                // if (basketStates.paymentFlow === 'AUD' || basketStates.paymentFlow === 'USD') {
                //     setMessageCrypto(`Sorry crypto doesn't support ${basketStates.paymentFlow} Payment Flow.`)
                //     setIsCryptoEnable(false)
                // }
                if (basketStates.paymentFlow === 'UK') {
                    // setIsSubscription(true);
                    acceptableCurrenciesForCrypto() && setIsCryptoEnable(true)

                }
                if (basketStates.paymentFlow === 'AUD') {
                    setIsSubscription(false);
                    setIsCryptoEnable(false)
                    // setIsPaypalEnable(true);
                }
                if (basketStates.paymentFlow === 'USD') {
                    // setIsSubscription(true);
                    setIsCryptoEnable(false)
                    // setShowPaypal(false);
                }
                if (basketStates.paymentFlow === 'OTHER') {
                    setIsSubscription(false);
                    acceptableCurrenciesForCrypto() && setIsCryptoEnable(true)

                }
            } else {
                setIsCryptoEnable(false)
                setMessageCrypto(`Désolé, la crypto ne prend pas en charge les abonnements. Veuillez utiliser une carte de crédit ou de débit, ou sélectionnez un don unique si vous souhaitez utiliser la crypto.`)
                // console.log("in if", basketStates.user.currency, basketStates.currency)
                if (basketStates.user.currency && basketStates.user.currency !== basketStates.currency) {
                    setSubscriptionError({ ...subscriptionError, state: true, message: `Veuillez revenir en arrière et changer votre devise en ${basketStates.user.currency} car vous êtes un donateur existant, ou utilisez une autre adresse e-mail.` });
                    setHasMultipleCurrencyError(true);
                    setIsSubscription(true);
                } else {
                    if (basketStates.products.length > 1) {
                        setIsSubscription(true);
                    } else {
                        if (basketStates.currency === 'MYR' || basketStates.currency === 'AED' || basketStates.currency === 'IDR' || basketStates.products[0].quantity > 1 || basketStates.adminCost !== 0) {
                            setIsSubscription(true);
                        } else {
                            setIsSubscription(false)
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basketStates])
    useEffect(() => {
        if (userIP === '') {
            fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
                .then(res => res.json())
                .then(response => {
                    dispatch(setUserIP(response.query));
                    //  console.log("Country is : ", response);
                })
                .catch((data, status) => {
                    console.log('Request failed:', data);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIP])
    useEffect(() => {
        checkIsIOS();
        setIsloading(false);
        const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
        if (basketStatesFromLocalStorage) {
            setBasketStates(basketStatesFromLocalStorage);
        }
        if (userIP === '') {
            fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
                .then(res => res.json())
                .then(response => {
                    dispatch(setUserIP(response.query));
                    //  console.log("Country is : ", response);
                })
                .catch((data, status) => {
                    console.log('Request failed:', data);
                });
        }
        // ReactDOM.findDOMNode(body).getElementsByClassName('snap')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        window.onpopstate = e => {
            setIsloading(false)
        };
        if (window && document) {
            const script = document.createElement('script')
            const body = document.getElementsByTagName('body')[0]
            script.src = 'https://apps.newagecrypto.com/widget/nacepay.js?v4'
            body.appendChild(script)
            // script.addEventListener('load', () => {
            //     window.hbspt.forms.create({
            //         // this example embeds a Hubspot form into a React app but you can tweak it for your use case
            //         // any code inside this 'load' listener will run after the script is appended to the page and loaded in the client
            //     })
            // })
        }
    });
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full">
            {showConfirmModal && <ConfirmModal
                show={showConfirmModal}
                confirm={() => {
                    getSessionId();
                    setShowConfirmModal(false);
                }}
                closeModal={() => setShowConfirmModal(false)} />}
            <div className="content p-2 md:p-5">
                {(subscriptionError.state && selectedMethod !== 'Paypal') && <div className='p-3 rounded-md border border-[#f60362] bg-red-50 text-[#f60362] flex flex-col md:flex-row justify-center items-center'><ExclamationTriangleIcon className='mr-2 w-6 h-6 text-[#f60362]' />{subscriptionError.message}</div>}
                {/* <div className="flex flex-wrap justify-center gap-4- mt-6"> */}
                <div className="grid grid-cols-4 gap-4 mt-6">
                    {/* <div className="flex- flex-wrap- justify-center grid grid-cols-4 gap-4 mt-6"> */}
                    {
                        isLaylatulQadr ?
                            paymentMethodsOnlyLayla.map((item, index) => (
                                <div
                                    key={`payment${index}`}
                                    data-tooltip-target
                                    // className={`col-span-2 sm:col-span-2 md:col-span-1 border rounded p-0 md:p-3 flex flex-col justify-center items-center md:h-[170px] transition-all duration-150 ease-linear ${selectedMethod === item.title ? 'border-[#00a3da] bg-blue-100' : 'border-stone-200'} ${isSubscription && index === 3 ? 'cursor-not-allowed bg-stone-200' : 'cursor-pointer'}`}
                                    className={`col-span-2 md:col-span-1 p-1- md:p-2-`}
                                    onClick={() => handleOptionChange(item.title)}
                                >
                                    {/* <img src={`/images/${item.imgName}.png`} alt={item.imgName} className={`max-w-[70%] sm:w-[auto] md:w-[80%] transition-all duration-150 ${selectedMethod === item.title ? 'scale-105' : 'scale-1'} `} /> */}
                                    <div className={`border rounded p-0 md:p-3 flex flex-col justify-center items-center md:h-[170px] transition-all duration-150 ease-linear ${selectedMethod === item.title ? 'border-[#00a3da] bg-blue-100' : 'border-stone-200'} ${isSubscription && index === 3 ? '!cursor-not-allowed bg-stone-200' : 'cursor-pointer'} ${!isCryptoEnable && index === 4 ? '!cursor-not-allowed bg-stone-200' : 'cursor-pointer'}`}>
                                        <img src={`/images/payment/${item.imgName}.png`} alt={item.imgName} className={`max-w-[70%] sm:w-[auto] md:w-[80%] transition-all duration-150 ${selectedMethod === item.title ? 'scale-105' : 'scale-1'} `} />
                                    </div>
                                </div>
                            ))
                            :
                            paymentMethods.map((item, index) => {
                                let showItem = true;
                                if (index === 3 && !isCryptoEnable) {
                                    showItem = false
                                }
                                // if (index === 2 && !isPaypalEnable) {
                                //     showItem = false
                                // }
                                return showItem && <div
                                    key={`payment${index}`}
                                    data-tooltip-target
                                    // className={`col-span-2 sm:col-span-2 md:col-span-1 border rounded p-0 md:p-3 flex flex-col justify-center items-center md:h-[170px] transition-all duration-150 ease-linear ${selectedMethod === item.title ? 'border-[#00a3da] bg-blue-100' : 'border-stone-200'} ${isSubscription && index === 3 ? 'cursor-not-allowed bg-stone-200' : 'cursor-pointer'}`}
                                    className={`col-span-2 md:col-span-1 p-1- md:p-2-`}
                                    // className={`basis-1/2 md:basis-1/3 p-1 md:p-2`}
                                    onClick={() => handleOptionChange(item.title)}
                                >
                                    {/* <img src={`/images/${item.imgName}.png`} alt={item.imgName} className={`max-w-[70%] sm:w-[auto] md:w-[80%] transition-all duration-150 ${selectedMethod === item.title ? 'scale-105' : 'scale-1'} `} /> */}
                                    <div className={`border rounded p-0 md:p-3 flex flex-col justify-center items-center md:h-[170px] transition-all duration-150 ease-linear ${selectedMethod === item.title ? 'border-[#00a3da] bg-blue-100' : 'border-stone-200'} ${isSubscription && index === 2 ? '!cursor-not-allowed bg-stone-200' : 'cursor-pointer'} ${!isCryptoEnable && index === 3 ? '!cursor-not-allowed bg-stone-200' : 'cursor-pointer'}`}>
                                        <img src={`/images/payment/${item.imgName}.png`} alt={item.imgName} className={`max-w-[70%] sm:w-[auto] md:w-[80%] transition-all duration-150 ${selectedMethod === item.title ? 'scale-105' : 'scale-1'} `} />
                                    </div>
                                </div>
                            })
                    }
                </div>
                <div className="grid grid-cols-6 mt-8 gap-2 md:gap-4">
                    <div className="col-start-1 col-span-2 md:col-span-1">
                        <button
                            type="button"
                            className="w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-200 bg-[#00a3da] text-white sm:px-2 hover:bg-[#f60362] focus:outline-none focus:ring-0"
                            onClick={() => props.backStep()}
                        >
                            Back
                        </button>
                    </div>
                    {/* {selectedMethod === 'Crypto' && <div onClick={() => handleClick()} nacepay="Paywidget" nacepay_id="14548063-cb5e-4b42-9adb-d6c19165b8c9" amount={basketStates.grandTotal} currency={basketStates.currency} ref_id={uuid} */}
                    {selectedMethod === 'Crypto' && <div onClick={() => handleClick()} nacepay="Paywidget" nacepay_id="e0c0674c-c942-47f7-96ea-6c7a5505313a" amount={basketStates.grandTotal} currency={basketStates.currency} ref_id={uuid}
                        className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2 flex justify-end [&>img]:!hidden [&>img]:max-h-10">
                        <div className={`cursor-pointer w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${hasMultipleCurrencyError ? 'bg-stone-300 active:bg-stone-300 text-stone-600 cursor-not-allowed' : 'bg-[#f60362] text-white focus:bg-[#00a3da] hover:bg-[#00a3da]'} focus:outline-none focus:ring-0 active:bg-[#00a3da]`}>
                            <span>Next</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                            </svg>
                        </div>
                    </div>}
                    {selectedMethod === 'Paypal' && <div className="col-start-3 col-span-4 sm:col-span-3 md:col-start-5 md:col-span-2 h-[40px] md:h-[48px] overflow-hidden">
                        {basketStates.mode === 'subscription'
                            ? <PayPalButtons
                                createSubscription={(data, actions) => {
                                    // console.log("===========A======== subscription", paypalId)
                                    return actions.subscription.create({
                                        plan_id: paypalId,
                                    })
                                }}
                                onApprove={(data, actions) => {
                                    console.log("onapprove subscription", data);
                                    handleApprovePaypalSubscription(data)
                                }}
                            />
                            : <PayPalButtons
                                createOrder={(data, actions) => {
                                    console.log("currency in createOrder", basketStates.currency);
                                    handlePaymentMethodDataLayer('Paypal', selectedMethod);
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                description: basketStates.products[0].product.name,
                                                amount: {
                                                    currency_code: basketStates.currency,
                                                    value: basketStates.grandTotal,
                                                },
                                            },
                                        ],
                                    });
                                }}
                                onApprove={(data, actions) => {
                                    return actions.order.capture().then((details) => {
                                        console.log("onapprove", details);
                                        handleApprovePaypalOrder(details)
                                    })
                                }}
                            />
                        }
                    </div>}
                    {selectedMethod !== 'Paypal' && selectedMethod !== 'Crypto' && <div className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2">
                        <button
                            type="submit"
                            disabled={isLoading || hasMultipleCurrencyError}
                            onClick={() => getSessionId()}
                            className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out ${hasMultipleCurrencyError ? 'bg-stone-300 active:bg-stone-300 text-stone-600 cursor-not-allowed' : 'bg-[#f60362] text-white focus:bg-[#00a3da] hover:bg-[#00a3da]'} focus:outline-none focus:ring-0 active:bg-[#00a3da] `}
                        >
                            {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            </>) : <><span>Next</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                            </svg>
                            </>}
                        </button>
                    </div>}
                </div>
            </div>
        </div>
    );
}
export default StepThreeWithLocalStorage;