import foodInitImg from '../../images/landings/food-aid/food-initiative.jpg';
import feedAnOrphanImg from '../../images/landings/food-aid/feed-orphan.jpg';
import crisisInitiativeImg from '../../images/landings/food-aid/crisis-initiative.jpg';
import buildWaterImg from '../../images/landings/food-aid/build-water.jpg';
import sadaqahImg from '../../images/landings/food-aid/sadaqah-jariyah.jpg';

const aids = [
    {
        image: foodInitImg,
        name: {
            en: 'Our Food Initiative: Ending Hunger Together',
            fr: `Notre initiative alimentaire : Ensemble, mettons fin à la faim`,
            ar: '"مبادرتنا للغذاء: إنهاء الجوع معًا"'
        },
        description: {
            main: {
                en: 'With our Food Crisis initiatives, you hold the power to create meaningful change. Donate now to provide vital assistance, including crucial items like baby milk, rice bags, bread, and food packs to those in urgent need. Every contribution, no matter how small, can make a significant impact. Discover more about the key items we urgently require and join us in our mission to alleviate hunger and nourish lives.',
                fr: `Grâce à nos initiatives pour combattre la crise alimentaire, vous avez le pouvoir de créer un changement significatif. Faire un don pour fournir une aide vitale, notamment des produits essentiels comme du lait pour bébé, des sacs de riz, du pain et des paniers alimentaires aux personnes qui en ont besoin de toute urgence. Chaque contribution, aussi petite soit-elle, peut avoir un impact significatif. Découvrez les produits essentiels dont nous avons besoin de toute urgence et rejoignez-nous dans notre mission visant à soulager la faim et à sauver des vies.`,
                ar: 'مع مبادراتنا لأزمة الغذاء، أنت تحمل القوة لإحداث تغيير معنوي. تبرع الآن لتوفير المساعدة الحيوية، بما في ذلك الأشياء الضرورية مثل حليب الأطفال، وأكياس الأرز، والخبز، وحزم الطعام لأولئك الذين في حاجة ماسة. كل مساهمة، مهما كانت صغيرة، يمكن أن تحقق تأثيرًا كبيرًا. اكتشف المزيد عن العناصر الرئيسية التي نحتاجها بشدة وانضم إلينا في مهمتنا للتخفيف من الجوع وإغناء الحياة."'
            },
            bottom: null
        },
        buttonText: {
            en: 'DONATE NOW',
            fr: `Faire un don`,
            ar: 'تبرع الآن'
        },
        link: null
    },
    {
        image: feedAnOrphanImg,
        name: {
            en: 'Feed An Orphan',
            fr: `Nourrir un orphelin`,
            ar: '"أطعم يتيماً"'
        },
        description: {
            main: {
                en: 'With our Food Crisis initiatives, you hold the power to create meaningful change. Donate now to provide vital assistance, including crucial items like baby milk, rice bags, bread, and food packs to those in urgent need. Every contribution, no matter how small, can make a significant impact. Discover more about the key items we urgently require and join us in our mission to alleviate hunger and nourish lives.',
                fr: `En temps de catastrophe ou de conflit, les enfants vulnérables et les orphelins sont les premiers à souffrir. Au moins 40 millions d'enfants dans le monde souffrent d'une grave insécurité alimentaire. Aidez-nous à leur fournir des repas nutritifs chaque jour.`,
                ar: 'عندما تضرب الكوارث والنزاعات، يكون الأطفال الضعفاء والأيتام هم الأكثر تأثرًا. يعاني على الأقل 40 مليون طفل حول العالم من نقص شديد في الغذاء. ساعدنا في توفير وجبات غذائية غنية لهم كل يوم.'
            },
            bottom: null
        },
        buttonText: {
            en: 'Feed an Oprhan',
            fr: `Nourrir un orphelin`,
            ar: 'أطعم يتيماً'
        },
        link: '/orphans/orphan-feast'
    },
    {
        image: crisisInitiativeImg,
        name: {
            en: 'Our Water Crisis Initiative: Empower Lives with Clean Water',
            fr: `Notre initiative sur la crise de l'eau : Transformer des vies grâce à l'eau propre`,
            ar: '"مبادرتنا لأزمة المياه: تمكين الحياة بالمياه النظيفة"'
        },
        description: {
            main: {
                en: `Your donation can provide essential assistance through the installation of water wells and the establishment of water distribution systems. Even the tiniest ripples make a significant difference. Explore the critical resources we urgently require and be part of the wave of change that brings hope and relief to communities in need. Together, let's create a sustainable future with clean water as its foundation.`,
                fr: `Votre don peut apporter une aide essentielle grâce à l'installation de puits d'eau et à la mise en place de systèmes de distribution d'eau. Même les plus petits gestes font une différence significative. Découvrez les ressources essentielles dont nous avons besoin de toute urgence et participez à la vague de changement qui apporte espoir et soulagement aux communautés dans le besoin. Ensemble, créons un avenir durable fondé sur l'eau potable.`,
                ar: 'مكن أن يوفر تبرعك مساعدة أساسية من خلال تركيب آبار مياه وإنشاء أنظمة توزيع المياه. حتى أصغر التموجات تجعل فرقًا كبيرًا. استكشف الموارد الحرجة التي نحتاجها بشكل عاجل وكن جزءًا من موجة التغيير التي تجلب الأمل والراحة للمجتمعات في حاجة. معًا، دعونا نخلق مستقبلًا مستدامًا يتمتع بالمياه النظيفة كأساس له.'
            },
            bottom: null
        },
        buttonText: {
            en: 'DONATE NOW',
            fr: `Faire un don`,
            ar: 'تبرع الآن'
        },
        link: null
    },
    {
        image: buildWaterImg,
        name: {
            en: 'Build A Water Well',
            fr: `Construire un puits d'eau`,
            ar: '"بناء بئر مياه"'
        },
        description: {
            main: {
                en: `When you donate to build a water wells , not only does it act as a Sadaqah Jariyah but you’re also helping impoverished communities facing water scarcity, develop and grow.`,
                fr: `Lorsque vous faites un don pour construire un puits d'eau, non seulement cela est considéré comme une Sadaqah Jariyah, mais vous aidez également les communautés démunies confrontées à la pénurie d'eau à se développer et à croître.`,
                ar: 'عندما تتبرع لبناء آبار مياه، لا يكون ذلك فقط صدقة جارية، بل أنت أيضًا تساعد المجتمعات المعدمة التي تواجه نقص المياه على التنمية والنمو.'
            },
            bottom: null
        },
        buttonText: {
            en: 'Build A Well',
            fr: `Construire un puits`,
            ar: 'بناء بئر'
        },
        link: '/sadaqah-jariyah/build-a-water-well'
    },
    {
        image: sadaqahImg,
        name: {
            en: 'Sadaqah Jariyah',
            fr: `Sadaqah Jariyah`,
            ar: 'الصدقة الجارية'
        },
        description: {
            main: {
                en: `When you donate to build a water wells , not only does it act as a Sadaqah Jariyah but you’re also helping impoverished communities facing water scarcity, develop and grow.`,
                fr: `Sadaqah Jariyah is a charity that continues to earn the rewards, even after the giver has passed away. When you give Sadaqah Jariyah, it will support a project of lasting benefit both for the communities and for the giver. Ali Banat’s legacy started with three Sadaqah Jariyah projects, and thus MATW was born.`,
                ar: 'الصدقة الجارية هي صدقة تستمر في جني الأجر حتى بعد وفاة المعطي. عندما تتبرع بالصدقة الجارية، فإنها ستدعم مشروعاً ذا فائدة دائمة لكل من المجتمعات والمعطي. بدأ إرث علي بنات بثلاث مشاريع صدقة جارية، ومن هنا بدأت MATW.'
            },
            bottom: {
                en: `What will YOUR Sadaqah Jariyah and legacy be?`,
                fr: `Quel sera VOTRE Sadaqah Jariyah et VOTRE héritage ?`,
                ar: 'ماذا سيكون إرثك الصدقة الجارية؟'
            }
        },
        buttonText: {
            en: 'DONATE NOW',
            fr: `Faire un don`,
            ar: 'تبرع الآن'
        },
        link: '/sadaqah-jariyah'
    },
]

export { aids };