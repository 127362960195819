import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../../store/products';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import SeoData from '../../../utils/seo.json';
import Layout from '../../../components/general/layout';
import LimbsProductBox from './limbsProductBox';
import limbsImg1 from '../../../images/landings/limbs/limbs-img-1.jpg';
import limbsImg2 from '../../../images/landings/limbs/limbs-img-2.jpg';
import CustomButton from '../../../components/general/button';


const LimbsOfHopeLandingPage = ({ page = 'general' }) => {
  const products = useSelector(state => selectProducts(state));
  const productRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [limbsProducts, setLimbsProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [view, setView] = useState('list');
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempLimbsProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'EMR-PAL-MASU-201') tempLimbsProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-202') tempLimbsProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-203') tempLimbsProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-204') tempLimbsProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-205') tempLimbsProducts[4] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-206') tempLimbsProducts[5] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-207') tempLimbsProducts[6] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-208') tempLimbsProducts[7] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-209') tempLimbsProducts[8] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-210') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // alternativeName: '100% of a Prosthetic Limb (Most Popular)',
              alternativeName: <div className='block'>100% of a Prosthetic Limb <strong className='text-red-500 text-sm'>(Most Popular)</strong></div>,
            });
            tempLimbsProducts[9] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-MASU-211') tempLimbsProducts[10] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-212') tempLimbsProducts[11] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-213') tempLimbsProducts[12] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-214') tempLimbsProducts[13] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-215') tempLimbsProducts[14] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-216') tempLimbsProducts[15] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-217') tempLimbsProducts[16] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-218') tempLimbsProducts[17] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-219') tempLimbsProducts[18] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-003') tempLimbsProducts[19] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-005') tempLimbsProducts[20] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-006') tempLimbsProducts[21] = inner_item_product;
          if (inner_item_product.creator === 'EMR-PAL-MASU-009') tempLimbsProducts[22] = inner_item_product;
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempLimbsProducts = tempLimbsProducts.filter(n => n);
    setLimbsProducts(tempLimbsProducts)
  };
  const handleScrollToProducts = () => {
    if (productRef.current) {
      const topPos = productRef.current.getBoundingClientRect().top + window.scrollY - 100; // 100 pixels offset, adjust as needed
      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore])
  /* -------------------------------------------------------------------------- */
  return (
    <Layout showRegularImg={false} className='bg-white' hideHighlights>
      {seo &&
        <Helmet>
          <title>{`${seo['appeals']['title']}`}</title>
          <meta name="title" content={`${seo['appeals']['title']}`} />
          <meta name="description" content={`${seo['appeals']['description']}`} />
        </Helmet>
      }
      <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 pb-8 md:py-8">
        <div className="col-span-2 md:col-span-1 order-2 md:order-1">
          <div className="font-brandingBold text-center font-bold text-DarkerBlue text-sm md:text-xl px-4 w-full mb-3">
            ATTENTION: THIS IS AN URGENT APPEAL FOR HELP
          </div>
          <div className="font-['Gotcha'] text-center font-bold- text-LightBlue text-5xl sm:text-8xl px-4 w-full my-2 md:my-4">
            Limbs of Hope
          </div>
          <div className="font-brandingBold text-center font-bold text-LightBlue text-lg md:text-2xl px-4 w-full">
            Will you help a <strong className='underline'>CHILD</strong>  walk again?
            <br />
            Can you help a <strong className='underline'>MOTHER</strong> hold again?
            <br />
            Could you help a <strong className='underline'>FATHER</strong> work again?
            <br />
            They need <strong className='underline'>YOUR</strong> help! Please help us make a difference right now, we are <strong className='underline'>on the ground.</strong>
          </div>
          <CustomButton
            onClick={handleScrollToProducts}
            title={<div className='flex flex-col items-center justify-center'>
              <span>Donate Now</span>
              <span className='text-xs text-[#e9adc4]'>Save Lives In Palestine</span>
            </div>}
            className='my-3 mx-auto !py-3 !h-auto hover:!bg-[#f60362]'
          />
        </div>
        <div className="col-span-2 md:col-span-1 order-1 md:order-2">
          <div className={`basis-full xs:aspect-w-16 xs:aspect-h-9 sm:h-[300px] md:h-[400px] lg:h-[890px] min-h-fit md:!max-h-[400px] overflow-hidden`}>
            <iframe
              className="video"
              width={'100%'}
              height={'100%'}
              title={'youtube'}
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src='https://youtube.com/embed/ScnETpaXUSY?autoplay=0&feature=oembed'
            />
          </div>
        </div>
      </section>

      <div className="md:container md:mx-auto mt-5">
        <section className="xl:px-10 mt-5 sm:mt-12">
          <div className="px-4 md:px-6 mt-2 sm:mt-10 leading-5">
            <div className="text-[#78716C] leading-6 text-[18px] text-center sm:text-left  font-brandingMedium">
              <strong>This is the reality in Palestine.</strong> Innocent children and parents losing limbs, their bodies torn apart by the devastation of conflict. <strong>Imagine this happening to your child.</strong> Imagine your loved one suddenly unable to walk, to run, to hold you.
              <br />
              <br />
              These are not just statistics or faraway tragedies. These are real people. Mothers, fathers, and children who once had dreams just like yours—dreams that have been crushed in the blink of an eye. <strong>But today, you can change everything.</strong>
            </div>
            <div className='w-full my-2 md:my-4'>
              <img src={limbsImg1} alt="limbs" className='w-full rounded-lg' />
            </div>
            <CustomButton
              onClick={handleScrollToProducts}
              title={<div className='flex flex-col items-center justify-center'>
                <span>Donate Now</span>
                <span className='text-xs mt-1 text-[#e9adc4]'>Save Lives In Palestine</span>
              </div>}
              className='my-3 mx-auto !py-3 !h-auto hover:!bg-[#f60362]'
            />
            <div className="text-center sm:text-left font-brandingBold font-bold text-LightBlue leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl mb-2 w-full mx-auto mt-4 md:my-6">
              A War They Never Chose—A Battle They Can’t Fight Alone
            </div>
            <div className="text-[#78716C] leading-6 text-[18px] text-center sm:text-left  font-brandingMedium">
              In Palestine, the conflict has left countless children and adults without their limbs. Stripped of their freedom, their dignity, their ability to live a normal life. <strong>Their lives stolen.</strong>
              <br />
              <br />
              Can you imagine not being able to walk again? Watching your child crawl on the ground, with no hope of standing tall, running, or playing again? <strong>That’s the horror they’re facing.</strong>
              <br />
              <br />
              But amidst this chaos, there is a beacon of hope. <strong>YOU</strong> are that hope. You have the power to change their story.
            </div>
            <div className="text-center sm:text-left font-brandingBold font-bold text-LightBlue leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl mb-2 w-full mx-auto mt-4 md:my-6">
              What Will Your Heart Let You Do?
            </div>
            <div className="text-[#78716C] leading-6 text-[18px] text-center sm:text-left font-brandingMedium">
              The price of a prosthetic limb in Palestine is on average <strong>$1,600</strong>. For a family just trying to survive the conflict, this is an impossible sum. And without help, these children and parents are condemned to a life of struggle—dependent on others just to get by. But today, you can give them back their future.
              <br />
              <br />
              With a simple donation, you could restore a child’s ability to walk. You could bring a father back to his feet. You could allow a mother to hold her child again.
              <br />
              <br />
              How does that make you feel? Knowing that your compassion, your generosity, could change everything for a family in Palestine?
            </div>
            <div className='w-full my-2 md:my-4'>
              <img src={limbsImg2} alt="limbs" className='w-full rounded-lg' />
            </div>
            <CustomButton
              onClick={handleScrollToProducts}
              title={<div className='flex flex-col items-center justify-center'>
                <span>Donate Now</span>
                <span className='text-xs mt-1 text-[#e9adc4]'>Save Lives In Palestine</span>
              </div>}
              className='my-3 mx-auto !py-3 !h-auto hover:!bg-[#f60362]'
            />
            <div className="text-center sm:text-left font-brandingBold font-bold text-LightBlue leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl mb-2 w-full mx-auto mt-4 md:my-6">
              Their Pain Is Real. Their Need Is Urgent.
            </div>
            <div className="text-[#78716C] leading-6 text-[18px] text-center sm:text-left font-brandingMedium">
              <strong>Every minute, they are suffering.</strong> As you read this, there are families in Palestine praying for relief, for hope, for a miracle. They don’t know where help will come from, but they know they can’t face this alone.
              <br />
              <br />
              What would it mean to you if you were in their shoes? If you saw your child, your loved one, unable to move, unable to live a normal life, and no way to change that?
              <br />
              <br />
              This is the reality of the conflict. And <strong>you can change it.</strong>
            </div>
            <CustomButton
              onClick={handleScrollToProducts}
              title={<div className='flex flex-col items-center justify-center'>
                <span>Donate Now</span>
                <span className='text-xs mt-1 text-[#e9adc4]'>Save Lives In Palestine</span>
              </div>}
              className='my-3 mx-auto !py-3 !h-auto hover:!bg-[#f60362]'
            />
            <div className="text-center sm:text-left font-brandingBold font-bold text-LightBlue leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl mb-2 w-full mx-auto mt-4 md:my-6">
              Every Dollar You Give Is A Step Closer To Hope
            </div>
            <div className="text-[#78716C] leading-6 text-[18px] text-center sm:text-left font-brandingMedium">
              A donation of just <strong>$160</strong> covers <strong>10%</strong> of the cost of a prosthetic limb. <strong>$160</strong>. That’s all it takes to start changing a life. Ten people like you, each giving <strong>$160</strong>, and a child will walk again. A parent will regain their ability to provide. (Please note amounts are average in USD)
              <br />
              <br />
              <strong> Don’t wait. Don’t look away. Their lives depend on your compassion.</strong>
            </div>
            <CustomButton
              onClick={handleScrollToProducts}
              title={<div className='flex flex-col items-center justify-center'>
                <span>Donate Now</span>
                <span className='text-xs mt-1 text-[#e9adc4]'>Save Lives In Palestine</span>
              </div>}
              className='my-3 mx-auto !py-3 !h-auto hover:!bg-[#f60362]'
            />
            <div className="text-center sm:text-left font-brandingBold font-bold text-LightBlue leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl mb-2 w-full mx-auto mt-4 md:my-6">
              How Much Is a Child’s Smile Worth?
            </div>
            <div className="text-[#78716C] leading-6 text-[18px] text-center sm:text-left font-brandingMedium">
              Imagine watching a child who hasn’t walked in months take their first steps. Imagine a mother weeping as she hugs her child, standing tall for the first time since the bombing. These are not just dreams—they can be real, <strong>because of you.</strong>
              <br />
              <br />
              What will you give to see a child smile again? To restore hope where there was none? <strong>This is your moment.</strong> Don’t let it slip by. Don’t let them slip through the cracks of this world, forgotten and alone.
            </div>
            <CustomButton
              onClick={handleScrollToProducts}
              title={<div className='flex flex-col items-center justify-center'>
                <span>Donate Now</span>
                <span className='text-xs mt-1 text-[#e9adc4]'>Save Lives In Palestine</span>
              </div>}
              className='my-3 mx-auto !py-3 !h-auto hover:!bg-[#f60362]'
            />
            <div className="text-center sm:text-left font-brandingBold font-bold text-LightBlue leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl mb-2 w-full mx-auto mt-4 md:my-6">
              You Are Their Only Hope
            </div>
            <div className="text-[#78716C] leading-6 text-[18px] text-center sm:text-left font-brandingMedium">
              They’re not just sitting in some hospital waiting for a miracle to happen. They’re fighting to survive every single day. And without <strong>you</strong>, their fight gets harder. Their pain continues.
              <br />
              <br />
              You don’t have to turn away. You don’t have to wonder if someone else will step up. <strong>This is your moment to make a real difference.</strong>
            </div>
            <CustomButton
              onClick={handleScrollToProducts}
              title={<div className='flex flex-col items-center justify-center'>
                <span>Donate Now</span>
                <span className='text-xs mt-1 text-[#e9adc4]'>Save Lives In Palestine</span>
              </div>}
              className='my-3 mx-auto !py-3 !h-auto hover:!bg-[#f60362]'
            />
            <div className="text-center sm:text-left font-brandingBold font-bold text-LightBlue leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl mb-2 w-full mx-auto mt-4 md:my-6">
              Act Now. Change Everything.
            </div>
            <div className="text-[#78716C] leading-6 text-[18px] text-center sm:text-left font-brandingMedium">
              The conflict in Palestine has already taken too much. <strong>But with your help, it doesn’t have to take any more.</strong> You can give a child the chance to walk again, to run, to laugh. You can give a mother the chance to stand tall once more. You can help a father provide for his family again.
              <br />
              <br />
              <strong>Don’t let them suffer any longer.</strong> They need you, and they need you now.
              <br />
              <br />
              <strong>Choose a donation, below to make a life-changing donation both for you and for someone who;s life you will change forever,</strong>
            </div>
            <div className="text-center sm:text-left font-brandingBold font-bold text-LightBlue leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl mb-2 w-full mx-auto mt-4 md:my-6">
              Limbs of Hope: Because no one should be left behind in the dust of war.
            </div>
            <CustomButton
              onClick={handleScrollToProducts}
              title={<div className='flex flex-col items-center justify-center'>
                <span>Donate Now</span>
                <span className='text-xs mt-1 text-[#e9adc4]'>Save Lives In Palestine</span>
              </div>}
              className='my-3 mx-auto !py-3 !h-auto hover:!bg-[#f60362]'
            />
          </div>
        </section>
        <div className="w-full px-4 md:px-2 lg:px-0" ref={productRef}>
          <div className="flex md:hidden pt-4 mb-4 justify-start items-center border-b border-stone-300">
            <div className={`flex rounded-t-md ${view === 'list' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('list')}>
              List
            </div>
            <div className={`flex rounded-t-md ${view === 'tile' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('tile')}>
              Tile
            </div>
            <div className={`flex rounded-t-md ${view === 'grid' ? 'bg-[#f60362] text-white' : 'text-stone-500'} px-4 cursor-pointer`} onClick={() => setView('grid')}>
              Grid
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4">
              <div className="flex justify-center flex-wrap">
                {limbsProducts.map((prod, index) => {
                  return <div className={`${view === 'list' ? 'basis-full' : view === 'tile' ? 'basis-1/2 px-1' : 'basis-1/3 px-1'} sm:basis-1/3- md:basis-1/3 xl:basis-1/5 py-1 md:p-2`} key={`top10${index}`}>
                    <LimbsProductBox product={prod} currency={selectedCurrency} view={view} showDescription />
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LimbsOfHopeLandingPage;