import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import Layout from '../../../components/general/layout';
import SeoData from '../../../utils/seo.json';
import { selectProducts } from '../../../store/products';
import { OurPromiseSection } from '../../../components/general';
import { Helmet } from 'react-helmet';
import giftImg1 from '../../../images/landings/gift-sound/gift-sound-img1.jpg';
import giftImg2 from '../../../images/landings/gift-sound/gift-sound-img2.jpg';
import giftImg3 from '../../../images/landings/gift-sound/gift-sound-img3.jpg';
import CustomButton from '../../../components/general/button';
import banner from '../../../images/landings/gift-sound/Hearing-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../../images/landings/gift-sound/Hearing-Hero-Banner-Mobile.jpg';
import CrisisWidget from '../../crisis-and-emergencies/components/crisisWidget';
import GiftSoundProducts from './products';
import { giftSoundFaqs } from './faq';
import useHandleAddProduct from '../../../utils/handleAddProduct';

const GiftOfSoundLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [addProduct] = useHandleAddProduct();
  const products = useSelector(state => selectProducts(state));
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [giftSoundsProducts, setGiftSoundsProducts] = useState([0, 0]);
  const [helpThemHearPrd, setHelpThemHearPrd] = useState();
  const giftSounds = [
    {
      image: giftImg1,
      title: 'Collaboration avec des partenaires et évaluation des besoins.',
      alt: 'Organisation caritative musulmane pour aider les Muslims Around The World'
    },
    {
      image: giftImg2,
      title: 'Mise en place de cliniques mobiles de santé pour distribuer et ajuster des appareils auditifs.',
      alt: 'Organisation caritative musulmane pour aider les Muslims Around The World'
    },
    {
      image: giftImg3,
      title: 'Suivi des progrès et évaluation de l’impact pour recueillir des retours.',
      alt: 'Organisation caritative musulmane pour aider les Muslims Around The World'
    },
  ];

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempGiftSoundsProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'HLT-DIS-EAR-2024-01-A') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Islamic Charity: donate to build Masjids',
            })
            setHelpThemHearPrd(inner_item_product);
            tempGiftSoundsProducts[1] = inner_item_product
          }
          if (inner_item_product.creator === 'HLT-DIS-EAR-2024-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestinian refugees',
            })
            tempGiftSoundsProducts[0] = inner_item_product
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempGiftSoundsProducts = tempGiftSoundsProducts.filter(n => n)
    setGiftSoundsProducts(tempGiftSoundsProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
      <Layout className="bg-white" isRamadanPage>
        {seo &&
            <Helmet>
              <title>{`${seo['home']['title']}`}</title>
              <meta name="title" content={`${seo['home']['title']}`}/>
              <meta name="description" content={`${seo['home']['description']}`}/>
            </Helmet>
        }
        {/* /* --------------------------------- Widget --------------------------------- */}

        {/* /* ------------------------------- Our Promise ------------------------------ */}

        {/* /* ------------------------------- Tab ------------------------------ */}
        <GiftSoundProducts giftSoundsProducts={giftSoundsProducts}/>
        {/* /* ------------------------------- Our Promise ------------------------------ */}
        <div className="md:hidden">
          <OurPromiseSection alt={'Muslim Charity with 100% donation policy'}/>
        </div>
        {/* /* -------------------------------- Our senses ------------------------------- */}
        <div
            className="w-full flex flex-wrap justify-center items-center md:container mx-auto py-8 px-4 md:px-0 bg-[#F1F1F1] md:bg-white">
          <p className="md:text-center font-bold text-[#093686] leading-[34px] sm:leading-[55px] text-[30px] sm:text-3xl md:text-[40px] md:px-4 basis-full md:basis-10/12 mb-3">
            Nos sens sont véritablement un cadeau incroyable d'Allah SWT.
            <br/>
            <span className="text-[#F60362]">Aidez à offrir le Cadeau du son à ceux qui en ont besoin.</span>
          </p>
          <p className="text-[#78716C] md:text-[18px] md:text-center mb-3 md:mb-6 lg:mb-12">
            Nous ne troquerions notre capacité d'entendre contre aucune somme d'argent, car elle est inestimable.
            Imaginez ne pas pouvoir entendre les voix de vos proches ou la belle récitation du Coran. L'ouïe est quelque
            chose sans laquelle nous ne pourrions jamais imaginer vivre. L'Organisation Mondiale de la Santé (OMS)
            estime
            que 278 millions de personnes souffrent d'une déficience auditive invalidante, et que 70% d'entre elles
            vivent
            dans des pays à faible revenu. Pour ces personnes, le traitement est difficile à obtenir et coûteux, mais
            avec
            un petit acte de bonté, nous pouvons changer cela.
            <br/>
            <br/>
            Nous avons la capacité de faire en sorte que les personnes vivant dans des communautés démunies, souffrant
            de troubles auditifs, aient l'opportunité de retrouver leur ouïe.
            <br/>
            <br/>
            En restaurant l'audition de quelqu'un, vous offrez une magnifique Sadaqa Jariya. Cette charité continue
            est non seulement un investissement dans votre Akhirah, mais aussi un cadeau inestimable pour quelqu'un
            dans le besoin, lui permettant de marcher indépendamment dans la vie.
          </p>
          <CustomButton onClick={() => addProduct({product: helpThemHearPrd, currency: selectedCurrency})}
                        title='Faire un don maintenant' className='mb-2 sm:mb-0 min-w-[226px]'/>
        </div>


        {/* /* --------------------------------- YouTube -------------------------------- */}
        <section className="xl:px-20 sm:mb-16">
          <div className="md:container md:mx-auto overflow-hidden w-full relative flex flex-wrap">
            <div
                className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[150px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute pt-6 pb-6 md:pb-8"
            >
              <p className="text-[25px] sm:text-[30px] font-brandingMedium leading-[36px] mt-3- px-10 text-white text-center italic">
                Le plus petit geste de bonté peut avoir le plus grand impact.
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]"/>
            <div
                className=" basis-full max-[380px]:min-h-[300px] min-[381px]:min-h-[300px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1020px]">
              <iframe
                  className="video pointer-events-none"
                  width={'100%'}
                  height={'100%'}
                  title={'youtube'}
                  frameBorder={0}
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://www.youtube.com/embed/pgNqtKfd8sA?controls=0&autoplay=1&loop=1&mute=1&playlist=pgNqtKfd8sA`}
              ></iframe>
            </div>
          </div>
        </section>
        {/* /* ------------------------------- Gift Sound Project ----------------------- */}
        <section className="sm:mt-20 py-6 md:py-8 px-4 md:px-0 bg-[#DFEEF2]">
          <div className="md:container md:mx-auto">
            <p className="md:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-[25px] md:text-[40px] md:px-4 basis-full md:basis-10/12 mb-4">
              Le projet <span className="text-[#F60362] ml-1">Cadeau du son</span> de MATW
            </p>
            <p className="md:leading-7 text-[#78716C] md:text-center font-['Montserrat'] text-md lg:text-lg md:px-30 lg:px-32">
              Notre équipe collabore étroitement avec des organisations locales dans les pays où nous intervenons afin
              d'assurer la meilleure qualité de soins de santé et des solutions auditives pour les patients.
            </p>
            <p className="md:text-center font-bold text-[#093686] leading-[20px] sm:leading-[30px] text-[18px] sm:text-[25px] md:px-4 basis-full md:basis-10/12 mt-5 md:my-3">
              Les phases de notre projet incluent :
            </p>
            <div className='grid grid-cols-3 gap-2 md:gap-4 xl:gap-6 md:gap-y-10 mt-8'>
              {giftSounds.map((item, index) => (
                  <div key={index} className='col-span-3 sm:col-span-1 mb-3 md:mb-0'>
                    <div className={`max-h-[300px] overflow-hidden rounded-lg`}>
                      <img alt={item.alt ? item.alt : "matw"} src={item.image} className='rounded-lg'/>
                    </div>
                    <p className="text-[#253A7E] text-[18px] mt-2 text-center font-bold">{item.title}</p>
                  </div>
              ))}
            </div>
            <p className="text-center font-bold text-[#093686] leading-[30px] sm:leading-[50px] text-2xl sm:text-3xl md:text-[40px] md:px-4 basis-full md:basis-10/12 my-5">
              Ce travail transformateur ne serait pas possible sans le soutien de nos donateurs.
              <span className="text-[#F60362] mx-1">Aidez-nous à offrir le Cadeau du son</span>
              avec votre Sadaqa Jariya dès aujourd'hui.
            </p>
          </div>
        </section>

        {/* /* --------------------------------- FAQS --------------------------------- */}
        <section className='xl:px-8 px-4 md:container mx-auto py-4 md:py-8'>
          <h4 className='text-[#253B7E] font-bold text-[25px] md:text-[40px] leading-7 md:leading-[50px] md:text-center my-6 md:my-10'>
            Donations pour le Cadeau du son <span className="text-[#F60362] ml-1">FAQs</span>
          </h4>
          <div className="grid grid-cols-3 gap-3 md:gap-6">
            {giftSoundFaqs.map((item, index) => {
              return <div key={`faq_${index}`}
                          className="col-span-3 md:col-span-1 flex flex-col md:px-2 font-bold mb-6 md:mb-0">
                <h4 className='text-[#253B7E] text-[18px] mb-2 md:mb-3'>
                  {item.question}
                </h4>
                <p className="text-[#78716C] text-base font-brandingMedium">{item.answer}</p>
              </div>
            })}
          </div>
        </section>

      </Layout>
  );
};

export default GiftOfSoundLandingPage;