const giftSoundFaqs = [
    {
        id: 0,
        question: 'Comment identifiez-vous les personnes qui bénéficieront de ce projet ?',
        answer: `Nous avons un processus d'identification strict qui est suivi après avoir mené une évaluation approfondie des besoins. Nous travaillons en étroite collaboration avec des organisations et autorités locales qui nous aident à identifier les cas potentiels. Les personnes nécessitant un soutien passent ensuite par le processus de sélection de MATW et peuvent recevoir une aide si elles remplissent les critères.`
    },
    {
        id: 1,
        question: 'Qu’est-ce que le projet "Cadeau du son" ?',
        answer: `De la même manière que nous utilisons votre Sadaqa Jariya pour restaurer la vue dans le cadre du projet Noor, notre nouveau projet "Cadeau du son" vise à accomplir la même chose, mais par le biais du son. Les bénéficiaires recevront des appareils auditifs pour traiter les pertes auditives curables. Non seulement cela restaure la dignité, l'espoir et donne un sentiment d’autonomisation - mais c'est également une magnifique Sadaqa Jariya.`
    },
    {
        id: 2,
        question: 'Ce projet est-il une Sadaqa Jariya ?',
        answer: `Absolument ! Offrir l'utilisation de l'un des sens, que ce soit par la vue ou dans ce cas par l'audition, est une Sadaqa Jariya - en particulier lorsqu'il est utilisé dans la voie d'Allah, pour améliorer la vie de ceux qui en ont besoin.`
    }
];
export { giftSoundFaqs };
