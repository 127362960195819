import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { feastFaqs } from './faqs';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import ZakatWidget from '../zakat/zakatWidget';
import { useKeenSlider } from 'keen-slider/react'
import LittleOften from '../../images/orphans/littleOften.jpeg';
// import SpreadSmiles from '../../images/orphans/spreadSmiles.jpeg';
import services from '../../services';
import OrphanFeast1 from '../../images/orphans/orphanFeast1.jpg'
import OrphanFeast2 from '../../images/orphans/orphanFeast2.jpg'
import OrphanFeast3 from '../../images/orphans/orphanFeast3.jpg'
import OrphanFeast4 from '../../images/orphans/orphanFeast4.jpg'
import OrphanFeast5 from '../../images/orphans/orphanFeast5.jpg'
import { useNavigate } from 'react-router-dom';
import { getProducts } from '../../store/products';
import banner from '../../images/orphans/Orphan-Feast-Generic-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/orphans/Orphan-Feast-Generic-Hero-Banner-Mobile.jpg';
import { OurPromiseSection } from '../../components/general';
import ProductBox from '../../components/general/productBox';
import FundraiserProductBox from '../../components/general/fundraiserProductBox';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
// import useHandleAddProduct from '../../utils/handleAddProduct';

const images = [
    OrphanFeast1,
    OrphanFeast2,
    OrphanFeast3,
    OrphanFeast4,
    OrphanFeast5,
];

const OrphansFeastUat = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [orphansProducts, setOrphansProducts] = useState([0, 0, 0]);
    const products = useSelector((state) => getProducts(state, 'Orphans', 'Orphan Feast'));
    // const allProducts = useSelector(state => selectProducts(state));
    const supportProducts = useSelector((state) => getProducts(state, 'Orphans', 'Support An Orphan'));
    // const ramadanProducts = useSelector((state) => getProducts(state, 'Ramadan', 'General'));
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const [futureProducts, setFutureProducts] = useState();
    // const [eidGiftProduct, setEidGiftProduct] = useState({});
    const seo = SeoData[selectedcountryCode];
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        loop: true,
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    },
        [
            slider => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 2000);
                }
                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on('dragStarted', clearNextTimeout);
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]

    )
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */

    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        let tempFutureProducts = [];
        let product = products.subCategory.products;

        product?.forEach(item => {
            let i = { ...item }
            // if (i.name === 'Orphan Feast For 25') {
            //     i.description = 'Change the lives of 25 orphans by sponsoring a hearty feast (Wallima).'
            // }

            // if (i.name === 'Orphan Feast For 150') {
            //     i.description = 'Transform the lives of 150 orphans with your generous sponsorship of a nourishing feast (Wallima). Your kindness brings warmth and hope.'
            // }
            // if (i.name === 'Feed 1 Orphan For 30 Days') {
            //     i.description = `Give one orphan in Africa, Lebanon, or Bangladesh a month's worth of meals (up to 60 meals). Your support makes a huge difference in their lives.`
            // }
            // if (i.name === 'Feed 5 Orphans For 30 Days') {
            //     i.description = `Support five orphans in Africa, Lebanon, or Bangladesh for a month with up to 60 meals. Your help brings comfort and sustenance to their lives.`
            // }
            if (i.creator === 'FST-DEL-F150-2023-01-010') tempProductsList[0] = i;
            if (i.creator === 'FST-DEL-F50-2023-01-009') tempProductsList[1] = i;
            if (i.creator === 'FST-DEL-F25-2023-01-008') tempProductsList[2] = i;
            // if (tempProductsList.length < 3) {
            //     tempProductsList.push(i)
            // }
        })

        supportProducts?.subCategory?.products?.forEach(i => {
            let item = { ...i };
            if (item.name === 'Nurturing Futures') {
                item.goal = 200000;
                tempFutureProducts.push(item)
            }
            if (item.name === 'HealthGuard Orphans') {
                item.goal = 200000;
                tempFutureProducts.push(item)

            }
            if (item.name === 'Orphan Centre Lebanon') {
                item.goal = 500000;
                tempFutureProducts.push(item)
            }
        });

        // let eidGift = ramadanProducts?.subCategory?.products?.filter(i => i.creator === 'RM24-OP-014');
        // eidGift && setEidGiftProduct(eidGift[0]);
        tempProductsList = tempProductsList.filter(n => n)

        getOrphanProductsReports(tempFutureProducts);
        setOrphansProducts(tempProductsList)
    };
    // const getEidProduct = () => {
    //     setIsLoaded(true);
    //     allProducts.map(item => {
    //         item.sub_categories.map(inner_item => {
    //             inner_item.products.map(inner_item_product => {
    //                 if (inner_item_product.creator === 'RM24-OP-014') setEidGiftProduct(inner_item_product)
    //                 return inner_item_product;
    //             });
    //             return inner_item;
    //         });
    //         return item;
    //     });
    //     // let eidGift = ramadanProducts?.subCategory?.products?.filter(i => i.creator === 'RM24-OP-014');
    //     // eidGift && setEidGiftProduct(eidGift[0]);
    // };
    const getOrphanProductsReports = async (tempFutureProducts) => {
        try {
            const response = await services.orphanProductTransactions();
            response.data.length && response.data.map((item, i) => {
                tempFutureProducts.map(future_item => {
                    if (future_item.name === item.product_name) {
                        future_item.raised = item.total_sales
                    }
                    return future_item
                })
                return null;
            })
            // Object.keys(response.data.data).length && Object.keys(response.data.data).map((keyName, i) => {
            //     tempFutureProducts.map(future_item => {
            //         if (future_item.name === keyName) {
            //             future_item.raised = response.data.data[keyName]
            //         }
            //         return future_item
            //     })
            //     return null;
            // })
            setFutureProducts(tempFutureProducts)

        } catch (error) {
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    // useEffect(() => {
    //     allProducts && Object.keys(allProducts).length && !isLoaded && getEidProduct();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [allProducts])
    /* -------------------------------------------------------------------------- */
    return (
        <Layout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['orphans']['orphanFeast']['title']}`}</title>
                    <meta name="title" content={`${seo['orphans']['orphanFeast']['title']}`} />
                    <meta name="description" content={`${seo['orphans']['orphanFeast']['description']}`} />
                </Helmet>
            }
            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                <img src={banner} alt="banner" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="banner" className='min-[501px]:hidden w-full' />
            </section>
            <section className='bg-[#DFEEF2]'>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Donation</div>
                    <div className='w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <CrisisWidget className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                            defaultProduct={{
                                creator: "GD-MP015",
                                label: "Orphans Around The World",
                                value: 105
                            }}
                        />
                    </div>
                    <div className='w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center'>
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                            creator: "GD-MP015",
                            label: "Orphans Around The World",
                            value: 105
                        }} />
                    </div>
                </section>
            </section>
            <OurPromiseSection />
            <main className='max-w-[1440px] mx-auto'>
                <section className='xl:px-20 mt-8 mb-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px]  w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-brandingBold'>The Prophet PBUH said:</div>
                            <p className='text-[18px] sm:text-[24px] italic font-brandingMedium leading-6 mt-3 px-10 text-white text-center'>
                                “Whoever takes in an orphan among the Muslims to raise, to
                                feed him and give him drink, Allah admits him into Paradise without a doubt”
                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1000px]">
                            <iframe className='video pointer-events-none'
                                width={'100%'}
                                height={'100%'}
                                title={'youtube'}
                                frameBorder={0}
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://www.youtube.com/embed/nXsa4Q71HOM?controls=0&autoplay=1&loop=1&mute=1&playlist=nXsa4Q71HOM`}
                            >
                            </iframe>
                        </div>

                    </div>
                </section>
                <section className='xl:px-10'>
                    <div className='flex justify-center'>
                        <div className='text-left sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12'>
                            Help us feed as many orphans as possible  and
                            <span className='text-[#F60362] mx-2'>
                                gain the rewards
                            </span>
                            of caring for an orphan.
                            {/* <span className='text-[#F60362] ml-2'>
                                Holy month.
                            </span> */}
                        </div>
                    </div>
                    <div className='px-4 md:px-6 mt-2 sm:mt-10  font- leading-5'>
                        <div className='text-[#78716C] lg:px-20 leading-6 text-[18px] text-left  font-brandingMedium'>
                            Not knowing where your next meal will come from is scary, especially for an orphaned children with no one
                            to turn to and no place to go. If you’re a vulnerable child in a natural disaster or conflict zone, this
                            fear is accompanied by the struggle for safety and survival.
                            <br /><br />
                            From Togo to Bangladesh, Lebanon to Palestine, our MATW orphan feeding programme is among the most
                            expansive here at MATW. Every day, our global team in the field are hard at work, preparing thousands
                            of meals to help feed orphaned children.
                            <br /><br />
                            In our centres in Lebanon, Togo and Bangladesh the meals are prepared in our MATW kitchens and
                            distributed to the children.
                        </div>


                        <div className="col-span-12 rounded-md  my-4">
                            <div className="flex flex-row justify-center flex-wrap">
                                {orphansProducts.map((item, index) => {
                                    return <div key={`feast${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                        <ProductBox product={item} isStaticProduct={item.isStaticProduct} staticLink={item.staticLink} staticLinkText={item.staticLinkText} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                    </div>
                                })
                                }
                            </div>
                        </div>

                    </div>
                </section>

                <section className='xl:px-10 mt-12'>
                    <div className='flex justify-center mb-6'>
                        <div className='text-left sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12'>
                            Build a
                            <span className='text-[#F60362] mx-2'>
                                brighter future
                            </span>
                            for them.

                        </div>
                    </div>
                    <div className='px-4 md:px-6 mt-2 sm:mt-10  font- leading-5'>
                        <div className='text-[#78716C] lg:px-20 leading-6 text-[18px] text-left  font-brandingMedium'>
                            Help us raise funds to provide orphans with essential needs, education,
                            and a chance at a better future. Every contribution counts!

                        </div>


                        <div className="col-span-12 rounded-md  my-4">
                            <div className="flex flex-row justify-center flex-wrap">
                                {futureProducts?.map((item, index) => {
                                    return <div key={`fundrising${index}`} className="basis-full sm:basis-1/2 md:basis-1/3 2xl:basis-1/4 p-1 sm:p-2 overflow-hidden">
                                        <FundraiserProductBox goal={item.goal} subTitle='Orphan Education' product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                    </div>
                                })
                                }
                            </div>
                        </div>

                    </div>
                </section>






                <section className='px-4 xl:px-20 bg-white'>

                    <div className='flex gap-x-10 flex-wrap sm:flex-nowrap mt-8'>
                        <div className="basis-full sm:basis-1/2"><img src={LittleOften} alt="LittleOften" /></div>
                        <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                            <h1 className='text-[#253B7E] text-[24px] sm:text-[45px] sm:leading-[55px] font-brandingBold'>A little often can change lives forever.</h1>


                            <div className='text-[16px] sm:text-[18px] text-left sm:text-center text-[#78716C] leading[19px] sm:leading-6 font-brandingMedium my-4'>
                                By subscribing and giving any amount monthly in support of our Orphan projects, you help us provide regular crucial care and support.
                                You enable us to cater to the basic needs of the children within our care and the children we reach in emergencies.
                                <br />
                                <br />
                                Your monthly donation is life-saving and your regular gift is an investment in their future and your Akhirah.
                            </div>
                            <button onClick={() => navigate(generateLinkWithLang('/subscription', lang))}
                                className='bg-[#F60362] rounded-xl text-white w-[190px] h-10 text-[18px]  font-bold'>Give Monthly</button>
                        </div>
                    </div>



                </section>



                {/* /* --------------------------------- Images --------------------------------- */}
                <section className='bg-[#F8F8F8] mt-10 sm:mt-20 py-10'>
                    <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">

                        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {

                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                        {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                        <img src={item} alt={`carousel_images${index}`} className=' bg-top object-cover w-full object-top' />
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="w-full  flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 pb-6">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {/* /* -------------------------------------------------------------------------- */}

                {/* -------------------------------------FAQS--------------------------------- */}
                <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h1 className='text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                        Feed an orphan donations <span className='text-[#F60362]'> FAQ’s</span>
                    </h1>
                    <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{feastFaqs[0].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {feastFaqs[0].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{feastFaqs[1].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {feastFaqs[1].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{feastFaqs[2].question}</h1>
                            <p className='text-base font-brandingMedium leading-4 mt-3'>
                                {feastFaqs[2].answer}
                            </p>
                        </div>
                    </div>
                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {feastFaqs.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default OrphansFeastUat;
