import { useEffect, useState } from 'react';
import { emergencyFaqs } from './faqs';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import services from '../../services';
import Layout from '../../components/general/layout';
import Accordionn from '../../components/general/accordion';
import SadaqahWidget from './sadaqahWidget';
// import ChangeDocumentTitle from '../../utils/changeDocumentTitle';
import ProductBox from '../../components/general/productBox';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';

const EmergencyLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [products, setProducts] = useState({
        name: '',
        products: [0, 0]
    });
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getProductsByName = async (name) => {
        try {
            const response = await services.categoryProductsByName(name);
            let tempProducts = [];
            let tempAllProducts = response.data;
            response.data.products.map(item => {
                if (Number(item.status) === 1) {
                    item.raised = 0;
                    if (item.name === 'Emergency Home Rebuild') tempProducts.push(item);
                    if (item.name === 'Emergency Caravan Homes') tempProducts.push(item);
                    if (item.name === 'Emergency Caravan Long Term Shelter') tempProducts.push(item);
                }
                return item;
            });
            tempAllProducts.products = tempProducts;
            setProducts(tempAllProducts);
            // ChangeDocumentTitle(`${response.data.name} - ${response.data.category ? response.data.category.name : ''} - MATW Project Muslim Charity`);
        } catch (error) {
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // window.scroll(0, 0);
        getProductsByName('caravan');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='!bg-white'>
            {seo &&
                <Helmet>
                    <title>{`${seo['sadaqahJariyah']['emergency']['title']}`}</title>
                    <meta name="title" content={`${seo['sadaqahJariyah']['emergency']['title']}`} />
                </Helmet>
            }
            <div className="w-full flex flex-wrap max-[380px]:min-h-[135px] min-[381px]:min-h-[165px] min-[501px]:min-h-[205px] sm:min-h-[260px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0">
                <div className="w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                    <iframe className='video'
                        width={'100%'}
                        height={'100%'}
                        title={'youtube'}
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/embed/XRziciz2sZA?autoplay=0`}
                    >
                    </iframe>
                </div>
            </div>

            {/* /* ------------------------------- Widget ----------------------------------- */}
            <div className='w-full lg:container lg:mx-auto px-4 xl:px-2 py-4 md:py-6 flex justify-center items-center'>
                <SadaqahWidget
                    defaultProduct={{
                        creator: "EM-SDQ-EQ1-02-2023",
                        label: "Emergency Caravan Long Term Shelter",
                        value: 326
                    }}
                />
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section One - Texts ---------------------------------- */}
            <div className="w-full flex flex-wrap justify-center md:container md:mx-auto px-4 py-4 md:py-8">
                <div className="basis-full flex flex-col justify-center mb-8 md:mb-16">
                    <div className="w-full md:container flex justify-center">
                        <div className="flex items-start mr-2 md:mr-4 w-[48px]">
                            <svg className='rotate-180' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 186.15">
                                <path d="M242.5,87.53c-1.52,7.1-2.55,14.35-4.63,21.27-10.7,35.68-35.15,57.79-69.71,69.59-5,1.71-10.16,2.82-15.56,4.28l-9.89-21.3c24.63-11.76,37.24-31.54,40.19-58.79H142.83V2.5H242.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                                <path d="M2.5,2.5h99.14c.39.75.67,1,.66,1.31-.22,28.16,1,56.43-1,84.46-3,41-25.34,69.46-62.62,86.09C30.52,178,21.75,180.14,13,183.08L2.5,161.56v-1a16.11,16.11,0,0,0,2.8-.76c18.55-8.7,29.5-23.71,35.12-42.94,1.31-4.48,1.63-9.25,2.48-14.32H2.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                            </svg>
                        </div>
                        <div className="flex w-[80%] md:w-[60%] xl:w-[45%]">
                            <p className="text-center text-lg md:text-4xl text-[#00a3da]">
                                <span className="font-['Gotcha'] font-medium text-[#253b7e] mx-2 md:mx-4">
                                    Sadaqah Jariyah
                                </span>
                                is a way to invest in the future of humanity and secure the rewards of the hereafter.
                                <span className="font-['Abeezee'] text-[#253b7e] mx-2 md:mx-4 text-base md:text-lg">
                                    ~ Yasir Qadhi
                                </span>
                            </p>
                        </div>
                        <div className="flex items-end ml-2 md:ml-4 w-12">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 186.15">
                                <path d="M242.5,87.53c-1.52,7.1-2.55,14.35-4.63,21.27-10.7,35.68-35.15,57.79-69.71,69.59-5,1.71-10.16,2.82-15.56,4.28l-9.89-21.3c24.63-11.76,37.24-31.54,40.19-58.79H142.83V2.5H242.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                                <path d="M2.5,2.5h99.14c.39.75.67,1,.66,1.31-.22,28.16,1,56.43-1,84.46-3,41-25.34,69.46-62.62,86.09C30.52,178,21.75,180.14,13,183.08L2.5,161.56v-1a16.11,16.11,0,0,0,2.8-.76c18.55-8.7,29.5-23.71,35.12-42.94,1.31-4.48,1.63-9.25,2.48-14.32H2.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section Two - Second Widget -------------------------- */}
            <div className="w-full pt-4 md:pt-48 flex flex-wrap bg-[url('../public/images/landings/sadaqah-jariyah/hero-emergency.jpg')] bg-center sm:bg-center bg-cover max-[500px]:min-h-[50px] min-[501px]:min-h-[80px] md:min-h-[320px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0">
                <div className="w-full flex flex-wrap justify-center md:container md:mx-auto">
                    <div className="flex flex-col basis-full items-center text-center bg-[#f60362] text-white mt-12 p-4 md:p-8">
                        <h3 className="font-['Gotcha'] text-2xl md:text-4xl mb-2">
                            Emergency
                        </h3>
                        <h1 className="text-2xl md:text-5xl text-center font-medium mb-2 md:mb-4">
                            Home Rebuild
                        </h1>
                        <p className='w-full md:w-2/3 text-center my-3 md:my-6 text-lg md:text-2xl'>
                            MATW is committed to providing a lifeline to individuals and families impacted by emergencies,
                            such as natural disasters and unforeseen crises. We raise funds and collaborate with local communities to rebuild or renovate homes,
                            offering a stable and secure environment for those affected. By supporting our cause,
                            you directly contribute to restoring hope and normalcy in the lives of these resilient survivors.
                        </p>
                    </div>
                    <div className="basis-full flex justify-center bg-white p-4 md:p-8">
                        <SadaqahWidget
                            defaultProduct={{
                                creator: "EM-SDQ-EQ1-02-2023",
                                label: "Emergency Caravan Long Term Shelter",
                                value: 326
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section Two - Products ------------------------------- */}
            <div className="w-full px-4 py-8 md:pb-16 md:pt-32 bg-[#e2f0f3]">
                <div className="w-full flex flex-wrap justify-center md:container md:mx-auto">
                    <div className="flex w-full justify-center">
                        <h1 className="text-2xl md:text-5xl w-full md:w-9/12 text-[#253B7E] text-center font-medium mb-2 md:mb-4">
                            According to research, natural disasters affect
                            <strong className='text-[#f60362]'>218 million</strong> people and claim
                            <strong className='text-[#f60362]'>68,000</strong> lives per year.
                        </h1>
                    </div>
                    {products.products.map((item, index) => {
                        return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                            <ProductBox product={item} currency={selectedCurrency} />
                        </div>
                    })
                    }
                    <div className="basis-full flex justify-center">
                        <p className='w-full md:w-2/3 text-center my-3 md:my-6 text-lg md:text-xl'>
                            Life is unpredictable. What we know and own today may change in just a few seconds. Our homes may be destroyed, or our loved ones may be lost.
                            You can help these people began the rebuilding of their lives with your one donation today,
                            but your sadaqah jariyah will be a part of their lives forever, and you will reap ever-growing rewards for it.
                        </p>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
            <div className='w-full h-auto flex flex-col justify-center items-center py-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4 text-[#253B7E]">Emergency donations <strong className='text-[#f60362]'>FAQs</strong></h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-2 md:px-0 mt-6">
                    {emergencyFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordionn key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordionn>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

        </Layout>
    );
}

export default EmergencyLandingPage;
